import { put, select, takeLatest } from 'typed-redux-saga';
import { wizardRouter } from 'utils/wizardRouter';
import {
    actionWithPromise,
    CategoryKeys,
    ClaimLocationKeys,
    InsuranceTypeKeys,
    InsuredByProtectorKeys,
    LightOfDayKeys,
    MotorClaimCauseKeys,
    NeedVehicleTowingKeys,
    Rejectable,
    Resolvable,
    RoadConditionKeys,
    RoundaboutPositionKeys,
    TypeOfAnimalCollisionKeys,
    TypeOfAutoClaimKeys,
    TypeOfDoctorKeys,
    UnidentifiedTypeKeys,
    WhoAtFaultKeys,
    WhoPaidExpensesKeys,
    WhoReportKeys,
    WizardRouterModel,
    YesNoKeys,
} from '@protectorinsurance/ds-can';
import { LocationChangeAction } from 'connected-react-router';
import { BaseRoutePaths } from '../config/wizardRouter/baseWizardRoutes';
import {
    selectWizardRouterCurrent,
    selectWizardRouterPrevious,
    selectWizardRouterSkipBackToPrev,
} from './selectors/wizardRouterSelectors';

/**
 * Constants
 */
export enum WizardRouterActionTypes {
    GO_TO = '@wizardRouter/GO_TO',
    GO_TO_NEXT = '@wizardRouter/GO_TO_NEXT',
    BACK_TO_PREV = '@wizardRouter/BACK_TO_PREV',
    SKIP_BACK_TO_PREV = '@wizardRouter/SKIP_BACK_TO_PREV',
    UPDATE = '@wizardRouter/UPDATE',
    LOCATION_CHANGE = '@@router/LOCATION_CHANGE',
}

/**
 * Interfaces
 */
export type WizardRouterType = WizardRouterAction<string | undefined | null>;
export type WizardRouterState = WizardRouterModel;
export interface WizardRouterAction<D = WizardRouterState> {
    type: WizardRouterActionTypes;
    data: Partial<D>;
    resolve?: Resolvable;
    reject?: Rejectable;
}
export type RouterKeys =
    | TypeOfAutoClaimKeys
    | YesNoKeys
    | MotorClaimCauseKeys
    | InsuredByProtectorKeys
    | NeedVehicleTowingKeys
    | TypeOfAnimalCollisionKeys
    | WhoAtFaultKeys
    | ClaimLocationKeys
    | RoundaboutPositionKeys
    | WhoReportKeys
    | UnidentifiedTypeKeys
    | WhoPaidExpensesKeys
    | TypeOfDoctorKeys
    | CategoryKeys
    | InsuranceTypeKeys
    | RoadConditionKeys
    | LightOfDayKeys;

/**
 * Initial state
 */
export const wizardRouterInitState: WizardRouterModel = {
    current: '',
    previous: '',
    backToPrev: false,
    skipBackToPrev: false,
    listener: null,
};

/**
 * Default reducer
 *
 * @param state
 * @param action
 */
export default function (state = wizardRouterInitState, { type, data }: WizardRouterAction) {
    switch (type) {
        case WizardRouterActionTypes.UPDATE:
            return { ...state, ...data };
        default:
            return state;
    }
}

/**
 * Redux Actions
 */
export const wizardRouterActions = {
    goTo: actionWithPromise<WizardRouterActionTypes, string | BaseRoutePaths>(WizardRouterActionTypes.GO_TO),
    goToNext: actionWithPromise<WizardRouterActionTypes, string | RouterKeys | BaseRoutePaths>(
        WizardRouterActionTypes.GO_TO_NEXT
    ),
    update: actionWithPromise<WizardRouterActionTypes, Partial<WizardRouterModel>>(WizardRouterActionTypes.UPDATE),
    backToPrev: actionWithPromise<WizardRouterActionTypes, boolean>(WizardRouterActionTypes.BACK_TO_PREV),
    skipBackToPrev: actionWithPromise<WizardRouterActionTypes, boolean>(WizardRouterActionTypes.SKIP_BACK_TO_PREV),
};

/**
 * Saga watchers
 */
export const wizardRouterWatcher = function* () {
    yield* takeLatest(WizardRouterActionTypes.GO_TO, wizardRouterSagas.goTo);
    yield* takeLatest(WizardRouterActionTypes.GO_TO_NEXT, wizardRouterSagas.goToNext);
    yield* takeLatest(WizardRouterActionTypes.BACK_TO_PREV, wizardRouterSagas.backToPrev);
    yield* takeLatest(WizardRouterActionTypes.SKIP_BACK_TO_PREV, wizardRouterSagas.skipBackToPrev);
    yield* takeLatest(WizardRouterActionTypes.LOCATION_CHANGE, wizardRouterSagas.onLocationChange);
};

/**
 * Saga functions
 */
export const wizardRouterSagas = {
    *goTo({ data }: WizardRouterAction<BaseRoutePaths>) {
        yield wizardRouter.goTo(data);
    },

    *goToNext({ data }: WizardRouterAction<BaseRoutePaths>): any {
        return yield wizardRouter.gotoNext(data);
    },

    *backToPrev({ data }: WizardRouterAction<boolean>) {
        yield* put(wizardRouterActions.update({ backToPrev: data }));
    },

    *skipBackToPrev({ data }: WizardRouterAction<boolean>) {
        yield* put(wizardRouterActions.update({ skipBackToPrev: data }));
    },

    *onLocationChange({ payload: { location } }: LocationChangeAction) {
        const skipBackToPrev = yield* select(selectWizardRouterSkipBackToPrev);
        const current = location.pathname;
        const previous = skipBackToPrev
            ? yield* select(selectWizardRouterPrevious)
            : yield* select(selectWizardRouterCurrent);

        yield* put(wizardRouterActions.update({ previous, current }));
    },
};
