import React, { MouseEvent, SyntheticEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { wizardRouterActions as wizardActions } from 'sagas/wizardRouter';
import { useI18n } from '../../../hooks/useI18n';
import {
    AddIcon,
    CircleIconButton,
    FormChangeable,
    getRelationToWitnessOptions,
    Grid,
    HiddenInputSubmit,
    MuiAutocomplete,
    MuiTextInput,
    OptionType,
    PageLayout,
    PersonList,
    RelationToWitnessKeys,
    WitnessModel,
} from '@protectorinsurance/ds-can';
import { useGoBack } from '../../../hooks/useGoBack';
import { PhraseKeys } from '../../../config/phraseKeys';
import { FormFieldErrors, FormFieldNames } from '../../../config/formFieldNames';
import { selectWitnesses } from '../../../sagas/selectors/motorSelectors';
import { Controller, useForm } from 'react-hook-form';
import { witnessesSchema } from '../../../validations/schemas/witnessesSchema';
import { personExists, removeWitness } from '../../../utils/personUtils';
import { motorActions } from '../../../sagas/motor';
import { AutocompleteValueKeys } from '../../../config/AutocompleteValueKeys';
import { crossContactInfoFieldValidation } from '../../../utils/validation/crossContactInfoFieldValidation';
import { yupResolver } from '@hookform/resolvers/yup';
import dispatcherWithPromise from '../../../utils/dispatcherWithPromise';
import { commonActions } from '../../../sagas/common';
import { selectCustomCAN } from '../../../sagas/selectors/commonSelectors';

/**
 * Destructure necessary imports
 */
const {
    ADD_BUTTON,
    BACK_BUTTON,
    CITY_LABEL,
    CITY_PLACEHOLDER,
    CONTINUE_BUTTON,
    DELETE_BUTTON,
    EMAIL_LABEL,
    EMAIL_PLACEHOLDER,
    FIRST_NAME_LABEL,
    FIRST_NAME_PLACEHOLDER,
    HELP_TEXT,
    LAST_NAME_LABEL,
    LAST_NAME_PLACEHOLDER,
    NO_OPTIONS_MESSAGE,
    PAGE_NAME,
    PHONE_LABEL,
    PHONE_PLACEHOLDER,
    RELATION_TO_WITNESS_LABEL,
    RELATION_TO_WITNESS_PLACEHOLDER,
    SUB_TITLE,
    STREET_LABEL,
    STREET_PLACEHOLDER,
    TITLE,
    ZIP_LABEL,
    ZIP_PLACEHOLDER,
} = PhraseKeys;
const { CITY, EMAIL, FIRST_NAME, LAST_NAME, PHONE, RELATION_TO_WITNESS, STREET, ZIP } = FormFieldNames;
const { PERSON_EXISTS } = FormFieldErrors;
const {
    CITY: CITY_AUTOCOMPLETE,
    EMAIL: EMAIL_AUTOCOMPLETE,
    FAMILY_NAME,
    GIVEN_NAME,
    POSTAL_CODE,
    STREET_ADDRESS,
    TEL,
} = AutocompleteValueKeys;

/**
 * Page view and page logic
 */
export const WitnessInformationPage = () => {
    const dispatch = useDispatch();
    const witnesses = useSelector(selectWitnesses);
    const customCAN = useSelector(selectCustomCAN);
    const [canGoNext, setCanGoNext] = useState<boolean>(false);
    const [relationToWitness, setRelationToWitness] = useState<OptionType | null>(null);
    const { t } = useI18n();
    const tWithNS = useI18n('motor.end.witnessInformation');
    const options = getRelationToWitnessOptions(t);
    const {
        control,
        formState: { errors },
        handleSubmit,
        register,
        reset,
        setError,
        setValue,
        trigger,
        watch,
    } = useForm<WitnessModel>({
        resolver: yupResolver(witnessesSchema(t)),
    });
    const cityField = watch(CITY);
    const emailField = watch(EMAIL);
    const firstNameField = watch(FIRST_NAME);
    const lastNameField = watch(LAST_NAME);
    const phoneField = watch(PHONE);
    const relationToWitnessField = watch(RELATION_TO_WITNESS);
    const streetField = watch(STREET);
    const zipField = watch(ZIP);
    const fieldValues = [
        firstNameField,
        lastNameField,
        relationToWitnessField,
        emailField,
        phoneField,
        streetField,
        zipField,
        cityField,
    ];
    const isFormEmpty = fieldValues.filter(Boolean).length === 0;

    useEffect(() => {
        setCanGoNext(witnesses.length > 0 && isFormEmpty);
    }, [isFormEmpty, witnesses]);

    useEffect(() => {
        register(RELATION_TO_WITNESS);
    }, [register]);

    const handleBackButton = useGoBack();

    const handleChange = async (e: FormChangeable) => {
        e.preventDefault();
        const { id, value } = e.currentTarget;
        await setValue(id, value, { shouldValidate: true });
        await crossContactInfoFieldValidation(id, trigger);
    };

    const handleBlur = async (e: FormChangeable) => {
        e.preventDefault();
        const { id } = e.currentTarget;
        await trigger(id);
    };

    const handleDelete = (e: MouseEvent, witness: WitnessModel) => {
        e.preventDefault();
        const updatedWitnesses = removeWitness(witness, witnesses);
        dispatch(motorActions.update({ witnesses: updatedWitnesses }));
    };

    const handleSelect = async (e: SyntheticEvent, option: OptionType) => {
        const selected = option ? option : null;
        if (selected) {
            await setValue('relationToWitness', selected.value as RelationToWitnessKeys, { shouldValidate: true });
        }
        setRelationToWitness(selected);
    };

    const handleNext = () => {
        dispatcherWithPromise(dispatch, commonActions.send).then(() => dispatch(wizardActions.goToNext()));
    };

    const handleFormSubmit = (values: WitnessModel): boolean => {
        // Check if witness exists
        if (personExists(values, witnesses)) {
            setError(FIRST_NAME, { type: 'exists', message: t(PERSON_EXISTS) });
            return false;
        } else {
            dispatch(motorActions.update({ witnesses: [...witnesses, values] }));
            reset({
                city: '',
                email: '',
                firstName: '',
                lastName: '',
                phone: '',
                relationToWitness: undefined,
                street: '',
                zip: '',
            });
            return true;
        }
    };

    const onSubmit = (values: WitnessModel) => {
        handleFormSubmit(values);
    };

    const onSubmitFormNext = (values: WitnessModel) => {
        if (handleFormSubmit(values)) {
            handleNext();
        }
    };

    return (
        <PageLayout
            backBtnText={t(BACK_BUTTON)}
            continueBtnText={t(CONTINUE_BUTTON)}
            domainTitle={t(PAGE_NAME)}
            footerText={tWithNS.t(HELP_TEXT)}
            handleContinueButton={canGoNext ? handleNext : handleSubmit(onSubmitFormNext)}
            headerSubTitle={tWithNS.t(SUB_TITLE)}
            headerTitle={tWithNS.t(TITLE)}
            {...{ handleBackButton }}
        >
            <form onSubmit={handleSubmit(onSubmit)}>
                <HiddenInputSubmit />
                <Grid className={'align-center'}>
                    <Controller
                        control={control}
                        name={FIRST_NAME}
                        render={({ field: { ref, ...field } }) => (
                            <MuiTextInput
                                {...field}
                                autoComplete={GIVEN_NAME}
                                error={!!errors.firstName}
                                errorMessage={errors.firstName?.message}
                                id={FIRST_NAME}
                                inputFieldWrapper={'col-6'}
                                label={t(FIRST_NAME_LABEL)}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                placeholder={t(FIRST_NAME_PLACEHOLDER)}
                                reference={ref}
                                {...{ customCAN }}
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name={LAST_NAME}
                        render={({ field: { ref, ...field } }) => (
                            <MuiTextInput
                                {...field}
                                autoComplete={FAMILY_NAME}
                                error={!!errors.lastName}
                                errorMessage={errors.lastName?.message}
                                id={LAST_NAME}
                                inputFieldWrapper={'col-6'}
                                label={t(LAST_NAME_LABEL)}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                placeholder={t(LAST_NAME_PLACEHOLDER)}
                                reference={ref}
                                {...{ customCAN }}
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name={EMAIL}
                        render={({ field: { ref, ...field } }) => (
                            <MuiTextInput
                                {...field}
                                autoComplete={EMAIL_AUTOCOMPLETE}
                                error={!!errors.email}
                                errorMessage={errors.email?.message}
                                id={EMAIL}
                                inputFieldWrapper={'col-4'}
                                label={t(EMAIL_LABEL)}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                placeholder={t(EMAIL_PLACEHOLDER)}
                                reference={ref}
                                type={'email'}
                                {...{ customCAN }}
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name={PHONE}
                        render={({ field: { ref, ...field } }) => (
                            <MuiTextInput
                                {...field}
                                autoComplete={TEL}
                                error={!!errors.phone}
                                errorMessage={errors.phone?.message}
                                id={PHONE}
                                inputFieldWrapper={'col-4'}
                                label={t(PHONE_LABEL)}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                placeholder={t(PHONE_PLACEHOLDER)}
                                reference={ref}
                                {...{ customCAN }}
                            />
                        )}
                    />
                    <MuiAutocomplete
                        error={!!errors.relationToWitness}
                        errorMessage={errors.relationToWitness?.message}
                        id={RELATION_TO_WITNESS}
                        inputFieldWrapper={'col-4'}
                        label={t(RELATION_TO_WITNESS_LABEL)}
                        noOptionsText={t(NO_OPTIONS_MESSAGE)}
                        onBlur={handleBlur}
                        onChange={handleSelect}
                        openOnFocus={true}
                        placeholder={t(RELATION_TO_WITNESS_PLACEHOLDER)}
                        value={relationToWitness}
                        {...{ customCAN, options }}
                    />
                    <Controller
                        control={control}
                        name={STREET}
                        render={({ field: { ref, ...field } }) => (
                            <MuiTextInput
                                {...field}
                                autoComplete={STREET_ADDRESS}
                                error={!!errors.street}
                                errorMessage={errors.street?.message}
                                id={STREET}
                                inputFieldWrapper={'col-4'}
                                label={t(STREET_LABEL)}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                placeholder={t(STREET_PLACEHOLDER)}
                                reference={ref}
                                {...{ customCAN }}
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name={ZIP}
                        render={({ field: { ref, ...field } }) => (
                            <MuiTextInput
                                {...field}
                                autoComplete={POSTAL_CODE}
                                error={!!errors.zip}
                                errorMessage={errors.zip?.message}
                                id={ZIP}
                                inputFieldWrapper={'col-4'}
                                label={t(ZIP_LABEL)}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                placeholder={t(ZIP_PLACEHOLDER)}
                                reference={ref}
                                {...{ customCAN }}
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name={CITY}
                        render={({ field: { ref, ...field } }) => (
                            <MuiTextInput
                                {...field}
                                autoComplete={CITY_AUTOCOMPLETE}
                                error={!!errors.city}
                                errorMessage={errors.city?.message}
                                id={CITY}
                                inputFieldWrapper={'col-4'}
                                label={t(CITY_LABEL)}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                placeholder={t(CITY_PLACEHOLDER)}
                                reference={ref}
                                {...{ customCAN }}
                            />
                        )}
                    />
                    <CircleIconButton
                        ariaLabel={t(ADD_BUTTON)}
                        className={'flex flex-col single-4-center'}
                        dataTestId={'btn-add-injured-person'}
                        handleClick={handleSubmit(onSubmit)}
                        icon={<AddIcon />}
                    />
                </Grid>
            </form>

            <PersonList
                persons={witnesses}
                handleDelete={handleDelete}
                buttonText={t(DELETE_BUTTON)}
                {...{ customCAN }}
            />
        </PageLayout>
    );
};
