import * as Yup from 'yup';
import { TFunction } from 'i18next';
import { PhraseKeys } from '../../config/phraseKeys';

/**
 * Destructure necessary imports
 */
const { TITLE_LABEL } = PhraseKeys;

/**
 * Validation logic
 */
export const titleSchema = (t: TFunction) => {
    return {
        title: Yup.string().label(t(TITLE_LABEL)).noSpecialChars().nullable(),
    };
};
