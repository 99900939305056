import React from 'react';
import {
    CarouselConfigLookupResponse,
    CarouselConfigOptionType,
    Clickable,
    findClaimVehicle,
    InsuredByProtectorKeys,
    NoIcon,
    PartyRelationKeys,
    YesIcon,
} from '@protectorinsurance/ds-can';
import { wizardRouterActions as wizardActions } from '../../../../sagas/wizardRouter';
import { CarouselConfigLookupProps } from '../../../../utils/carouselPageLookup';
import dispatcherWithPromise from '../../../../utils/dispatcherWithPromise';
import { motorActions } from '../../../../sagas/motor';
import { selectVehicles } from '../../../../sagas/selectors/motorSelectors';
import { MotorRoutePaths } from '../../../wizardRouter/motorWizardRoutes';
import { commonActions } from '../../../../sagas/common';

/**
 * Destructure necessary imports
 */
const { YES, NO } = InsuredByProtectorKeys;

/**
 * Page view and page logic
 */
export const insuredByProtectorPage = ({
    t,
    state,
    dispatch,
}: CarouselConfigLookupProps): CarouselConfigLookupResponse<MotorRoutePaths> => {
    const options = [
        { id: YES, name: t(`motor.start.insuredByProtector.select.${YES}`), icon: <YesIcon /> },
        { id: NO, name: t(`motor.start.insuredByProtector.select.${NO}`), icon: <NoIcon /> },
    ];
    const vehicles = selectVehicles(state);
    const claimVehicle = findClaimVehicle(vehicles);

    return {
        slug: MotorRoutePaths.DYN_CAROUSEL_START_INSURED_BY_PROTECTOR,
        stateKey: 'vehicles',
        i18n: {
            ns: 'motor.start.insuredByProtector',
        },
        options,
        selectorValue: null,
        onSelect: (id: CarouselConfigOptionType, e: Clickable) => {
            e.preventDefault();
            const option = options?.find((x) => x.id === id);

            if (option) {
                const isCounterparty = option.id === NO;

                dispatcherWithPromise(dispatch, motorActions.updateClaimVehicle, {
                    ...claimVehicle,
                    insuredByProtector: option.id,
                    ...(isCounterparty && { partyRelation: PartyRelationKeys.COUNTERPARTY_VEHICLE }),
                })
                    .then(() =>
                        dispatcherWithPromise(dispatch, motorActions.update, {
                            ...(isCounterparty && { isCounterparty: true }),
                        })
                    )
                    .then(() => dispatcherWithPromise(dispatch, commonActions.send))
                    .then(() => dispatch(wizardActions.goToNext(option.id)));
            }
        },
        isSelected: (id) => {
            return id === (vehicles[0]?.insuredByProtector ? vehicles[0].insuredByProtector : undefined);
        },
        testInitState: (key) => ({ vehicles: [{ ...claimVehicle, insuredByProtector: key, isClaimVehicle: true }] }),
    };
};
