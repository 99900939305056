import { DoctorInformationModel } from '@protectorinsurance/ds-can';

/**
 * Utility logic
 */
export const formatDoctorInformation = (doctor: DoctorInformationModel) => {
    return `${doctor.name ? doctor.name : ''}${doctor.street ? `, ${doctor.street}` : ''}${
        doctor.zip ? `, ${doctor.zip}` : ''
    }${doctor.city ? ` ${doctor.city}` : ''}`;
};
