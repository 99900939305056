import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { wizardRouterActions as wizardActions } from 'sagas/wizardRouter';
import { useI18n } from '../../../hooks/useI18n';
import { useGoBack } from '../../../hooks/useGoBack';
import { selectExternalReference, selectPolicyHoldersContact } from '../../../sagas/selectors/motorSelectors';
import { Controller, useForm } from 'react-hook-form';
import dispatcherWithPromise from '../../../utils/dispatcherWithPromise';
import { motorActions } from '../../../sagas/motor';
import { commonActions } from '../../../sagas/common';
import {
    FormChangeable,
    Grid,
    HiddenInputSubmit,
    MuiTextInput,
    Nullable,
    PageLayout,
    PolicyHoldersContactModel,
} from '@protectorinsurance/ds-can';
import { FormFieldNames } from '../../../config/formFieldNames';
import { PhraseKeys } from '../../../config/phraseKeys';
import { crossContactInfoFieldValidation } from '../../../utils/validation/crossContactInfoFieldValidation';
import { selectCustomCAN } from '../../../sagas/selectors/commonSelectors';

/**
 * Destructure necessary imports
 */
const { EXTERNAL_REFERENCE } = FormFieldNames;
const {
    BACK_BUTTON,
    CONTINUE_BUTTON,
    EXTERNAL_REFERENCE_LABEL,
    EXTERNAL_REFERENCE_PLACEHOLDER,
    HELP_TEXT,
    PAGE_NAME,
    SUB_TITLE,
    TITLE,
} = PhraseKeys;

interface PolicyHoldersContactForm extends PolicyHoldersContactModel {
    externalReference: Nullable<string>;
}

/**
 * Page view and page logic
 */
export const PolicyHolderPage = () => {
    const dispatch = useDispatch();
    const policyHoldersContact = useSelector(selectPolicyHoldersContact);
    const externalReference = useSelector(selectExternalReference);
    const customCAN = useSelector(selectCustomCAN);
    const isReporter = policyHoldersContact.isReporter;
    const { t } = useI18n();
    const tWithNS = useI18n('motor.end.policyHoldersContact');
    const {
        control,
        formState: { errors },
        handleSubmit,
        setValue,
        trigger,
    } = useForm<PolicyHoldersContactForm>({
        defaultValues: {
            ...policyHoldersContact,
            externalReference,
        },
    });

    const handleBackButton = useGoBack();

    const handleBlur = async (e: FormChangeable) => {
        e.preventDefault();
        const { id } = e.currentTarget;
        await trigger(id);
    };

    const handleChange = async (e: FormChangeable) => {
        e.preventDefault();
        const { id, value } = e.currentTarget;
        await setValue(id, value, { shouldValidate: true });
        await crossContactInfoFieldValidation(id, trigger);
    };

    const onSubmit = (values: PolicyHoldersContactForm) => {
        const { email, externalReference, firstName, lastName, phone } = values;
        dispatcherWithPromise(dispatch, motorActions.update, {
            policyHoldersContact: { email, firstName, isReporter, lastName, phone },
            externalReference,
        })
            .then(() => dispatcherWithPromise(dispatch, commonActions.send))
            .then(() => dispatch(wizardActions.goToNext()));
    };

    return (
        <PageLayout
            backBtnText={t(BACK_BUTTON)}
            continueBtnText={t(CONTINUE_BUTTON)}
            domainTitle={t(PAGE_NAME)}
            footerText={tWithNS.t(HELP_TEXT)}
            handleContinueButton={handleSubmit(onSubmit)}
            headerSubTitle={tWithNS.t(SUB_TITLE)}
            headerTitle={tWithNS.t(TITLE)}
            {...{ handleBackButton }}
        >
            <form onSubmit={handleSubmit(onSubmit)}>
                <HiddenInputSubmit />
                <Grid className={'align-center'}>
                    <Controller
                        control={control}
                        name={EXTERNAL_REFERENCE}
                        render={({ field: { ref, ...field } }) => (
                            <MuiTextInput
                                {...field}
                                error={!!errors.externalReference}
                                errorMessage={errors.externalReference?.message}
                                id={EXTERNAL_REFERENCE}
                                inputFieldWrapper={'col-12'}
                                label={t(EXTERNAL_REFERENCE_LABEL)}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                placeholder={t(EXTERNAL_REFERENCE_PLACEHOLDER)}
                                reference={ref}
                                {...{ customCAN }}
                            />
                        )}
                    />
                </Grid>
            </form>
        </PageLayout>
    );
};
