import React from 'react';
import { MotorRoutePaths } from '../../../config/wizardRouter/motorWizardRoutes';
import { findClaimVehicle, InsuredByProtectorKeys, is, SummaryItem } from '@protectorinsurance/ds-can';
import { createTextYesNoDontKnow } from '../../../utils/displayUtils';
import { dtParseAndFormat } from '../../../utils/date/dateUtils';
import { MotorSummaryProps } from '../../../types/SummaryProps';
import { TEST_ID_PREFIX } from '../../../utils/testIdUtil';

/**
 * Destructure necessary imports
 */
const {
    START_CLAIM_DATE,
    START_CLAIM_NUMBER_INFORMATION,
    START_COMPANY_INFORMATION,
    DYN_CAROUSEL_START_INSURED_BY_PROTECTOR,
    DYN_CAROUSEL_START_IS_DRIVING,
    DYN_CAROUSEL_START_OTHER_PART_REPORTING,
} = MotorRoutePaths;
const { NO } = InsuredByProtectorKeys;

/**
 * Page view and logic
 */
export const DisplayMotorStartSummary = ({ model, t, tWithNS }: MotorSummaryProps) => {
    /**
     * Helper constants/functions
     */
    const { claimDate, claimNumber, companyInformation, isDriving, otherPartReporting, vehicles } = model;
    const claimVehicle = findClaimVehicle(vehicles);

    const renderClaimNumber = () => {
        return (
            <>
                {/* Claim Number */}
                {claimNumber && (
                    <SummaryItem
                        textKey={tWithNS('claimNumber.heading.title')}
                        dataTestId={TEST_ID_PREFIX.claimNumber}
                        buttonText={claimNumber ? claimNumber : '-'}
                        path={START_CLAIM_NUMBER_INFORMATION}
                        inputWrapperClass={'col-12 divider text-align-left'}
                    />
                )}
            </>
        );
    };

    const renderClaimDate = () => {
        return (
            <>
                {/* Claim Date */}
                <SummaryItem
                    textKey={tWithNS('claimDate.heading.title')}
                    dataTestId={TEST_ID_PREFIX.claimDate}
                    buttonText={claimDate ? dtParseAndFormat(claimDate) : ''}
                    path={START_CLAIM_DATE}
                    inputWrapperClass={'col-12 divider text-align-left'}
                />
            </>
        );
    };

    const renderVehicleInformation = () => {
        return (
            <>
                {/* Insured By Protector */}
                {claimVehicle?.insuredByProtector &&
                    claimVehicle?.insuredByProtector !== InsuredByProtectorKeys.YES_RECENTLY_INSURED_BY_PROTECTOR && (
                        <SummaryItem
                            textKey={tWithNS('insuredByProtector.heading.title')}
                            dataTestId={TEST_ID_PREFIX.insuredByProtector}
                            buttonText={t(`motor.start.insuredByProtector.select.${claimVehicle?.insuredByProtector}`)}
                            path={DYN_CAROUSEL_START_INSURED_BY_PROTECTOR}
                            inputWrapperClass={'col-12 spacing text-align-left'}
                        />
                    )}

                {/* Company Information */}
                {companyInformation.name && companyInformation.policyNumber && (
                    <SummaryItem
                        textKey={tWithNS('companyInformation.heading.title')}
                        dataTestId={TEST_ID_PREFIX.companyInformation}
                        buttonText={`${companyInformation.name} (${companyInformation.policyNumber})`}
                        path={START_COMPANY_INFORMATION}
                        inputWrapperClass={'col-12 divider text-align-left'}
                    />
                )}
            </>
        );
    };

    const renderOtherPart = () => {
        return (
            <>
                {/* Other Part Reporting */}
                {is(claimVehicle?.insuredByProtector, NO) && (
                    <SummaryItem
                        textKey={tWithNS('otherPartReporting.heading.title')}
                        dataTestId={TEST_ID_PREFIX.otherPartReporting}
                        buttonText={t(createTextYesNoDontKnow(otherPartReporting))}
                        path={DYN_CAROUSEL_START_OTHER_PART_REPORTING}
                        inputWrapperClass={'col-12 divider text-align-left'}
                    />
                )}
            </>
        );
    };

    const renderIsDriving = () => {
        return (
            <>
                <SummaryItem
                    textKey={tWithNS('isDriving.heading.title')}
                    dataTestId={TEST_ID_PREFIX.isDriving}
                    buttonText={t(createTextYesNoDontKnow(isDriving))}
                    path={DYN_CAROUSEL_START_IS_DRIVING}
                    inputWrapperClass={'col-12 divider text-align-left'}
                />
            </>
        );
    };

    return (
        <>
            {renderClaimNumber()}

            {renderClaimDate()}

            {renderVehicleInformation()}

            {renderOtherPart()}

            {renderIsDriving()}
        </>
    );
};
