import {
    ClaimDescriptionTypeModel,
    findCounterpartyVehicle,
    InsuredByProtectorKeys,
    YesNoKeys,
} from '@protectorinsurance/ds-can';
import { MotorState } from '../../sagas/motor';
import { displayDescription } from '../strings/displayDescription';

export const getClaimDescriptionHistory = (motorState: MotorState): ClaimDescriptionTypeModel => {
    const {
        theftAndDamagesClaimDescription,
        otherMissingItemsClaimDescription,
        fireClaimDescription,
        roadConditionClaimDescription,
        whoResponsibleClaimDescription,
        isDriving,
        vehicles,
    } = motorState;
    const claimVehicle = findCounterpartyVehicle(vehicles);

    if (isDriving === YesNoKeys.NO || claimVehicle?.insuredByProtector === InsuredByProtectorKeys.NO) {
        const fields = [roadConditionClaimDescription, whoResponsibleClaimDescription];
        return fields
            .filter(Boolean)
            .map((string) => displayDescription(string))
            .join(' ');
    } else {
        const fields = [theftAndDamagesClaimDescription, otherMissingItemsClaimDescription, fireClaimDescription];
        return fields
            .filter(Boolean)
            .map((string) => displayDescription(string))
            .join(' ');
    }
};
