import { AppState } from '../../store';
import { cleanStateBeforeSubmit } from '../../utils/cleanStateBeforeSubmit';
import { convertYesNoKeysToBool, dtParse, LobKeys, YesNoKeys } from '@protectorinsurance/ds-can';
import { HTML5_FMT } from 'moment';

/**
 * Destructure necessary imports
 */
const { AUTO } = LobKeys;

export const selectClaimReportId = (state: AppState) => state.common.id;
export const selectClaimReport = (state: AppState) => {
    const { id, lob, country, requestId, timeoutWarning } = state.common;
    const { driverInformation, hasParkedWitnesses, reporterInformation } = state.motor;
    const { isAccident, isWorkRelated } = state.lpo;

    // Convert date/time to only date
    const driverDateOfBirth = driverInformation.dateOfBirth
        ? dtParse(driverInformation.dateOfBirth).format(HTML5_FMT.DATE)
        : null;

    // Convert date/time to only date
    const reporterDateOfBirth = reporterInformation.dateOfBirth
        ? dtParse(reporterInformation.dateOfBirth).format(HTML5_FMT.DATE)
        : null;

    let lobState;
    let accidentLocation;
    if (lob === AUTO) {
        lobState = state.motor;
        accidentLocation = state.motor.accidentLocation;
    } else {
        lobState = state.lpo;
        accidentLocation = state.lpo.accidentLocation;
    }

    const sessionObject = {
        id,
        lob,
        country,
        requestId,
        timeoutWarning,
        ...lobState,
        ...(reporterDateOfBirth && {
            reporterInformation: { ...reporterInformation, dateOfBirth: reporterDateOfBirth },
        }),
        ...(driverDateOfBirth && { driverInformation: { ...driverInformation, dateOfBirth: driverDateOfBirth } }),
        ...(!!hasParkedWitnesses &&
            hasParkedWitnesses === YesNoKeys.YES && { hasParkedWitnesses: null, hasWitnesses: YesNoKeys.YES }),
        // If note field is empty, populate with address value
        ...(accidentLocation &&
            !accidentLocation.note &&
            !accidentLocation.isUnknownLocation && {
                accidentLocation: { ...accidentLocation, note: accidentLocation.address },
            }),
    };
    return {
        ...cleanStateBeforeSubmit({ ...sessionObject }),
        ...(!!isWorkRelated && { isWorkRelated: convertYesNoKeysToBool(isWorkRelated) }),
        ...(!!isAccident && { isAccident: convertYesNoKeysToBool(isAccident) }),
    };
};
