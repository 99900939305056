import React from 'react';
import { ClaimantModel, SummaryItem, SummaryItemProps } from '@protectorinsurance/ds-can';

/**
 * Component view and component logic
 */
export const renderClaimantInformation = (
    { inputWrapperClass, textKey, dataTestId, buttonText, path, handleClick }: SummaryItemProps,
    claimantInformation: ClaimantModel
) => {
    if (claimantInformation.firstName || claimantInformation.lastName) {
        return <SummaryItem {...{ buttonText, dataTestId, handleClick, inputWrapperClass, path, textKey }} />;
    }
    return <></>;
};
