import React, { SyntheticEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { wizardRouterActions as wizardActions } from 'sagas/wizardRouter';
import { useI18n } from '../../../hooks/useI18n';
import { useGoBack } from '../../../hooks/useGoBack';
import { FormFieldErrors, FormFieldNames } from '../../../config/formFieldNames';
import { PhraseKeys } from '../../../config/phraseKeys';
import dispatcherWithPromise from '../../../utils/dispatcherWithPromise';
import { commonActions } from '../../../sagas/common';
import {
    Clickable,
    FormChangeable,
    Grid,
    is,
    isYes,
    MuiAutocomplete,
    OptionType,
    OtherInsuranceCompanyEnums,
    PageLayout,
} from '@protectorinsurance/ds-can';
import { selectHealthInsurance, selectIsWorkRelated } from '../../../sagas/selectors/lpoSelectors';
import { lpoActions } from '../../../sagas/lpo';
import { getOtherInsuranceOption } from '../../../utils/lpo/otherInsuranceUtils';
import { LpoRoutePaths } from '../../../config/wizardRouter/lpoWizardRoutes';
import { selectCustomCAN } from '../../../sagas/selectors/commonSelectors';

/**
 * Destructure necessary imports
 */
const { HEALTH_INSURANCE } = FormFieldNames;
const {
    BACK_BUTTON,
    CONTINUE_BUTTON,
    HEALTH_INSURANCE_LABEL,
    HEALTH_INSURANCE_PLACEHOLDER,
    HELP_TEXT,
    NO_OPTIONS_MESSAGE,
    PAGE_NAME,
    SUB_TITLE,
    TITLE,
} = PhraseKeys;
const { REQUIRED_ERROR_MESSAGE } = FormFieldErrors;
const { DYN_CAROUSEL_GROUP_ACCIDENT_DURING_WORK } = LpoRoutePaths;
const { DONT_KNOW, GROUP_1, GROUP_2, GROUP_5, PASSIVE } = OtherInsuranceCompanyEnums;

/**
 * Page view and page logic
 */
export const GlassHealthInsurancePage = () => {
    const dispatch = useDispatch();
    const [insurance, setInsurance] = useState<OptionType | null>(null);
    const [error, setError] = useState<string>('');
    const healthInsurance = useSelector(selectHealthInsurance);
    const isWorkRelated = useSelector(selectIsWorkRelated);
    const customCAN = useSelector(selectCustomCAN);
    const { t } = useI18n();
    const tWithNS = useI18n('lpo.glass.healthInsurance');

    // Options
    const dontKnowOption = getOtherInsuranceOption(t, DONT_KNOW);
    const groupOneOption = getOtherInsuranceOption(t, GROUP_1);
    const groupTwoOption = getOtherInsuranceOption(t, GROUP_2);
    const groupFiveOption = getOtherInsuranceOption(t, GROUP_5);
    const passiveOption = getOtherInsuranceOption(t, PASSIVE);

    const options = [groupOneOption, groupTwoOption, groupFiveOption, passiveOption, dontKnowOption];

    useEffect(() => {
        const selected = options.find((x: OptionType) => x.label === healthInsurance);
        if (selected) {
            setInsurance(selected);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [healthInsurance, setInsurance]);

    const handleBackButton = useGoBack();

    const handleBlur = async (e: FormChangeable) => {
        e.preventDefault();
        if (is(healthInsurance, null)) {
            setError(t(REQUIRED_ERROR_MESSAGE));
        } else {
            setError('');
        }
    };

    const handleSelect = (e: SyntheticEvent, option: OptionType) => {
        const object = option ? option : null;
        const value = object ? object.label : null;
        setError('');
        setInsurance(object);
        dispatcherWithPromise(dispatch, lpoActions.update, { healthInsurance: value });
    };

    const handleContinueButton = (e: Clickable) => {
        e.preventDefault();
        if (is(healthInsurance, null)) {
            setError(t(REQUIRED_ERROR_MESSAGE));
        } else {
            let nextAction = wizardActions.goToNext();
            if (!isYes(isWorkRelated)) {
                nextAction = wizardActions.goTo(DYN_CAROUSEL_GROUP_ACCIDENT_DURING_WORK);
            }

            setError('');
            dispatcherWithPromise(dispatch, commonActions.send).then(() => dispatch(nextAction));
        }
    };

    return (
        <PageLayout
            backBtnText={t(BACK_BUTTON)}
            continueBtnText={t(CONTINUE_BUTTON)}
            domainTitle={t(PAGE_NAME)}
            footerText={tWithNS.t(HELP_TEXT)}
            headerSubTitle={tWithNS.t(SUB_TITLE)}
            headerTitle={tWithNS.t(TITLE)}
            {...{ handleBackButton, handleContinueButton }}
        >
            <Grid className={'align-center'}>
                <MuiAutocomplete
                    error={!!error}
                    errorMessage={error}
                    id={HEALTH_INSURANCE}
                    inputFieldWrapper={'col-12'}
                    label={t(HEALTH_INSURANCE_LABEL)}
                    noOptionsText={t(NO_OPTIONS_MESSAGE)}
                    onBlur={handleBlur}
                    onChange={handleSelect}
                    openOnFocus={true}
                    placeholder={t(HEALTH_INSURANCE_PLACEHOLDER)}
                    value={insurance}
                    {...{ customCAN, options }}
                />
            </Grid>
        </PageLayout>
    );
};
