import { CountryCodeISOEnums, ObjectWithDynamicKey } from '@protectorinsurance/ds-can';
import { baseWizardRoutes } from './baseWizardRoutes';
import { motorWizardRoutes } from './motorWizardRoutes';
import { unitTestWizardRoutes } from './unitTestWizardRoutes';
import { lpoWizardRoutes } from './lpoWizardRoutes';

export const wizardRoutes: ObjectWithDynamicKey = {
    [CountryCodeISOEnums.DENMARK]: {
        ...baseWizardRoutes,
        ...motorWizardRoutes,
        ...lpoWizardRoutes,
        ...unitTestWizardRoutes,
    },
};
