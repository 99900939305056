import React from 'react';
import { wizardRouterActions as wizardActions } from '../../../../../../sagas/wizardRouter';
import {
    CarouselConfigLookupResponse,
    CarouselConfigOptionType,
    Clickable,
    IDontKnowIcon,
    NoIcon,
    YesIcon,
    YesNoKeys,
} from '@protectorinsurance/ds-can';
import { CarouselConfigLookupProps } from '../../../../../../utils/carouselPageLookup';
import dispatcherWithPromise from '../../../../../../utils/dispatcherWithPromise';
import { motorActions } from '../../../../../../sagas/motor';
import { selectHasAnimalEscaped } from '../../../../../../sagas/selectors/motorSelectors';
import { MotorRoutePaths } from '../../../../../wizardRouter/motorWizardRoutes';
import { commonActions } from '../../../../../../sagas/common';

/**
 * Destructure necessary imports
 */
const { DONT_KNOW, NO, YES } = YesNoKeys;

/**
 * Page view and page logic
 */
export const drivingNoVehicleAnimalHasAnimalEscapedPage = ({
    t,
    state,
    dispatch,
}: CarouselConfigLookupProps): CarouselConfigLookupResponse<MotorRoutePaths> => {
    const options = [
        { id: YES, name: t(`motor.driving.hasAnimalEscaped.select.${YES}`), icon: <YesIcon /> },
        { id: NO, name: t(`motor.driving.hasAnimalEscaped.select.${NO}`), icon: <NoIcon /> },
        { id: DONT_KNOW, name: t(`motor.driving.hasAnimalEscaped.select.${DONT_KNOW}`), icon: <IDontKnowIcon /> },
    ];

    return {
        slug: MotorRoutePaths.DYN_CAROUSEL_DRIVING_NO_VEHICLE_ANIMAL_HAS_ANIMAL_ESCAPED,
        stateKey: 'hasAnimalEscaped',
        i18n: {
            ns: 'motor.driving.hasAnimalEscaped',
        },
        selectorValue: selectHasAnimalEscaped(state),
        options,
        onSelect: (id: CarouselConfigOptionType, e: Clickable) => {
            e.preventDefault();
            const option = options?.find((x) => x.id === id);

            if (option) {
                dispatcherWithPromise(dispatch, motorActions.update, { hasAnimalEscaped: option.id })
                    .then(() => dispatcherWithPromise(dispatch, commonActions.send))
                    .then(() => dispatch(wizardActions.goToNext()));
            }
        },
    };
};
