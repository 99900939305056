import * as Yup from 'yup';
import { TFunction } from 'i18next';
import { FormFieldErrors, FormFieldNames, MAX_LENGTH_250 } from '../../config/formFieldNames';
import { ZIP_REGEX } from '../formats/stringFormats';
import { PhraseKeys } from '../../config/phraseKeys';
import { PHONE_REGEX } from '../../utils/validation/stringFormats';

/**
 * Destructure necessary imports
 */
const { MAX_LENGTH_ERROR_MESSAGE, EMAIL_ERROR_MESSAGE, FIELD_ERROR, INVALID_ZIP_CODE } = FormFieldErrors;
const {
    CITY_LABEL,
    COMPANY_NAME_LABEL,
    COMPANY_BUSINESS_NUMBER_LABEL,
    DAMAGED_ITEM_LABEL,
    EMAIL_LABEL,
    FIRST_NAME_LABEL,
    LAST_NAME_LABEL,
    PHONE_LABEL,
    STREET_LABEL,
    ZIP_LABEL,
} = PhraseKeys;
const { EMAIL, PHONE } = FormFieldNames;

/**
 * Validation logic
 */
export const isCompany = (isCompany: boolean, schema: Yup.StringSchema) =>
    isCompany ? schema.required() : schema.notRequired();
export const isBusinessNumberRequired = (isCompany: boolean, schema: Yup.StringSchema) =>
    isCompany ? schema.required().businessNumber(true) : schema.notRequired();
export const isNotCompany = (isCompany: boolean, schema: Yup.StringSchema) =>
    isCompany ? schema.notRequired() : schema.required();

export const ownerInformationListSchema = (t: TFunction) => {
    const zipLabel = t(ZIP_LABEL);
    return Yup.object().shape({
        // COMMON
        isCompany: Yup.boolean(),
        email: Yup.string()
            .label(t(EMAIL_LABEL))
            .email(t(EMAIL_ERROR_MESSAGE))
            .secureEndingEmail(t(EMAIL_ERROR_MESSAGE))
            .onlyAscii()
            .requiredIf([PHONE])
            .nullable(),
        phone: Yup.string()
            .label(t(PHONE_LABEL))
            .matches(PHONE_REGEX, {
                message: t(FIELD_ERROR),
                excludeEmptyString: true,
            })
            .requiredIf([EMAIL])
            .nullable(),

        // COMPANY
        name: Yup.string().when('isCompany', isCompany).label(t(COMPANY_NAME_LABEL)).noSpecialChars().nullable(),

        businessNumber: Yup.string()
            .when('isCompany', isBusinessNumberRequired)
            .label(t(COMPANY_BUSINESS_NUMBER_LABEL))
            .nullable(),

        // PERSON
        firstName: Yup.string()
            .when('isCompany', isNotCompany)
            .label(t(FIRST_NAME_LABEL))
            .max(
                MAX_LENGTH_250,
                t(MAX_LENGTH_ERROR_MESSAGE, {
                    field: t(FIRST_NAME_LABEL),
                    max: MAX_LENGTH_250,
                })
            )
            .noSpecialChars()
            .nullable(),
        lastName: Yup.string()
            .label(t(LAST_NAME_LABEL))
            .max(
                MAX_LENGTH_250,
                t(MAX_LENGTH_ERROR_MESSAGE, {
                    field: t(LAST_NAME_LABEL),
                    max: MAX_LENGTH_250,
                })
            )
            .noSpecialChars()
            .nullable(),
        street: Yup.string()
            .label(t(STREET_LABEL))
            .noSpecialChars()
            .max(
                MAX_LENGTH_250,
                t(MAX_LENGTH_ERROR_MESSAGE, {
                    field: t(STREET_LABEL),
                    max: MAX_LENGTH_250,
                })
            )
            .nullable(),
        city: Yup.string()
            .label(t(CITY_LABEL))
            .noSpecialChars()
            .max(
                MAX_LENGTH_250,
                t(MAX_LENGTH_ERROR_MESSAGE, {
                    field: t(CITY_LABEL),
                    max: MAX_LENGTH_250,
                })
            )
            .nullable(),
        zip: Yup.string().matches(ZIP_REGEX, t(INVALID_ZIP_CODE)).label(zipLabel).nullable(),
        damagedItem: Yup.string()
            .label(t(DAMAGED_ITEM_LABEL))
            .max(
                MAX_LENGTH_250,
                t(MAX_LENGTH_ERROR_MESSAGE, {
                    field: t(FIRST_NAME_LABEL),
                    max: MAX_LENGTH_250,
                })
            )
            .noSpecialChars()
            .required()
            .nullable(),
    });
};
