import { CarouselConfigPageProps } from '@protectorinsurance/ds-can';
import { CarouselConfigLookupProps } from '../../../utils/carouselPageLookup';
import { isDrivingPage } from './start/isDrivingPage';
import { insuredByProtectorPage } from './start/insuredByProtectorPage';
import { otherPartReportingPage } from './start/otherPartReporting';
import { parkedClaimCausePage } from './parked/claimCause';
import { parkedOtherClaimCauseDetailsPage } from './parked/other/claimCauseDetails';
import { parkedParkingHasWitnessesPage } from './parked/parking/hasWitnesses';
import { parkedParkingIsCounterpartyKnownPage } from './parked/parking/isCounterpartyKnown';
import { parkedParkingUnidentifiedPage } from './parked/parking/unidentifiedType';
import { parkedTheftAndDamagesHasAllKeysPage } from './parked/theftAndDamages/hasAllKeys';
import { parkedTheftAndDamagesIsCounterpartyKnownPage } from './parked/theftAndDamages/isCounterpartyKnown';
import { parkedTheftAndDamagesIsItemMissingPage } from './parked/theftAndDamages/isItemMissing';
import { parkedTheftAndDamagesIsSelfDiscoveredClaimPage } from './parked/theftAndDamages/isSelfDiscoveredClaim';
import { parkedTheftAndDamagesIsVehicleRecoveredPage } from './parked/theftAndDamages/isVehicleRecovered';
import { parkedTheftAndDamagesIsVehicleStolenPage } from './parked/theftAndDamages/isVehicleStolen';
import { endHasNonVehicleDamagesPage } from './end/hasNonVehicleDamages';
import { endHasPersonInjuriesPage } from './end/hasPersonInjuries';
import { endHasWitnessesPage } from './end/hasWitnesses';
import { endIsPoliceContactedPage } from './end/isPoliceContacted';
import { drivingIsOtherVehicleInvolvedPage } from './driving/isOtherVehicleInvolved';
import { drivingNoVehicleClaimCausePage } from './driving/noVehicle/claimCause';
import { drivingNoVehicleAnimalTypeOfAnimalPage } from './driving/noVehicle/animal/typeOfAnimal';
import { drivingNoVehicleAnimalHasAnimalEscapedPage } from './driving/noVehicle/animal/hasAnimalEscaped';
import { drivingVehiclesUnidentifiedTypePage } from './driving/vehicles/unidentifiedType';
import { drivingVehiclesClaimLocationPage } from './driving/vehicles/claimLocation';
import { drivingVehiclesIsLaneChangePage } from './driving/vehicles/isLaneChange';
import { drivingVehiclesHeadOnIsCounterpartyStationaryPage } from './driving/vehicles/headOn/isCounterpartyStationary';
import { drivingVehiclesIntersectionClaimCausePage } from './driving/vehicles/intersection/claimCause';
import { drivingVehiclesRightOfWayTypeOfRightOfWayPage } from './driving/vehicles/roundabout/rightOfWay';
import { drivingVehiclesLaneChangeWhoAtFaultPage } from './driving/vehicles/laneChange/whoAtFault';
import { drivingVehiclesOvertakingWhoAtFaultPage } from './driving/vehicles/overtaking/whoAtFault';
import { drivingVehiclesParkedWhoAtFaultPage } from './driving/vehicles/parked/whoAtFault';
import { drivingVehiclesParkedIsReversingPage } from './driving/vehicles/parked/isReversing';
import { drivingVehiclesParkingOtherClaimCausePage } from './driving/vehicles/parkingOther/claimCause';
import { drivingVehiclesReversingWhoAtFaultPage } from './driving/vehicles/reversing/whoAtFault';
import { drivingVehiclesRoadClaimCausePage } from './driving/vehicles/road/claimCause';
import { drivingVehiclesRoundaboutClaimantRoundaboutPositioningPage } from './driving/vehicles/roundabout/claimantRoundaboutPositioning';
import { drivingVehiclesRoundaboutCounterpartyRoundaboutPositioningPage } from './driving/vehicles/roundabout/counterpartyRoundaboutPositioning';
import { drivingVehiclesRoundaboutClaimCausePage } from './driving/vehicles/roundabout/claimCause';

export const motorCarouselConfigPages = (props: CarouselConfigLookupProps): CarouselConfigPageProps => [
    // Start
    insuredByProtectorPage(props),
    otherPartReportingPage(props),
    isDrivingPage(props),

    // Driving
    drivingIsOtherVehicleInvolvedPage(props),

    // Driving / Vehicles
    drivingVehiclesUnidentifiedTypePage(props),
    drivingVehiclesClaimLocationPage(props),
    drivingVehiclesIsLaneChangePage(props),

    // Driving / Vehicles / Head On
    drivingVehiclesHeadOnIsCounterpartyStationaryPage(props),

    // Driving / Vehicles / Intersection
    drivingVehiclesIntersectionClaimCausePage(props),

    // Driving / Vehicles / Lane Change
    drivingVehiclesLaneChangeWhoAtFaultPage(props),

    // Driving / Vehicles / Overtaking
    drivingVehiclesOvertakingWhoAtFaultPage(props),

    // Driving / Vehicles / Parked
    drivingVehiclesParkedWhoAtFaultPage(props),
    drivingVehiclesParkedIsReversingPage(props),

    // Driving / Vehicles / Parking Other
    drivingVehiclesParkingOtherClaimCausePage(props),

    // Driving / Vehicles / Reversing
    drivingVehiclesReversingWhoAtFaultPage(props),

    // Driving / Vehicles / Road
    drivingVehiclesRoadClaimCausePage(props),

    // Driving / Vehicles / Right Of Way Type
    drivingVehiclesRightOfWayTypeOfRightOfWayPage(props),

    // Driving / Vehicles / Roundabout
    drivingVehiclesRoundaboutClaimantRoundaboutPositioningPage(props),
    drivingVehiclesRoundaboutCounterpartyRoundaboutPositioningPage(props),
    drivingVehiclesRoundaboutClaimCausePage(props),

    // Driving / No Vehicle
    drivingNoVehicleClaimCausePage(props),

    // Driving / No Vehicle / Animal
    drivingNoVehicleAnimalTypeOfAnimalPage(props),
    drivingNoVehicleAnimalHasAnimalEscapedPage(props),

    // Parked
    parkedClaimCausePage(props),

    // Parked / Theft And Damages
    parkedTheftAndDamagesIsCounterpartyKnownPage(props),
    parkedTheftAndDamagesIsSelfDiscoveredClaimPage(props),
    parkedTheftAndDamagesIsVehicleStolenPage(props),
    parkedTheftAndDamagesHasAllKeysPage(props),
    parkedTheftAndDamagesIsVehicleRecoveredPage(props),
    parkedTheftAndDamagesIsItemMissingPage(props),

    // Parked / Parking
    parkedParkingIsCounterpartyKnownPage(props),
    parkedParkingUnidentifiedPage(props),
    parkedParkingHasWitnessesPage(props),

    // Parked / Other
    parkedOtherClaimCauseDetailsPage(props),

    // End
    endHasNonVehicleDamagesPage(props),
    endHasPersonInjuriesPage(props),
    endIsPoliceContactedPage(props),
    endHasWitnessesPage(props),
];
