import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useI18n } from '../../../hooks/useI18n';
import {
    ClaimVehicleSummary,
    Clickable,
    DamagePoint,
    findOwnerByDamagedItem,
    FormChangeable,
    Grid,
    HiddenInputSubmit,
    IncidentSummary,
    InputValidationError,
    isYes,
    LogServiceModel,
    MuiTextInput,
    NeedVehicleTowingKeys,
    NeedVehicleTowingModel,
    OwnerInformationListModel,
    PageLayout,
    pdfServiceActions,
    PDFServiceActionTypes,
    Printable,
    SummaryItemText,
    SummaryText,
    SummaryVehicleItem,
    VehicleModel,
} from '@protectorinsurance/ds-can';
import { useGoBack } from '../../../hooks/useGoBack';
import { PhraseKeys } from '../../../config/phraseKeys';
import { FormFieldNames } from '../../../config/formFieldNames';
import {
    selectAccidentDescription,
    selectClaimDescription,
    selectMotorState,
} from '../../../sagas/selectors/motorSelectors';
import { motorActions, MotorState } from '../../../sagas/motor';
import { Controller, useForm } from 'react-hook-form';
import { claimDescriptionSchema } from '../../../validations/schemas/claimDescriptionSchema';
import 'components/summary/final/finalSummary.scss';
import { DisplayEndSummary } from './DisplayEndSummary';
import dispatcherWithPromise from '../../../utils/dispatcherWithPromise';
import { selectUpload } from '../../../sagas/selectors/uploadSelectors';
import { getClaimDescriptionHistory } from '../../../utils/summary/getClaimDescriptionHistory';
import { dtParseAndFormat } from '../../../utils/date/dateUtils';
import { PrintableVehicleBodyDamages } from '../../vehicle/PrintableVehicleBodyDamages';
import { commonActions } from '../../../sagas/common';
import { DisplayIncidentEndSummary } from './DisplayIncidentEndSummary';
import { DisplayMotorStartSummary } from './DisplayMotorStartSummary';
import { DisplayMotorParkedSummary } from './DisplayParkedSummary';
import { DisplayMotorDrivingSummary } from './DisplayMotorDrivingSummary';
import { useVehicles } from '../../../hooks/useVehicles';
import {
    selectApiId,
    selectCustomCAN,
    selectLob,
    selectLocaleCountryCode,
    selectLocaleLanguageCode,
    selectRequestId,
    selectSubmitted,
} from '../../../sagas/selectors/commonSelectors';
import { formatOwner } from '../../../utils/strings/formatStrings';
import { formatAddress } from '../../../utils/maps/formatAddress';
import { yupResolver } from '@hookform/resolvers/yup';
import { isClaimCauseOther } from '../../../utils/claimCauseUtils';
import { logServiceActions } from '../../../sagas/services/logService';
import useErrorLoader from '../../../hooks/useErrorSelector';
import { claimDescriptionUtil } from '../../../utils/claimDescriptionUtil';

/**
 * Destructure necessary imports
 */
const {
    BACK_BUTTON,
    CLAIM_DESCRIPTION_PLACEHOLDER,
    CLAIM_DESCRIPTION_LABEL,
    DAMAGE_DESCRIPTION_LABEL,
    HELP_TEXT,
    PAGE_NAME,
    SEND_BUTTON,
    SUB_TITLE,
    SUBMITTING_BUTTON,
    TITLE,
} = PhraseKeys;
const { CLAIM_DESCRIPTION } = FormFieldNames;
const { YES, NO, ALREADY_TOWED } = NeedVehicleTowingKeys;

/**
 * Interface
 */
interface PrintableSummaryPageProps {
    editDescription: boolean;
    showSummary?: boolean;
    handleClickOption?: (e: Clickable, path: string) => void;
}

/**
 * Page view and page logic
 */
export const PrintableSummaryPage = ({
    editDescription,
    showSummary = true,
    handleClickOption,
}: PrintableSummaryPageProps) => {
    const dispatch = useDispatch();
    const uuid = useSelector(selectApiId);
    const country = useSelector(selectLocaleCountryCode);
    const language = useSelector(selectLocaleLanguageCode);
    const lob = useSelector(selectLob);
    const requestId = useSelector(selectRequestId);
    const motorState = useSelector(selectMotorState);
    const uploadState = useSelector(selectUpload);
    const temporaryClaimDescription = useSelector(selectClaimDescription);
    const accidentDescription = useSelector(selectAccidentDescription);
    const customCAN = useSelector(selectCustomCAN);
    const { error: pdfError } = useErrorLoader(PDFServiceActionTypes.FAILURE);
    const claimDescriptionHistory = getClaimDescriptionHistory(motorState);
    const claimDescription =
        temporaryClaimDescription === ''
            ? [claimDescriptionHistory, accidentDescription, temporaryClaimDescription].join('\n')
            : temporaryClaimDescription;
    const { t } = useI18n();
    const tWithNS = useI18n('motor.end.finalSummary');
    const {
        control,
        formState: { errors },
        handleSubmit,
        setValue,
        trigger,
    } = useForm<Pick<MotorState, 'claimDescription'>>({
        resolver: yupResolver(claimDescriptionSchema(t, CLAIM_DESCRIPTION_LABEL)),
        defaultValues: {
            claimDescription,
        },
    });
    const submitted = useSelector(selectSubmitted);

    const model = {
        ...motorState,
    };

    const upload = {
        ...uploadState,
    };

    const handleBackButton = useGoBack();

    const {
        accidentLocation,
        claimCause,
        claimDate,
        isDriving,
        needVehicleTowing,
        ownerInformationList,
        vehicles,
        accidentSketch,
        isCounterparty,
    } = model;
    const { claimVehicle, counterpartyVehicle, notClaimVehicle, otherVehicles } = useVehicles();
    const prefix = !isCounterparty ? 'claimant' : 'counterparty';
    const tWithNSHeader = useI18n(`motor.end.finalSummary.${prefix}`);

    useEffect(() => {
        dispatch(motorActions.update({ claimDescription }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const claimDescription =
            temporaryClaimDescription === ''
                ? [claimDescriptionHistory, accidentDescription, temporaryClaimDescription].join('\n')
                : temporaryClaimDescription;

        setValue('claimDescription', claimDescription);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accidentDescription, claimDescriptionHistory, temporaryClaimDescription]);

    const handleContinueButton = ({ claimDescription }: Pick<MotorState, 'claimDescription'>, e?: FormChangeable) => {
        if (
            !model.claimDate ||
            !model.claimCause ||
            (!model.reporterInformation.firstName && !isClaimCauseOther(model.claimCause))
        ) {
            const missingClaimDate = !model.claimDate ? 'claimDate was missing upon submission.' : '';
            const missingClaimCause = !model.claimCause ? 'claimCause was missing upon submission.' : '';
            const missingReporterInformation =
                !model.reporterInformation.firstName && !isClaimCauseOther(model.claimCause)
                    ? 'reporterInformation was missing upon submission.'
                    : '';
            const fieldValues = [missingClaimDate, missingClaimCause, missingReporterInformation];
            const missingFieldsMessage = fieldValues.filter(Boolean).join(' ');
            const errorPayload: LogServiceModel = {
                level: 'error',
                message: `UUID: ${uuid}. ${missingFieldsMessage}`,
            };
            dispatch(logServiceActions.request(errorPayload));
        }
        const data = { uuid, requestId, lob, locale: { country, language }, model: { ...model, claimDescription } };
        e?.preventDefault();
        dispatch(commonActions.update({ submitted: true }));
        dispatcherWithPromise(dispatch, motorActions.update, { claimDescription })
            .then(() => dispatcherWithPromise(dispatch, commonActions.send))
            .then(() => dispatcherWithPromise(dispatch, pdfServiceActions.request, data))
            .then(() => dispatch(commonActions.submit()))
            .catch(() => dispatch(commonActions.update({ submitted: false })));
    };

    const handleChange = async (e: FormChangeable) => {
        e.preventDefault();
        claimDescriptionUtil(e);
        const { id, value } = e.currentTarget;
        await trigger(id);
        await setValue(id, value, { shouldValidate: true });
    };

    const handleBlur = async (e: FormChangeable) => {
        e.preventDefault();
        const { id } = e.currentTarget;
        await trigger(id);
    };

    const handleNeedTowingChange = (e: NeedVehicleTowingModel) => {
        dispatcherWithPromise(dispatch, motorActions.update, { needVehicleTowing: e }).then(() =>
            dispatcherWithPromise(dispatch, commonActions.send)
        );
    };

    const handleDamages = (v: VehicleModel) => {
        const damages = v.damages.map((item) => t(`motor.end.damagesSummary.select.${item.body}`));
        const damagesList = damages.reduce((damagesCount: any, currentDamage) => {
            damagesCount[currentDamage] = (damagesCount[currentDamage] || 0) + 1;
            return damagesCount;
        }, {});

        const reducedDamagesList = Object.entries(damagesList).map(([key, value]) => ({ key, value }));
        return reducedDamagesList;
    };

    const renderDamages = (claimVehicle: VehicleModel) => {
        const damages: DamagePoint[] = claimVehicle.damages.map((da): DamagePoint => {
            const t = da.svgLocation;
            return { x: t.x, y: t.y, vehicleBodyPart: da.body, svgId: t.svgId };
        });
        return (
            <PrintableVehicleBodyDamages
                addDamagePoint={() => null}
                className={claimVehicle.isTotalDamage ? 'total-damage' : ''}
                removeDamagePoint={() => null}
                selectedDamagePoints={damages}
                tWithNs={() => null}
                vehicleType={claimVehicle.vehicleType}
            />
        );
    };

    const renderFooterHelpComponent = `<span class="summary-help-text">${tWithNS.t('help.marked')}</span>`;

    const renderClaimVehicle = () => {
        const vehicle = isCounterparty ? counterpartyVehicle : claimVehicle;

        return (
            vehicle && (
                <ClaimVehicleSummary
                    claimVehicle={vehicle}
                    claimVehicleDamages={renderDamages(vehicle)}
                    damages={handleDamages(vehicle)}
                    damageDescription={vehicle.otherDamageComment}
                    damageDescriptionTitle={t(DAMAGE_DESCRIPTION_LABEL)}
                    damagesTextKey={tWithNS.t('damages.heading.title')}
                    handleChange={handleNeedTowingChange}
                    isTotalDamage={vehicle.isTotalDamage}
                    isTotalDamageTitle={tWithNS.t('totalDamage.text')}
                    needTowingTextKey={tWithNS.t('needVehicleTowing.heading.title')}
                    needVehicleTowing={needVehicleTowing}
                    needVehicleTowingSummary={t(`motor.end.needVehicleTowing.select.${needVehicleTowing}`)}
                    needVehicleTowingAlreadyTowed={t(`motor.end.needVehicleTowing.select.${ALREADY_TOWED}`)}
                    needVehicleTowingNo={t(`motor.end.needVehicleTowing.select.${NO}`)}
                    needVehicleTowingYes={t(`motor.end.needVehicleTowing.select.${YES}`)}
                    passengersText={tWithNS.t('passengers.heading.title')}
                    printSummary={!showSummary}
                    textKey={tWithNS.t('claimVehicle.heading.title')}
                    {...{ customCAN }}
                />
            )
        );
    };

    const renderCounterpartyVehicle = () => {
        if (!isCounterparty || !claimVehicle) {
            return null;
        }

        const owner = findOwnerByDamagedItem(ownerInformationList, claimVehicle.internalId);
        return (
            <Grid>
                <div className={'col-12'}>
                    <SummaryText text={tWithNS.t('counterpartyVehicle.heading.title')} />
                </div>

                <SummaryVehicleItem
                    className={`${showSummary ? 'col-6' : 'col-12'} no-padding padding-top`}
                    damages={handleDamages(claimVehicle)}
                    damagesTextKey={tWithNS.t('damages.heading.title')}
                    drivingText={tWithNS.t('driving.heading.title')}
                    isTotalDamage={claimVehicle.isTotalDamage}
                    isTotalDamageTitle={tWithNS.t('totalDamage.text')}
                    ownerInformationTextKey={`${owner?.firstName ? owner.firstName : ''}${
                        owner?.lastName ? ` ${owner.lastName}` : ''
                    }`}
                    passengersText={tWithNS.t('passengers.heading.title')}
                    printSummary={!showSummary}
                    textKey={tWithNS.t('vehicleOwnedBy.heading.title')}
                    vehicle={claimVehicle}
                    vehicleDamages={renderDamages(claimVehicle)}
                    {...{ customCAN }}
                />
            </Grid>
        );
    };

    const renderOtherVehicles = () => {
        const vehicleList = isCounterparty ? otherVehicles : notClaimVehicle;

        return (
            <Grid>
                {vehicleList && vehicleList.length > 0 && (
                    <div className={'col-12'}>
                        <SummaryText text={tWithNS.t('otherVehicle.heading.title')} />
                    </div>
                )}
                {vehicleList.map((vehicle: VehicleModel, idx: number) => {
                    const owner = findOwnerByDamagedItem(ownerInformationList, vehicle.internalId);
                    return (
                        <SummaryVehicleItem
                            className={`${showSummary ? 'col-6' : 'col-12'} no-padding padding-top`}
                            damageDescription={vehicle.otherDamageComment}
                            damageDescriptionTitle={t(DAMAGE_DESCRIPTION_LABEL)}
                            damages={handleDamages(vehicle)}
                            damagesTextKey={tWithNS.t('damages.heading.title')}
                            drivingText={tWithNS.t('driving.heading.title')}
                            isTotalDamage={vehicle.isTotalDamage}
                            isTotalDamageTitle={tWithNS.t('totalDamage.text')}
                            key={idx}
                            ownerInformationTextKey={`${owner?.firstName ? owner.firstName : ''}${
                                owner?.lastName ? ` ${owner.lastName}` : ''
                            }`}
                            passengersText={tWithNS.t('passengers.heading.title')}
                            printSummary={!showSummary}
                            textKey={tWithNS.t('vehicleOwnedBy.heading.title')}
                            vehicle={vehicle}
                            vehicleDamages={renderDamages(vehicle)}
                            {...{ customCAN }}
                        />
                    );
                })}
            </Grid>
        );
    };

    return (
        <>
            <PageLayout
                backBtnAriaLabel={t(BACK_BUTTON)}
                backBtnText={t(BACK_BUTTON)}
                continueBtnAriaLabel={submitted ? t(SUBMITTING_BUTTON) : t(SEND_BUTTON)}
                continueBtnText={submitted ? t(SUBMITTING_BUTTON) : t(SEND_BUTTON)}
                disableBackButton={submitted}
                disableContinueButton={submitted}
                domainTitle={t(PAGE_NAME)}
                footerText={tWithNS.t(HELP_TEXT, { component: renderFooterHelpComponent })}
                handleContinueButton={handleSubmit(handleContinueButton)}
                headerSubTitle={tWithNS.t(SUB_TITLE)}
                headerTitle={tWithNSHeader.t(TITLE)}
                pageClassName={!showSummary ? 'hide-content' : ''}
                {...{ handleBackButton }}
            >
                <Printable className={'hide-all-content'} title={tWithNSHeader.t('print.title')}>
                    <Grid>
                        {/* Incident Summary */}
                        <IncidentSummary
                            claimCause={claimCause ? tWithNS.t(`claimCause.select.${claimCause}`) : ''}
                            claimDate={claimDate ? dtParseAndFormat(claimDate) : ''}
                            className={'col-12 divider'}
                            incidentAt={tWithNS.t('text.incidentAt')}
                            incidentBy={tWithNS.t('text.incidentBy')}
                            incidentInvolving={tWithNS.t('text.incidentInvolving')}
                            incidentOn={tWithNS.t('text.incidentOn')}
                            location={formatAddress(accidentLocation, tWithNS.t)}
                            make={claimVehicle?.make}
                            registrationNumber={claimVehicle?.registrationNumber}
                            type={claimVehicle?.type}
                            {...{ customCAN }}
                        >
                            {vehicles.map((vehicle: VehicleModel, idx: number) => {
                                const owner: OwnerInformationListModel | undefined = ownerInformationList.find(
                                    (o: OwnerInformationListModel) => o.damagedItem === vehicle.internalId
                                );
                                let contactInformation = '';
                                if (owner) {
                                    const { firstName, lastName } = owner;
                                    contactInformation = `${firstName || lastName ? tWithNS.t('text.ownedBy') : ''}
                                    ${firstName ? firstName : ''} ${lastName ? lastName : ''}`;
                                } else if (vehicle.driverInformation) {
                                    const { firstName, lastName } = vehicle.driverInformation;
                                    contactInformation = `${firstName || lastName ? tWithNS.t('text.drivenBy') : ''}
                                    ${firstName ? firstName : ''} ${lastName ? lastName : ''}`;
                                }

                                return (
                                    <p key={idx} className={'incident-text'}>
                                        {`${vehicle.registrationNumber ? vehicle.registrationNumber : ''}, ${
                                            vehicle.model ? vehicle.model : ''
                                        } ${vehicle.make ? vehicle.make : ''} ${vehicle.type ? vehicle.type : ''}`}{' '}
                                        {contactInformation}
                                    </p>
                                );
                            })}
                            {ownerInformationList.map((owner: OwnerInformationListModel, idx: number) => {
                                const isVehicle = vehicles.find(
                                    (v: VehicleModel) => v.internalId === owner.damagedItem
                                );
                                return (
                                    <p key={idx} className={'incident-text'}>
                                        {formatOwner(isVehicle, owner, t)}
                                    </p>
                                );
                            })}
                        </IncidentSummary>

                        <DisplayIncidentEndSummary
                            handleClick={handleClickOption}
                            model={model}
                            printSummary={true}
                            upload={upload}
                            t={t}
                            tWithNS={tWithNS.t}
                        />

                        {/* Registered Vehicles and Damages */}
                        <div className={'col-12 divider'}>
                            {claimVehicle && !isCounterparty && (
                                <ClaimVehicleSummary
                                    claimVehicle={claimVehicle}
                                    claimVehicleDamages={renderDamages(claimVehicle)}
                                    damageDescription={claimVehicle.otherDamageComment}
                                    damageDescriptionTitle={t(DAMAGE_DESCRIPTION_LABEL)}
                                    damages={handleDamages(claimVehicle)}
                                    damagesTextKey={tWithNS.t('damages.heading.title')}
                                    handleChange={handleNeedTowingChange}
                                    isTotalDamage={claimVehicle.isTotalDamage}
                                    isTotalDamageTitle={tWithNS.t('totalDamage.text')}
                                    needTowingTextKey={tWithNS.t('needVehicleTowing.heading.title')}
                                    needVehicleTowing={needVehicleTowing}
                                    needVehicleTowingSummary={t(
                                        `motor.end.needVehicleTowing.select.${needVehicleTowing}`
                                    )}
                                    passengersText={tWithNS.t('passengers.heading.title')}
                                    printSummary={true}
                                    textKey={tWithNS.t('claimVehicle.heading.title')}
                                    {...{ customCAN }}
                                />
                            )}
                            {counterpartyVehicle && isCounterparty && (
                                <ClaimVehicleSummary
                                    claimVehicle={counterpartyVehicle}
                                    claimVehicleDamages={renderDamages(counterpartyVehicle)}
                                    damages={handleDamages(counterpartyVehicle)}
                                    damagesTextKey={tWithNS.t('damages.heading.title')}
                                    handleChange={handleNeedTowingChange}
                                    isTotalDamage={counterpartyVehicle?.isTotalDamage}
                                    isTotalDamageTitle={tWithNS.t('totalDamage.text')}
                                    needTowingTextKey={tWithNS.t('needVehicleTowing.heading.title')}
                                    needVehicleTowing={needVehicleTowing}
                                    needVehicleTowingSummary={t(
                                        `motor.end.needVehicleTowing.select.${needVehicleTowing}`
                                    )}
                                    passengersText={tWithNS.t('passengers.heading.title')}
                                    printSummary={true}
                                    textKey={tWithNS.t('claimVehicle.heading.title')}
                                    {...{ customCAN }}
                                />
                            )}
                            {renderCounterpartyVehicle()}
                            <Grid>
                                {notClaimVehicle && notClaimVehicle.length > 0 && (
                                    <div className={'col-12'}>
                                        <SummaryText text={tWithNS.t('otherVehicle.heading.title')} />
                                    </div>
                                )}
                                {!isCounterparty &&
                                    notClaimVehicle.map((vehicle: VehicleModel, idx: number) => {
                                        const owner = findOwnerByDamagedItem(ownerInformationList, vehicle.internalId);
                                        return (
                                            <SummaryVehicleItem
                                                damages={handleDamages(vehicle)}
                                                damagesTextKey={tWithNS.t('damages.heading.title')}
                                                drivingText={tWithNS.t('driving.heading.title')}
                                                isTotalDamage={vehicle.isTotalDamage}
                                                isTotalDamageTitle={tWithNS.t('totalDamage.text')}
                                                key={idx}
                                                ownerInformationTextKey={`${owner?.firstName ? owner.firstName : ''}${
                                                    owner?.lastName ? ` ${owner.lastName}` : ''
                                                }`}
                                                passengersText={tWithNS.t('passengers.heading.title')}
                                                printSummary={true}
                                                textKey={tWithNS.t('vehicleOwnedBy.heading.title')}
                                                vehicle={vehicle}
                                                vehicleDamages={renderDamages(vehicle)}
                                                {...{ customCAN }}
                                            />
                                        );
                                    })}
                                {isCounterparty &&
                                    otherVehicles.map((vehicle: VehicleModel, idx: number) => {
                                        const owner = findOwnerByDamagedItem(ownerInformationList, vehicle.internalId);
                                        return (
                                            <SummaryVehicleItem
                                                damageDescription={vehicle.otherDamageComment}
                                                damageDescriptionTitle={t(DAMAGE_DESCRIPTION_LABEL)}
                                                damages={handleDamages(vehicle)}
                                                damagesTextKey={tWithNS.t('damages.heading.title')}
                                                drivingText={tWithNS.t('driving.heading.title')}
                                                isTotalDamage={vehicle.isTotalDamage}
                                                isTotalDamageTitle={tWithNS.t('totalDamage.text')}
                                                key={idx}
                                                ownerInformationTextKey={`${owner?.firstName ? owner.firstName : ''}${
                                                    owner?.lastName ? ` ${owner.lastName}` : ''
                                                }`}
                                                passengersText={tWithNS.t('passengers.heading.title')}
                                                printSummary={true}
                                                textKey={tWithNS.t('vehicleOwnedBy.heading.title')}
                                                vehicle={vehicle}
                                                vehicleDamages={renderDamages(vehicle)}
                                                {...{ customCAN }}
                                            />
                                        );
                                    })}
                            </Grid>
                        </div>

                        {/* Claim Description */}
                        <SummaryItemText
                            className={`col-12 divider text-align-left`}
                            label={t(CLAIM_DESCRIPTION_LABEL)}
                            text={claimDescription}
                        />

                        {/* Start Summary */}
                        <DisplayMotorStartSummary tWithNS={tWithNS.t} {...{ model, upload, t }} />

                        {/* Parked Summary */}
                        {isYes(isDriving) ? (
                            <DisplayMotorDrivingSummary tWithNS={tWithNS.t} {...{ customCAN, model, upload, t }} />
                        ) : (
                            <DisplayMotorParkedSummary tWithNS={tWithNS.t} {...{ model, upload, t }} />
                        )}

                        {/* End Summary */}
                        <DisplayEndSummary tWithNS={tWithNS.t} {...{ model, upload, t }} />
                    </Grid>
                </Printable>
                <Printable title={tWithNS.t('print.title')}>
                    <Grid>
                        {/* Incident Summary */}
                        <IncidentSummary
                            claimCause={claimCause ? tWithNS.t(`claimCause.select.${claimCause}`) : ''}
                            claimDate={claimDate ? dtParseAndFormat(claimDate) : ''}
                            className={'col-12 divider'}
                            incidentAt={tWithNS.t('text.incidentAt')}
                            incidentBy={tWithNS.t('text.incidentBy')}
                            incidentInvolving={tWithNS.t('text.incidentInvolving')}
                            incidentOn={tWithNS.t('text.incidentOn')}
                            location={formatAddress(accidentLocation, tWithNS.t)}
                            make={claimVehicle?.make}
                            registrationNumber={claimVehicle?.registrationNumber}
                            type={claimVehicle?.type}
                            {...{ customCAN }}
                        >
                            {vehicles.map((vehicle: VehicleModel, idx: number) => {
                                const owner: OwnerInformationListModel | undefined = ownerInformationList.find(
                                    (o: OwnerInformationListModel) => o.damagedItem === vehicle.internalId
                                );
                                let contactInformation = 'tom';
                                if (owner) {
                                    const { firstName, lastName } = owner;
                                    contactInformation = `${firstName || lastName ? tWithNS.t('text.ownedBy') : ''}
                                     ${firstName ? firstName : ''} ${lastName ? lastName : ''}`;
                                } else if (vehicle.driverInformation) {
                                    const { firstName, lastName } = vehicle.driverInformation;
                                    contactInformation = `${firstName || lastName ? tWithNS.t('text.drivenBy') : ''}
                                     ${firstName ? firstName : ''} ${lastName ? lastName : ''}`;
                                }
                                return (
                                    <p key={idx} className={'incident-text'}>
                                        {`${vehicle.registrationNumber ? vehicle.registrationNumber : ''}, ${
                                            vehicle.model ? vehicle.model : ''
                                        } ${vehicle.make ? vehicle.make : ''} ${vehicle.type ? vehicle.type : ''}`}{' '}
                                        {contactInformation}
                                    </p>
                                );
                            })}
                            {ownerInformationList.map((owner: OwnerInformationListModel, idx: number) => {
                                const isVehicle = vehicles.find(
                                    (v: VehicleModel) => v.internalId === owner.damagedItem
                                );
                                return (
                                    <p className={'incident-text'} key={idx}>
                                        {formatOwner(isVehicle, owner, t)}
                                    </p>
                                );
                            })}
                        </IncidentSummary>

                        {/* Claim Description */}
                        {editDescription ? (
                            <div className={`col-12 divider`}>
                                <form onSubmit={handleSubmit(handleContinueButton)}>
                                    <HiddenInputSubmit />
                                    <Grid className={'align-center'}>
                                        <Controller
                                            control={control}
                                            name={CLAIM_DESCRIPTION}
                                            render={({ field: { ref, ...field } }) => (
                                                <MuiTextInput
                                                    {...field}
                                                    error={!!errors.claimDescription}
                                                    errorMessage={errors.claimDescription?.message}
                                                    id={CLAIM_DESCRIPTION}
                                                    inputFieldWrapper={'col-12'}
                                                    label={t(CLAIM_DESCRIPTION_LABEL)}
                                                    multiline={true}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    placeholder={t(CLAIM_DESCRIPTION_PLACEHOLDER)}
                                                    reference={ref}
                                                    rows={10}
                                                    {...{ customCAN }}
                                                />
                                            )}
                                        />
                                    </Grid>
                                </form>
                            </div>
                        ) : (
                            <SummaryItemText
                                className={`col-12 divider text-align-left`}
                                label={t(CLAIM_DESCRIPTION_LABEL)}
                                text={claimDescription}
                            />
                        )}

                        {/* Accident Description / Sketch */}
                        {accidentSketch?.blob && (
                            <>
                                <div className={`col-12 divider`}>
                                    <img alt={claimDescription} src={accidentSketch?.blob} />
                                </div>
                            </>
                        )}

                        {/* Registered Vehicles and Damages */}
                        <div className={'col-12 divider'}>
                            {renderClaimVehicle()}
                            {renderCounterpartyVehicle()}
                            {renderOtherVehicles()}
                        </div>

                        <DisplayIncidentEndSummary
                            handleClick={handleClickOption}
                            model={model}
                            printSummary={!showSummary}
                            t={t}
                            tWithNS={tWithNS.t}
                            upload={upload}
                        />
                        {pdfError ? (
                            <div className={'col-12'}>
                                <InputValidationError
                                    fieldName={'PdfError'}
                                    error={t('generatePDF.' + pdfError.status + 'Error')}
                                />
                            </div>
                        ) : (
                            ''
                        )}
                    </Grid>
                </Printable>
            </PageLayout>
        </>
    );
};
