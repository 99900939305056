import { all, fork } from 'redux-saga/effects';
import { flowWatcher } from '../sagas/flow';
import { wizardRouterWatcher } from '../sagas/wizardRouter';
import { motorWatcher } from '../sagas/motor';
import { initWatcher } from '../sagas/init';
import { commonWatcher } from '../sagas/common';
import { uploadWatchers } from '../sagas/upload';
import { servicesWatchers } from '../sagas/services';
import { lpoWatcher } from '../sagas/lpo';

export default function* () {
    yield all([
        fork(initWatcher),
        fork(flowWatcher),
        fork(wizardRouterWatcher),
        fork(motorWatcher),
        fork(commonWatcher),
        ...servicesWatchers.map((w) => fork(w)),
        ...uploadWatchers.map((watcher) => fork(watcher)),
        fork(lpoWatcher),
    ]);
}
