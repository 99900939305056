import React, { SyntheticEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Clickable,
    FormChangeable,
    Grid,
    is,
    MuiAutocomplete,
    OptionType,
    PageLayout,
    TypeOfGlassDamageEnums,
} from '@protectorinsurance/ds-can';
import { selectTypeOfGlassDamage } from '../../../sagas/selectors/lpoSelectors';
import { useI18n } from '../../../hooks/useI18n';
import { PhraseKeys } from '../../../config/phraseKeys';
import { useGoBack } from '../../../hooks/useGoBack';
import { FormFieldErrors, FormFieldNames } from '../../../config/formFieldNames';
import dispatcherWithPromise from '../../../utils/dispatcherWithPromise';
import { lpoActions } from '../../../sagas/lpo';
import { wizardRouterActions } from '../../../sagas/wizardRouter';
import { commonActions } from '../../../sagas/common';
import { getTypeOfGlassDamageOption } from '../../../utils/lpo/typeOfGlassDamageUtils';
import { selectCustomCAN } from '../../../sagas/selectors/commonSelectors';

/**
 * Destructure necessary imports
 */
const {
    BACK_BUTTON,
    CONTINUE_BUTTON,
    HELP_TEXT,
    NO_OPTIONS_MESSAGE,
    PAGE_NAME,
    SUB_TITLE,
    TITLE,
    TYPE_OF_GLASS_DAMAGE_LABEL,
    TYPE_OF_GLASS_DAMAGE_PLACEHOLDER,
} = PhraseKeys;
const { REQUIRED_ERROR_MESSAGE } = FormFieldErrors;
const { TYPE_OF_GLASS_DAMAGE } = FormFieldNames;
const { BOTH, FRAME, LENSES } = TypeOfGlassDamageEnums;

/**
 * Page view and page logic
 */
export const GlassTypeOfGlassDamagePage = () => {
    const dispatch = useDispatch();
    const [type, setType] = useState<OptionType | null>(null);
    const [error, setError] = useState<string>('');
    const typeOfGlassDamage = useSelector(selectTypeOfGlassDamage);
    const customCAN = useSelector(selectCustomCAN);
    const { t } = useI18n();
    const tWithNS = useI18n('lpo.glass.typeOfGlassDamage');

    // Options
    const bothOption = getTypeOfGlassDamageOption(t, BOTH);
    const frameOption = getTypeOfGlassDamageOption(t, FRAME);
    const lensesOption = getTypeOfGlassDamageOption(t, LENSES);

    const options = [frameOption, lensesOption, bothOption];

    useEffect(() => {
        const selected = options.find((x) => x.value === typeOfGlassDamage);
        if (selected) {
            setType(selected);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [typeOfGlassDamage, setType]);

    const handleBackButton = useGoBack();

    const handleBlur = (e: FormChangeable) => {
        e.preventDefault();
        if (is(typeOfGlassDamage, null)) {
            setError(t(REQUIRED_ERROR_MESSAGE));
        } else {
            setError('');
        }
    };

    const handleSelect = (e: SyntheticEvent, option: OptionType) => {
        const object = option ? option : null;
        const value = object ? object.value : null;
        setError('');
        setType(object);
        dispatch(lpoActions.update({ typeOfGlassDamage: value }));
    };

    const handleContinueButton = (e: Clickable) => {
        e.preventDefault();
        if (is(typeOfGlassDamage, null)) {
            setError(t(REQUIRED_ERROR_MESSAGE));
        } else {
            setError('');
            dispatcherWithPromise(dispatch, commonActions.send).then(() => dispatch(wizardRouterActions.goToNext()));
        }
    };

    return (
        <PageLayout
            backBtnText={t(BACK_BUTTON)}
            continueBtnText={t(CONTINUE_BUTTON)}
            domainTitle={t(PAGE_NAME)}
            footerText={tWithNS.t(HELP_TEXT)}
            headerSubTitle={tWithNS.t(SUB_TITLE)}
            headerTitle={tWithNS.t(TITLE)}
            {...{ handleBackButton, handleContinueButton }}
        >
            <Grid className={'align-center'}>
                <MuiAutocomplete
                    error={!!error}
                    errorMessage={error}
                    id={TYPE_OF_GLASS_DAMAGE}
                    inputFieldWrapper={'col-12'}
                    label={t(TYPE_OF_GLASS_DAMAGE_LABEL)}
                    noOptionsText={t(NO_OPTIONS_MESSAGE)}
                    onBlur={handleBlur}
                    onChange={handleSelect}
                    openOnFocus={true}
                    placeholder={t(TYPE_OF_GLASS_DAMAGE_PLACEHOLDER)}
                    value={type}
                    {...{ customCAN, options }}
                />
            </Grid>
        </PageLayout>
    );
};
