import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { wizardRouterActions as wizardActions } from 'sagas/wizardRouter';
import { useI18n } from '../../../hooks/useI18n';
import { useGoBack } from '../../../hooks/useGoBack';
import { Controller, useForm } from 'react-hook-form';
import dispatcherWithPromise from '../../../utils/dispatcherWithPromise';
import { commonActions } from '../../../sagas/common';
import {
    ClaimantModel,
    FormChangeable,
    Grid,
    HiddenInputSubmit,
    isYes,
    MuiTextInput,
    Nullable,
    PageLayout,
} from '@protectorinsurance/ds-can';
import { FormFieldNames } from '../../../config/formFieldNames';
import { PhraseKeys } from '../../../config/phraseKeys';
import { AutocompleteValueKeys } from '../../../config/AutocompleteValueKeys';
import { crossContactInfoFieldValidation } from '../../../utils/validation/crossContactInfoFieldValidation';
import { selectClaimantInformation, selectIsUnderaged } from '../../../sagas/selectors/lpoSelectors';
import { lpoActions } from '../../../sagas/lpo';
import { claimantInformationSchema } from '../../../validations/schemas/claimantInformationSchema';
import { LpoRoutePaths } from '../../../config/wizardRouter/lpoWizardRoutes';
import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment';
import { selectCustomCAN } from '../../../sagas/selectors/commonSelectors';

/**
 * Destructure necessary imports
 */
const { CITY, EMAIL, FIRST_NAME, LAST_NAME, NATIONAL_IDENTITY, STREET, PHONE, TITLE: TITLE_FORM, ZIP } = FormFieldNames;
const {
    BACK_BUTTON,
    CITY_LABEL,
    CITY_PLACEHOLDER,
    CONTINUE_BUTTON,
    EMAIL_LABEL,
    EMAIL_PLACEHOLDER,
    FIRST_NAME_LABEL,
    FIRST_NAME_PLACEHOLDER,
    HELP_TEXT,
    LAST_NAME_LABEL,
    LAST_NAME_PLACEHOLDER,
    NATIONAL_IDENTITY_LABEL,
    NATIONAL_IDENTITY_PLACEHOLDER,
    PHONE_LABEL,
    PHONE_PLACEHOLDER,
    PAGE_NAME,
    STREET_LABEL,
    STREET_PLACEHOLDER,
    SUB_TITLE,
    TITLE,
    TITLE_LABEL,
    TITLE_PLACEHOLDER,
    ZIP_LABEL,
    ZIP_PLACEHOLDER,
} = PhraseKeys;
const {
    CITY: CITY_AUTOCOMPLETE,
    EMAIL: EMAIL_AUTOCOMPLETE,
    FAMILY_NAME,
    GIVEN_NAME,
    POSTAL_CODE,
    STREET_ADDRESS,
    TEL,
} = AutocompleteValueKeys;
const { GROUP_ACCIDENT_GUARDIAN_INFORMATION } = LpoRoutePaths;

interface ClaimantFormModel extends Omit<ClaimantModel, 'dateOfBirth'> {
    dateOfBirth?: Nullable<Date>;
}

/**
 * Page view and page logic
 */
export const GlassClaimantInformationPage = () => {
    const dispatch = useDispatch();
    const claimantInformation = useSelector(selectClaimantInformation);
    const isUnderaged = useSelector(selectIsUnderaged);
    const customCAN = useSelector(selectCustomCAN);
    const { t } = useI18n();
    const tWithNS = useI18n('lpo.glass.claimantInformation');
    const {
        control,
        formState: { errors },
        handleSubmit,
        setValue,
        trigger,
    } = useForm<ClaimantFormModel>({
        resolver: yupResolver(claimantInformationSchema(t)),
        defaultValues: {
            ...claimantInformation,
            dateOfBirth: claimantInformation.dateOfBirth ? claimantInformation.dateOfBirth.toDate() : null,
        },
    });
    const handleBackButton = useGoBack();

    const handleBlur = async (e: FormChangeable) => {
        e.preventDefault();
        const { id } = e.currentTarget;
        await trigger(id);
    };

    const handleChange = async (e: FormChangeable) => {
        e.preventDefault();
        const { id, value } = e.currentTarget;
        await setValue(id, value, { shouldValidate: true });
        await crossContactInfoFieldValidation(id, trigger);
    };

    const onSubmit = (values: ClaimantFormModel) => {
        let nextAction = wizardActions.goToNext();
        if (isYes(isUnderaged)) {
            nextAction = wizardActions.goTo(GROUP_ACCIDENT_GUARDIAN_INFORMATION);
        }

        dispatcherWithPromise(dispatch, lpoActions.update, {
            claimantInformation: { ...values, dateOfBirth: values.dateOfBirth ? moment(values.dateOfBirth) : null },
        })
            .then(() => dispatcherWithPromise(dispatch, commonActions.send))
            .then(() => dispatch(nextAction));
    };

    return (
        <PageLayout
            backBtnText={t(BACK_BUTTON)}
            continueBtnText={t(CONTINUE_BUTTON)}
            domainTitle={t(PAGE_NAME)}
            footerText={tWithNS.t(HELP_TEXT)}
            handleContinueButton={handleSubmit(onSubmit)}
            headerSubTitle={tWithNS.t(SUB_TITLE)}
            headerTitle={tWithNS.t(TITLE)}
            {...{ handleBackButton }}
        >
            <form onSubmit={handleSubmit(onSubmit)}>
                <HiddenInputSubmit />
                <Grid className={'align-center'}>
                    <Controller
                        control={control}
                        name={FIRST_NAME}
                        render={({ field: { ref, ...field } }) => (
                            <MuiTextInput
                                {...field}
                                autoComplete={GIVEN_NAME}
                                error={!!errors.firstName}
                                errorMessage={errors.firstName?.message}
                                id={FIRST_NAME}
                                inputFieldWrapper={'col-4'}
                                label={t(FIRST_NAME_LABEL)}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                placeholder={t(FIRST_NAME_PLACEHOLDER)}
                                reference={ref}
                                {...{ customCAN }}
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name={LAST_NAME}
                        render={({ field: { ref, ...field } }) => (
                            <MuiTextInput
                                {...field}
                                autoComplete={FAMILY_NAME}
                                error={!!errors.lastName}
                                errorMessage={errors.lastName?.message}
                                id={LAST_NAME}
                                inputFieldWrapper={'col-4'}
                                label={t(LAST_NAME_LABEL)}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                placeholder={t(LAST_NAME_PLACEHOLDER)}
                                reference={ref}
                                {...{ customCAN }}
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name={TITLE_FORM}
                        render={({ field: { ref, ...field } }) => (
                            <MuiTextInput
                                {...field}
                                error={!!errors.title}
                                errorMessage={errors.title?.message}
                                id={TITLE_FORM}
                                inputFieldWrapper={'col-4'}
                                label={t(TITLE_LABEL)}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                placeholder={t(TITLE_PLACEHOLDER)}
                                reference={ref}
                                {...{ customCAN }}
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name={EMAIL}
                        render={({ field: { ref, ...field } }) => (
                            <MuiTextInput
                                {...field}
                                autoComplete={EMAIL_AUTOCOMPLETE}
                                error={!!errors.email}
                                errorMessage={errors.email?.message}
                                id={EMAIL}
                                inputFieldWrapper={'col-4'}
                                label={t(EMAIL_LABEL)}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                placeholder={t(EMAIL_PLACEHOLDER)}
                                reference={ref}
                                type={'email'}
                                {...{ customCAN }}
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name={PHONE}
                        render={({ field: { ref, ...field } }) => (
                            <MuiTextInput
                                {...field}
                                autoComplete={TEL}
                                error={!!errors.phone}
                                errorMessage={errors.phone?.message}
                                id={PHONE}
                                inputFieldWrapper={'col-4'}
                                inputMode={'tel'}
                                label={t(PHONE_LABEL)}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                placeholder={t(PHONE_PLACEHOLDER)}
                                reference={ref}
                                {...{ customCAN }}
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name={NATIONAL_IDENTITY}
                        render={({ field: { ref, ...field } }) => (
                            <MuiTextInput
                                {...field}
                                error={!!errors.nationalIdentity}
                                errorMessage={errors.nationalIdentity?.message}
                                id={NATIONAL_IDENTITY}
                                inputFieldWrapper={'col-4'}
                                label={t(NATIONAL_IDENTITY_LABEL)}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                placeholder={t(NATIONAL_IDENTITY_PLACEHOLDER)}
                                reference={ref}
                                {...{ customCAN }}
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name={STREET}
                        render={({ field: { ref, ...field } }) => (
                            <MuiTextInput
                                {...field}
                                autoComplete={STREET_ADDRESS}
                                error={!!errors.street}
                                errorMessage={errors.street?.message}
                                id={STREET}
                                inputFieldWrapper={'col-4'}
                                label={t(STREET_LABEL)}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                placeholder={t(STREET_PLACEHOLDER)}
                                reference={ref}
                                {...{ customCAN }}
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name={ZIP}
                        render={({ field: { ref, ...field } }) => (
                            <MuiTextInput
                                {...field}
                                autoComplete={POSTAL_CODE}
                                error={!!errors.zip}
                                errorMessage={errors.zip?.message}
                                id={ZIP}
                                inputFieldWrapper={'col-4'}
                                label={t(ZIP_LABEL)}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                placeholder={t(ZIP_PLACEHOLDER)}
                                reference={ref}
                                {...{ customCAN }}
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name={CITY}
                        render={({ field: { ref, ...field } }) => (
                            <MuiTextInput
                                {...field}
                                autoComplete={CITY_AUTOCOMPLETE}
                                error={!!errors.city}
                                errorMessage={errors.city?.message}
                                id={CITY}
                                inputFieldWrapper={'col-4'}
                                label={t(CITY_LABEL)}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                placeholder={t(CITY_PLACEHOLDER)}
                                reference={ref}
                                {...{ customCAN }}
                            />
                        )}
                    />
                </Grid>
            </form>
        </PageLayout>
    );
};
