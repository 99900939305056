import { put, takeEvery, takeLatest } from 'redux-saga/effects';
import {
    AccidentSketchTypeModel,
    actionWithPromise,
    ClaimDescriptionTypeModel,
    ClaimLocationTypeModel,
    CollisionSituationTypeKeys,
    CompanyModel,
    Datable,
    DriverInformationModel,
    emptyFn,
    initDriverInformation,
    initLocation,
    initPolicyHoldersContact,
    initReporterInformation,
    initVehicleModel,
    InjuredPersonInformationListTypeModel,
    LocationModel,
    MissingItemsTypeModel,
    MotorClaimCauseTypeModel,
    NeedVehicleTowingModel,
    Nullable,
    ObjectWithDynamicKey,
    OwnerInformationListTypeModel,
    PersonModel,
    PoliceCaseNumberTypeModel,
    PolicyHoldersContactModel,
    Rejectable,
    ReporterInformationModel,
    Resolvable,
    RoundaboutPositionTypeKeys,
    SelectedVehicleTypeModel,
    TypeOfAnimalCollisionTypeKeys,
    TypeOfAutoClaimModel,
    updateOrAddVehicle,
    VehicleModel,
    VehicleServiceModel,
    VehiclesModel,
    WhoAtFaultTypeKeys,
    WitnessesTypeModel,
    YesNoModel,
} from '@protectorinsurance/ds-can';

/**
 * Constants
 */
export enum MotorActionTypes {
    UPDATE = '@motor/UPDATE',
    UPDATED = '@motor/UPDATED',
    UPDATE_CLAIM_VEHICLE = '@motor/vehicles/UPDATE_CLAIM_VEHICLE',
}

/**
 * Interfaces
 */

export interface IMotorUpdateAction {
    type: MotorActionTypes.UPDATE | MotorActionTypes.UPDATED;
    data?: Partial<MotorState>;
    resolve?: Resolvable;
    reject?: Rejectable;
}
export interface IVehicleAction {
    type: MotorActionTypes.UPDATE_CLAIM_VEHICLE;
    data?: Partial<VehicleModel>;
    resolve?: Resolvable;
    reject?: Rejectable;
}
export type IMotorAction = IMotorUpdateAction | IVehicleAction;

export interface MotorState extends ObjectWithDynamicKey {
    accidentDescription: ClaimDescriptionTypeModel;
    accidentLocation: LocationModel;
    accidentSketch: AccidentSketchTypeModel;
    claimCause: MotorClaimCauseTypeModel;
    claimDate: Datable;
    claimDescription: ClaimDescriptionTypeModel;
    claimDiscovererInformation: PersonModel;
    claimLocation: ClaimLocationTypeModel;
    claimNumber: Nullable<string>;
    claimantRoundaboutPositioning: RoundaboutPositionTypeKeys;
    collisionSituation: CollisionSituationTypeKeys;
    companyInformation: CompanyModel;
    counterpartyRoundaboutPositioning: RoundaboutPositionTypeKeys;
    distanceFromRoadSide: Nullable<number>;
    driverInformation: DriverInformationModel;
    drivingSpeed: Nullable<number>;
    externalReference: Nullable<string>;
    fireClaimDescription: ClaimDescriptionTypeModel;
    hasAllKeys: YesNoModel;
    hasAnimalEscaped: YesNoModel;
    hasNonVehicleDamages: YesNoModel;
    hasParkedWitnesses: YesNoModel;
    hasPersonInjuries: YesNoModel;
    hasWitnesses: YesNoModel;
    injuredPersonInformationList: InjuredPersonInformationListTypeModel;
    isCounterparty: boolean;
    isCounterpartyKnown: YesNoModel;
    isCounterpartyStationary: YesNoModel;
    isCyclistResponsible: boolean;
    isDriving: YesNoModel;
    isItemMissing: YesNoModel;
    isLaneChange: YesNoModel;
    isOtherVehicleInvolved: YesNoModel;
    isOverRoadCenterUnknown: boolean;
    isPoliceContacted: YesNoModel;
    isReversing: YesNoModel;
    isSelfDiscoveredClaim: YesNoModel;
    isVehicleRecovered: YesNoModel;
    isVehicleStolen: YesNoModel;
    missingItems: MissingItemsTypeModel;
    needVehicleTowing: NeedVehicleTowingModel;
    otherMissingItemsClaimDescription: ClaimDescriptionTypeModel;
    otherPartReporting: YesNoModel;
    ownerInformationList: OwnerInformationListTypeModel;
    parkingDate: Datable;
    policeCaseNumber: PoliceCaseNumberTypeModel;
    policeDistrict: Nullable<string>;
    policyHoldersContact: PolicyHoldersContactModel;
    recovererInformation: PersonModel;
    recoveryDate: Datable;
    reporterInformation: ReporterInformationModel;
    rightOfWayType: Nullable<string>;
    roadConditionClaimDescription: ClaimDescriptionTypeModel;
    roadWidth: Nullable<number>;
    searchedVehicles: VehiclesModel;
    selectedVehicle: VehicleModel;
    selectedVehicleId: SelectedVehicleTypeModel;
    speedLimit: Nullable<number>;
    speedOnImpact: Nullable<number>;
    theftAndDamagesClaimDescription: ClaimDescriptionTypeModel;
    thirdPartySpeed: Nullable<number>;
    timeSinceAction: Nullable<string>;
    typeOfAnimalCollision: TypeOfAnimalCollisionTypeKeys;
    typeOfAutoClaim: TypeOfAutoClaimModel;
    vehicles: VehiclesModel;
    whoAtFault: WhoAtFaultTypeKeys;
    whoResponsibleClaimDescription: ClaimDescriptionTypeModel;
    witnesses: WitnessesTypeModel;
}

/**
 * Initial state
 */
export const motorInitState: MotorState = {
    accidentDescription: '',
    accidentLocation: initLocation,
    accidentSketch: {
        data: null,
        blob: null,
    },
    claimCause: null,
    claimDate: null,
    claimDescription: '',
    claimDiscovererInformation: {
        firstName: null,
        lastName: null,
        phone: null,
        email: null,
    },
    claimLocation: null,
    claimNumber: null,
    claimantRoundaboutPositioning: null,
    collisionSituation: null,
    companyInformation: {
        name: null,
        businessNumber: null,
        policyNumber: null,
    },
    counterpartyRoundaboutPositioning: null,
    distanceFromRoadSide: null,
    driverInformation: initDriverInformation,
    drivingSpeed: null,
    externalReference: null,
    fireClaimDescription: '',
    hasAllKeys: null,
    hasAnimalEscaped: null,
    hasNonVehicleDamages: null,
    hasParkedWitnesses: null,
    hasPersonInjuries: null,
    hasWitnesses: null,
    injuredPersonInformationList: [],
    isCounterparty: false,
    isCounterpartyKnown: null,
    isCounterpartyStationary: null,
    isCyclistResponsible: false,
    isDriving: null,
    isItemMissing: null,
    isLaneChange: null,
    isOtherVehicleInvolved: null,
    isOverRoadCenterUnknown: false,
    isPoliceContacted: null,
    isReversing: null,
    isSelfDiscoveredClaim: null,
    isVehicleRecovered: null,
    isVehicleStolen: null,
    missingItems: [],
    needVehicleTowing: null,
    otherMissingItemsClaimDescription: '',
    otherPartReporting: null,
    ownerInformationList: [],
    parkingDate: null,
    policeCaseNumber: null,
    policeDistrict: null,
    policyHoldersContact: initPolicyHoldersContact,
    recovererInformation: {
        firstName: null,
        lastName: null,
        phone: null,
        email: null,
    },
    recoveryDate: null,
    reporterInformation: initReporterInformation,
    rightOfWayType: null,
    roadConditionClaimDescription: '',
    roadWidth: null,
    searchedVehicles: [],
    selectedVehicle: initVehicleModel,
    selectedVehicleId: null,
    speedLimit: null,
    speedOnImpact: null,
    theftAndDamagesClaimDescription: '',
    thirdPartySpeed: null,
    timeSinceAction: null,
    typeOfAnimalCollision: null,
    typeOfAutoClaim: null,
    vehicles: [],
    whoAtFault: null,
    whoResponsibleClaimDescription: '',
    witnesses: [],
};

/**
 * Default reducer
 *
 * @param state
 * @param action
 */
export default function (state = motorInitState, { type, data }: IMotorAction) {
    switch (type) {
        case MotorActionTypes.UPDATED:
            return { ...state, ...data };
        case MotorActionTypes.UPDATE_CLAIM_VEHICLE:
            return { ...state, vehicles: updateOrAddVehicle(state.vehicles, data as VehicleServiceModel) };
        default:
            return state;
    }
}

/**
 * Redux Actions
 */
export const motorActions = {
    update: actionWithPromise<MotorActionTypes.UPDATE, Partial<MotorState>>(MotorActionTypes.UPDATE),
    updated: actionWithPromise<MotorActionTypes.UPDATED, Partial<MotorState>>(MotorActionTypes.UPDATED),
    updateClaimVehicle: actionWithPromise<MotorActionTypes.UPDATE_CLAIM_VEHICLE, Partial<VehicleServiceModel>>(
        MotorActionTypes.UPDATE_CLAIM_VEHICLE
    ),
};

/**
 * Saga watchers
 */
export const motorWatcher = function* () {
    yield takeEvery(MotorActionTypes.UPDATE, motorSagas.update);
    yield takeLatest(MotorActionTypes.UPDATE_CLAIM_VEHICLE, motorSagas.updateClaimVehicle);
};

/**
 * Saga functions
 */
export const motorSagas = {
    *update({ data, resolve = emptyFn, reject = emptyFn }: IMotorUpdateAction) {
        try {
            yield put(motorActions.updated(data));
            resolve();
        } catch (e) {
            reject();
        }
    },
    updateClaimVehicle({ resolve = emptyFn }: IVehicleAction) {
        // for using dispatchWithPromise
        resolve();
    },
};
