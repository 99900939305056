import { OptionType } from '@protectorinsurance/ds-can';

export const policeDistrictOptions: OptionType[] = [
    { value: 'bornholmsPoliti3700', label: 'Bornholms Politi' },
    { value: 'fynsPoliti5000', label: 'Fyns Politi' },
    { value: 'københavnsPoliti1567', label: 'Københavns Politi' },
    { value: 'københavnsVestegnsPoliti2620', label: 'Københavns Vestegns Politi' },
    { value: 'midtOgVestsjyllandsPoliti7500', label: 'Midt- og Vestsjyllands Politi' },
    { value: 'midtOgVestsjællandsPoliti4000', label: 'Midt- og Vestsjællands Politi' },
    { value: 'nordjyllandsPoliti9000', label: 'Nordjyllands Politi' },
    { value: 'nordsjællandsPoliti3000', label: 'Nordsjællands Politi' },
    { value: 'sydOgSønderjyllandsPoliti6700', label: 'Syd- og Sønderjyllands Politi' },
    { value: 'SydsjællandsOgLollandFalstersPoliti4700', label: 'Sydsjællands og Lolland-Falsters Politi' },
    { value: 'SydøstjyllandsPoliti8700', label: 'Sydøstjyllands Politi' },
    { value: 'østjyllandsPoliti8000', label: 'Østjyllands Politi' },
];
