import React from 'react';
import { LpoSummaryProps } from '../../../types/SummaryProps';
import { TEST_ID_PREFIX } from 'utils/testIdUtil';
import { LpoRoutePaths } from '../../../config/wizardRouter/lpoWizardRoutes';
import { BaseRoutePaths } from '../../../config/wizardRouter/baseWizardRoutes';
import { dtParseAndFormat } from '../../../utils/date/dateUtils';
import { renderLob } from './components/start/RenderLob';
import { renderPrivacy } from './components/start/RenderPrivacy';
import { renderClaimDate } from './components/start/RenderClaimDate';
import { renderClaimReporterRole } from './components/start/RenderClaimReporterRole';
import { renderClaimType } from './components/start/RenderClaimType';
import { renderLocation } from './components/start/RenderLocation';
import { is, isYes, LobKeys, trueFalseToYesNo } from '@protectorinsurance/ds-can';
import classNames from 'classnames';
import { renderUnit } from './components/start/RenderUnit';
import { renderRegistrationNumber } from './components/start/RenderRegistrationNumber';
import { renderIsPersonInjury } from './components/start/RenderIsPersonInjury';
import { createTextYesNoDontKnow } from '../../../utils/displayUtils';
import { renderLiabilityDamageClaimDescription } from './components/start/RenderLiabilityDamageClaimDescription';
import { renderClaimantInformation } from './components/start/RenderClaimantInformation';

/**
 * Destructure necessary imports
 */
const { INDEX } = BaseRoutePaths;
const {
    DYN_CAROUSEL_LIABILITY_IS_PERSON_INJURY,
    DYN_CAROUSEL_START_CLAIM_REPORTER_ROLE,
    LIABILITY_CLAIMANT_INFORMATION,
    LIABILITY_DAMAGE_CLAIM_DESCRIPTION,
    PROPERTY_CLAIM_TYPE,
    START_ACCIDENT_LOCATION,
    START_CLAIM_DATE,
    START_PRIVACY,
    START_PROPERTY_ADDRESS,
    TRANSPORT_VEHICLE_REGISTRATION_NUMBER,
} = LpoRoutePaths;
const { PERSON } = LobKeys;

/**
 * Component view and component logic
 */
export const DisplayStartSummary = ({ model, t, tWithNS, handleClick, upload, common }: LpoSummaryProps) => {
    const {
        acceptedPrivacy,
        accidentLocation,
        claimDate,
        claimReporterRole,
        claimType,
        claimantInformationList,
        isPersonInjury,
        isWorkRelated,
        liabilityDamageClaimDescription,
        registrationNumber,
    } = model;
    const { lob } = common;
    let lobKey = `lob.select.${lob}`;
    if (is(lob, PERSON)) {
        if (isYes(isWorkRelated)) {
            lobKey = `lob.select.glass.${lob}`;
        } else {
            lobKey = `lob.select.groupAccident.${lob}`;
        }
    }

    // Classes
    const locationClass = classNames(
        `${!is(accidentLocation.unit, null) ? 'col-6' : 'col-12'}`,
        'divider text-align-left'
    );

    return (
        <>
            {renderLob({
                inputWrapperClass: 'col-12 divider text-align-left',
                textKey: tWithNS('lob.heading.title'),
                dataTestId: TEST_ID_PREFIX.lob,
                buttonText: t(lobKey),
                path: INDEX,
                handleClick,
            })}

            {renderPrivacy(
                {
                    inputWrapperClass: 'col-12 divider text-align-left',
                    textKey: tWithNS('privacy.heading.title'),
                    dataTestId: TEST_ID_PREFIX.acceptedPrivacy,
                    buttonText: t(trueFalseToYesNo(acceptedPrivacy)),
                    path: START_PRIVACY,
                    handleClick,
                },
                lob
            )}

            {renderClaimReporterRole(
                {
                    inputWrapperClass: 'col-12 divider text-align-left',
                    textKey: tWithNS('claimReporterRole.heading.title'),
                    dataTestId: TEST_ID_PREFIX.claimReporterRole,
                    buttonText: claimReporterRole ? t(`lpo.start.claimReporterRole.select.${claimReporterRole}`) : '-',
                    path: DYN_CAROUSEL_START_CLAIM_REPORTER_ROLE,
                    handleClick,
                },
                lob
            )}

            {renderClaimType(
                {
                    inputWrapperClass: 'col-12 divider text-align-left',
                    textKey: tWithNS('claimType.heading.title'),
                    dataTestId: TEST_ID_PREFIX.claimType,
                    buttonText: claimType ? t(`lpo.claimType.select.${claimType}`) : '-',
                    path: PROPERTY_CLAIM_TYPE,
                    handleClick,
                },
                lob
            )}

            {renderRegistrationNumber(
                {
                    inputWrapperClass: 'col-12 divider text-align-left',
                    textKey: tWithNS('vehicleRegistrationNumber.heading.title'),
                    dataTestId: TEST_ID_PREFIX.registrationNumber,
                    buttonText: registrationNumber ? registrationNumber : '-',
                    path: TRANSPORT_VEHICLE_REGISTRATION_NUMBER,
                    handleClick,
                },
                claimType,
                t
            )}

            {renderClaimDate({
                inputWrapperClass: 'col-12 divider text-align-left',
                textKey: tWithNS('claimDate.heading.title'),
                dataTestId: TEST_ID_PREFIX.claimDate,
                buttonText: claimDate ? dtParseAndFormat(claimDate) : '-',
                path: START_CLAIM_DATE,
                handleClick,
            })}

            {renderIsPersonInjury(
                {
                    inputWrapperClass: 'col-12 divider text-align-left',
                    textKey: tWithNS('isPersonInjury.heading.title'),
                    dataTestId: TEST_ID_PREFIX.isPersonInjury,
                    buttonText: t(createTextYesNoDontKnow(isPersonInjury)),
                    path: DYN_CAROUSEL_LIABILITY_IS_PERSON_INJURY,
                    handleClick,
                },
                lob
            )}

            {renderLiabilityDamageClaimDescription(
                {
                    inputWrapperClass: 'col-12 divider text-align-left',
                    textKey: tWithNS('damageClaimDescription.heading.title'),
                    dataTestId: TEST_ID_PREFIX.liabilityDamageClaimDescription,
                    buttonText: liabilityDamageClaimDescription ? liabilityDamageClaimDescription : '-',
                    path: LIABILITY_DAMAGE_CLAIM_DESCRIPTION,
                    handleClick,
                },
                lob,
                isPersonInjury
            )}

            {renderClaimantInformation(
                {
                    list: claimantInformationList,
                    textKey: tWithNS('claimantInformation.heading.title'),
                    path: LIABILITY_CLAIMANT_INFORMATION,
                    show: claimantInformationList.length > 0,
                    dataTestId: TEST_ID_PREFIX.claimantInformation,
                    className: 'col-12 divider text-align-left',
                },
                lob,
                isPersonInjury
            )}

            {renderLocation(
                {
                    inputWrapperClass: locationClass,
                    textKey: tWithNS('accidentLocation.heading.title'),
                    dataTestId: TEST_ID_PREFIX.accidentLocation,
                    buttonText: accidentLocation.note
                        ? accidentLocation.note
                        : accidentLocation.address
                        ? accidentLocation.address
                        : `${accidentLocation.latitude}, ${accidentLocation.longitude}`,
                    path: START_ACCIDENT_LOCATION,
                    handleClick,
                },
                accidentLocation
            )}

            {renderUnit(
                {
                    inputWrapperClass: 'col-6 divider text-align-left',
                    textKey: tWithNS('propertyAddress.heading.title'),
                    dataTestId: TEST_ID_PREFIX.propertyAddress,
                    buttonText: accidentLocation.unit ? accidentLocation.unit : '-',
                    path: START_PROPERTY_ADDRESS,
                    handleClick,
                },
                accidentLocation
            )}
        </>
    );
};
