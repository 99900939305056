import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpApi from 'i18next-http-backend';
import { I18N_API_URL, I18N_PROJECT_KEY_DK } from '../config/i18n';
import { LocaleServiceInstance, isObject, yupSetLocale } from '@protectorinsurance/ds-can';

const devLocale = localStorage.getItem('dev.locale');
const locale = devLocale ? devLocale : LocaleServiceInstance.getLocaleFromUrl().language;

i18n.use(initReactI18next) // passes i18n down to react-i18next
    .use(HttpApi)
    .init(
        {
            lng: locale ? locale : 'en',
            fallbackLng: 'en',
            react: {
                wait: true,
            },
            interpolation: {
                escapeValue: false, // react already safes from xss
            },
            backend: {
                crossDomain: true,
                loadPath: `${I18N_API_URL}/phrases/${I18N_PROJECT_KEY_DK}/{{lng}}`,
                parse: (payload: any) => {
                    try {
                        return JSON.parse(payload).data.content;
                    } catch (e) {
                        console.log(e);
                    }
                },
            },
        },
        (err, t) => {
            if (err) {
                return;
            }

            const yupTranslation: object = t('yup.validation', { returnObjects: true });
            const yupCustomTranslation: object = t('yupCustom.validation', { returnObjects: true });

            const hasYupTranslation = yupTranslation && isObject(yupTranslation);
            const hasYupCustomTranslation = yupCustomTranslation && isObject(yupCustomTranslation);

            if (hasYupTranslation && hasYupCustomTranslation) {
                yupSetLocale(yupTranslation, yupCustomTranslation);
            }
        }
    );
