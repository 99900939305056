import React, { ChangeEvent, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { wizardRouterActions as wizardActions } from 'sagas/wizardRouter';
import { useI18n } from '../../../../hooks/useI18n';
import { useGoBack } from '../../../../hooks/useGoBack';
import {
    findOwnerByVehicles,
    FlowKeys,
    Grid,
    OwnerInformationListModel,
    PageLayout,
    SingleCheckbox,
} from '@protectorinsurance/ds-can';
import { PhraseKeys } from '../../../../config/phraseKeys';
import { FormFieldNames } from '../../../../config/formFieldNames';
import { FormProvider, useForm } from 'react-hook-form';
import dispatcherWithPromise from '../../../../utils/dispatcherWithPromise';
import { motorActions } from '../../../../sagas/motor';
import { commonActions } from '../../../../sagas/common';
import { CounterpartyInformationCompanyForm } from '../../../../components/counterpartyInformation/CounterpartyInformationCompanyForm';
import { CounterpartyInformationPersonForm } from '../../../../components/counterpartyInformation/CounterpartyInformationPersonForm';
import { flowActions } from '../../../../sagas/flow';
import {
    selectOwnerInformationList,
    selectSelectedVehicleId,
    selectVehicles,
} from '../../../../sagas/selectors/motorSelectors';
import { counterpartyOwnerSchema } from '../../../../validations/fieldSchemas/counterpartyOwnerSchema';
import { yupResolver } from '@hookform/resolvers/yup';
import { selectCustomCAN } from '../../../../sagas/selectors/commonSelectors';

/**
 * Destructure necessary imports
 */
const { BACK_BUTTON, CONTINUE_BUTTON, HELP_TEXT, IS_COMPANY_LABEL, PAGE_NAME, SUB_TITLE, TITLE } = PhraseKeys;
const { IS_COMPANY } = FormFieldNames;
const { END } = FlowKeys;

/**
 * Page view and page logic
 */
export const PPCounterpartyInformationPage = () => {
    const dispatch = useDispatch();
    const { t } = useI18n();
    const tWithNS = useI18n('motor.parked.counterpartyInformation');
    const selectedVehicleId = useSelector(selectSelectedVehicleId);
    const ownerInformationList = useSelector(selectOwnerInformationList);
    const vehicles = useSelector(selectVehicles);
    const customCAN = useSelector(selectCustomCAN);
    const vehicleOwner = findOwnerByVehicles(vehicles, ownerInformationList);
    const ownerIsCompany = vehicleOwner?.isCompany;
    const [isCompany, setIsCompany] = useState<boolean>(ownerIsCompany ? ownerIsCompany : false);
    const form = useForm<OwnerInformationListModel>({
        resolver: yupResolver(counterpartyOwnerSchema(t)),
        defaultValues: { ...vehicleOwner },
    });

    const isEmptyForm = (values: OwnerInformationListModel) => {
        const { firstName, lastName, email, phone, isCompany, name, businessNumber } = values;
        let empty: boolean;
        if (isCompany) {
            empty =
                (!name || name.length === 0) &&
                (!businessNumber || businessNumber.length === 0) &&
                (!email || email.length === 0) &&
                (!phone || phone.length === 0);
        } else {
            empty =
                (!firstName || firstName.length === 0) &&
                (!lastName || lastName.length === 0) &&
                (!email || email.length === 0) &&
                (!phone || phone.length === 0);
        }
        return empty;
    };

    const handleBackButton = useGoBack();

    const handleChecked = async (e: ChangeEvent<HTMLInputElement>) => {
        const { checked } = e.target;
        await form.setValue('isCompany', checked);
        await setIsCompany(checked);
    };

    const onSubmit = () => {
        const values = form.getValues();
        if (!isEmptyForm(values)) {
            counterpartyOwnerSchema(t)
                .isValid(values)
                .then((valid) => {
                    if (valid) {
                        const owner = { ...values, damagedItem: selectedVehicleId };
                        dispatcherWithPromise(dispatch, motorActions.update, {
                            ownerInformationList: [owner],
                        })
                            .then(() => dispatcherWithPromise(dispatch, flowActions.update, END))
                            .then(() => dispatcherWithPromise(dispatch, commonActions.send))
                            .then(() => dispatch(wizardActions.goToNext()));
                    } else {
                        form.trigger();
                    }
                });
        } else {
            dispatcherWithPromise(dispatch, commonActions.send).then(() => dispatch(wizardActions.goToNext()));
        }
    };

    return (
        <PageLayout
            backBtnText={t(BACK_BUTTON)}
            continueBtnText={t(CONTINUE_BUTTON)}
            domainTitle={t(PAGE_NAME)}
            footerText={tWithNS.t(HELP_TEXT)}
            handleContinueButton={onSubmit}
            headerSubTitle={tWithNS.t(SUB_TITLE)}
            headerTitle={tWithNS.t(TITLE)}
            {...{ handleBackButton }}
        >
            <FormProvider {...form}>
                <Grid>
                    <SingleCheckbox
                        checked={isCompany}
                        handleChange={handleChecked}
                        name={IS_COMPANY}
                        wrapperClass={'col-12 multiple margin-bottom'}
                        {...{ customCAN }}
                    >
                        {t(IS_COMPANY_LABEL)}
                    </SingleCheckbox>
                </Grid>
                {isCompany ? (
                    <CounterpartyInformationCompanyForm onSubmit={onSubmit} />
                ) : (
                    <CounterpartyInformationPersonForm onSubmit={onSubmit} />
                )}
            </FormProvider>
        </PageLayout>
    );
};
