import React from 'react';
import { wizardRouterActions as wizardActions } from '../../../../sagas/wizardRouter';
import {
    CarouselConfigLookupResponse,
    CarouselConfigOptionType,
    Clickable,
    NoIcon,
    YesIcon,
    YesNoKeys,
} from '@protectorinsurance/ds-can';
import { CarouselConfigLookupProps } from '../../../../utils/carouselPageLookup';
import dispatcherWithPromise from '../../../../utils/dispatcherWithPromise';
import { commonActions } from '../../../../sagas/common';
import { LpoRoutePaths } from '../../../wizardRouter/lpoWizardRoutes';
import { selectIsWorkRelated } from '../../../../sagas/selectors/lpoSelectors';
import { lpoActions } from '../../../../sagas/lpo';

/**
 * Destructure necessary imports
 */
const { YES, NO } = YesNoKeys;

/**
 * Page view and page logic
 */
export const glassIsWorkRelatedPage = ({
    t,
    state,
    dispatch,
}: CarouselConfigLookupProps): CarouselConfigLookupResponse<LpoRoutePaths> => {
    const options = [
        { id: YES, name: t(`lpo.glass.isWorkRelated.select.${YES}`), icon: <YesIcon /> },
        { id: NO, name: t(`lpo.glass.isWorkRelated.select.${NO}`), icon: <NoIcon /> },
    ];

    return {
        slug: LpoRoutePaths.DYN_CAROUSEL_GLASS_IS_WORK_RELATED,
        stateKey: 'isWorkRelated',
        i18n: {
            ns: 'lpo.glass.isWorkRelated',
        },
        selectorValue: selectIsWorkRelated(state),
        options,
        onSelect: (id: CarouselConfigOptionType, e: Clickable) => {
            e.preventDefault();
            const option = options?.find((x) => x.id === id);

            if (option) {
                dispatcherWithPromise(dispatch, lpoActions.update, {
                    isWorkRelated: option.id,
                })
                    .then(() => dispatcherWithPromise(dispatch, commonActions.send))
                    .then(() => dispatch(wizardActions.goToNext(option.id)));
            }
        },
    };
};
