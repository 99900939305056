import React, { useEffect, useState } from 'react';
import {
    Clickable,
    Grid,
    PageLayout,
    PartnerWorkshopsLinkCard,
    PrintLink,
    StandardModal,
    TogglesServiceTogglesEnums,
} from '@protectorinsurance/ds-can';
import { PhraseKeys } from '../../../config/phraseKeys';
import '../../../styles/utils/_mixins.scss';
import { PrintableSummaryPage } from 'components/summary/final/PrintableSummary';
import { useI18n } from '../../../hooks/useI18n';
import { useSelector } from 'react-redux';
import {
    selectClaimDate,
    selectCompanyInformation,
    selectIsCounterparty,
    selectReporterInformation,
} from '../../../sagas/selectors/motorSelectors';
import { SERVICE_PARTNER_WORKSHOPS_URL } from '../../../config/services';
import { useToggles } from '../../../hooks/useToggles';
import { useVehicles } from '../../../hooks/useVehicles';
import { selectCustomCAN, selectLocaleLanguageCode } from 'sagas/selectors/commonSelectors';

/**
 * Destructure necessary imports
 */
const { COMPLETED_BUTTON, HELP_TEXT, PAGE_NAME, SUB_TITLE, TITLE } = PhraseKeys;

/**
 * Page view and page logic
 */
export const ReportCompletedPage = () => {
    const [isEnabled] = useToggles();
    const { claimVehicle } = useVehicles();
    const isCounterparty = useSelector(selectIsCounterparty);
    const claimDate = useSelector(selectClaimDate);
    const companyInformation = useSelector(selectCompanyInformation);
    const reporterInformation = useSelector(selectReporterInformation);
    const customCAN = useSelector(selectCustomCAN);
    const prefix = isCounterparty ? 'counterparty' : 'claimant';
    const { t } = useI18n();
    const tWithNs = useI18n(`motor.end.${prefix}.reportCompleted`);
    const language = useSelector(selectLocaleLanguageCode);
    const [showModal, setShowModal] = useState<boolean>(false);

    const handleBackButton = (e: any) => {
        e.preventDefault();
        setShowModal(true);
    };

    const handleCloseModal = (e: Clickable) => {
        e.preventDefault();
        window.history.pushState(null, '', window.location.pathname);
        setShowModal(false);
    };

    const handleConfirmModal = (e: Clickable) => {
        e.preventDefault();
        window.location.href = `/nor/${language}`;
    };

    useEffect(() => {
        window.history.pushState(null, '', window.location.pathname);
        window.addEventListener('popstate', handleBackButton);

        return () => {
            window.removeEventListener('popstate', handleBackButton);
        };
    }, []);

    const handleContinueButton = (e: Clickable) => {
        e.preventDefault();
        window.location.replace(tWithNs.t('content.homePageLink'));
    };

    const printSummary = (e: Clickable) => {
        e.preventDefault();
        window.print();
    };

    return (
        <>
            <PageLayout
                backBtnText={''}
                continueBtnText={t(COMPLETED_BUTTON)}
                domainTitle={t(PAGE_NAME)}
                footerComponent={<PrintLink onClick={printSummary} title={tWithNs.t(HELP_TEXT)} />}
                handleContinueButton={handleContinueButton}
                headerSubTitle={tWithNs.t(SUB_TITLE)}
                headerTitle={tWithNs.t(TITLE)}
                pageClassName={'no-print'}
                showBackButton={false}
            >
                <Grid>
                    <div className={'col-12'}>
                        <p className={'no-print col-12'} tabIndex={0}>
                            {tWithNs.t('body.paragraph.one')}{' '}
                        </p>
                        <p className={'no-print col-12 padding-top'} tabIndex={0}>
                            {tWithNs.t('body.paragraph.two')}
                        </p>

                        {isEnabled(TogglesServiceTogglesEnums.ENABLE_PARTNER_WORKSHOPS) && (
                            <PartnerWorkshopsLinkCard
                                btnText={t('ds.components.partnerWorkshopsLink.btn.text')}
                                data={{
                                    licensePlateNo: claimVehicle?.registrationNumber,
                                    damageDate: claimDate?.format('YYYY-MM-DD'),
                                    policyNumber: companyInformation?.policyNumber,
                                    name: reporterInformation.firstName,
                                    phone: reporterInformation.phone,
                                    email: reporterInformation.email,
                                }}
                                description={t('ds.components.partnerWorkshopsLink.description')}
                                goToUrl={SERVICE_PARTNER_WORKSHOPS_URL}
                                title={t('ds.components.partnerWorkshopsLink.title')}
                                {...{ customCAN }}
                            />
                        )}
                    </div>
                </Grid>
            </PageLayout>
            <PrintableSummaryPage editDescription={false} showSummary={false} />
            {showModal && (
                <StandardModal
                    closeButtonText={t('modal.reportCompleted.closeButtonText')}
                    confirmButtonText={t('modal.reportCompleted.confirmButtonText')}
                    onClose={handleCloseModal}
                    onConfirm={handleConfirmModal}
                    showCloseButton={true}
                    showConfirmButton={true}
                    title={t('modal.reportCompleted.title')}
                >
                    <div className={'dialog__content text-center'}>{t('modal.reportCompleted.bodyText')}</div>
                </StandardModal>
            )}
        </>
    );
};
