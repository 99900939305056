import React from 'react';
import { wizardRouterActions as wizardActions } from '../../../../sagas/wizardRouter';
import {
    AtWorkIcon,
    CarouselConfigLookupResponse,
    CarouselConfigOptionType,
    ClaimLocationKeys,
    Clickable,
    OtherInjuriesIcon,
    WayToWorkIcon,
} from '@protectorinsurance/ds-can';
import { CarouselConfigLookupProps } from '../../../../utils/carouselPageLookup';
import dispatcherWithPromise from '../../../../utils/dispatcherWithPromise';
import { commonActions } from '../../../../sagas/common';
import { LpoRoutePaths } from '../../../wizardRouter/lpoWizardRoutes';
import { selectClaimLocation } from '../../../../sagas/selectors/lpoSelectors';
import { lpoActions } from '../../../../sagas/lpo';

/**
 * Destructure necessary imports
 */
const { AT_WORK, ON_WAY_TO_WORK, OTHER } = ClaimLocationKeys;

/**
 * Page view and page logic
 */
export const glassClaimLocationPage = ({
    t,
    state,
    dispatch,
}: CarouselConfigLookupProps): CarouselConfigLookupResponse<LpoRoutePaths> => {
    const options = [
        { id: AT_WORK, name: t(`lpo.glass.claimLocation.select.${AT_WORK}`), icon: <AtWorkIcon /> },
        { id: ON_WAY_TO_WORK, name: t(`lpo.glass.claimLocation.select.${ON_WAY_TO_WORK}`), icon: <WayToWorkIcon /> },
        { id: OTHER, name: t(`lpo.glass.claimLocation.select.${OTHER}`), icon: <OtherInjuriesIcon /> },
    ];

    return {
        slug: LpoRoutePaths.DYN_CAROUSEL_GLASS_CLAIM_LOCATION,
        stateKey: 'claimLocation',
        i18n: {
            ns: 'lpo.glass.claimLocation',
        },
        selectorValue: selectClaimLocation(state),
        options,
        onSelect: (id: CarouselConfigOptionType, e: Clickable) => {
            e.preventDefault();
            const option = options?.find((x) => x.id === id);

            if (option) {
                dispatcherWithPromise(dispatch, lpoActions.update, {
                    claimLocation: option.id,
                })
                    .then(() => dispatcherWithPromise(dispatch, commonActions.send))
                    .then(() => dispatch(wizardActions.goToNext(option.id)));
            }
        },
    };
};
