import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { wizardRouterActions as wizardActions } from 'sagas/wizardRouter';
import {
    AddIcon,
    CircleIconButton,
    Clickable,
    generateId,
    Grid,
    initVehicleModel,
    PageLayout,
    PartyRelationKeys,
    SELECT_NO,
    SELECT_YES,
    SelectedVehicleTypeModel,
    StandardModal,
    VehicleListItem,
    VehicleModel,
} from '@protectorinsurance/ds-can';
import { useGoBack } from '../../../../hooks/useGoBack';
import { useI18n } from '../../../../hooks/useI18n';
import { PhraseKeys } from '../../../../config/phraseKeys';
import { TEST_ID_PREFIX } from '../../../../utils/testIdUtil';
import { MotorRoutePaths } from '../../../../config/wizardRouter/motorWizardRoutes';
import dispatcherWithPromise from '../../../../utils/dispatcherWithPromise';
import { motorActions } from '../../../../sagas/motor';
import { useVehicles } from '../../../../hooks/useVehicles';
import { selectIsCounterparty, selectSelectedVehicle } from '../../../../sagas/selectors/motorSelectors';
import { commonActions } from '../../../../sagas/common';
import { selectCustomCAN } from '../../../../sagas/selectors/commonSelectors';

/**
 * Destructure necessary imports
 */
const {
    ADD_BUTTON,
    ADD_UNKNOWN_BUTTON,
    BACK_BUTTON,
    CONTINUE_BUTTON,
    HELP_TEXT,
    PAGE_NAME,
    SUB_TITLE,
    TITLE,
    UNKNOWN_COUNTERPARTY,
} = PhraseKeys;
const { DRIVING_VEHICLE_VEHICLE_REGISTRATION_NUMBER } = MotorRoutePaths;
const { vehicleList } = TEST_ID_PREFIX;
const { OTHER } = PartyRelationKeys;

/**
 * Page view and page logic
 */
export const DVDamagesPage = () => {
    const dispatch = useDispatch();
    const { vehicles, claimVehicle, otherVehicles, counterpartyVehicle } = useVehicles();
    const isCounterparty = useSelector(selectIsCounterparty);
    const selectedVehicle = useSelector(selectSelectedVehicle);
    const customCAN = useSelector(selectCustomCAN);
    const internalId = generateId();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { t } = useI18n();
    const tWithNS = useI18n('motor.driving.damages');
    const handleBackButton = useGoBack();

    const claimVehicleHeader = isCounterparty ? 'heading.counterpartyVehicle' : 'heading.claimVehicle';
    const addButtonLabel = claimVehicle ? 'add.button' : 'add.buttonCounterparty';

    const handleAddButton = (e: Clickable) => {
        e.preventDefault();
        dispatch(wizardActions.goTo(DRIVING_VEHICLE_VEHICLE_REGISTRATION_NUMBER));
    };

    const handleAddUnknownButton = (e: Clickable) => {
        e.preventDefault();
        setIsModalOpen(true);
    };

    const handleContinueButton = (e: Clickable) => {
        e.preventDefault();
        dispatcherWithPromise(dispatch, commonActions.send).then(() => dispatch(wizardActions.goToNext()));
    };

    const handleDelete = (id: SelectedVehicleTypeModel, e?: Clickable) => {
        e?.preventDefault();
        const vehicleArray = vehicles.filter((vehicle: VehicleModel) => vehicle.internalId !== id);
        dispatcherWithPromise(dispatch, motorActions.update, { vehicles: vehicleArray });
    };

    const handleModalClose = () => {
        setIsModalOpen(() => false);
    };

    const handleModalConfirm = () => {
        setIsModalOpen(() => false);

        const vehicle = {
            ...selectedVehicle,
            country: null,
            internalId: internalId,
            make: t(UNKNOWN_COUNTERPARTY),
            otherId: null,
            partyRelation: OTHER,
            vehicleType: null,
        };

        dispatcherWithPromise(dispatch, motorActions.update, {
            vehicles: [...vehicles, { ...vehicle }],
            selectedVehicle: initVehicleModel,
        }).then(() => dispatcherWithPromise(dispatch, commonActions.send));
    };

    const renderClaimVehicle = () =>
        claimVehicle && (
            <>
                <h2 className={'vehicle-list-header'}>{tWithNS.t(claimVehicleHeader)}</h2>
                <Grid>
                    <VehicleListItem
                        dataTestId={`${vehicleList}claimVehicle`}
                        make={claimVehicle?.make}
                        model={claimVehicle?.model}
                        registrationNumber={claimVehicle?.registrationNumber}
                        type={claimVehicle?.type}
                        vehicleType={claimVehicle?.vehicleType}
                        {...{ customCAN }}
                    />
                </Grid>
            </>
        );

    const renderCounterpartyVehicle = () =>
        counterpartyVehicle && (
            <>
                <h2 className={'vehicle-list-header'}>{tWithNS.t('heading.claimVehicle')}</h2>
                <Grid>
                    <VehicleListItem
                        dataTestId={`${vehicleList}counterpartyVehicle`}
                        make={counterpartyVehicle?.make}
                        model={counterpartyVehicle?.model}
                        registrationNumber={counterpartyVehicle?.registrationNumber}
                        type={counterpartyVehicle?.type}
                        vehicleType={counterpartyVehicle?.vehicleType}
                        {...{ customCAN }}
                    />
                </Grid>
            </>
        );

    const renderOtherVehicles = () =>
        otherVehicles.length > 0 && (
            <>
                <h2 className={'vehicle-list-header'}>{tWithNS.t('heading.otherVehicles')}</h2>
                <Grid>
                    {otherVehicles.map((vehicle: VehicleModel, idx: number) => {
                        const { registrationNumber, vehicleType, make, type, model, internalId } = vehicle;
                        return (
                            <VehicleListItem
                                dataTestId={`${vehicleList}${idx}`}
                                handleDelete={() => handleDelete(internalId)}
                                {...{ customCAN, make, model, registrationNumber, type, vehicleType }}
                            />
                        );
                    })}
                </Grid>
            </>
        );

    return (
        <PageLayout
            backBtnText={t(BACK_BUTTON)}
            continueBtnText={t(CONTINUE_BUTTON)}
            disableContinueButton={vehicles.length < 2}
            domainTitle={t(PAGE_NAME)}
            footerText={tWithNS.t(HELP_TEXT)}
            headerSubTitle={tWithNS.t(SUB_TITLE)}
            headerTitle={tWithNS.t(TITLE)}
            {...{ handleBackButton, handleContinueButton }}
        >
            {renderCounterpartyVehicle()}
            {renderClaimVehicle()}
            {renderOtherVehicles()}

            <CircleIconButton
                ariaLabel={t(ADD_BUTTON)}
                className={'dual add-btn'}
                dataTestId={'btn-add-vehicle'}
                handleClick={handleAddButton}
                icon={<AddIcon />}
                label={tWithNS.t(addButtonLabel)}
            />
            {claimVehicle && (
                <CircleIconButton
                    ariaLabel={t(ADD_UNKNOWN_BUTTON)}
                    className={'dual add-btn'}
                    dataTestId={'btn-add-unknown-vehicle'}
                    handleClick={handleAddUnknownButton}
                    icon={<AddIcon />}
                    label={tWithNS.t('add.buttonUnknown')}
                />
            )}
            {isModalOpen && (
                <StandardModal
                    closeButtonText={t(SELECT_NO)}
                    confirmButtonText={t(SELECT_YES)}
                    onClose={handleModalClose}
                    onConfirm={handleModalConfirm}
                    showCloseButton={true}
                    showConfirmButton={true}
                    title={tWithNS.t('modal.title')}
                >
                    <div className="dialog__content text-center">{tWithNS.t('modal.question')}</div>
                </StandardModal>
            )}
        </PageLayout>
    );
};
