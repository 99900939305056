import React from 'react';
import { LpoRoutePaths } from '../../../config/wizardRouter/lpoWizardRoutes';
import { LpoSummaryProps } from '../../../types/SummaryProps';
import { TEST_ID_PREFIX } from '../../../utils/testIdUtil';
import { renderClaimLocation } from './components/glassGroupAccident/RenderClaimLocation';
import { renderGlassClaimDescription } from './components/glassGroupAccident/RenderGlassClaimDescription';
import { renderClaimantInformation } from './components/glassGroupAccident/RenderClaimantInformation';
import { formatClaimantInformation } from '../../../utils/strings/formatClaimantInformation';
import { renderIsAccident } from './components/glassGroupAccident/RenderIsAccident';
import { createTextYesNoDontKnow } from '../../../utils/displayUtils';
import { renderIsPoliceContacted } from './components/glassGroupAccident/RenderIsPoliceContacted';
import classNames from 'classnames';
import { isYes } from '@protectorinsurance/ds-can';
import { renderPoliceDistrict } from './components/glassGroupAccident/RenderPoliceDistrict';
import { renderPoliceCaseNumber } from './components/glassGroupAccident/RenderPoliceCaseNumber';
import { renderReportedToCounterparty } from './components/glassGroupAccident/RenderReportedToCounterparty';
import { renderCounterpartyInsuranceCompany } from './components/glassGroupAccident/RenderCounterpartyInsuranceCompany';
import { renderCounterpartyExternalReference } from './components/glassGroupAccident/RenderCounterpartyExternalReference';
import { renderDuringWork } from './components/glassGroupAccident/RenderDuringWork';
import { renderReportedToInsurersInsurance } from './components/glassGroupAccident/RenderReportedToInsurersInsurance';
import { renderInjuryInsurance } from './components/glassGroupAccident/RenderInjuryInsurance';
import { renderInjuryInsuranceExternalReference } from './components/glassGroupAccident/RenderInjuryInsuranceExternalReference';
import { renderIsUnderaged } from './components/glassGroupAccident/RenderIsUnderaged';
import { renderGuardianInformation } from './components/glassGroupAccident/RenderGuardianInformation';
import { formatGuardianInformation } from '../../../utils/strings/formatGuardianInformation';
import { renderClaimCause } from './components/glassGroupAccident/RenderClaimCause';
import { renderIsDoctorContacted } from './components/glassGroupAccident/RenderIsDoctorContacted';
import { renderDateOfTreatment } from './components/glassGroupAccident/RenderDateOfTreatment';
import { dtParseAndFormat } from '../../../utils/date/dateUtils';
import { renderDoctorInformation } from './components/glassGroupAccident/RenderDoctorInformation';
import { formatDoctorInformation } from '../../../utils/strings/formatDoctorInformation';
import { renderPreviousInjury } from './components/glassGroupAccident/RenderPreviousInjury';
import { renderTypeOfInjury } from './components/glassGroupAccident/RenderTypeOfInjury';
import { renderDateOfPreviousInjury } from './components/glassGroupAccident/RenderDateOfPreviousInjury';
import { renderHasHealthInsurance } from './components/glassGroupAccident/RenderHasHealthInsurance';
import { renderHealthInsurance } from './components/glassGroupAccident/RenderHealthInsurance';

/**
 * Destructure necessary imports
 */
const {
    DYN_CAROUSEL_GLASS_CLAIM_LOCATION,
    DYN_CAROUSEL_GROUP_ACCIDENT_DURING_WORK,
    DYN_CAROUSEL_GLASS_HAS_HEALTH_INSURANCE,
    DYN_CAROUSEL_GROUP_ACCIDENT_IS_ACCIDENT,
    DYN_CAROUSEL_GROUP_ACCIDENT_IS_DOCTOR_CONTACTED,
    DYN_CAROUSEL_GROUP_ACCIDENT_IS_POLICE_CONTACTED,
    DYN_CAROUSEL_GROUP_ACCIDENT_IS_UNDERAGED,
    DYN_CAROUSEL_GROUP_ACCIDENT_PREVIOUS_INJURY,
    DYN_CAROUSEL_GROUP_ACCIDENT_REPORTED_TO_COUNTERPARTY,
    DYN_CAROUSEL_GROUP_ACCIDENT_REPORTED_TO_INSURERS_INSURANCE,
    GLASS_CLAIMANT_INFORMATION,
    GLASS_HEALTH_INSURANCE,
    GLASS_OTHER_CLAIM_LOCATION,
    GROUP_ACCIDENT_CLAIM_CAUSE,
    GROUP_ACCIDENT_COUNTERPARTY_EXTERNAL_REFERENCE,
    GROUP_ACCIDENT_COUNTERPARTY_INSURANCE_COMPANY,
    GROUP_ACCIDENT_DATE_OF_PREVIOUS_INJURY,
    GROUP_ACCIDENT_DATE_OF_TREATMENT,
    GROUP_ACCIDENT_DOCTOR_INFORMATION,
    GROUP_ACCIDENT_GUARDIAN_INFORMATION,
    GROUP_ACCIDENT_INJURY_INSURANCE,
    GROUP_ACCIDENT_INJURY_INSURANCE_EXTERNAL_REFERENCE,
    GROUP_ACCIDENT_POLICE_CASE_NUMBER,
    GROUP_ACCIDENT_POLICE_DISTRICT,
    GROUP_ACCIDENT_TYPE_OF_INJURY,
} = LpoRoutePaths;

/**
 * Component view and component logic
 */
export const DisplayGlassGroupAccidentSummary = ({
    model,
    t,
    tWithNS,
    handleClick,
    upload,
    common,
}: LpoSummaryProps) => {
    const {
        claimCause,
        claimLocation,
        claimantInformation,
        counterpartyExternalReference,
        counterpartyInsuranceCompany,
        dateOfPreviousInjury,
        dateOfTreatment,
        doctorInformation,
        duringWork,
        glassClaimDescription,
        guardianInformation,
        hasHealthInsurance,
        healthInsurance,
        injuryInsurance,
        injuryInsuranceExternalReference,
        isAccident,
        isDoctorContacted,
        isPoliceContacted,
        isUnderaged,
        policeCaseNumber,
        policeDistrict,
        previousInjury,
        reportedToCounterparty,
        reportedToInsurersInsurance,
        typeOfInjury,
    } = model;

    // Classes
    const policeClass = classNames(
        {
            'col-4': isYes(isPoliceContacted),
            'col-12': !isYes(isPoliceContacted),
        },
        'divider text-align-left'
    );
    const healthInsuranceClass = classNames(
        {
            'col-6': isYes(hasHealthInsurance),
            'col-12': !isYes(hasHealthInsurance),
        },
        'divider text-align-left'
    );

    return (
        <>
            {renderClaimLocation(
                {
                    inputWrapperClass: 'col-12 divider text-align-left',
                    textKey: tWithNS('claimLocation.heading.title'),
                    dataTestId: TEST_ID_PREFIX.claimLocation,
                    buttonText: t(`lpo.glass.claimLocation.select.${claimLocation}`),
                    path: DYN_CAROUSEL_GLASS_CLAIM_LOCATION,
                    handleClick,
                },
                claimLocation
            )}

            {renderGlassClaimDescription(
                {
                    inputWrapperClass: 'col-12 divider text-align-left',
                    textKey: tWithNS('glassClaimDescription.heading.title'),
                    dataTestId: TEST_ID_PREFIX.glassClaimDescription,
                    buttonText: glassClaimDescription ? glassClaimDescription : '-',
                    path: GLASS_OTHER_CLAIM_LOCATION,
                    handleClick,
                },
                glassClaimDescription
            )}

            {renderIsAccident(
                {
                    inputWrapperClass: 'col-12 divider text-align-left',
                    textKey: tWithNS('isAccident.heading.title'),
                    dataTestId: TEST_ID_PREFIX.isAccident,
                    buttonText: t(createTextYesNoDontKnow(isAccident)),
                    path: DYN_CAROUSEL_GROUP_ACCIDENT_IS_ACCIDENT,
                    handleClick,
                },
                isAccident
            )}

            {renderIsPoliceContacted(
                {
                    inputWrapperClass: policeClass,
                    textKey: tWithNS('isPoliceContacted.heading.title'),
                    dataTestId: TEST_ID_PREFIX.isPoliceContacted,
                    buttonText: t(createTextYesNoDontKnow(isPoliceContacted)),
                    path: DYN_CAROUSEL_GROUP_ACCIDENT_IS_POLICE_CONTACTED,
                    handleClick,
                },
                isPoliceContacted
            )}

            {renderPoliceDistrict(
                {
                    inputWrapperClass: 'col-4 divider text-align-left',
                    textKey: tWithNS('policeDistrict.heading.title'),
                    dataTestId: TEST_ID_PREFIX.policeDistrict,
                    buttonText: policeDistrict ? policeDistrict : '-',
                    path: GROUP_ACCIDENT_POLICE_DISTRICT,
                    handleClick,
                },
                isPoliceContacted
            )}

            {renderPoliceCaseNumber(
                {
                    inputWrapperClass: 'col-4 divider text-align-left',
                    textKey: tWithNS('policeCaseNumber.heading.title'),
                    dataTestId: TEST_ID_PREFIX.policeCaseNumber,
                    buttonText: policeCaseNumber ? policeCaseNumber : '-',
                    path: GROUP_ACCIDENT_POLICE_CASE_NUMBER,
                    handleClick,
                },
                isPoliceContacted
            )}

            {renderReportedToCounterparty(
                {
                    inputWrapperClass: 'col-12 divider text-align-left',
                    textKey: tWithNS('reportedToCounterparty.heading.title'),
                    dataTestId: TEST_ID_PREFIX.reportedToCounterparty,
                    buttonText: t(createTextYesNoDontKnow(reportedToCounterparty)),
                    path: DYN_CAROUSEL_GROUP_ACCIDENT_REPORTED_TO_COUNTERPARTY,
                    handleClick,
                },
                reportedToCounterparty
            )}

            {renderCounterpartyInsuranceCompany(
                {
                    inputWrapperClass: 'col-6 divider text-align-left',
                    textKey: tWithNS('counterpartyInsuranceCompany.heading.title'),
                    dataTestId: TEST_ID_PREFIX.counterpartyInsuranceCompany,
                    buttonText: counterpartyInsuranceCompany ? counterpartyInsuranceCompany : '-',
                    path: GROUP_ACCIDENT_COUNTERPARTY_INSURANCE_COMPANY,
                    handleClick,
                },
                reportedToCounterparty
            )}

            {renderCounterpartyExternalReference(
                {
                    inputWrapperClass: 'col-6 divider text-align-left',
                    textKey: tWithNS('counterpartyExternalReference.heading.title'),
                    dataTestId: TEST_ID_PREFIX.counterpartyExternalReference,
                    buttonText: counterpartyExternalReference ? counterpartyExternalReference : '-',
                    path: GROUP_ACCIDENT_COUNTERPARTY_EXTERNAL_REFERENCE,
                    handleClick,
                },
                reportedToCounterparty
            )}

            {renderHasHealthInsurance(
                {
                    inputWrapperClass: healthInsuranceClass,
                    textKey: tWithNS('hasHealthInsurance.heading.title'),
                    dataTestId: TEST_ID_PREFIX.hasHealthInsurance,
                    buttonText: t(createTextYesNoDontKnow(hasHealthInsurance)),
                    path: DYN_CAROUSEL_GLASS_HAS_HEALTH_INSURANCE,
                    handleClick,
                },
                hasHealthInsurance
            )}

            {renderHealthInsurance(
                {
                    inputWrapperClass: 'col-6 divider text-align-left',
                    textKey: tWithNS('healthInsurance.heading.title'),
                    dataTestId: TEST_ID_PREFIX.healthInsurance,
                    buttonText: healthInsurance ? healthInsurance : '-',
                    path: GLASS_HEALTH_INSURANCE,
                    handleClick,
                },
                hasHealthInsurance
            )}

            {renderDuringWork(
                {
                    inputWrapperClass: 'col-12 divider text-align-left',
                    textKey: tWithNS('duringWork.heading.title'),
                    dataTestId: TEST_ID_PREFIX.duringWork,
                    buttonText: t(createTextYesNoDontKnow(duringWork)),
                    path: DYN_CAROUSEL_GROUP_ACCIDENT_DURING_WORK,
                    handleClick,
                },
                duringWork
            )}

            {renderReportedToInsurersInsurance(
                {
                    inputWrapperClass: 'col-12 divider text-align-left',
                    textKey: tWithNS('reportedToInsurersInsurance.heading.title'),
                    dataTestId: TEST_ID_PREFIX.reportedToInsurersInsurance,
                    buttonText: t(createTextYesNoDontKnow(reportedToInsurersInsurance)),
                    path: DYN_CAROUSEL_GROUP_ACCIDENT_REPORTED_TO_INSURERS_INSURANCE,
                    handleClick,
                },
                reportedToInsurersInsurance
            )}

            {renderInjuryInsurance(
                {
                    inputWrapperClass: 'col-6 divider text-align-left',
                    textKey: tWithNS('injuryInsurance.heading.title'),
                    dataTestId: TEST_ID_PREFIX.injuryInsurance,
                    buttonText: injuryInsurance ? injuryInsurance : '-',
                    path: GROUP_ACCIDENT_INJURY_INSURANCE,
                    handleClick,
                },
                reportedToInsurersInsurance
            )}

            {renderInjuryInsuranceExternalReference(
                {
                    inputWrapperClass: 'col-6 divider text-align-left',
                    textKey: tWithNS('injuryInsuranceExternalReference.heading.title'),
                    dataTestId: TEST_ID_PREFIX.injuryInsuranceExternalReference,
                    buttonText: injuryInsuranceExternalReference ? injuryInsuranceExternalReference : '-',
                    path: GROUP_ACCIDENT_INJURY_INSURANCE_EXTERNAL_REFERENCE,
                    handleClick,
                },
                reportedToInsurersInsurance
            )}

            {renderIsUnderaged(
                {
                    inputWrapperClass: 'col-12 divider text-align-left',
                    textKey: tWithNS('isUnderaged.heading.title'),
                    dataTestId: TEST_ID_PREFIX.isUnderaged,
                    buttonText: t(createTextYesNoDontKnow(isUnderaged)),
                    path: DYN_CAROUSEL_GROUP_ACCIDENT_IS_UNDERAGED,
                    handleClick,
                },
                isUnderaged
            )}

            {renderClaimantInformation(
                {
                    inputWrapperClass: 'col-12 divider text-align-left',
                    textKey: tWithNS('claimantInformation.heading.title'),
                    dataTestId: TEST_ID_PREFIX.claimantInformation,
                    buttonText: claimantInformation ? formatClaimantInformation(claimantInformation) : '-',
                    path: GLASS_CLAIMANT_INFORMATION,
                    handleClick,
                },
                claimantInformation
            )}

            {renderGuardianInformation(
                {
                    inputWrapperClass: 'col-12 divider text-align-left',
                    textKey: tWithNS('guardianInformation.heading.title'),
                    dataTestId: TEST_ID_PREFIX.guardianInformation,
                    buttonText: guardianInformation ? formatGuardianInformation(guardianInformation) : '-',
                    path: GROUP_ACCIDENT_GUARDIAN_INFORMATION,
                    handleClick,
                },
                isUnderaged
            )}

            {renderClaimCause(
                {
                    inputWrapperClass: 'col-12 divider text-align-left',
                    textKey: tWithNS('claimCause.heading.title'),
                    dataTestId: TEST_ID_PREFIX.claimCause,
                    buttonText: claimCause ? t(`lpo.claimCause.select.${claimCause}`) : '-',
                    path: GROUP_ACCIDENT_CLAIM_CAUSE,
                    handleClick,
                },
                claimCause
            )}

            {renderIsDoctorContacted(
                {
                    inputWrapperClass: 'col-12 divider text-align-left',
                    textKey: tWithNS('isDoctorContacted.heading.title'),
                    dataTestId: TEST_ID_PREFIX.isDoctorContacted,
                    buttonText: t(createTextYesNoDontKnow(isDoctorContacted)),
                    path: DYN_CAROUSEL_GROUP_ACCIDENT_IS_DOCTOR_CONTACTED,
                    handleClick,
                },
                isDoctorContacted
            )}

            {renderDateOfTreatment(
                {
                    inputWrapperClass: 'col-12 divider text-align-left',
                    textKey: tWithNS('dateOfTreatment.heading.title'),
                    dataTestId: TEST_ID_PREFIX.dateOfTreatment,
                    buttonText: dateOfTreatment ? dtParseAndFormat(dateOfTreatment) : '-',
                    path: GROUP_ACCIDENT_DATE_OF_TREATMENT,
                    handleClick,
                },
                isDoctorContacted
            )}

            {renderDoctorInformation(
                {
                    inputWrapperClass: 'col-12 divider text-align-left',
                    textKey: tWithNS('doctorInformation.heading.title'),
                    dataTestId: TEST_ID_PREFIX.doctorInformation,
                    buttonText: doctorInformation ? formatDoctorInformation(doctorInformation) : '-',
                    path: GROUP_ACCIDENT_DOCTOR_INFORMATION,
                    handleClick,
                },
                doctorInformation
            )}

            {renderPreviousInjury(
                {
                    inputWrapperClass: 'col-12 divider text-align-left',
                    textKey: tWithNS('previousInjury.heading.title'),
                    dataTestId: TEST_ID_PREFIX.previousInjury,
                    buttonText: t(createTextYesNoDontKnow(previousInjury)),
                    path: DYN_CAROUSEL_GROUP_ACCIDENT_PREVIOUS_INJURY,
                    handleClick,
                },
                previousInjury
            )}

            {renderTypeOfInjury(
                {
                    inputWrapperClass: 'col-12 divider text-align-left',
                    textKey: tWithNS('typeOfInjury.heading.title'),
                    dataTestId: TEST_ID_PREFIX.typeOfInjury,
                    buttonText: typeOfInjury ? t(`lpo.claimCause.select.${typeOfInjury}`) : '-',
                    path: GROUP_ACCIDENT_TYPE_OF_INJURY,
                    handleClick,
                },
                previousInjury
            )}

            {renderDateOfPreviousInjury(
                {
                    inputWrapperClass: 'col-12 divider text-align-left',
                    textKey: tWithNS('dateOfPreviousInjury.heading.title'),
                    dataTestId: TEST_ID_PREFIX.dateOfPreviousInjury,
                    buttonText: dateOfPreviousInjury ? dtParseAndFormat(dateOfPreviousInjury) : '-',
                    path: GROUP_ACCIDENT_DATE_OF_PREVIOUS_INJURY,
                    handleClick,
                },
                previousInjury
            )}
        </>
    );
};
