import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Clickable,
    Datable,
    is,
    LocaleServiceInstance,
    MuiDatePicker,
    PageLayout,
    updateDateByDate,
} from '@protectorinsurance/ds-can';
import { wizardRouterActions as wizardActions } from 'sagas/wizardRouter';
import { useI18n } from '../../../hooks/useI18n';
import { useGoBack } from '../../../hooks/useGoBack';
import { commonActions } from '../../../sagas/common';
import dispatcherWithPromise from '../../../utils/dispatcherWithPromise';
import { PhraseKeys } from '../../../config/phraseKeys';
import { FormFieldErrors, FormFieldNames } from '../../../config/formFieldNames';
import { selectDateOfPreviousInjury } from 'sagas/selectors/lpoSelectors';
import { lpoActions } from '../../../sagas/lpo';
import { selectCustomCAN } from '../../../sagas/selectors/commonSelectors';

/**
 * Destructure models, phrases etc.
 */
const { BACK_BUTTON, CONTINUE_BUTTON, HELP_TEXT, PAGE_NAME, SUB_TITLE, TITLE } = PhraseKeys;
const { DATE_OF_PREVIOUS_INJURY } = FormFieldNames;
const { MANDATORY_DATE_FIELD } = FormFieldErrors;

/**
 * Page view and page logic
 */
export const GroupAccidentDateOfPreviousInjuryPage = () => {
    const dispatch = useDispatch();
    const dateOfPreviousInjury = useSelector(selectDateOfPreviousInjury);
    const customCAN = useSelector(selectCustomCAN);
    const [date, setDate] = useState<Datable>(dateOfPreviousInjury || null);
    const [errors, setErrors] = useState<string[]>([]);
    const { t } = useI18n();
    const tWithNS = useI18n('lpo.groupAccident.dateOfPreviousInjury');
    const handleBackButton = useGoBack();

    useEffect(() => {
        setDate(dateOfPreviousInjury);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleContinueButton = (e: Clickable) => {
        e.preventDefault();
        if (is(date, null)) {
            setErrors([t(MANDATORY_DATE_FIELD)]);
        } else {
            dispatcherWithPromise(dispatch, lpoActions.update, { dateOfPreviousInjury })
                .then(() => dispatcherWithPromise(dispatch, commonActions.send))
                .then(() => dispatch(wizardActions.goToNext()));
        }
    };

    const selectedDate = (date: Datable) => {
        if (date) {
            const newFullDate = updateDateByDate(dateOfPreviousInjury, date);
            setDate(date);
            setErrors((prev) => {
                return prev ? prev.filter((p) => p !== t(MANDATORY_DATE_FIELD)) : [];
            });
            dispatch(lpoActions.update({ dateOfPreviousInjury: newFullDate }));
        }
    };

    return (
        <PageLayout
            backBtnText={t(BACK_BUTTON)}
            continueBtnText={t(CONTINUE_BUTTON)}
            domainTitle={t(PAGE_NAME)}
            footerText={tWithNS.t(HELP_TEXT)}
            headerSubTitle={tWithNS.t(SUB_TITLE)}
            headerTitle={tWithNS.t(TITLE)}
            {...{ handleBackButton, handleContinueButton }}
        >
            <MuiDatePicker
                disableFuture={true}
                error={errors.find((e: string) => e === t(MANDATORY_DATE_FIELD))}
                locale={LocaleServiceInstance.getMoment()}
                name={DATE_OF_PREVIOUS_INJURY}
                onChange={selectedDate}
                {...{ customCAN, date }}
            />
        </PageLayout>
    );
};
