import React, { SyntheticEvent, useEffect, useState } from 'react';
import { PhraseKeys } from '../../../config/phraseKeys';
import { useDispatch, useSelector } from 'react-redux';
import { Clickable, FormChangeable, Grid, MuiAutocomplete, OptionType, PageLayout } from '@protectorinsurance/ds-can';
import { selectPoliceDistrict } from '../../../sagas/selectors/motorSelectors';
import { useI18n } from '../../../hooks/useI18n';
import { policeDistrictOptions } from '../../../config/police/policeDistrictOptions';
import { useGoBack } from '../../../hooks/useGoBack';
import { FormFieldErrors, FormFieldNames } from '../../../config/formFieldNames';
import { motorActions } from '../../../sagas/motor';
import { wizardRouterActions } from '../../../sagas/wizardRouter';
import dispatcherWithPromise from '../../../utils/dispatcherWithPromise';
import { commonActions } from '../../../sagas/common';
import { selectCustomCAN } from '../../../sagas/selectors/commonSelectors';

/**
 * Destructure necessary imports
 */
const {
    BACK_BUTTON,
    CONTINUE_BUTTON,
    HELP_TEXT,
    NO_OPTIONS_MESSAGE,
    PAGE_NAME,
    POLICE_DISTRICT_LABEL,
    POLICE_DISTRICT_PLACEHOLDER,
    SUB_TITLE,
    TITLE,
} = PhraseKeys;
const { REQUIRED_ERROR_MESSAGE } = FormFieldErrors;
const { POLICE_DISTRICT } = FormFieldNames;

/**
 * Page view and page logic
 */
export const EndPoliceDistrictPage = () => {
    const dispatch = useDispatch();
    const [district, setDistrict] = useState<OptionType | null>(null);
    const [error, setError] = useState<string>('');
    const policeDistrict = useSelector(selectPoliceDistrict);
    const customCAN = useSelector(selectCustomCAN);
    const { t } = useI18n();
    const tWithNS = useI18n('motor.end.policeDistrict');
    const options = policeDistrictOptions;

    useEffect(() => {
        const selected = policeDistrictOptions.find((x) => x.label === policeDistrict);
        if (selected) {
            setDistrict(selected);
        }
    }, [policeDistrict, setDistrict]);

    const handleBackButton = useGoBack();

    const handleBlur = (e: FormChangeable) => {
        e.preventDefault();
        if (policeDistrict === null) {
            setError(t(REQUIRED_ERROR_MESSAGE));
        } else {
            setError('');
        }
    };

    const handleSelect = (e: SyntheticEvent, option: OptionType) => {
        const object = option ? option : null;
        const value = object ? object.label : null;
        setError('');
        setDistrict(object);
        dispatch(motorActions.update({ policeDistrict: value }));
    };

    const handleContinueButton = (e: Clickable) => {
        e.preventDefault();
        if (policeDistrict === null) {
            setError(t(REQUIRED_ERROR_MESSAGE));
        } else {
            setError('');
            dispatcherWithPromise(dispatch, commonActions.send).then(() => dispatch(wizardRouterActions.goToNext()));
        }
    };

    return (
        <PageLayout
            backBtnText={t(BACK_BUTTON)}
            continueBtnText={t(CONTINUE_BUTTON)}
            domainTitle={t(PAGE_NAME)}
            footerText={tWithNS.t(HELP_TEXT)}
            headerSubTitle={tWithNS.t(SUB_TITLE)}
            headerTitle={tWithNS.t(TITLE)}
            {...{ handleBackButton, handleContinueButton }}
        >
            <Grid className={'align-center'}>
                <MuiAutocomplete
                    error={!!error}
                    errorMessage={error}
                    id={POLICE_DISTRICT}
                    inputFieldWrapper={'col-12'}
                    label={t(POLICE_DISTRICT_LABEL)}
                    noOptionsText={t(NO_OPTIONS_MESSAGE)}
                    onBlur={handleBlur}
                    onChange={handleSelect}
                    openOnFocus={true}
                    placeholder={t(POLICE_DISTRICT_PLACEHOLDER)}
                    value={district}
                    {...{ customCAN, options }}
                />
            </Grid>
        </PageLayout>
    );
};
