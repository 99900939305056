import React, { SyntheticEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    ClaimTypeEnums,
    Clickable,
    FormChangeable,
    Grid,
    is,
    LobKeys,
    MuiAutocomplete,
    OptionType,
    PageLayout,
} from '@protectorinsurance/ds-can';
import { selectClaimType } from '../../../sagas/selectors/lpoSelectors';
import { useI18n } from '../../../hooks/useI18n';
import { PhraseKeys } from '../../../config/phraseKeys';
import { useGoBack } from '../../../hooks/useGoBack';
import { FormFieldErrors, FormFieldNames } from '../../../config/formFieldNames';
import dispatcherWithPromise from '../../../utils/dispatcherWithPromise';
import { lpoActions } from '../../../sagas/lpo';
import { wizardRouterActions } from '../../../sagas/wizardRouter';
import { commonActions } from '../../../sagas/common';
import { LpoRoutePaths } from '../../../config/wizardRouter/lpoWizardRoutes';
import { getClaimTypeOption } from '../../../utils/lpo/claimTypeUtils';
import { selectCustomCAN } from '../../../sagas/selectors/commonSelectors';

/**
 * Destructure necessary imports
 */
const {
    BACK_BUTTON,
    CLAIM_TYPE_LABEL,
    CLAIM_TYPE_PLACEHOLDER,
    CONTINUE_BUTTON,
    HELP_TEXT,
    NO_OPTIONS_MESSAGE,
    PAGE_NAME,
    SUB_TITLE,
    TITLE,
} = PhraseKeys;
const { TRANSPORT } = LobKeys;
const { REQUIRED_ERROR_MESSAGE } = FormFieldErrors;
const { CLAIM_TYPE } = FormFieldNames;
const { TRANSPORT_VEHICLE_REGISTRATION_NUMBER } = LpoRoutePaths;
const { CARRIER, FREIGHT, GOODS, MOVING } = ClaimTypeEnums;

/**
 * Page view and page logic
 */
export const TransportClaimTypePage = () => {
    const dispatch = useDispatch();
    const customCAN = useSelector(selectCustomCAN);
    const [type, setType] = useState<OptionType | null>(null);
    const [error, setError] = useState<string>('');
    const claimType = useSelector(selectClaimType);
    const { t } = useI18n();
    const tWithNS = useI18n('lpo.transport.claimType');

    // Options
    const carrierOption = getClaimTypeOption(t, CARRIER);
    const freightOption = getClaimTypeOption(t, FREIGHT);
    const goodsOption = getClaimTypeOption(t, GOODS);
    const movingOption = getClaimTypeOption(t, MOVING);

    const options = [movingOption, carrierOption, freightOption, goodsOption];

    useEffect(() => {
        const selected = options.find((x) => x.value === claimType);
        if (selected) {
            setType(selected);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [claimType, setType]);

    const handleBackButton = useGoBack();

    const handleBlur = (e: FormChangeable) => {
        e.preventDefault();
        if (is(claimType, null)) {
            setError(t(REQUIRED_ERROR_MESSAGE));
        } else {
            setError('');
        }
    };

    const handleSelect = (e: SyntheticEvent, option: OptionType) => {
        const object = option ? option : null;
        const value = object ? object.value : null;
        setError('');
        setType(object);
        dispatcherWithPromise(dispatch, lpoActions.update, { claimType: value });
    };

    const handleContinueButton = (e: Clickable) => {
        e.preventDefault();
        if (is(claimType, null)) {
            setError(t(REQUIRED_ERROR_MESSAGE));
        } else {
            setError('');
            let nextAction = wizardRouterActions.goToNext();
            if (is(claimType, CARRIER)) {
                nextAction = wizardRouterActions.goTo(TRANSPORT_VEHICLE_REGISTRATION_NUMBER);
            }

            dispatcherWithPromise(dispatch, commonActions.update, { lob: TRANSPORT });
            dispatcherWithPromise(dispatch, commonActions.send).then(() => dispatch(nextAction));
        }
    };

    return (
        <PageLayout
            backBtnText={t(BACK_BUTTON)}
            continueBtnText={t(CONTINUE_BUTTON)}
            domainTitle={t(PAGE_NAME)}
            footerText={tWithNS.t(HELP_TEXT)}
            headerSubTitle={tWithNS.t(SUB_TITLE)}
            headerTitle={tWithNS.t(TITLE)}
            {...{ handleBackButton, handleContinueButton }}
        >
            <Grid className={'align-center'}>
                <MuiAutocomplete
                    error={!!error}
                    errorMessage={error}
                    id={CLAIM_TYPE}
                    inputFieldWrapper={'col-12'}
                    label={t(CLAIM_TYPE_LABEL)}
                    noOptionsText={t(NO_OPTIONS_MESSAGE)}
                    onBlur={handleBlur}
                    onChange={handleSelect}
                    openOnFocus={true}
                    placeholder={t(CLAIM_TYPE_PLACEHOLDER)}
                    value={type}
                    {...{ customCAN, options }}
                />
            </Grid>
        </PageLayout>
    );
};
