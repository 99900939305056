import React, { useEffect, useState } from 'react';
import { isDev, isTest } from '../../utils/env';
import './Layout.scss';
import { NavBar, ProgressBar } from '@protectorinsurance/ds-can';
import { useSelector } from 'react-redux';
import { selectFlow } from '../../sagas/selectors/flowSelectors';
import { selectRouterPathname } from '../../sagas/selectors/routerSelectors';
import { progressBarUtil } from '../../utils/flow/progressBarUtil';
import { getLobProgressBarRoutes } from '../../utils/getLobProgressBarRoutes';
import { useI18n } from '../../hooks/useI18n';
import {
    selectCustomCAN,
    selectLob,
    selectLocaleCountryCode,
    selectLocaleLanguageCode,
} from '../../sagas/selectors/commonSelectors';
import { setHTMLLang } from '../../utils/locale/setHTMLLang';

interface IProps {
    children: React.ReactNode;
}

export const Layout = ({ children }: IProps) => {
    const flow = useSelector(selectFlow);
    const path = useSelector(selectRouterPathname);
    const lob = useSelector(selectLob);
    const [progress, setProgress] = useState(0);
    const progressBarRoutes = getLobProgressBarRoutes(lob);
    const customCAN = useSelector(selectCustomCAN);

    useEffect(() => {
        setProgress(progressBarUtil(flow, path, progressBarRoutes));
    }, [flow, path, progressBarRoutes]);

    const envClassName = isDev() ? 'is-dev' : isTest() ? 'is-test' : '';
    const { t } = useI18n();
    const country = useSelector(selectLocaleCountryCode);
    const language = useSelector(selectLocaleLanguageCode);
    const titlePostfix = isDev() ? 'DEV' : isTest() ? 'TEST' : '';
    const logoText = 'logo.link';
    document.documentElement.lang = setHTMLLang();

    return (
        <>
            <header className={`header ${envClassName} no-print`}>
                <NavBar
                    domain={t('pageName')}
                    environment={titlePostfix}
                    label={t(logoText)}
                    {...{ country, customCAN, envClassName, language }}
                />
            </header>
            <section role="main" className={'content'}>
                {children}
            </section>
            <footer className={'footer no-print'}>
                <ProgressBar width={progress} {...{ customCAN }} />
            </footer>
        </>
    );
};
