import React from 'react';
import { wizardRouterActions as wizardActions } from '../../../../sagas/wizardRouter';
import {
    CarouselConfigLookupResponse,
    CarouselConfigOptionType,
    Clickable,
    isYes,
    NoIcon,
    YesIcon,
    YesNoKeys,
} from '@protectorinsurance/ds-can';
import { CarouselConfigLookupProps } from '../../../../utils/carouselPageLookup';
import dispatcherWithPromise from '../../../../utils/dispatcherWithPromise';
import { motorActions } from '../../../../sagas/motor';
import { selectHasNonVehicleDamages } from '../../../../sagas/selectors/motorSelectors';
import { MotorRoutePaths } from '../../../wizardRouter/motorWizardRoutes';
import { commonActions } from '../../../../sagas/common';

/**
 * Destructure necessary imports
 */
const { YES, NO } = YesNoKeys;

/**
 * Page view and page logic
 */
export const endHasNonVehicleDamagesPage = ({
    t,
    state,
    dispatch,
}: CarouselConfigLookupProps): CarouselConfigLookupResponse<MotorRoutePaths> => {
    const options = [
        { id: YES, name: t(`motor.end.hasNonVehicleDamages.select.${YES}`), icon: <YesIcon /> },
        { id: NO, name: t(`motor.end.hasNonVehicleDamages.select.${NO}`), icon: <NoIcon /> },
    ];

    return {
        slug: MotorRoutePaths.DYN_CAROUSEL_END_HAS_NON_VEHICLE_DAMAGES,
        stateKey: 'hasNonVehicleDamages',
        i18n: {
            ns: 'motor.end.hasNonVehicleDamages',
        },
        selectorValue: selectHasNonVehicleDamages(state),
        options,
        onSelect: (id: CarouselConfigOptionType, e: Clickable) => {
            e.preventDefault();
            const option = options?.find((x) => x.id === id);

            if (option) {
                if (isYes(option.id)) {
                    dispatch(wizardActions.skipBackToPrev(true));
                }

                dispatcherWithPromise(dispatch, motorActions.update, { hasNonVehicleDamages: option.id })
                    .then(() => dispatcherWithPromise(dispatch, commonActions.send))
                    .then(() => dispatch(wizardActions.goToNext(option.id)));
            }
        },
    };
};
