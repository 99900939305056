import * as Yup from 'yup';
import { TFunction } from 'i18next';
import { nameSchema } from '../fieldSchemas/nameSchema';
import { contactDetailsSchema } from '../fieldSchemas/contactDetailsSchema';
import { optionalAddressSchema } from '../fieldSchemas/optionalAddressSchema';
import { relationSchema } from '../fieldSchemas/relationSchema';

export const guardianInformationSchema = (t: TFunction) => {
    return Yup.object().shape({
        ...nameSchema(t),
        ...contactDetailsSchema(t),
        ...relationSchema(t),
        ...optionalAddressSchema(t),
    });
};
