import * as Yup from 'yup';
import { TFunction } from 'i18next';
import { nameSchema } from '../fieldSchemas/nameSchema';
import { contactDetailsSchema } from '../fieldSchemas/contactDetailsSchema';
import { optionalAddressSchema } from '../fieldSchemas/optionalAddressSchema';
import { nationalIdentitySchema } from '../fieldSchemas/nationalIdentitySchema';
import { titleSchema } from '../fieldSchemas/titleSchema';

export const claimantInformationSchema = (t: TFunction) => {
    return Yup.object().shape({
        ...nameSchema(t),
        ...titleSchema(t),
        ...contactDetailsSchema(t),
        ...nationalIdentitySchema(t),
        ...optionalAddressSchema(t),
    });
};
