import * as Yup from 'yup';
import { TFunction } from 'i18next';
import { claimNumberSchema } from '../fieldSchemas/claimNumberSchema';

/**
 * Validation logic
 */
export const claimNumberInformationSchema = (t: TFunction) => {
    return Yup.object().shape({
        ...claimNumberSchema(t),
    });
};
