import React from 'react';
import { wizardRouterActions as wizardActions } from '../../../../../../sagas/wizardRouter';
import {
    CarouselConfigLookupResponse,
    CarouselConfigOptionType,
    CirculatingRoundaboutIcon,
    Clickable,
    EnteringRoundaboutIcon,
    ExitingRoundaboutIcon,
    RoundaboutPositionKeys,
} from '@protectorinsurance/ds-can';
import { CarouselConfigLookupProps } from '../../../../../../utils/carouselPageLookup';
import dispatcherWithPromise from '../../../../../../utils/dispatcherWithPromise';
import { motorActions } from '../../../../../../sagas/motor';
import { selectCounterpartyRoundaboutPositioning } from '../../../../../../sagas/selectors/motorSelectors';
import { MotorRoutePaths } from '../../../../../wizardRouter/motorWizardRoutes';
import { commonActions } from '../../../../../../sagas/common';

/**
 * Destructure necessary imports
 */
const { CIRCULATING, ENTERING, EXITING } = RoundaboutPositionKeys;

/**
 * Page view and page logic
 */
export const drivingVehiclesRoundaboutCounterpartyRoundaboutPositioningPage = ({
    t,
    state,
    dispatch,
}: CarouselConfigLookupProps): CarouselConfigLookupResponse<MotorRoutePaths> => {
    const options = [
        {
            id: ENTERING,
            name: t(`motor.driving.counterpartyRoundaboutPositioning.select.${ENTERING}`),
            icon: <EnteringRoundaboutIcon />,
        },
        {
            id: CIRCULATING,
            name: t(`motor.driving.counterpartyRoundaboutPositioning.select.${CIRCULATING}`),
            icon: <CirculatingRoundaboutIcon />,
        },
        {
            id: EXITING,
            name: t(`motor.driving.counterpartyRoundaboutPositioning.select.${EXITING}`),
            icon: <ExitingRoundaboutIcon />,
        },
    ];

    return {
        slug: MotorRoutePaths.DYN_CAROUSEL_DRIVING_VEHICLES_ROUNDABOUT_COUNTERPARTY_ROUNDABOUT_POSITIONING,
        stateKey: 'counterpartyRoundaboutPositioning',
        i18n: {
            ns: 'motor.driving.counterpartyRoundaboutPositioning',
        },
        selectorValue: selectCounterpartyRoundaboutPositioning(state),
        options,
        onSelect: (id: CarouselConfigOptionType, e: Clickable) => {
            e.preventDefault();
            const option = options?.find((x) => x.id === id);

            if (option) {
                dispatcherWithPromise(dispatch, motorActions.update, { counterpartyRoundaboutPositioning: option.id })
                    .then(() => dispatcherWithPromise(dispatch, commonActions.send))
                    .then(() => dispatch(wizardActions.goToNext()));
            }
        },
    };
};
