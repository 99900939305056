import React from 'react';
import { wizardRouterActions as wizardActions } from '../../../../../../sagas/wizardRouter';
import {
    CarouselConfigLookupResponse,
    CarouselConfigOptionType,
    Clickable,
    OtherDriverIcon,
    SelfOwnDriverIcon,
    WhoAtFaultKeys,
} from '@protectorinsurance/ds-can';
import { CarouselConfigLookupProps } from '../../../../../../utils/carouselPageLookup';
import dispatcherWithPromise from '../../../../../../utils/dispatcherWithPromise';
import { motorActions } from '../../../../../../sagas/motor';
import { selectWhoAtFault } from '../../../../../../sagas/selectors/motorSelectors';
import { MotorRoutePaths } from '../../../../../wizardRouter/motorWizardRoutes';
import { commonActions } from '../../../../../../sagas/common';

/**
 * Destructure necessary imports
 */
const { CLAIMANT, COUNTERPARTY } = WhoAtFaultKeys;

/**
 * Page view and page logic
 */
export const drivingVehiclesParkedWhoAtFaultPage = ({
    t,
    state,
    dispatch,
}: CarouselConfigLookupProps): CarouselConfigLookupResponse<MotorRoutePaths> => {
    const options = [
        { id: CLAIMANT, name: t(`motor.driving.parkedWhoAtFault.select.${CLAIMANT}`), icon: <SelfOwnDriverIcon /> },
        {
            id: COUNTERPARTY,
            name: t(`motor.driving.parkedWhoAtFault.select.${COUNTERPARTY}`),
            icon: <OtherDriverIcon />,
        },
    ];

    return {
        slug: MotorRoutePaths.DYN_CAROUSEL_DRIVING_VEHICLES_PARKED_WHO_AT_FAULT,
        stateKey: 'whoAtFault',
        i18n: {
            ns: 'motor.driving.parkedWhoAtFault',
        },
        selectorValue: selectWhoAtFault(state),
        options,
        onSelect: (id: CarouselConfigOptionType, e: Clickable) => {
            e.preventDefault();
            const option = options?.find((x) => x.id === id);

            if (option) {
                dispatcherWithPromise(dispatch, motorActions.update, { whoAtFault: option.id })
                    .then(() => dispatcherWithPromise(dispatch, commonActions.send))
                    .then(() => dispatch(wizardActions.goToNext()));
            }
        },
    };
};
