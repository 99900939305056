import React, { useEffect, useState } from 'react';
import { FormFieldNames } from '../../config/formFieldNames';
import { PhraseKeys } from '../../config/phraseKeys';
import { AutocompleteValueKeys } from '../../config/AutocompleteValueKeys';
import {
    FormChangeable,
    Grid,
    HiddenInputSubmit,
    MuiTextInput,
    OwnerInformationListModel,
} from '@protectorinsurance/ds-can';
import { Controller, useFormContext } from 'react-hook-form';
import { useI18n } from '../../hooks/useI18n';
import { useDispatch, useSelector } from 'react-redux';
import { selectOrgnrService } from '../../sagas/selectors/orgnrSelectors';
import { orgnrServiceActions } from '../../sagas/services/orgnrService';
import { selectCustomCAN } from '../../sagas/selectors/commonSelectors';

/**
 * Destructure necessary imports
 */
const { BUSINESS_NUMBER, EMAIL, NAME, PHONE } = FormFieldNames;
const {
    COMPANY_BUSINESS_NUMBER_LABEL,
    COMPANY_BUSINESS_NUMBER_PLACEHOLDER,
    COMPANY_NAME_LABEL,
    COMPANY_NAME_PLACEHOLDER,
    EMAIL_LABEL,
    EMAIL_PLACEHOLDER,
    PHONE_LABEL,
    PHONE_PLACEHOLDER,
} = PhraseKeys;
const { EMAIL: EMAIL_AUTOCOMPLETE, TEL } = AutocompleteValueKeys;

/**
 * Interfaces and types
 */
interface CounterpartyInformationCompanyFormProps {
    onSubmit: (values: OwnerInformationListModel) => void;
}

/**
 * Component view and component logic
 */
export const CounterpartyInformationCompanyForm = ({ onSubmit }: CounterpartyInformationCompanyFormProps) => {
    const dispatch = useDispatch();
    const getBusinessName = useSelector(selectOrgnrService);
    const customCAN = useSelector(selectCustomCAN);
    const [businessName, setBusinessName] = useState('');
    const {
        control,
        formState: { errors },
        handleSubmit,
        setValue,
        trigger,
    } = useFormContext<OwnerInformationListModel>();
    const { t } = useI18n();

    useEffect(() => {
        if (getBusinessName.name) {
            setBusinessName(getBusinessName.name);
        }
    }, [getBusinessName]);

    const handleBlur = async (e: FormChangeable) => {
        e.preventDefault();
        const { id } = e.currentTarget;
        await trigger(id);
    };

    const handleChange = async (e: FormChangeable) => {
        e.preventDefault();
        const { id, value } = e.currentTarget;
        await setValue(id, value, { shouldValidate: true });
        if (id === 'businessNumber' && value.length === 8) {
            dispatch(orgnrServiceActions.request({ country: 'dk', orgnr: value.toString() }));
        }
        if (id === 'name') {
            setBusinessName(value);
        }
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <HiddenInputSubmit />
            <Grid className={'align-center'}>
                <Controller
                    control={control}
                    name={BUSINESS_NUMBER}
                    render={({ field: { ref, ...field } }) => (
                        <MuiTextInput
                            {...field}
                            error={!!errors.businessNumber}
                            errorMessage={errors.businessNumber?.message}
                            id={BUSINESS_NUMBER}
                            inputFieldWrapper={'col-6'}
                            label={t(COMPANY_BUSINESS_NUMBER_LABEL)}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            placeholder={t(COMPANY_BUSINESS_NUMBER_PLACEHOLDER)}
                            reference={ref}
                            {...{ customCAN }}
                        />
                    )}
                />
                <Controller
                    control={control}
                    name={NAME}
                    render={({ field: { ref, ...field } }) => (
                        <MuiTextInput
                            {...field}
                            error={!!errors.name}
                            errorMessage={errors.name?.message}
                            id={NAME}
                            inputFieldWrapper={'col-6'}
                            label={t(COMPANY_NAME_LABEL)}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            placeholder={t(COMPANY_NAME_PLACEHOLDER)}
                            reference={ref}
                            value={businessName}
                            {...{ customCAN }}
                        />
                    )}
                />
                <Controller
                    control={control}
                    name={EMAIL}
                    render={({ field: { ref, ...field } }) => (
                        <MuiTextInput
                            {...field}
                            autoComplete={EMAIL_AUTOCOMPLETE}
                            error={!!errors.email}
                            errorMessage={errors.email?.message}
                            id={EMAIL}
                            inputFieldWrapper={'col-6'}
                            label={t(EMAIL_LABEL)}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            placeholder={t(EMAIL_PLACEHOLDER)}
                            reference={ref}
                            type={'email'}
                            {...{ customCAN }}
                        />
                    )}
                />
                <Controller
                    control={control}
                    name={PHONE}
                    render={({ field: { ref, ...field } }) => (
                        <MuiTextInput
                            {...field}
                            autoComplete={TEL}
                            error={!!errors.phone}
                            errorMessage={errors.phone?.message}
                            id={PHONE}
                            inputFieldWrapper={'col-6'}
                            label={t(PHONE_LABEL)}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            placeholder={t(PHONE_PLACEHOLDER)}
                            reference={ref}
                            {...{ customCAN }}
                        />
                    )}
                />
            </Grid>
        </form>
    );
};
