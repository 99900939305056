import React, { FC, useEffect, useState } from 'react';
import { MaintenanceModeModal } from '../MaintenanceModeModal';
import { useToggles } from '../../../hooks/useToggles';
import {
    CountryCodeISOEnums,
    togglesServiceActions,
    TogglesServiceAppEnums,
    TogglesServiceTogglesEnums,
} from '@protectorinsurance/ds-can';
import { useDispatch } from 'react-redux';
import dispatcherWithPromise from '../../../utils/dispatcherWithPromise';

const { CLAIMS_AT_NET } = TogglesServiceAppEnums;
const { DENMARK } = CountryCodeISOEnums;

export const MaintenanceModeTracker: FC = () => {
    const [isEnabled] = useToggles();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const dispatch = useDispatch();
    const isMaintenance = isEnabled(TogglesServiceTogglesEnums.MAINTENANCE_MODE);
    const handleOnBeforeUnload = (e: BeforeUnloadEvent) => {
        e.preventDefault();
        e.returnValue = '';
    };

    useEffect(() => {
        if (isMaintenance) {
            window.addEventListener('beforeunload', handleOnBeforeUnload);
        }

        return () => {
            window.removeEventListener('beforeunload', handleOnBeforeUnload);
        };
    }, [isMaintenance]);

    const handleOnConfirm = () => {
        setIsLoading(true);
        dispatcherWithPromise(dispatch, togglesServiceActions.request, {
            application: CLAIMS_AT_NET,
            country: DENMARK,
        }).then(() => {
            setIsLoading(false);
        });
    };

    if (!isMaintenance) {
        return null;
    }

    return (
        <>
            <MaintenanceModeModal onConfirm={handleOnConfirm} isLoading={isLoading} />
        </>
    );
};
