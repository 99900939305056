import * as Yup from 'yup';
import { PhraseKeys } from '../../config/phraseKeys';
import { TFunction } from 'i18next';

/**
 * Destructure necessary imports
 */
const { INJURY_INSURANCE_EXTERNAL_REFERENCE_LABEL } = PhraseKeys;

/**
 * Validation logic
 */
export const injuryExternalReferenceSchema = (t: TFunction) => {
    return {
        injuryInsuranceExternalReference: Yup.string()
            .label(t(INJURY_INSURANCE_EXTERNAL_REFERENCE_LABEL))
            .required()
            .nullable(),
    };
};
