import React, { SyntheticEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { wizardRouterActions as wizardActions } from 'sagas/wizardRouter';
import {
    FormChangeable,
    Grid,
    HiddenInputSubmit,
    initVehicleModel,
    MuiAutocomplete,
    MuiTextInput,
    OptionType,
    PageLayout,
    VehicleModel,
    VehicleTypeKeys,
} from '@protectorinsurance/ds-can';
import { useGoBack } from '../../../../hooks/useGoBack';
import { useI18n } from '../../../../hooks/useI18n';
import { PhraseKeys } from '../../../../config/phraseKeys';
import { FormFieldNames } from '../../../../config/formFieldNames';
import { getVehicleTypeOptions } from '../../../../utils/flow/vehicleType';
import { Controller, useForm } from 'react-hook-form';
import { otherVehicleInformationSchema } from '../../../../validations/schemas/otherVehicleInformation';
import { countryCodes, countryNames } from '../../../../utils/i18nCountriesUtils';
import dispatcherWithPromise from '../../../../utils/dispatcherWithPromise';
import { motorActions } from '../../../../sagas/motor';
import { commonActions } from '../../../../sagas/common';
import { useVehicles } from '../../../../hooks/useVehicles';
import { selectSelectedVehicle, selectSelectedVehicleId } from '../../../../sagas/selectors/motorSelectors';
import { yupResolver } from '@hookform/resolvers/yup';
import { selectCustomCAN } from '../../../../sagas/selectors/commonSelectors';

/**
 * Destructure necessary imports
 */
const {
    BACK_BUTTON,
    CONTINUE_BUTTON,
    COUNTRY_LABEL,
    COUNTRY_PLACEHOLDER,
    HELP_TEXT,
    MAKE_LABEL,
    MAKE_PLACEHOLDER,
    NO_OPTIONS_MESSAGE,
    OTHER_ID_LABEL,
    OTHER_ID_PLACEHOLDER,
    PAGE_NAME,
    SUB_TITLE,
    TITLE,
    VEHICLE_TYPE_LABEL,
    VEHICLE_TYPE_PLACEHOLDER,
} = PhraseKeys;
const { COUNTRY, MAKE, OTHER_ID, VEHICLE_TYPE } = FormFieldNames;

/**
 * Page view and page logic
 */
export const DVVehicleInformationPage = () => {
    const dispatch = useDispatch();
    const [vehicleType, setVehicleType] = useState<OptionType | null>(null);
    const [country, setCountry] = useState<OptionType | null>(null);
    const { vehicles } = useVehicles();
    const selectedVehicle = useSelector(selectSelectedVehicle);
    const selectedVehicleId = useSelector(selectSelectedVehicleId);
    const customCAN = useSelector(selectCustomCAN);
    const { t } = useI18n();
    const tWithNS = useI18n('motor.driving.vehicleInformation');
    const options = getVehicleTypeOptions(t);
    const {
        control,
        formState: { errors },
        handleSubmit,
        register,
        setValue,
        trigger,
    } = useForm<VehicleModel>({
        resolver: yupResolver(otherVehicleInformationSchema(t)),
    });
    const countries = Object.keys(countryCodes).map((key) => ({
        value: countryCodes[key],
        label: `${countryNames[key]} (${countryCodes[key]})`,
    }));

    useEffect(() => {
        register(VEHICLE_TYPE);
        register(COUNTRY);
    }, [register]);

    useEffect(() => {
        const selected = options.find(
            (x) => x.value.toUpperCase() === (selectedVehicle?.vehicleType as VehicleTypeKeys)
        );

        if (selected) {
            setVehicleType(selected);
            setValue('vehicleType', VehicleTypeKeys[selected.value.toUpperCase() as VehicleTypeKeys], {
                shouldValidate: true,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleBackButton = useGoBack();

    const handleBlur = async (e: FormChangeable) => {
        e.preventDefault();
        const { id } = e.currentTarget;
        await trigger(id);
    };

    const handleChange = async (e: FormChangeable) => {
        e.preventDefault();
        const { id, value } = e.currentTarget;
        await setValue(id, value, { shouldValidate: true });
    };

    const handleVehicleSelect = async (e: SyntheticEvent, option: OptionType) => {
        const selected = option ? option : null;
        if (selected) {
            await setValue('vehicleType', VehicleTypeKeys[selected.value.toUpperCase() as VehicleTypeKeys], {
                shouldValidate: true,
            });
        }
        setVehicleType(selected);
    };

    const handleCountrySelect = async (e: SyntheticEvent, option: OptionType) => {
        const selected = option ? option : null;
        if (selected) {
            await setValue('country', selected.value, { shouldValidate: true });
        }
        setCountry(selected);
    };

    const onSubmit = (values: VehicleModel) => {
        const { country, make, otherId, vehicleType } = values;
        const vehicle = {
            ...selectedVehicle,
            country,
            internalId: selectedVehicleId,
            make,
            otherId,
            vehicleType: (vehicleType && vehicleType.toString()) !== '' ? vehicleType : null,
        };

        dispatcherWithPromise(dispatch, motorActions.update, {
            vehicles: [...vehicles, { ...vehicle }],
            selectedVehicle: initVehicleModel,
        })
            .then(() => dispatcherWithPromise(dispatch, commonActions.send))
            .then(() => dispatch(wizardActions.goToNext()));
    };

    return (
        <PageLayout
            backBtnText={t(BACK_BUTTON)}
            continueBtnText={t(CONTINUE_BUTTON)}
            domainTitle={t(PAGE_NAME)}
            footerText={tWithNS.t(HELP_TEXT)}
            handleContinueButton={handleSubmit(onSubmit)}
            headerSubTitle={tWithNS.t(SUB_TITLE)}
            headerTitle={tWithNS.t(TITLE)}
            {...{ handleBackButton }}
        >
            <form onSubmit={handleSubmit(onSubmit)}>
                <HiddenInputSubmit />
                <Grid className={'align-center'}>
                    <Controller
                        control={control}
                        name={MAKE}
                        render={({ field: { ref, ...field } }) => (
                            <MuiTextInput
                                {...field}
                                error={!!errors.make}
                                errorMessage={errors.make?.message}
                                id={MAKE}
                                inputFieldWrapper={'col-6'}
                                label={t(MAKE_LABEL)}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                placeholder={t(MAKE_PLACEHOLDER)}
                                reference={ref}
                                {...{ customCAN }}
                            />
                        )}
                    />
                    <MuiAutocomplete
                        error={!!errors.country}
                        errorMessage={errors.country?.message}
                        id={COUNTRY}
                        inputFieldWrapper={'col-6'}
                        label={t(COUNTRY_LABEL)}
                        noOptionsText={t(NO_OPTIONS_MESSAGE)}
                        onBlur={handleBlur}
                        onChange={handleCountrySelect}
                        openOnFocus={true}
                        options={countries}
                        placeholder={t(COUNTRY_PLACEHOLDER)}
                        value={country}
                        {...{ customCAN }}
                    />
                    <Controller
                        control={control}
                        name={OTHER_ID}
                        render={({ field: { ref, ...field } }) => (
                            <MuiTextInput
                                {...field}
                                error={!!errors.otherId}
                                errorMessage={errors.otherId?.message}
                                id={OTHER_ID}
                                inputFieldWrapper={'col-6'}
                                label={t(OTHER_ID_LABEL)}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                placeholder={t(OTHER_ID_PLACEHOLDER)}
                                reference={ref}
                                {...{ customCAN }}
                            />
                        )}
                    />
                    <MuiAutocomplete
                        error={!!errors.vehicleType}
                        errorMessage={errors.vehicleType?.message}
                        id={VEHICLE_TYPE}
                        inputFieldWrapper={'col-6'}
                        label={t(VEHICLE_TYPE_LABEL)}
                        noOptionsText={t(NO_OPTIONS_MESSAGE)}
                        onBlur={handleBlur}
                        onChange={handleVehicleSelect}
                        openOnFocus={true}
                        placeholder={t(VEHICLE_TYPE_PLACEHOLDER)}
                        value={vehicleType}
                        {...{ customCAN, options }}
                    />
                </Grid>
            </form>
        </PageLayout>
    );
};
