import { useDispatch, useSelector } from 'react-redux';
import { errorsActions } from '../sagas/errors';
import { AppState } from '../store';
import { AppActionTypes } from '../store/rootReducers';
import { CANRequestError, emptyFn, Nullable } from '@protectorinsurance/ds-can';

interface UseErrorLoader {
    hasError: boolean;
    error: Nullable<CANRequestError>;
    removeCurrentError: () => void;
    resetAllError: () => void;
}

const useErrorLoader = (actionType: AppActionTypes): UseErrorLoader => {
    const error = useSelector((state: AppState) => state.errors[actionType] || null);
    const dispatch = useDispatch();
    // Check that we have an actionType
    if (error) {
        return {
            hasError: true,
            error,
            removeCurrentError: () => dispatch(errorsActions.remove(actionType)),
            resetAllError: () => dispatch(errorsActions.reset()),
        };
    }

    // Return empty
    return {
        hasError: false,
        error: null,
        removeCurrentError: emptyFn,
        resetAllError: emptyFn,
    };
};

export default useErrorLoader;
