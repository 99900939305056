import React, { ChangeEvent, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { wizardRouterActions as wizardActions } from 'sagas/wizardRouter';
import {
    Clickable,
    Grid,
    initVehicleModel,
    InputValidationError,
    isEmpty,
    Nullable,
    PageLayout,
    PartyRelationKeys,
    SecondaryButton,
    SingleCheckbox,
    VehicleModel,
} from '@protectorinsurance/ds-can';
import { useGoBack } from '../../../../../hooks/useGoBack';
import { useI18n } from '../../../../../hooks/useI18n';
import { PhraseKeys } from '../../../../../config/phraseKeys';
import { selectIsCounterparty, selectIsOverRoadCenterUnknown } from '../../../../../sagas/selectors/motorSelectors';
import dispatcherWithPromise from '../../../../../utils/dispatcherWithPromise';
import { motorActions } from '../../../../../sagas/motor';
import { commonActions } from '../../../../../sagas/common';
import { useVehicles } from '../../../../../hooks/useVehicles';
import { FormFieldNames } from '../../../../../config/formFieldNames';
import { selectCustomCAN } from '../../../../../sagas/selectors/commonSelectors';

/**
 * Destructure necessary imports
 */
const { BACK_BUTTON, CONTINUE_BUTTON, HELP_TEXT, PAGE_NAME, SUB_TITLE, TITLE, UNKNOWN_COUNTERPARTY, IS_UNKNOWN_LABEL } =
    PhraseKeys;
const { CLAIM_VEHICLE } = PartyRelationKeys;
const { IS_UNKNOWN } = FormFieldNames;

/**
 * Page view and page logic
 */
export const DVHeadOnRoadCenterInformationPage = () => {
    const dispatch = useDispatch();
    const { vehicles } = useVehicles();
    const isCounterparty = useSelector(selectIsCounterparty);
    const isOverRoadCenterUnknown = useSelector(selectIsOverRoadCenterUnknown);
    const customCAN = useSelector(selectCustomCAN);
    const responsibleVehicles = vehicles.filter((v: VehicleModel) => v.isOverRoadCenter);
    const notResponsibleVehicles = vehicles.filter((v: VehicleModel) => !v.isOverRoadCenter);
    const [responsibleVehiclesIsEmpty, setResponsibleVehicleIsEmpty] = useState<boolean>(false);
    const [isUnknownChecked, setIsUnknownChecked] = useState<boolean>(false);
    const { t } = useI18n();
    const tWithNS = useI18n('motor.driving.roadCenterInformation');

    const handleBackButton = useGoBack();

    const handleContinueButton = (e: Clickable) => {
        e.preventDefault();
        if (!isEmpty(responsibleVehicles) || isUnknownChecked) {
            dispatcherWithPromise(dispatch, commonActions.send).then(() => dispatch(wizardActions.goToNext()));
        } else {
            setResponsibleVehicleIsEmpty(true);
        }
    };

    const handleButtonAction = (internalId: Nullable<string>, e?: Clickable) => {
        e?.preventDefault();
        const vehiclesList = [...vehicles];
        const vehicle = vehicles.find((v: VehicleModel) => v.internalId === internalId);
        const vehicleIndex = vehicles.findIndex((v: VehicleModel) => v.internalId === internalId);
        setIsUnknownChecked(false);
        vehiclesList[vehicleIndex] = {
            ...initVehicleModel,
            ...vehicle,
            isOverRoadCenter: !vehicle?.isOverRoadCenter,
        };
        dispatcherWithPromise(dispatch, motorActions.update, { vehicles: vehiclesList })
            .then(() =>
                dispatch(
                    motorActions.update({
                        isOverRoadCenterUnknown: false,
                    })
                )
            )
            .then(() => dispatcherWithPromise(dispatch, commonActions.send));
        setResponsibleVehicleIsEmpty(false);
    };

    const handleChecked = async (e: ChangeEvent<HTMLInputElement>) => {
        const { checked } = e.target;
        const updatedVehicleList = vehicles.map((vehicle) => {
            return {
                ...vehicle,
                isOverRoadCenter: false,
            };
        });

        await setIsUnknownChecked(checked);
        dispatch(
            motorActions.update({
                vehicles: updatedVehicleList,
                isOverRoadCenterUnknown: checked,
            })
        );
    };

    const getButtonValue = (vehicle: VehicleModel) => {
        const label = isCounterparty ? 'COUNTERPARTY_VEHICLE' : 'CLAIM_VEHICLE';
        return vehicle.registrationNumber
            ? vehicle.registrationNumber.toUpperCase()
            : t(
                  vehicle.partyRelation === CLAIM_VEHICLE
                      ? `motor.driving.whoResponsible.select.${label}`
                      : `motor.driving.unidentifiedType.select.${vehicle.unidentifiedType}`
              );
    };

    return (
        <PageLayout
            backBtnText={t(BACK_BUTTON)}
            continueBtnText={t(CONTINUE_BUTTON)}
            domainTitle={t(PAGE_NAME)}
            footerText={tWithNS.t(HELP_TEXT)}
            headerSubTitle={tWithNS.t(SUB_TITLE)}
            headerTitle={tWithNS.t(TITLE)}
            {...{ handleBackButton, handleContinueButton }}
        >
            <Grid>
                <SingleCheckbox
                    name={IS_UNKNOWN}
                    checked={isOverRoadCenterUnknown}
                    handleChange={handleChecked}
                    wrapperClass={'col-6 justify-content-center'}
                    {...{ customCAN }}
                >
                    {t(IS_UNKNOWN_LABEL)}
                </SingleCheckbox>
            </Grid>
            <Grid>
                <div className={'col-6 no-padding padding-right'}>
                    <Grid>
                        <div className={'col-12'}>
                            <h2 className={'sub-title'}>{tWithNS.t('notSelected.title')}</h2>
                        </div>
                        {notResponsibleVehicles.map((vehicle: VehicleModel, idx: number) => {
                            return (
                                <div className={'col-6 no-padding padding-top'} key={idx}>
                                    <p className={'overflow-paragraph'}>
                                        {vehicle.make}
                                        {vehicle.model}
                                    </p>
                                    {vehicle.make === t(UNKNOWN_COUNTERPARTY) ? (
                                        <SecondaryButton
                                            ariaLabel={t(UNKNOWN_COUNTERPARTY)}
                                            className={'center-button'}
                                            onClick={() => handleButtonAction(vehicle.internalId)}
                                            value={t(UNKNOWN_COUNTERPARTY)}
                                            {...{ customCAN }}
                                        />
                                    ) : (
                                        <SecondaryButton
                                            ariaLabel={getButtonValue(vehicle)}
                                            className={'center-button'}
                                            onClick={() => handleButtonAction(vehicle.internalId)}
                                            value={getButtonValue(vehicle)}
                                            {...{ customCAN }}
                                        />
                                    )}
                                </div>
                            );
                        })}
                    </Grid>
                </div>
                <div className={'col-6 no-padding padding-right'}>
                    <Grid>
                        <div className={'col-12'}>
                            <h2 className={'sub-title'}>{tWithNS.t('selected.title')}</h2>
                        </div>
                        {responsibleVehicles.map((vehicle: VehicleModel, idx: number) => {
                            return (
                                <div className={'col-6 no-padding padding-top'} key={idx}>
                                    <p className={'overflow-paragraph'}>
                                        {vehicle.make}
                                        {vehicle.model}
                                    </p>
                                    {vehicle.make === t(UNKNOWN_COUNTERPARTY) ? (
                                        <SecondaryButton
                                            ariaLabel={t(UNKNOWN_COUNTERPARTY)}
                                            className={'active-secondary center-button'}
                                            onClick={() => handleButtonAction(vehicle.internalId)}
                                            value={t(UNKNOWN_COUNTERPARTY)}
                                            {...{ customCAN }}
                                        />
                                    ) : (
                                        <SecondaryButton
                                            ariaLabel={getButtonValue(vehicle)}
                                            className={'active-secondary center-button'}
                                            onClick={() => handleButtonAction(vehicle.internalId)}
                                            value={getButtonValue(vehicle)}
                                            {...{ customCAN }}
                                        />
                                    )}
                                </div>
                            );
                        })}
                    </Grid>
                </div>
                <div className={'col-12'}>
                    {responsibleVehiclesIsEmpty && (
                        <InputValidationError fieldName={'Road Center Information'} error={tWithNS.t('error')} />
                    )}
                </div>
            </Grid>
        </PageLayout>
    );
};
