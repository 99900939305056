import * as Yup from 'yup';
import { TFunction } from 'i18next';
import { externalReferenceSchema } from '../fieldSchemas/externalReferenceSchema';

/**
 * Validation logic
 */
export const counterpartyExternalReferenceSchema = (t: TFunction) => {
    return Yup.object().shape({
        ...externalReferenceSchema(t),
    });
};
