import React from 'react';
import { MCArialImage } from './MCArialImage';
import { MCLeftImage } from './MCLeftImage';
import { MCRightImage } from './MCRightImage';
import { createSvgTabs, MainImageSVGProps, SVGImageView } from '@protectorinsurance/ds-can';
import '../VehicleImageSvg.scss';
import TabPanel from '../../tabs/TabPanel';
import Tabs from '../../tabs/Tabs';
import { useI18n } from '../../../hooks/useI18n';

/**
 * Destructure necessary imports
 */
const { LEFT, RIGHT, TOP } = SVGImageView;

/**
 * Component view and component logic
 */
export const MCImage = (props: MainImageSVGProps) => {
    const { t } = useI18n();
    const tabs = createSvgTabs(t);

    return (
        <div className={'vehicle-body-damages'}>
            <Tabs label={t('tabs.label')} tabs={tabs}>
                <TabPanel tabId={TOP}>
                    <MCArialImage title={props.titles.top} {...props} />
                </TabPanel>
                <TabPanel tabId={RIGHT}>
                    <MCRightImage title={props.titles.right} {...props} />
                </TabPanel>
                <TabPanel tabId={LEFT}>
                    <MCLeftImage title={props.titles.left} {...props} />
                </TabPanel>
            </Tabs>
        </div>
    );
};
