import React, { SyntheticEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useI18n } from '../../../hooks/useI18n';
import { Controller, useForm } from 'react-hook-form';
import { selectSearchedVehicles, selectSelectedVehicle, selectVehicles } from '../../../sagas/selectors/motorSelectors';
import dispatcherWithPromise from '../../../utils/dispatcherWithPromise';
import { motorActions } from '../../../sagas/motor';
import { PhraseKeys } from '../../../config/phraseKeys';
import { FormFieldErrors, FormFieldNames } from '../../../config/formFieldNames';
import { vehicleInformationSchema } from '../../../validations/methods/registrationNumberSchema';
import {
    selectVehicleServiceError,
    selectVehicleServiceLoading,
} from '../../../sagas/selectors/vehicleServiceSelectors';
import { commonActions } from '../../../sagas/common';
import { wizardRouterActions } from '../../../sagas/wizardRouter';
import { DisplayVehicleInformation } from '../../../components/vehicle/DisplayVehicleInformation';
import { MotorRoutePaths } from '../../../config/wizardRouter/motorWizardRoutes';
import {
    Clickable,
    CountryCodeEnums,
    FormChangeable,
    generateId,
    Grid,
    HiddenInputSubmit,
    initVehicleModel,
    MuiTextInput,
    OptionType,
    PageLayout,
    PartyRelationKeys,
    PrimaryButton,
    RegistrationNumberModel,
    transformVehicleServiceResponse,
    VehicleModel,
    vehicleServiceActions,
    vehicleServiceErrorHandler,
    VehicleServiceModel,
    VehicleTypeKeys,
} from '@protectorinsurance/ds-can';
import { getVehicleTypeOptions } from '../../../utils/flow/vehicleType';
import { goBack } from 'connected-react-router';
import { yupResolver } from '@hookform/resolvers/yup';
import { Card, CardContent } from '@mui/material';
import { selectCustomCAN } from '../../../sagas/selectors/commonSelectors';

/**
 * Destructure necessary imports
 */
const { REG_NR, VEHICLE_TYPE } = FormFieldNames;
const { REGISTRATION_NUMBER_EXISTS } = FormFieldErrors;
const {
    BACK_BUTTON,
    CONTINUE_BUTTON,
    HELP_TEXT,
    LOOKUP_BUTTON,
    PAGE_NAME,
    REG_NR_LABEL,
    REG_NR_PLACEHOLDER,
    SELECT_BUTTON,
    SUB_TITLE,
    TITLE,
    VEHICLE_TYPE_LABEL,
    VEHICLE_TYPE_PLACEHOLDER,
} = PhraseKeys;
const { OTHER } = PartyRelationKeys;
const { END_DAMAGE_OVERVIEW } = MotorRoutePaths;

/**
 * Page view and page logic
 */
export const EndVehicleRegistrationNumberPage = () => {
    const dispatch = useDispatch();
    const { t } = useI18n();
    const tWithNS = useI18n('motor.end.vehicleRegistrationNumber');
    const vehicles = useSelector(selectVehicles);
    const vehicle = initVehicleModel;
    const loading = useSelector(selectVehicleServiceLoading);
    const vehicleServiceError = useSelector(selectVehicleServiceError);
    const selectedVehicle = useSelector(selectSelectedVehicle);
    const searchedVehicles = useSelector(selectSearchedVehicles);
    const customCAN = useSelector(selectCustomCAN);
    const internalId = generateId();
    const {
        control,
        formState: { errors },
        getValues,
        handleSubmit,
        setError,
        setValue,
        trigger,
    } = useForm<RegistrationNumberModel>({
        resolver: yupResolver(vehicleInformationSchema(t)),
    });
    const [vehicleType, setVehicleType] = useState<OptionType | null>(null);
    const [noVehicle, setNoVehicle] = useState<boolean>(false);
    const [searchRegNr, setSearchRegNr] = useState<string>('');
    const options = getVehicleTypeOptions(t);

    const handleBackButton = () => {
        dispatcherWithPromise(dispatch, vehicleServiceActions.initialize)
            .then(() => dispatcherWithPromise(dispatch, motorActions.update, { selectedVehicle: initVehicleModel }))
            .then(() => dispatch(goBack()));
    };

    useEffect(() => {
        const error = vehicleServiceErrorHandler(vehicleServiceError);

        if (error) {
            setNoVehicle(true);
        } else {
            setNoVehicle(false);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [vehicleServiceError, vehicleServiceErrorHandler]);

    // used for setting the vehicleType based on the users choice
    useEffect(() => {
        const selected = options.find((x) => x.value.toUpperCase() === (vehicle?.vehicleType as VehicleTypeKeys));

        if (selected) {
            setVehicleType(selected);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [vehicle]);

    // used for setting the vehicleType based on info from vehicleService
    useEffect(() => {
        const selected = options.find(
            (x) => x.value.toUpperCase() === (selectedVehicle.vehicleType as VehicleTypeKeys)
        );
        if (selected) {
            setVehicleType(selected);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedVehicle]);

    const handleBlur = async (e: FormChangeable) => {
        e.preventDefault();
        const { id } = e.target;
        await trigger(id);
    };

    const handleChange = async (e: FormChangeable) => {
        e.preventDefault();
        const { id, value } = e.currentTarget;
        await setValue(id, value);
    };

    const handleClick = async (e: Clickable) => {
        e.preventDefault();
        const values = getValues();
        if (!errors.registrationNumber?.message) {
            if (values.registrationNumber && values.registrationNumber !== '') {
                setNoVehicle(false);
                dispatcherWithPromise(dispatch, motorActions.update, { selectedVehicle: initVehicleModel })
                    .then(() =>
                        dispatcherWithPromise(dispatch, vehicleServiceActions.request, {
                            country: CountryCodeEnums.DK,
                            registrationNumber: values.registrationNumber && values.registrationNumber.toUpperCase(),
                        })
                    )
                    .then((res) => {
                        dispatch(
                            motorActions.update({
                                searchedVehicles: [
                                    ...searchedVehicles,
                                    transformVehicleServiceResponse(res as VehicleModel | VehicleServiceModel),
                                ],
                                selectedVehicle: transformVehicleServiceResponse(
                                    res as VehicleModel | VehicleServiceModel
                                ),
                            })
                        );
                    })
                    .catch(() => {});
                setSearchRegNr(values.registrationNumber);
                setValue('registrationNumber', '');
            } else {
                setNoVehicle(true);
            }
        }
    };

    const onSubmit = (values: RegistrationNumberModel) => {
        if (!errors.registrationNumber?.message) {
            if (values.registrationNumber && values.registrationNumber !== '') {
                setNoVehicle(false);
                dispatcherWithPromise(dispatch, motorActions.update, { selectedVehicle: initVehicleModel })
                    .then(() =>
                        dispatcherWithPromise(dispatch, vehicleServiceActions.request, {
                            country: CountryCodeEnums.DK,
                            registrationNumber: values.registrationNumber && values.registrationNumber.toUpperCase(),
                        })
                    )
                    .then((res) => {
                        dispatch(
                            motorActions.update({
                                searchedVehicles: [
                                    ...searchedVehicles,
                                    transformVehicleServiceResponse(res as VehicleModel | VehicleServiceModel),
                                ],
                                selectedVehicle: transformVehicleServiceResponse(
                                    res as VehicleModel | VehicleServiceModel
                                ),
                            })
                        );
                    })
                    .catch(() => {});
                setSearchRegNr(values.registrationNumber);
                setValue('registrationNumber', '');
            } else {
                setNoVehicle(true);
            }
        }
    };

    const handleNoHit = () => {
        if (
            searchRegNr === '' ||
            !vehicles.some((v: VehicleModel) => v.registrationNumber === searchRegNr?.toUpperCase())
        ) {
            dispatcherWithPromise(dispatch, motorActions.update, {
                selectedVehicle: {
                    ...vehicle,
                    registrationNumber: searchRegNr,
                    internalId,
                    vehicleType: vehicleType?.value as VehicleTypeKeys,
                },
                selectedVehicleId: internalId,
            })
                .then(() => dispatcherWithPromise(dispatch, vehicleServiceActions.initialize))
                .then(() => dispatcherWithPromise(dispatch, commonActions.send))
                .then(() => dispatch(wizardRouterActions.goToNext()));
        } else {
            setError(REG_NR, { type: 'registrationNumber', message: t(REGISTRATION_NUMBER_EXISTS) });
        }
    };

    const handleSelectVehicle = (registrationNumber: string | null) => {
        const vehicle = searchedVehicles.find((v) => v.registrationNumber === registrationNumber);
        dispatcherWithPromise(dispatch, motorActions.update, { selectedVehicle: vehicle }).then(() =>
            dispatch(commonActions.send())
        );
    };

    const handleSubmitVehicle = () => {
        if (
            !vehicles.some(
                (v: VehicleModel) => v.registrationNumber === selectedVehicle.registrationNumber?.toUpperCase()
            )
        ) {
            dispatcherWithPromise(dispatch, motorActions.update, {
                vehicles: [
                    ...vehicles,
                    {
                        ...vehicle,
                        ...selectedVehicle,
                        internalId,
                        partyRelation: OTHER,
                        vehicleType: vehicleType?.value as VehicleTypeKeys,
                    },
                ],
                selectedVehicleId: internalId,
                selectedVehicle: initVehicleModel,
            })
                .then(() => dispatcherWithPromise(dispatch, vehicleServiceActions.initialize))
                .then(() => dispatcherWithPromise(dispatch, commonActions.send))
                .then(() => dispatch(wizardRouterActions.goTo(END_DAMAGE_OVERVIEW)));
        } else {
            setError(REG_NR, { type: 'registrationNumber', message: t(REGISTRATION_NUMBER_EXISTS) });
        }
    };

    const handleSelectVehicleType = async (e: SyntheticEvent, option: OptionType) => {
        const selected = option ? option : null;
        if (selected) {
            setVehicleType(selected);
        }
    };

    return (
        <PageLayout
            backBtnText={t(BACK_BUTTON)}
            continueBtnText={t(CONTINUE_BUTTON)}
            disableContinueButton={loading}
            domainTitle={t(PAGE_NAME)}
            footerText={tWithNS.t(HELP_TEXT)}
            handleContinueButton={handleSubmit(onSubmit)}
            headerSubTitle={tWithNS.t(SUB_TITLE)}
            headerTitle={tWithNS.t(TITLE)}
            showContinueButton={false}
            {...{ handleBackButton }}
        >
            <form onSubmit={handleSubmit(onSubmit)}>
                <HiddenInputSubmit />
                <Grid className={'align-center'}>
                    <Controller
                        control={control}
                        name={REG_NR}
                        render={({ field: { ref, ...field } }) => (
                            <MuiTextInput
                                {...field}
                                disabled={loading}
                                error={!!errors.registrationNumber}
                                errorMessage={errors.registrationNumber?.message}
                                id={REG_NR}
                                inputFieldWrapper={'col-8'}
                                label={t(REG_NR_LABEL)}
                                loading={loading}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                placeholder={t(REG_NR_PLACEHOLDER)}
                                reference={ref}
                                {...{ customCAN }}
                            />
                        )}
                    />
                    <div className={'col-4'}>
                        <PrimaryButton className={'grid'} onClick={handleClick} value={t(LOOKUP_BUTTON)} />
                    </div>
                    {noVehicle && (
                        <div className={'col-12'}>
                            <Card style={{ textAlign: 'left' }} variant={'outlined'}>
                                <CardContent>
                                    <p style={{ fontWeight: 900, marginBottom: '0.5rem', textTransform: 'uppercase' }}>
                                        {tWithNS.t('noHitTitle')}
                                    </p>
                                    <p style={{ marginBottom: '1rem' }}>
                                        {tWithNS.t('noHitMessage', {
                                            RegNr: searchRegNr ? ` ${t('withText')} ${searchRegNr?.toUpperCase()}` : '',
                                        })}
                                    </p>
                                    <PrimaryButton
                                        className={'no-max-width'}
                                        onClick={handleNoHit}
                                        value={tWithNS.t('noHitButton')}
                                    />
                                </CardContent>
                            </Card>
                        </div>
                    )}
                    {selectedVehicle && selectedVehicle.registrationNumber && (
                        <>
                            <div className={'col-12'}>
                                <h3 className={'vehicle-list-header'}>{tWithNS.t('selectedVehicle.title')}</h3>
                            </div>
                            {DisplayVehicleInformation({
                                buttonAction: handleSubmitVehicle,
                                buttonText: t(CONTINUE_BUTTON),
                                customCAN,
                                id: VEHICLE_TYPE,
                                label: t(VEHICLE_TYPE_LABEL),
                                onChange: handleSelectVehicleType,
                                placeholder: t(VEHICLE_TYPE_PLACEHOLDER),
                                vehicle: {
                                    ...selectedVehicle,
                                    vehicleType: vehicleType && (vehicleType.value as VehicleTypeKeys),
                                },
                                vehicleSelect: true,
                                vehicleTypeOptions: options,
                                vehicleTypeSelectedOption: vehicleType,
                            })}
                        </>
                    )}
                    {searchedVehicles && searchedVehicles.length > 0 && (
                        <>
                            <div className={'col-12 padding-top'}>
                                <h3 className={'vehicle-list-header'}>{tWithNS.t('lookup.title')}</h3>
                            </div>
                            {searchedVehicles
                                .slice(0)
                                .reverse()
                                .map((vehicle) => {
                                    return DisplayVehicleInformation({
                                        buttonAction: () => handleSelectVehicle(vehicle.registrationNumber),
                                        buttonText: t(SELECT_BUTTON),
                                        customCAN,
                                        vehicle,
                                    });
                                })}
                        </>
                    )}
                </Grid>
            </form>
        </PageLayout>
    );
};
