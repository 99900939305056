import React from 'react';
import { DoctorInformationModel, SummaryItem, SummaryItemProps } from '@protectorinsurance/ds-can';

/**
 * Component view and component logic
 */
export const renderDoctorInformation = (
    { inputWrapperClass, textKey, dataTestId, buttonText, path, handleClick }: SummaryItemProps,
    doctorInformation: DoctorInformationModel
) => {
    if (doctorInformation.name || doctorInformation.city || doctorInformation.zip || doctorInformation.street) {
        return <SummaryItem {...{ buttonText, dataTestId, handleClick, inputWrapperClass, path, textKey }} />;
    }
    return <></>;
};
