import {
    LocaleServiceInstance,
    CountryCodeISOEnums,
    LanguageCodeEnums,
    MomentLocaleEnums,
} from '@protectorinsurance/ds-can';

LocaleServiceInstance.setCountry(CountryCodeISOEnums.DENMARK)
    .setLanguage(LanguageCodeEnums.DA)
    .setMoment(MomentLocaleEnums.DA)
    .initialize();
