import * as Yup from 'yup';
import { PhraseKeys } from '../../config/phraseKeys';
import { TFunction } from 'i18next';

/**
 * Destructure necessary imports
 */
const { COMPANY_POLICY_NUMBER_LABEL } = PhraseKeys;

/**
 * Validation logic
 */
export const policyNumberSchema = (t: TFunction) => {
    return {
        policyNumber: Yup.string().label(t(COMPANY_POLICY_NUMBER_LABEL)).PIAPolicyNumber(false).nullable(),
    };
};
