import { StringSchema, TestContext, ValidationError } from 'yup';
import { yupCustomLocale } from '../../config/yup';
import { NORMAL_CHARS_REGEX } from '../../utils/validation/stringFormats';

export function allowSpecialChars(
    this: StringSchema,
    key: string,
    message: string = yupCustomLocale.string.allowSpecialChars
) {
    // @ts-ignore
    return this.test('allowSpecialChars', message, function (this: TestContext, value: string):
        | boolean
        | ValidationError
        | Promise<boolean | ValidationError> {
        return !value || !!value.match(NORMAL_CHARS_REGEX);
    });
}
