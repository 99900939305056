import * as Yup from 'yup';
import { PhraseKeys } from '../../config/phraseKeys';
import { TFunction } from 'i18next';

/**
 * Destructure necessary import
 */
const { CLAIM_NUMBER_LABEL } = PhraseKeys;

/**
 * Validation logic
 */
export const claimNumberSchema = (t: TFunction) => {
    return {
        claimNumber: Yup.string().label(t(CLAIM_NUMBER_LABEL)).claimNumber().nullable(),
    };
};
