import React from 'react';
import { MotorRoutePaths } from '../../../config/wizardRouter/motorWizardRoutes';
import {
    ClaimLocationKeys,
    CollisionSituationKeys,
    initVehicleModel,
    is,
    isYes,
    MotorClaimCauseKeys,
    SummaryItem,
    SummaryVehicleList,
    TypeOfAnimalCollisionKeys,
    VehicleModel,
    YesNoKeys,
} from '@protectorinsurance/ds-can';
import { MotorSummaryProps } from '../../../types/SummaryProps';
import { TEST_ID_PREFIX } from '../../../utils/testIdUtil';
import { createTextYesNoDontKnow } from '../../../utils/displayUtils';

/**
 * Destructure necessary imports
 */
const {
    DRIVING_VEHICLE_COLLISION,
    DRIVING_VEHICLE_ROAD_CENTER_INFORMATION,
    END_VEHICLE_WHO_RESPONSIBLE,
    DYN_CAROUSEL_DRIVING_NO_VEHICLE_ANIMAL_HAS_ANIMAL_ESCAPED,
    DYN_CAROUSEL_DRIVING_NO_VEHICLE_ANIMAL_TYPE_OF_ANIMAL,
    DYN_CAROUSEL_DRIVING_IS_OTHER_VEHICLE_INVOLVED,
    DYN_CAROUSEL_DRIVING_VEHICLES_CLAIM_LOCATION,
    DYN_CAROUSEL_DRIVING_VEHICLES_IS_LANE_CHANGE,
    DYN_CAROUSEL_DRIVING_VEHICLES_LANE_CHANGE_WHO_AT_FAULT,
    DYN_CAROUSEL_DRIVING_VEHICLES_OVERTAKING_WHO_AT_FAULT,
    DYN_CAROUSEL_DRIVING_VEHICLES_PARKED_IS_REVERSING,
    DYN_CAROUSEL_DRIVING_VEHICLES_PARKED_WHO_AT_FAULT,
    DYN_CAROUSEL_DRIVING_VEHICLES_REVERSING_WHO_AT_FAULT,
    DYN_CAROUSEL_DRIVING_VEHICLES_ROUNDABOUT_CLAIMANT_ROUNDABOUT_POSITIONING,
    DYN_CAROUSEL_DRIVING_VEHICLES_ROUNDABOUT_COUNTERPARTY_ROUNDABOUT_POSITIONING,
} = MotorRoutePaths;
const { ANIMAL_COLLISION, CYCLIST_COLLISION, HEAD_ON, LANE_CHANGE, OVERTAKING, PARKED, REAR_ENDED, REVERSING } =
    MotorClaimCauseKeys;
const { GAME } = TypeOfAnimalCollisionKeys;
const { ROUNDABOUT } = ClaimLocationKeys;
const { AFTER } = CollisionSituationKeys;

/**
 * Page view and logic
 */
export const DisplayMotorDrivingSummary = ({ customCAN, model, t, tWithNS }: MotorSummaryProps) => {
    /**
     * Helper constants/functions
     */
    const {
        claimCause,
        claimLocation,
        collisionSituation,
        claimantRoundaboutPositioning,
        counterpartyRoundaboutPositioning,
        hasAnimalEscaped,
        isCyclistResponsible,
        isLaneChange,
        isOverRoadCenterUnknown,
        isOtherVehicleInvolved,
        isReversing,
        timeSinceAction,
        typeOfAnimalCollision,
        vehicles,
        whoAtFault,
    } = model;

    const renderClaimCause = () => {
        return (
            <>
                {/* Is Other Vehicle Involved */}
                <SummaryItem
                    textKey={tWithNS('isOtherVehicleInvolved.heading.title')}
                    dataTestId={TEST_ID_PREFIX.isOtherVehicleInvolved}
                    buttonText={t(createTextYesNoDontKnow(isOtherVehicleInvolved))}
                    path={DYN_CAROUSEL_DRIVING_IS_OTHER_VEHICLE_INVOLVED}
                    inputWrapperClass={'col-12 divider text-align-left'}
                />
            </>
        );
    };

    const renderAnimal = () => {
        return (
            is(claimCause, ANIMAL_COLLISION) && (
                <>
                    {/* Type Of Animal */}
                    <SummaryItem
                        textKey={tWithNS('typeOfAnimal.heading.title')}
                        dataTestId={TEST_ID_PREFIX.typeOfAnimal}
                        buttonText={t(`motor.driving.typeOfAnimal.select.${typeOfAnimalCollision}`)}
                        path={DYN_CAROUSEL_DRIVING_NO_VEHICLE_ANIMAL_TYPE_OF_ANIMAL}
                        inputWrapperClass={`${
                            !is(typeOfAnimalCollision, GAME) ? 'spacing' : 'divider'
                        } col-12 text-align-left`}
                    />

                    {/* Has Animal Escaped */}
                    {!is(typeOfAnimalCollision, GAME) && (
                        <SummaryItem
                            textKey={tWithNS('hasAnimalEscaped.heading.title')}
                            dataTestId={TEST_ID_PREFIX.hasAnimalEscaped}
                            buttonText={t(createTextYesNoDontKnow(hasAnimalEscaped))}
                            path={DYN_CAROUSEL_DRIVING_NO_VEHICLE_ANIMAL_HAS_ANIMAL_ESCAPED}
                            inputWrapperClass={'col-12 divider text-align-left'}
                        />
                    )}
                </>
            )
        );
    };

    const renderClaimSituation = () => {
        let responsibleVehicles = vehicles.filter((v: VehicleModel) => v.isResponsible);
        if (isCyclistResponsible) {
            responsibleVehicles = [
                ...responsibleVehicles,
                { ...initVehicleModel, make: t('motor.driving.whoResponsible.text.cyclist') },
            ];
        }
        return (
            (isYes(isOtherVehicleInvolved) ||
                (!isYes(isOtherVehicleInvolved) && is(claimCause, CYCLIST_COLLISION))) && (
                <>
                    {/* Who Responsible */}
                    <SummaryVehicleList
                        className={'col-12 spacing text-align-left'}
                        list={responsibleVehicles}
                        path={END_VEHICLE_WHO_RESPONSIBLE}
                        textKey={tWithNS('whoResponsible.heading.title')}
                        dataTestId={TEST_ID_PREFIX.vehicleList}
                    />

                    {/* Claim Location Type */}
                    <SummaryItem
                        textKey={tWithNS('claimLocation.heading.title')}
                        dataTestId={TEST_ID_PREFIX.claimLocation}
                        buttonText={claimLocation ? t(`motor.driving.claimLocation.select.${claimLocation}`) : '-'}
                        path={DYN_CAROUSEL_DRIVING_VEHICLES_CLAIM_LOCATION}
                        inputWrapperClass={'col-12 divider text-align-left'}
                    />
                </>
            )
        );
    };

    const renderRoundabout = () => {
        return (
            is(claimLocation, ROUNDABOUT) && (
                <>
                    {/* Claimant Roundabout Positioning */}
                    <SummaryItem
                        textKey={tWithNS('claimantRoundaboutPositioning.heading.title')}
                        dataTestId={TEST_ID_PREFIX.claimantRoundaboutPositioning}
                        buttonText={
                            claimantRoundaboutPositioning
                                ? t(
                                      `motor.driving.claimantRoundaboutPositioning.select.${claimantRoundaboutPositioning}`
                                  )
                                : '-'
                        }
                        path={DYN_CAROUSEL_DRIVING_VEHICLES_ROUNDABOUT_CLAIMANT_ROUNDABOUT_POSITIONING}
                        inputWrapperClass={'col-12 spacing text-align-left'}
                    />

                    {/* Counterparty Roundabout Positioning */}
                    <SummaryItem
                        textKey={tWithNS('counterpartyRoundaboutPositioning.heading.title')}
                        dataTestId={TEST_ID_PREFIX.counterpartyRoundaboutPositioning}
                        buttonText={
                            counterpartyRoundaboutPositioning
                                ? t(
                                      `motor.driving.counterpartyRoundaboutPositioning.select.${counterpartyRoundaboutPositioning}`
                                  )
                                : '-'
                        }
                        path={DYN_CAROUSEL_DRIVING_VEHICLES_ROUNDABOUT_COUNTERPARTY_ROUNDABOUT_POSITIONING}
                        inputWrapperClass={'col-12 divider text-align-left'}
                    />
                </>
            )
        );
    };

    const renderReversing = () => {
        return (
            is(claimCause, REVERSING) && (
                <>
                    {/* Reversing Who At Fault */}
                    <SummaryItem
                        textKey={tWithNS('reversingWhoAtFault.heading.title')}
                        dataTestId={TEST_ID_PREFIX.whoAtFault}
                        buttonText={whoAtFault ? t(`motor.driving.reversingWhoAtFault.select.${whoAtFault}`) : '-'}
                        path={DYN_CAROUSEL_DRIVING_VEHICLES_REVERSING_WHO_AT_FAULT}
                        inputWrapperClass={'col-12 divider text-align-left'}
                    />
                </>
            )
        );
    };

    const renderLaneChange = () => {
        return (
            is(claimCause, LANE_CHANGE) && (
                <>
                    {/* Lane Change Who At Fault */}
                    <SummaryItem
                        textKey={tWithNS('laneChangeWhoAtFault.heading.title')}
                        dataTestId={TEST_ID_PREFIX.whoAtFault}
                        buttonText={whoAtFault ? t(`motor.driving.laneChangeWhoAtFault.select.${whoAtFault}`) : '-'}
                        path={DYN_CAROUSEL_DRIVING_VEHICLES_LANE_CHANGE_WHO_AT_FAULT}
                        inputWrapperClass={'col-12 divider text-align-left'}
                    />
                </>
            )
        );
    };

    const renderOvertaking = () => {
        return (
            is(claimCause, OVERTAKING) && (
                <>
                    {/* Overtaking Who At Fault */}
                    <SummaryItem
                        textKey={tWithNS('overtakingWhoAtFault.heading.title')}
                        dataTestId={TEST_ID_PREFIX.whoAtFault}
                        buttonText={whoAtFault ? t(`motor.driving.overtakingWhoAtFault.select.${whoAtFault}`) : '-'}
                        path={DYN_CAROUSEL_DRIVING_VEHICLES_OVERTAKING_WHO_AT_FAULT}
                        inputWrapperClass={'col-12 divider text-align-left'}
                    />
                </>
            )
        );
    };

    const renderHeadOn = () => {
        let vehicleOverRoadCenter = vehicles.filter((v: VehicleModel) => v.isOverRoadCenter);
        return (
            is(claimCause, HEAD_ON) && (
                <>
                    {/* Is Over Road Center Unknown */}
                    {isOverRoadCenterUnknown && (
                        <SummaryItem
                            textKey={tWithNS('overRoadCenter.heading.title')}
                            dataTestId={TEST_ID_PREFIX.isOverRoadCenter}
                            buttonText={t(`motor.driving.parkedWhoAtFault.select.${YesNoKeys.DONT_KNOW}`)}
                            path={DRIVING_VEHICLE_ROAD_CENTER_INFORMATION}
                            inputWrapperClass={'col-12 divider text-align-left'}
                        />
                    )}

                    {/* Over Road Center */}
                    {!isOverRoadCenterUnknown && (
                        <SummaryVehicleList
                            className={'col-12 spacing text-align-left'}
                            textKey={tWithNS('overRoadCenter.heading.title')}
                            dataTestId={TEST_ID_PREFIX.isOverRoadCenter}
                            list={vehicleOverRoadCenter}
                            path={DRIVING_VEHICLE_ROAD_CENTER_INFORMATION}
                            {...{ customCAN }}
                        />
                    )}
                </>
            )
        );
    };

    const renderParked = () => {
        return (
            is(claimCause, PARKED) && (
                <>
                    {/* Parked Who At Fault */}
                    <SummaryItem
                        textKey={tWithNS('parkedWhoAtFault.heading.title')}
                        dataTestId={TEST_ID_PREFIX.whoAtFault}
                        buttonText={whoAtFault ? t(`motor.driving.parkedWhoAtFault.select.${whoAtFault}`) : '-'}
                        path={DYN_CAROUSEL_DRIVING_VEHICLES_PARKED_WHO_AT_FAULT}
                        inputWrapperClass={'col-12 spacing text-align-left'}
                    />

                    {/* Is Reversing */}
                    <SummaryItem
                        textKey={tWithNS('isReversing.heading.title')}
                        dataTestId={TEST_ID_PREFIX.isReversing}
                        buttonText={t(createTextYesNoDontKnow(isReversing))}
                        path={DYN_CAROUSEL_DRIVING_VEHICLES_PARKED_IS_REVERSING}
                        inputWrapperClass={'col-12 divider text-align-left'}
                    />
                </>
            )
        );
    };

    const renderCollision = () => {
        return (
            (is(claimCause, REVERSING) || is(claimCause, LANE_CHANGE)) && (
                <>
                    {/* Collision Situation */}
                    <SummaryItem
                        textKey={tWithNS(
                            claimCause === REVERSING
                                ? 'reversingCollision.heading.title'
                                : 'laneChangeCollision.heading.title'
                        )}
                        dataTestId={TEST_ID_PREFIX.collisionSituation}
                        buttonText={
                            collisionSituation
                                ? t(
                                      claimCause === REVERSING
                                          ? `motor.driving.reversingCollision.${collisionSituation}`
                                          : `motor.driving.laneChangeCollision.${collisionSituation}`
                                  )
                                : '-'
                        }
                        path={DRIVING_VEHICLE_COLLISION}
                        inputWrapperClass={`${
                            is(collisionSituation, AFTER) ? 'spacing' : 'divider'
                        } col-12 text-align-left`}
                    />

                    {/* Time Since Action */}
                    {is(collisionSituation, AFTER) && (
                        <SummaryItem
                            textKey={tWithNS(
                                claimCause === REVERSING
                                    ? 'reversingTimeSinceAction.heading.title'
                                    : 'laneChangeTimeSinceAction.heading.title'
                            )}
                            dataTestId={TEST_ID_PREFIX.timeSinceAction}
                            buttonText={timeSinceAction ? timeSinceAction.toString() : '-'}
                            path={DRIVING_VEHICLE_COLLISION}
                            inputWrapperClass={'col-12 divider text-align-left'}
                        />
                    )}
                </>
            )
        );
    };

    const renderIsLaneChange = () => {
        return (
            (is(claimCause, HEAD_ON) || is(claimCause, OVERTAKING) || is(claimCause, REAR_ENDED)) && (
                <>
                    {/* Is Lane Change */}
                    <SummaryItem
                        textKey={tWithNS('isLaneChange.heading.title')}
                        dataTestId={TEST_ID_PREFIX.isLaneChange}
                        buttonText={t(createTextYesNoDontKnow(isLaneChange))}
                        path={DYN_CAROUSEL_DRIVING_VEHICLES_IS_LANE_CHANGE}
                        inputWrapperClass={'col-12 divider text-align-left'}
                    />
                </>
            )
        );
    };

    return (
        <>
            {renderClaimCause()}

            {renderAnimal()}

            {renderClaimSituation()}

            {renderRoundabout()}

            {renderHeadOn()}

            {renderOvertaking()}

            {renderLaneChange()}

            {renderReversing()}

            {renderParked()}

            {renderCollision()}

            {renderIsLaneChange()}
        </>
    );
};
