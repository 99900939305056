import React, { useRef } from 'react';
import { BodyKeys } from '@protectorinsurance/ds-can';
import { SvgImg } from '@protectorinsurance/ds-can';
import { useMarkers } from '../../../hooks/useMarkers';
import { createOnClickAddDamagePoint } from '../../../utils/svgUtils';
import { DamageMarkers } from '../DamageMarker';

/**
 * Destructure necessary imports
 */
const {
    BACK_BUMPER,
    BACK_LEFT,
    BACK_RIGHT,
    CENTER_LEFT,
    CENTER_RIGHT,
    FRONT_BUMPER,
    FRONT_LEFT,
    FRONT_RIGHT,
    REAR_WINDSHIELD,
    ROOF,
    WINDSHIELD,
} = BodyKeys;

/**
 * Component view and component logic
 */
export const CarArialImage = ({
    addDamagePoint,
    removeDamagePoint,
    selectedDamagePoints,
    title,
    id = 'car-arial-image-svg',
    className,
}: SvgImg) => {
    const refSVG = useRef<SVGSVGElement>(null);
    const thisMarkers = useMarkers(selectedDamagePoints, id);
    const onClick = createOnClickAddDamagePoint(refSVG, addDamagePoint);

    return (
        <>
            <svg
                id={id}
                data-testid={id}
                className={'vehicle-img-svg arial-image-svg'}
                ref={refSVG}
                width="326"
                height="698"
                viewBox="0 0 326 698"
                aria-labelledby={`${id}-title`}
                role="img"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <title id={`${id}-title`}>{title}</title>
                <svg width="326" height="698" viewBox="0 0 326 698" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g className={'arial-image-svg__selectable'}>
                        <rect
                            id={CENTER_LEFT}
                            onClick={onClick}
                            x="2.5"
                            y="204.5"
                            width="56"
                            height="289"
                            fill="#FFFFFF"
                            stroke="#7D8890"
                        />
                        <rect
                            id={CENTER_RIGHT}
                            onClick={onClick}
                            x="266.5"
                            y="204.5"
                            width="56"
                            height="289"
                            fill="#FFFFFF"
                            stroke="#7D8890"
                        />
                        <path
                            id={BACK_LEFT}
                            onClick={onClick}
                            d="M6.99902 695.5H162.245V494.601H2.49902V691C2.49902 693.485 4.51374 695.5 6.99902 695.5Z"
                            fill="#FFFFFF"
                            stroke="#7D8890"
                        />
                        <path
                            id={BACK_RIGHT}
                            onClick={onClick}
                            d="M319.119 695.5H163.245V494.601H323.619V691C323.619 693.485 321.604 695.5 319.119 695.5Z"
                            fill="#FFFFFF"
                            stroke="#7D8890"
                        />
                        <path
                            id={FRONT_LEFT}
                            onClick={onClick}
                            d="M7 2.5H162.5V203.5H2.5V7C2.5 4.51472 4.51472 2.5 7 2.5Z"
                            fill="#FFFFFF"
                            stroke="#7D8890"
                        />
                        <path
                            id={FRONT_RIGHT}
                            onClick={onClick}
                            d="M168 2.5H322.5V203.5H163.5V7C163.5 4.51472 165.515 2.5 168 2.5Z"
                            fill="#FFFFFF"
                            stroke="#7D8890"
                        />
                        <rect
                            id={FRONT_BUMPER}
                            onClick={onClick}
                            x="25"
                            y="0"
                            width="275"
                            height="25"
                            fill="#FFFFFF"
                            stroke="black"
                        />
                        <rect
                            id={WINDSHIELD}
                            onClick={onClick}
                            x="59"
                            y="150"
                            width="207"
                            height="130"
                            fill="#FFFFFF"
                            stroke="black"
                        />
                        <rect
                            id={ROOF}
                            onClick={onClick}
                            x="59"
                            y="280"
                            width="207"
                            height="290"
                            fill="#FFFFFF"
                            stroke="black"
                        />
                        <rect
                            id={REAR_WINDSHIELD}
                            onClick={onClick}
                            x="59"
                            y="490"
                            width="207"
                            height="130"
                            fill="#FFFFFF"
                            stroke="black"
                        />
                        <rect
                            id={BACK_BUMPER}
                            onClick={onClick}
                            x="45"
                            y="673"
                            width="235"
                            height="25"
                            fill="#FFFFFF"
                            stroke="black"
                        />
                    </g>
                    <g className={`arial-image-svg__content ${className}`}>
                        <path
                            d="M29.1186 406L29.1186 270.5H2.11865C1.07326 250.203 6.61853 243 29.1186 240.5L29.1186 111C29.1186 1.99994 95.1187 2 109.619 2H216.499C230.999 2 296.999 1.99994 296.999 111V240.5C319.499 243 325.044 250.203 323.999 270.5H296.999V406L283.499 649.5C282.983 668.283 274.744 674.513 256.118 683.5C238.343 691.612 225.831 694.075 200.499 696H129.574C105.057 695.667 91.7927 693.069 69.1185 683.5C47.9633 672.555 44.0003 664.736 42.999 649.5L29.1186 406Z"
                            stroke="black"
                            strokeWidth="3.6"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        <path
                            d="M243.999 486C180.733 499.241 145.265 499.429 81.999 486L67.999 594C143.548 630.425 185.118 629.595 257.999 594L243.999 486Z"
                            stroke="black"
                            strokeWidth="3.6"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        <path
                            d="M42.499 507V197.5C65.3381 305.95 72.5608 366.712 72.999 475L60.499 518L42.499 507Z"
                            stroke="black"
                            strokeWidth="3.6"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        <path
                            d="M282.999 507V197.5C260.16 305.95 252.937 366.712 252.499 475L264.999 518L282.999 507Z"
                            stroke="black"
                            strokeWidth="3.6"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        <path
                            d="M54.1185 194.5L69.1185 283.5C113.347 272.893 115.738 271.147 164.119 271.5C212.499 271.147 211.89 272.893 256.118 283.5L271.118 194.5C271.118 163 213.942 148.358 164.119 148.5C114.659 148.641 54.1185 163 54.1185 194.5Z"
                            stroke="black"
                            strokeWidth="3.6"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </g>
                </svg>
                <DamageMarkers removeDamagePoint={removeDamagePoint} selectedDamagePoints={thisMarkers} />
            </svg>
        </>
    );
};
