import React from 'react';
import { is, SummaryItem, SummaryItemProps, WhoReceivesSettlementTypeModel } from '@protectorinsurance/ds-can';

/**
 * Component view and component logic
 */
export const renderWhoReceivesSettlement = (
    { inputWrapperClass, textKey, dataTestId, buttonText, path, handleClick }: SummaryItemProps,
    whoReceivesSettlement: WhoReceivesSettlementTypeModel
) => {
    if (!is(whoReceivesSettlement, null)) {
        return <SummaryItem {...{ buttonText, dataTestId, handleClick, inputWrapperClass, path, textKey }} />;
    }
    return <></>;
};
