import { api, apiService } from '@protectorinsurance/ds-can';

export { api, apiService };

const APPLICATION_JSON = 'application/json';
export const APPLICATION_JSON_HEADER = {
    Accept: APPLICATION_JSON,
    'Content-Type': APPLICATION_JSON,
};

export enum HTTP_STATUS {
    OK = 200,
    NO_CONTENT = 204,
    BAD_REQUEST = 400,
    INTERNAL_SERVER_ERROR = 500,
}
