import React from 'react';
import { useI18n } from '../../hooks/useI18n';
import { useHistory, useLocation } from 'react-router-dom';
import './LanguageSwitcher.scss';
import {
    CountryCodeISOEnums,
    LanguageCodeEnums,
    LocaleServiceInstance,
    MomentLocaleEnums,
} from '@protectorinsurance/ds-can';
import { useDispatch } from 'react-redux';
import { commonActions } from '../../sagas/common';

export const LanguageSwitcher = () => {
    /**
     * Hooks
     */
    const { t, i18n } = useI18n();
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();

    /**
     * Action handlers
     */
    const handleSwitchLanguage = (language: LanguageCodeEnums) => {
        const [slash, country, , ...rest] = location.pathname.split('/');
        const path = [slash, country, language, ...rest].join('/');

        // Change language in i18n
        i18n.changeLanguage(language);

        // Change language on LocaleServiceInstance
        LocaleServiceInstance.setLanguage(language).setMoment(
            language === LanguageCodeEnums.DA ? MomentLocaleEnums.DA : MomentLocaleEnums.EN
        );

        // Update in store
        dispatch(
            commonActions.update({
                locale: {
                    language: language,
                    country: CountryCodeISOEnums.DENMARK,
                },
            })
        );

        // Change url
        history.push(path);
    };

    const currentLocale = LocaleServiceInstance.getLocale();
    const toggleLocale = currentLocale === LanguageCodeEnums.DA ? LanguageCodeEnums.EN : LanguageCodeEnums.DA;

    /**
     * Render functions
     */
    return (
        <div className={'link-container'}>
            <button onClick={() => handleSwitchLanguage(toggleLocale)}>
                {t(`footer.languageSwitcher.${toggleLocale.toUpperCase()}`)}
            </button>
        </div>
    );
};
