import { LpoRoutePaths } from './lpoWizardRoutes';
import { FlowKeys, getLobFromUrl, getLocaleFromUrl, ObjectWithDynamicKey } from '@protectorinsurance/ds-can';
import { strReplace } from '../../utils/strings/strReplace';

const lpoProgressBar = (path: LpoRoutePaths) => {
    const { language } = getLocaleFromUrl();
    const lob = getLobFromUrl();
    const find = [':language', ':lob'];
    const replace = [language ? language : '', lob.lob ? lob.lob.toString() : ''];
    const url = strReplace(path, find, replace);
    return url;
};

export const lpoProgressBarRoutes: ObjectWithDynamicKey = {
    [FlowKeys.START]: [
        lpoProgressBar(LpoRoutePaths.DYN_CAROUSEL_GLASS_IS_WORK_RELATED),
        lpoProgressBar(LpoRoutePaths.GLASS_REQUIREMENT),
        lpoProgressBar(LpoRoutePaths.START_ONBOARDING),
        lpoProgressBar(LpoRoutePaths.START_PRIVACY),
        lpoProgressBar(LpoRoutePaths.DYN_CAROUSEL_START_CLAIM_REPORTER_ROLE),
        lpoProgressBar(LpoRoutePaths.PROPERTY_CLAIM_TYPE),
        lpoProgressBar(LpoRoutePaths.TRANSPORT_CLAIM_TYPE),
        lpoProgressBar(LpoRoutePaths.LIABILITY_CLAIM_TYPE),
        lpoProgressBar(LpoRoutePaths.TRANSPORT_VEHICLE_REGISTRATION_NUMBER),
        lpoProgressBar(LpoRoutePaths.START_CLAIM_DATE),
        lpoProgressBar(LpoRoutePaths.DYN_CAROUSEL_LIABILITY_IS_PERSON_INJURY),
        lpoProgressBar(LpoRoutePaths.LIABILITY_DAMAGE_CLAIM_DESCRIPTION),
        lpoProgressBar(LpoRoutePaths.LIABILITY_CLAIMANT_INFORMATION),
        lpoProgressBar(LpoRoutePaths.START_ACCIDENT_LOCATION),
        lpoProgressBar(LpoRoutePaths.START_PROPERTY_ADDRESS),
    ],

    [FlowKeys.GLASS]: [
        lpoProgressBar(LpoRoutePaths.GLASS_CLAIM_DATE),
        lpoProgressBar(LpoRoutePaths.GROUP_ACCIDENT_ACCIDENT_LOCATION),
        lpoProgressBar(LpoRoutePaths.DYN_CAROUSEL_GLASS_CLAIM_LOCATION),
        lpoProgressBar(LpoRoutePaths.DYN_CAROUSEL_GROUP_ACCIDENT_IS_ACCIDENT),
        lpoProgressBar(LpoRoutePaths.GLASS_OTHER_CLAIM_LOCATION),
        lpoProgressBar(LpoRoutePaths.DYN_CAROUSEL_GROUP_ACCIDENT_IS_POLICE_CONTACTED),
        lpoProgressBar(LpoRoutePaths.GROUP_ACCIDENT_POLICE_DISTRICT),
        lpoProgressBar(LpoRoutePaths.GROUP_ACCIDENT_POLICE_CASE_NUMBER),
        lpoProgressBar(LpoRoutePaths.DYN_CAROUSEL_GROUP_ACCIDENT_REPORTED_TO_COUNTERPARTY),
        lpoProgressBar(LpoRoutePaths.GROUP_ACCIDENT_COUNTERPARTY_INSURANCE_COMPANY),
        lpoProgressBar(LpoRoutePaths.GROUP_ACCIDENT_COUNTERPARTY_EXTERNAL_REFERENCE),
        lpoProgressBar(LpoRoutePaths.DYN_CAROUSEL_GLASS_HAS_HEALTH_INSURANCE),
        lpoProgressBar(LpoRoutePaths.GLASS_HEALTH_INSURANCE),
        lpoProgressBar(LpoRoutePaths.DYN_CAROUSEL_GROUP_ACCIDENT_DURING_WORK),
        lpoProgressBar(LpoRoutePaths.DYN_CAROUSEL_GROUP_ACCIDENT_REPORTED_TO_INSURERS_INSURANCE),
        lpoProgressBar(LpoRoutePaths.GROUP_ACCIDENT_INJURY_INSURANCE),
        lpoProgressBar(LpoRoutePaths.GROUP_ACCIDENT_INJURY_INSURANCE_EXTERNAL_REFERENCE),
        lpoProgressBar(LpoRoutePaths.DYN_CAROUSEL_GROUP_ACCIDENT_IS_UNDERAGED),
        lpoProgressBar(LpoRoutePaths.GLASS_CLAIMANT_INFORMATION),
        lpoProgressBar(LpoRoutePaths.GROUP_ACCIDENT_GUARDIAN_INFORMATION),
        lpoProgressBar(LpoRoutePaths.END_CLAIM_DESCRIPTION),
        lpoProgressBar(LpoRoutePaths.GROUP_ACCIDENT_CLAIM_CAUSE),
        lpoProgressBar(LpoRoutePaths.DYN_CAROUSEL_GROUP_ACCIDENT_IS_DOCTOR_CONTACTED),
        lpoProgressBar(LpoRoutePaths.GROUP_ACCIDENT_DATE_OF_TREATMENT),
        lpoProgressBar(LpoRoutePaths.GROUP_ACCIDENT_DOCTOR_INFORMATION),
        lpoProgressBar(LpoRoutePaths.DYN_CAROUSEL_GROUP_ACCIDENT_PREVIOUS_INJURY),
        lpoProgressBar(LpoRoutePaths.GROUP_ACCIDENT_TYPE_OF_INJURY),
        lpoProgressBar(LpoRoutePaths.GROUP_ACCIDENT_DATE_OF_PREVIOUS_INJURY),
        lpoProgressBar(LpoRoutePaths.GLASS_TYPE_OF_GLASS_DAMAGE),
        lpoProgressBar(LpoRoutePaths.END_UPLOAD_ATTACHMENT),
        lpoProgressBar(LpoRoutePaths.DYN_CAROUSEL_GLASS_WHO_RECEIVES_SETTLEMENT),
        lpoProgressBar(LpoRoutePaths.GLASS_BANK_ACCOUNT_INFORMATION),
        lpoProgressBar(LpoRoutePaths.END_POLICY_HOLDER),
        lpoProgressBar(LpoRoutePaths.END_REPORTER_INFORMATION),
        lpoProgressBar(LpoRoutePaths.END_POLICY_HOLDERS_CONTACT),
        lpoProgressBar(LpoRoutePaths.END_FINAL_SUMMARY),
        lpoProgressBar(LpoRoutePaths.END_REPORT_COMPLETED),
    ],

    [FlowKeys.END]: [
        lpoProgressBar(LpoRoutePaths.END_CLAIM_DESCRIPTION),
        lpoProgressBar(LpoRoutePaths.GLASS_TYPE_OF_GLASS_DAMAGE),
        lpoProgressBar(LpoRoutePaths.DYN_CAROUSEL_LIABILITY_IS_RESPONSIBLE),
        lpoProgressBar(LpoRoutePaths.LIABILITY_CLAIM_DESCRIPTION),
        lpoProgressBar(LpoRoutePaths.DYN_CAROUSEL_LIABILITY_HAS_CONTRIBUTED_TO_INJURY),
        lpoProgressBar(LpoRoutePaths.LIABILITY_INJURED_CLAIM_DESCRIPTION),
        lpoProgressBar(LpoRoutePaths.END_UPLOAD_ATTACHMENT),
        lpoProgressBar(LpoRoutePaths.DYN_CAROUSEL_END_HAS_OTHER_INSURANCE),
        lpoProgressBar(LpoRoutePaths.END_OTHER_INSURANCE_COMPANY),
        lpoProgressBar(LpoRoutePaths.END_EXTERNAL_REFERENCE),
        lpoProgressBar(LpoRoutePaths.END_POLICY_HOLDER),
        lpoProgressBar(LpoRoutePaths.END_REPORTER_INFORMATION),
        lpoProgressBar(LpoRoutePaths.END_POLICY_HOLDERS_CONTACT),
        lpoProgressBar(LpoRoutePaths.END_FINAL_SUMMARY),
        lpoProgressBar(LpoRoutePaths.END_REPORT_COMPLETED),
    ],
};
