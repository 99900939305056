import React from 'react';
import { ClaimTypeEnums, ClaimTypeTypeModel, is, SummaryItem, SummaryItemProps } from '@protectorinsurance/ds-can';
import { TFunction } from 'i18next';

/**
 * Destructure necessary imports
 */
const { CARRIER } = ClaimTypeEnums;

/**
 * Component view and component logic
 */
export const renderRegistrationNumber = (
    { inputWrapperClass, textKey, dataTestId, buttonText, path, handleClick }: SummaryItemProps,
    claimType: ClaimTypeTypeModel,
    t: TFunction
) => {
    return (
        <>
            {is(claimType, CARRIER) && (
                <SummaryItem {...{ buttonText, dataTestId, handleClick, inputWrapperClass, path, textKey }} />
            )}
        </>
    );
};
