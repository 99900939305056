import { ClaimLocationKeys, ObjectWithDynamicKey, YesNoKeys } from '@protectorinsurance/ds-can';

export enum LpoRoutePaths {
    INDEX = '/dnk/:language/:lob',
    ERROR = '/error',

    // Dyn Paths
    DYN_BASE = '/dnk/:language/:lob/dyn/',
    DYN_CAROUSEL = '/dnk/:language/:lob/dyn/:flow?/:slug',
    // Start
    DYN_CAROUSEL_START_CLAIM_REPORTER_ROLE = '/dnk/:language/:lob/dyn/start/reporter-role',
    // Liability
    DYN_CAROUSEL_LIABILITY_IS_PERSON_INJURY = '/dnk/:language/:lob/dyn/liability/is-person-injury',
    DYN_CAROUSEL_LIABILITY_IS_RESPONSIBLE = '/dnk/:language/:lob/dyn/liability/is-responsible',
    DYN_CAROUSEL_LIABILITY_HAS_CONTRIBUTED_TO_INJURY = '/dnk/:language/:lob/dyn/liability/has-contributed-to-injury',
    // Glass
    DYN_CAROUSEL_GLASS_IS_WORK_RELATED = '/dnk/:language/:lob/dyn/glass/is-work-related',
    DYN_CAROUSEL_GLASS_CLAIM_LOCATION = '/dnk/:language/:lob/dyn/glass/claim-location',
    DYN_CAROUSEL_GLASS_HAS_HEALTH_INSURANCE = '/dnk/:language/:lob/dyn/glass/has-health-insurance',
    DYN_CAROUSEL_GLASS_WHO_RECEIVES_SETTLEMENT = '/dnk/:language/:lob/dyn/glass/who-receives-settlement',
    // Group Accident
    DYN_CAROUSEL_GROUP_ACCIDENT_IS_ACCIDENT = '/dnk/:language/:lob/dyn/group-accident/is-accident',
    DYN_CAROUSEL_GROUP_ACCIDENT_IS_POLICE_CONTACTED = '/dnk/:language/:lob/dyn/group-accident/is-police-contacted',
    DYN_CAROUSEL_GROUP_ACCIDENT_REPORTED_TO_COUNTERPARTY = '/dnk/:language/:lob/dyn/group-accident/reported-to-counterparty',
    DYN_CAROUSEL_GROUP_ACCIDENT_DURING_WORK = '/dnk/:language/:lob/dyn/group-accident/during-work',
    DYN_CAROUSEL_GROUP_ACCIDENT_REPORTED_TO_INSURERS_INSURANCE = '/dnk/:language/:lob/dyn/group-accident/reported-to-insurers-insurance',
    DYN_CAROUSEL_GROUP_ACCIDENT_IS_UNDERAGED = '/dnk/:language/:lob/dyn/group-accident/is-underaged',
    DYN_CAROUSEL_GROUP_ACCIDENT_IS_DOCTOR_CONTACTED = '/dnk/:language/:lob/dyn/group-accident/is-doctor-contacted',
    DYN_CAROUSEL_GROUP_ACCIDENT_PREVIOUS_INJURY = '/dnk/:language/:lob/dyn/group-accident/previous-injury',
    // End
    DYN_CAROUSEL_END_HAS_OTHER_INSURANCE = '/dnk/:language/:lob/dyn/end/has-other-insurance',

    // Start Paths
    START_ONBOARDING = '/dnk/:language/:lob/start/onboarding',
    START_PRIVACY = '/dnk/:language/:lob/start/privacy',
    START_CLAIM_DATE = '/dnk/:language/:lob/start/claim-date',
    START_ACCIDENT_LOCATION = '/dnk/:language/:lob/start/accident-location',
    START_PROPERTY_ADDRESS = '/dnk/:language/:lob/start/property-address',

    // Liability
    LIABILITY_CLAIM_TYPE = '/dnk/:language/:lob/liability/claim-type',
    LIABILITY_DAMAGE_CLAIM_DESCRIPTION = '/dnk/:language/:lob/liability/damage-claim-description',
    LIABILITY_CLAIMANT_INFORMATION = '/dnk/:language/:lob/liability/claimant-information',
    LIABILITY_CLAIM_DESCRIPTION = '/dnk/:language/:lob/liability/claim-description',
    LIABILITY_INJURED_CLAIM_DESCRIPTION = '/dnk/:language/:lob/liability/injured-claim-description',

    // Property
    PROPERTY_CLAIM_TYPE = '/dnk/:language/:lob/property/claim-type',

    // Transport
    TRANSPORT_CLAIM_TYPE = '/dnk/:language/:lob/transport/claim-type',
    TRANSPORT_VEHICLE_REGISTRATION_NUMBER = '/dnk/:language/:lob/transport/registration-number',

    // Glass
    GLASS_REQUIREMENT = '/dnk/:language/:lob/glass/requirement',
    GLASS_CLAIM_DATE = '/dnk/:language/:lob/glass/claim-date',
    GLASS_OTHER_CLAIM_LOCATION = '/dnk/:language/:lob/glass/other-claim-location',
    GLASS_HEALTH_INSURANCE = '/dnk/:language/:lob/glass/health-insurance',
    GLASS_CLAIMANT_INFORMATION = '/dnk/:language/:lob/glass/claimant-information',
    GLASS_TYPE_OF_GLASS_DAMAGE = '/dnk/:language/:lob/glass/type-of-glass-damage',
    GLASS_BANK_ACCOUNT_INFORMATION = '/dnk/:language/:lob/glass/bank-account-information',

    // Group Accident
    GROUP_ACCIDENT_ACCIDENT_LOCATION = '/dnk/:language/:lob/group-accident/accident-location',
    GROUP_ACCIDENT_POLICE_DISTRICT = '/dnk/:language/:lob/group-accident/police-district',
    GROUP_ACCIDENT_POLICE_CASE_NUMBER = '/dnk/:language/:lob/group-accident/police-case-number',
    GROUP_ACCIDENT_COUNTERPARTY_INSURANCE_COMPANY = '/dnk/:language/:lob/group-accident/counterparty-insurance-company',
    GROUP_ACCIDENT_COUNTERPARTY_EXTERNAL_REFERENCE = '/dnk/:language/:lob/group-accident/counterparty-external-reference',
    GROUP_ACCIDENT_INJURY_INSURANCE = '/dnk/:language/:lob/group-accident/injury-insurance',
    GROUP_ACCIDENT_INJURY_INSURANCE_EXTERNAL_REFERENCE = '/dnk/:language/:lob/group-accident/injury-insurance-external-reference',
    GROUP_ACCIDENT_GUARDIAN_INFORMATION = '/dnk/:language/:lob/group-accident/guardian-information',
    GROUP_ACCIDENT_CLAIM_CAUSE = '/dnk/:language/:lob/group-accident/claim-cause',
    GROUP_ACCIDENT_DATE_OF_TREATMENT = '/dnk/:language/:lob/group-accident/date-of-treatment',
    GROUP_ACCIDENT_DOCTOR_INFORMATION = '/dnk/:language/:lob/group-accident/doctor-information',
    GROUP_ACCIDENT_TYPE_OF_INJURY = '/dnk/:language/:lob/group-accident/type-of-injury',
    GROUP_ACCIDENT_DATE_OF_PREVIOUS_INJURY = '/dnk/:language/:lob/group-accident/date-of-previous-injury',

    // End Paths
    END_CLAIM_DESCRIPTION = '/dnk/:language/:lob/end/claim-description',
    END_UPLOAD_ATTACHMENT = '/dnk/:language/:lob/end/upload-attachment',
    END_OTHER_INSURANCE_COMPANY = '/dnk/:language/:lob/end/other-insurance-company',
    END_EXTERNAL_REFERENCE = '/dnk/:language/:lob/end/external-reference',
    END_POLICY_HOLDER = '/dnk/:language/:lob/end/policy-holder',

    END_REPORTER_INFORMATION = '/dnk/:language/:lob/end/reporter-information',
    END_POLICY_HOLDERS_CONTACT = '/dnk/:language/:lob/end/policy-holders-contact',
    END_FINAL_SUMMARY = '/dnk/:language/:lob/end/final-summary',
    END_REPORT_COMPLETED = '/dnk/:language/:lob/end/report-completed',
}

export const lpoWizardRoutes: ObjectWithDynamicKey = {
    // Start Routes
    [LpoRoutePaths.INDEX]: LpoRoutePaths.START_PRIVACY,
    [LpoRoutePaths.START_ONBOARDING]: LpoRoutePaths.START_PRIVACY,
    [LpoRoutePaths.START_PRIVACY]: LpoRoutePaths.DYN_CAROUSEL_START_CLAIM_REPORTER_ROLE,
    [LpoRoutePaths.DYN_CAROUSEL_START_CLAIM_REPORTER_ROLE]: LpoRoutePaths.PROPERTY_CLAIM_TYPE,
    [LpoRoutePaths.START_CLAIM_DATE]: LpoRoutePaths.START_ACCIDENT_LOCATION,
    [LpoRoutePaths.START_ACCIDENT_LOCATION]: LpoRoutePaths.END_CLAIM_DESCRIPTION,
    [LpoRoutePaths.START_PROPERTY_ADDRESS]: LpoRoutePaths.END_CLAIM_DESCRIPTION,

    // Liability Routes
    [LpoRoutePaths.LIABILITY_CLAIM_TYPE]: LpoRoutePaths.START_CLAIM_DATE,
    [LpoRoutePaths.DYN_CAROUSEL_LIABILITY_IS_PERSON_INJURY]: {
        [YesNoKeys.YES]: LpoRoutePaths.LIABILITY_CLAIMANT_INFORMATION,
        [YesNoKeys.NO]: LpoRoutePaths.LIABILITY_DAMAGE_CLAIM_DESCRIPTION,
    },
    [LpoRoutePaths.LIABILITY_DAMAGE_CLAIM_DESCRIPTION]: LpoRoutePaths.START_ACCIDENT_LOCATION,
    [LpoRoutePaths.LIABILITY_CLAIMANT_INFORMATION]: LpoRoutePaths.START_ACCIDENT_LOCATION,
    [LpoRoutePaths.DYN_CAROUSEL_LIABILITY_IS_RESPONSIBLE]: {
        [YesNoKeys.YES]: LpoRoutePaths.LIABILITY_CLAIM_DESCRIPTION,
        [YesNoKeys.NO]: LpoRoutePaths.END_UPLOAD_ATTACHMENT,
    },
    [LpoRoutePaths.LIABILITY_CLAIM_DESCRIPTION]: LpoRoutePaths.DYN_CAROUSEL_LIABILITY_HAS_CONTRIBUTED_TO_INJURY,
    [LpoRoutePaths.DYN_CAROUSEL_LIABILITY_HAS_CONTRIBUTED_TO_INJURY]: {
        [YesNoKeys.YES]: LpoRoutePaths.LIABILITY_INJURED_CLAIM_DESCRIPTION,
        [YesNoKeys.NO]: LpoRoutePaths.END_UPLOAD_ATTACHMENT,
    },
    [LpoRoutePaths.LIABILITY_INJURED_CLAIM_DESCRIPTION]: LpoRoutePaths.END_UPLOAD_ATTACHMENT,

    // Property Routes
    [LpoRoutePaths.PROPERTY_CLAIM_TYPE]: LpoRoutePaths.START_CLAIM_DATE,

    // Transport Routes
    [LpoRoutePaths.TRANSPORT_CLAIM_TYPE]: LpoRoutePaths.START_CLAIM_DATE,
    [LpoRoutePaths.TRANSPORT_VEHICLE_REGISTRATION_NUMBER]: LpoRoutePaths.START_CLAIM_DATE,

    // Glass Routes
    [LpoRoutePaths.DYN_CAROUSEL_GLASS_IS_WORK_RELATED]: {
        [YesNoKeys.YES]: LpoRoutePaths.START_ONBOARDING,
        [YesNoKeys.NO]: LpoRoutePaths.GLASS_REQUIREMENT,
    },
    [LpoRoutePaths.GLASS_CLAIM_DATE]: LpoRoutePaths.DYN_CAROUSEL_GLASS_CLAIM_LOCATION,
    [LpoRoutePaths.DYN_CAROUSEL_GLASS_CLAIM_LOCATION]: {
        [ClaimLocationKeys.AT_WORK]: LpoRoutePaths.DYN_CAROUSEL_GLASS_HAS_HEALTH_INSURANCE,
        [ClaimLocationKeys.ON_WAY_TO_WORK]: LpoRoutePaths.DYN_CAROUSEL_GLASS_HAS_HEALTH_INSURANCE,
        [ClaimLocationKeys.OTHER]: LpoRoutePaths.GLASS_OTHER_CLAIM_LOCATION,
    },
    [LpoRoutePaths.GLASS_OTHER_CLAIM_LOCATION]: LpoRoutePaths.DYN_CAROUSEL_GLASS_HAS_HEALTH_INSURANCE,
    [LpoRoutePaths.DYN_CAROUSEL_GLASS_HAS_HEALTH_INSURANCE]: {
        [YesNoKeys.YES]: LpoRoutePaths.GLASS_HEALTH_INSURANCE,
        [YesNoKeys.NO]: LpoRoutePaths.GLASS_CLAIMANT_INFORMATION,
    },
    [LpoRoutePaths.GLASS_HEALTH_INSURANCE]: LpoRoutePaths.GLASS_CLAIMANT_INFORMATION,
    [LpoRoutePaths.GLASS_CLAIMANT_INFORMATION]: LpoRoutePaths.END_CLAIM_DESCRIPTION,
    [LpoRoutePaths.GLASS_TYPE_OF_GLASS_DAMAGE]: LpoRoutePaths.END_UPLOAD_ATTACHMENT,
    [LpoRoutePaths.DYN_CAROUSEL_GLASS_WHO_RECEIVES_SETTLEMENT]: LpoRoutePaths.GLASS_BANK_ACCOUNT_INFORMATION,
    [LpoRoutePaths.GLASS_BANK_ACCOUNT_INFORMATION]: LpoRoutePaths.END_POLICY_HOLDER,

    // Group Accident
    [LpoRoutePaths.GROUP_ACCIDENT_ACCIDENT_LOCATION]: LpoRoutePaths.DYN_CAROUSEL_GROUP_ACCIDENT_IS_ACCIDENT,
    [LpoRoutePaths.DYN_CAROUSEL_GROUP_ACCIDENT_IS_ACCIDENT]: {
        [YesNoKeys.YES]: LpoRoutePaths.DYN_CAROUSEL_GROUP_ACCIDENT_IS_POLICE_CONTACTED,
        [YesNoKeys.NO]: LpoRoutePaths.DYN_CAROUSEL_GLASS_HAS_HEALTH_INSURANCE,
    },
    [LpoRoutePaths.DYN_CAROUSEL_GROUP_ACCIDENT_IS_POLICE_CONTACTED]: {
        [YesNoKeys.YES]: LpoRoutePaths.GROUP_ACCIDENT_POLICE_DISTRICT,
        [YesNoKeys.NO]: LpoRoutePaths.DYN_CAROUSEL_GROUP_ACCIDENT_REPORTED_TO_COUNTERPARTY,
    },
    [LpoRoutePaths.GROUP_ACCIDENT_POLICE_DISTRICT]: LpoRoutePaths.GROUP_ACCIDENT_POLICE_CASE_NUMBER,
    [LpoRoutePaths.GROUP_ACCIDENT_POLICE_CASE_NUMBER]:
        LpoRoutePaths.DYN_CAROUSEL_GROUP_ACCIDENT_REPORTED_TO_COUNTERPARTY,
    [LpoRoutePaths.DYN_CAROUSEL_GROUP_ACCIDENT_REPORTED_TO_COUNTERPARTY]: {
        [YesNoKeys.YES]: LpoRoutePaths.GROUP_ACCIDENT_COUNTERPARTY_INSURANCE_COMPANY,
        [YesNoKeys.NO]: LpoRoutePaths.DYN_CAROUSEL_GLASS_HAS_HEALTH_INSURANCE,
    },
    [LpoRoutePaths.GROUP_ACCIDENT_COUNTERPARTY_INSURANCE_COMPANY]:
        LpoRoutePaths.GROUP_ACCIDENT_COUNTERPARTY_EXTERNAL_REFERENCE,
    [LpoRoutePaths.GROUP_ACCIDENT_COUNTERPARTY_EXTERNAL_REFERENCE]:
        LpoRoutePaths.DYN_CAROUSEL_GLASS_HAS_HEALTH_INSURANCE,
    [LpoRoutePaths.DYN_CAROUSEL_GROUP_ACCIDENT_DURING_WORK]: {
        [YesNoKeys.YES]: LpoRoutePaths.DYN_CAROUSEL_GROUP_ACCIDENT_REPORTED_TO_INSURERS_INSURANCE,
        [YesNoKeys.NO]: LpoRoutePaths.DYN_CAROUSEL_GROUP_ACCIDENT_IS_UNDERAGED,
    },
    [LpoRoutePaths.DYN_CAROUSEL_GROUP_ACCIDENT_REPORTED_TO_INSURERS_INSURANCE]: {
        [YesNoKeys.YES]: LpoRoutePaths.GROUP_ACCIDENT_INJURY_INSURANCE,
        [YesNoKeys.NO]: LpoRoutePaths.DYN_CAROUSEL_GROUP_ACCIDENT_IS_UNDERAGED,
    },
    [LpoRoutePaths.GROUP_ACCIDENT_INJURY_INSURANCE]: LpoRoutePaths.GROUP_ACCIDENT_INJURY_INSURANCE_EXTERNAL_REFERENCE,
    [LpoRoutePaths.GROUP_ACCIDENT_INJURY_INSURANCE_EXTERNAL_REFERENCE]:
        LpoRoutePaths.DYN_CAROUSEL_GROUP_ACCIDENT_IS_UNDERAGED,
    [LpoRoutePaths.DYN_CAROUSEL_GROUP_ACCIDENT_IS_UNDERAGED]: LpoRoutePaths.GLASS_CLAIMANT_INFORMATION,
    [LpoRoutePaths.GROUP_ACCIDENT_GUARDIAN_INFORMATION]: LpoRoutePaths.END_CLAIM_DESCRIPTION,
    [LpoRoutePaths.GROUP_ACCIDENT_CLAIM_CAUSE]: LpoRoutePaths.DYN_CAROUSEL_GROUP_ACCIDENT_IS_DOCTOR_CONTACTED,
    [LpoRoutePaths.DYN_CAROUSEL_GROUP_ACCIDENT_IS_DOCTOR_CONTACTED]: {
        [YesNoKeys.YES]: LpoRoutePaths.GROUP_ACCIDENT_DATE_OF_TREATMENT,
        [YesNoKeys.NO]: LpoRoutePaths.GROUP_ACCIDENT_DOCTOR_INFORMATION,
    },
    [LpoRoutePaths.GROUP_ACCIDENT_DATE_OF_TREATMENT]: LpoRoutePaths.GROUP_ACCIDENT_DOCTOR_INFORMATION,
    [LpoRoutePaths.GROUP_ACCIDENT_DOCTOR_INFORMATION]: LpoRoutePaths.DYN_CAROUSEL_GROUP_ACCIDENT_PREVIOUS_INJURY,
    [LpoRoutePaths.DYN_CAROUSEL_GROUP_ACCIDENT_PREVIOUS_INJURY]: {
        [YesNoKeys.YES]: LpoRoutePaths.GROUP_ACCIDENT_TYPE_OF_INJURY,
        [YesNoKeys.NO]: LpoRoutePaths.END_UPLOAD_ATTACHMENT,
    },
    [LpoRoutePaths.GROUP_ACCIDENT_TYPE_OF_INJURY]: LpoRoutePaths.GROUP_ACCIDENT_TYPE_OF_INJURY,
    [LpoRoutePaths.GROUP_ACCIDENT_TYPE_OF_INJURY]: LpoRoutePaths.GROUP_ACCIDENT_DATE_OF_PREVIOUS_INJURY,
    [LpoRoutePaths.GROUP_ACCIDENT_DATE_OF_PREVIOUS_INJURY]: LpoRoutePaths.END_UPLOAD_ATTACHMENT,

    // End Routes
    [LpoRoutePaths.END_CLAIM_DESCRIPTION]: LpoRoutePaths.END_UPLOAD_ATTACHMENT,
    [LpoRoutePaths.END_UPLOAD_ATTACHMENT]: LpoRoutePaths.DYN_CAROUSEL_END_HAS_OTHER_INSURANCE,
    [LpoRoutePaths.DYN_CAROUSEL_END_HAS_OTHER_INSURANCE]: {
        [YesNoKeys.YES]: LpoRoutePaths.END_OTHER_INSURANCE_COMPANY,
        [YesNoKeys.NO]: LpoRoutePaths.END_EXTERNAL_REFERENCE,
    },
    [LpoRoutePaths.END_OTHER_INSURANCE_COMPANY]: LpoRoutePaths.END_EXTERNAL_REFERENCE,
    [LpoRoutePaths.END_EXTERNAL_REFERENCE]: LpoRoutePaths.END_POLICY_HOLDER,
    [LpoRoutePaths.END_POLICY_HOLDER]: LpoRoutePaths.END_REPORTER_INFORMATION,
    [LpoRoutePaths.END_REPORTER_INFORMATION]: LpoRoutePaths.END_POLICY_HOLDERS_CONTACT,
    [LpoRoutePaths.END_POLICY_HOLDERS_CONTACT]: LpoRoutePaths.END_FINAL_SUMMARY,
    [LpoRoutePaths.END_FINAL_SUMMARY]: LpoRoutePaths.END_REPORT_COMPLETED,
};
