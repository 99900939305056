import React from 'react';
import { wizardRouterActions as wizardActions } from '../../../../../sagas/wizardRouter';
import {
    CarouselConfigLookupResponse,
    CarouselConfigOptionType,
    ClaimLocationKeys,
    Clickable,
    IntersectionIcon,
    is,
    ParkingIcon,
    RoadIcon,
    TSectionIcon,
} from '@protectorinsurance/ds-can';
import { CarouselConfigLookupProps } from '../../../../../utils/carouselPageLookup';
import dispatcherWithPromise from '../../../../../utils/dispatcherWithPromise';
import { motorActions } from '../../../../../sagas/motor';
import { selectClaimLocation } from '../../../../../sagas/selectors/motorSelectors';
import { MotorRoutePaths } from '../../../../wizardRouter/motorWizardRoutes';
import { commonActions } from '../../../../../sagas/common';
import { flowActions } from '../../../../../sagas/flow';

/**
 * Destructure necessary imports
 */
const { INTERSECTION, PARKING_OTHER, ROAD, RIGHT_OF_WAY } = ClaimLocationKeys;

/**
 * Page view and page logic
 */
export const drivingVehiclesClaimLocationPage = ({
    t,
    state,
    dispatch,
}: CarouselConfigLookupProps): CarouselConfigLookupResponse<MotorRoutePaths> => {
    const claimLocation = selectClaimLocation(state);
    const options = [
        { id: ROAD, name: t(`motor.driving.claimLocation.select.${ROAD}`), icon: <RoadIcon /> },
        { id: INTERSECTION, name: t(`motor.driving.claimLocation.select.${INTERSECTION}`), icon: <IntersectionIcon /> },
        {
            id: RIGHT_OF_WAY,
            name: t(`motor.driving.claimLocation.select.${RIGHT_OF_WAY}`),
            icon: <TSectionIcon />,
        },
        { id: PARKING_OTHER, name: t(`motor.driving.claimLocation.select.${PARKING_OTHER}`), icon: <ParkingIcon /> },
    ];

    return {
        slug: MotorRoutePaths.DYN_CAROUSEL_DRIVING_VEHICLES_CLAIM_LOCATION,
        stateKey: 'claimLocation',
        i18n: {
            ns: 'motor.driving.claimLocation',
        },
        selectorValue: selectClaimLocation(state),
        options,
        onSelect: (id: CarouselConfigOptionType, e: Clickable) => {
            e.preventDefault();
            const option = options?.find((x) => x.id === id);

            if (option) {
                if (!is(option.id, claimLocation)) {
                    dispatch(wizardActions.skipBackToPrev(true));
                }

                dispatcherWithPromise(dispatch, motorActions.update, { claimLocation: option.id })
                    .then(() => dispatcherWithPromise(dispatch, flowActions.update, option.id))
                    .then(() => dispatcherWithPromise(dispatch, commonActions.send))
                    .then(() => dispatch(wizardActions.goToNext(option.id)));
            }
        },
    };
};
