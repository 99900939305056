import React from 'react';
import { is, isNo, LobKeys, Nullable, SummaryItem, SummaryItemProps, YesNoModel } from '@protectorinsurance/ds-can';

/**
 * Destructure necessary imports
 */
const { LIABILITY } = LobKeys;

/**
 * Component view and component logic
 */
export const renderLiabilityDamageClaimDescription = (
    { inputWrapperClass, textKey, dataTestId, buttonText, path, handleClick }: SummaryItemProps,
    lob: Nullable<LobKeys>,
    isPersonInjury: YesNoModel
) => {
    if (is(lob, LIABILITY) && isNo(isPersonInjury)) {
        return <SummaryItem {...{ buttonText, dataTestId, handleClick, inputWrapperClass, path, textKey }} />;
    }

    return <></>;
};
