import { ObjectWithDynamicKey } from '@protectorinsurance/ds-can';

/*eslint-disable */
const businessNumber = '${path} is not valid';
const digits = '${path} should only be numbers.';
const noSpecialChars = '${path} cannot contain special characters';
const requiredIf = '${path} is a required field.';
const ssn = '${path} is not valid';
const allowSpecialChars = '${path} can only contain the follow characters: A-Z 0-9 . - /';
const onlyAscii = 'Only normal letters/characters are allowed in ${path}.';
const registrationNumber = '${path} is not valid.';
const email = '${path} is not valid.';
/*eslint-enable */

export const yupCustomLocale: ObjectWithDynamicKey = {
    string: {
        businessNumber,
        digits,
        noSpecialChars,
        requiredIf,
        ssn,
        allowSpecialChars,
        onlyAscii,
        registrationNumber,
        email,
    },
};
