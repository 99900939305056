import React from 'react';
import { PhraseKeys } from '../../../config/phraseKeys';
import { useI18n } from '../../../hooks/useI18n';
import { PageLayout, StartSummary } from '@protectorinsurance/ds-can';
import { useGoBack } from '../../../hooks/useGoBack';
import { useSelector } from 'react-redux';
import { selectCustomCAN } from '../../../sagas/selectors/commonSelectors';

/**
 * Destructure necessary imports
 */
const { BACK_BUTTON, CONTINUE_BUTTON, HELP_TEXT, PAGE_NAME, SUB_TITLE, TITLE } = PhraseKeys;

/**
 * Page view and page logic
 */
export const GlassRequirementPage = () => {
    const customCAN = useSelector(selectCustomCAN);
    const { t } = useI18n();
    const tWithNS = useI18n('lpo.glass.requirement');

    const handleBackButton = useGoBack();

    return (
        <PageLayout
            backBtnText={t(BACK_BUTTON)}
            continueBtnText={t(CONTINUE_BUTTON)}
            domainTitle={t(PAGE_NAME)}
            footerText={tWithNS.t(HELP_TEXT)}
            headerSubTitle={tWithNS.t(SUB_TITLE)}
            headerTitle={tWithNS.t(TITLE)}
            showContinueButton={false}
            {...{ handleBackButton }}
        >
            <StartSummary
                avgMin={tWithNS.t('body.avgMin')}
                description={tWithNS.t('body.paragraph.one')}
                sessionText={tWithNS.t('body.paragraph.two')}
                subTitle={tWithNS.t('body.subTitle')}
                title={tWithNS.t('body.title')}
                {...{ customCAN }}
            />
        </PageLayout>
    );
};
