import { put, takeLatest } from 'redux-saga/effects';
import { wizardRouterActions } from './wizardRouter';
import { BaseRoutePaths } from '../config/wizardRouter/baseWizardRoutes';
import creator from 'utils/action-creator';
import { commonActions } from './common';
import { getLobFromUrl, is, LobKeys } from '@protectorinsurance/ds-can';
import { LpoRoutePaths } from '../config/wizardRouter/lpoWizardRoutes';
import { MotorRoutePaths } from '../config/wizardRouter/motorWizardRoutes';
import { isCypress, isProd, isTest } from 'utils/env';

/**
 * Constants
 */
export enum InitActionTypes {
    INIT = '@app/INIT',
}

/**
 * Redux Actions
 */
export const initActions = {
    init: () => creator<InitActionTypes>(InitActionTypes.INIT),
};

/**
 * Saga watchers
 */
export const initWatcher = function* () {
    yield takeLatest(InitActionTypes.INIT, initSagas.init);
};

/**
 * Saga functions
 */
export const initSagas = {
    *init() {
        // Set country and language
        yield put(commonActions.init());

        // Run on PROD, CYPRESS and TEST.
        if (isProd() || isCypress() || isTest()) {
            const lob = getLobFromUrl().lob;
            const lpo: LobKeys[] = [
                LobKeys.LIABILITY,
                LobKeys.PERSON,
                LobKeys.PROPERTY,
                LobKeys.TRANSPORT,
                LobKeys.WORKSHOP,
            ];
            if (lob && lpo.includes(lob)) {
                yield put(commonActions.update({ lob }));
                yield put(wizardRouterActions.goTo(LpoRoutePaths.INDEX));
            } else if (is(lob, LobKeys.AUTO)) {
                yield put(commonActions.update({ lob }));
                yield put(wizardRouterActions.goTo(MotorRoutePaths.INDEX));
            } else {
                yield put(wizardRouterActions.goTo(BaseRoutePaths.DEFAULT_INDEX));
            }
        }
    },
};
