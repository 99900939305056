import * as Yup from 'yup';
import { TFunction } from 'i18next';
import { contactDetailsSchema } from '../fieldSchemas/contactDetailsSchema';
import { driverSchema } from '../fieldSchemas/driverSchema';
import { firstNameSchema } from '../fieldSchemas/firstNameSchema';
import { optionalLastNameSchema } from '../fieldSchemas/optionalLastNameSchema';

export const reporterInformationSchema = (t: TFunction) => {
    return Yup.object().shape({
        ...firstNameSchema(t),
        ...optionalLastNameSchema(t),
        ...contactDetailsSchema(t),
        ...driverSchema(t),
    });
};
