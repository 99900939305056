import React from 'react';
import { wizardRouterActions as wizardActions } from '../../../../sagas/wizardRouter';
import {
    CarouselConfigLookupResponse,
    CarouselConfigOptionType,
    Clickable,
    CompanyIcon,
    InsuredByProtectorIcon,
    SchoolNurseIcon,
    WhoReceivesSettlementKeys,
} from '@protectorinsurance/ds-can';
import { CarouselConfigLookupProps } from '../../../../utils/carouselPageLookup';
import dispatcherWithPromise from '../../../../utils/dispatcherWithPromise';
import { commonActions } from '../../../../sagas/common';
import { LpoRoutePaths } from '../../../wizardRouter/lpoWizardRoutes';
import { selectWhoReceivesSettlement } from '../../../../sagas/selectors/lpoSelectors';
import { lpoActions } from '../../../../sagas/lpo';

/**
 * Destructure necessary imports
 */
const { CLAIMANT, OPTICIAN, POLICY_HOLDER } = WhoReceivesSettlementKeys;

/**
 * Page view and page logic
 */
export const glassWhoReceivesSettlementPage = ({
    t,
    state,
    dispatch,
}: CarouselConfigLookupProps): CarouselConfigLookupResponse<LpoRoutePaths> => {
    const options = [
        {
            id: CLAIMANT,
            name: t(`lpo.glass.whoReceivesSettlement.select.${CLAIMANT}`),
            icon: <InsuredByProtectorIcon />,
        },
        {
            id: POLICY_HOLDER,
            name: t(`lpo.glass.whoReceivesSettlement.select.${POLICY_HOLDER}`),
            icon: <CompanyIcon />,
        },
        { id: OPTICIAN, name: t(`lpo.glass.whoReceivesSettlement.select.${OPTICIAN}`), icon: <SchoolNurseIcon /> },
    ];

    return {
        slug: LpoRoutePaths.DYN_CAROUSEL_GLASS_WHO_RECEIVES_SETTLEMENT,
        stateKey: 'whoReceivesSettlement',
        i18n: {
            ns: 'lpo.glass.whoReceivesSettlement',
        },
        selectorValue: selectWhoReceivesSettlement(state),
        options,
        onSelect: (id: CarouselConfigOptionType, e: Clickable) => {
            e.preventDefault();
            const option = options?.find((x) => x.id === id);

            if (option) {
                dispatcherWithPromise(dispatch, lpoActions.update, {
                    whoReceivesSettlement: option.id,
                })
                    .then(() => dispatcherWithPromise(dispatch, commonActions.send))
                    .then(() => dispatch(wizardActions.goToNext()));
            }
        },
    };
};
