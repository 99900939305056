import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { wizardRouterActions as wizardActions } from 'sagas/wizardRouter';
import { useI18n } from '../../../../hooks/useI18n';
import {
    FlowKeys,
    FormChangeable,
    Grid,
    HiddenInputSubmit,
    MuiTextInput,
    PageLayout,
    PersonModel,
} from '@protectorinsurance/ds-can';
import { useGoBack } from '../../../../hooks/useGoBack';
import { FormFieldNames } from '../../../../config/formFieldNames';
import { PhraseKeys } from '../../../../config/phraseKeys';
import { selectRecovererInformation } from '../../../../sagas/selectors/motorSelectors';
import { Controller, useForm } from 'react-hook-form';
import { personSchema } from '../../../../validations/schemas/personSchema';
import dispatcherWithPromise from '../../../../utils/dispatcherWithPromise';
import { motorActions } from '../../../../sagas/motor';
import { commonActions } from '../../../../sagas/common';
import { AutocompleteValueKeys } from '../../../../config/AutocompleteValueKeys';
import { flowActions } from '../../../../sagas/flow';
import { crossContactInfoFieldValidation } from '../../../../utils/validation/crossContactInfoFieldValidation';
import { yupResolver } from '@hookform/resolvers/yup';
import { selectCustomCAN } from '../../../../sagas/selectors/commonSelectors';

/**
 * Destructure necessary imports
 */
const { EMAIL, FIRST_NAME, LAST_NAME, PHONE } = FormFieldNames;
const {
    BACK_BUTTON,
    CONTINUE_BUTTON,
    EMAIL_LABEL,
    EMAIL_PLACEHOLDER,
    FIRST_NAME_LABEL,
    FIRST_NAME_PLACEHOLDER,
    HELP_TEXT,
    LAST_NAME_LABEL,
    LAST_NAME_PLACEHOLDER,
    PAGE_NAME,
    PHONE_LABEL,
    PHONE_PLACEHOLDER,
    SUB_TITLE,
    TITLE,
} = PhraseKeys;
const { EMAIL: EMAIL_AUTOCOMPLETE, FAMILY_NAME, GIVEN_NAME, TEL } = AutocompleteValueKeys;
const { END } = FlowKeys;

/**
 * Page view and page logic
 */
export const PTRecovererInformationPage = () => {
    const dispatch = useDispatch();
    const recovererInformation = useSelector(selectRecovererInformation);
    const customCAN = useSelector(selectCustomCAN);
    const { t } = useI18n();
    const tWithNS = useI18n('motor.parked.recovererInformation');
    const {
        control,
        formState: { errors },
        handleSubmit,
        setValue,
        trigger,
    } = useForm<PersonModel>({
        resolver: yupResolver(personSchema(t)),
        defaultValues: {
            ...recovererInformation,
        },
    });

    const handleBackButton = useGoBack();

    const handleBlur = async (e: FormChangeable) => {
        e.preventDefault();
        const { id } = e.currentTarget;
        await trigger(id);
    };

    const handleChange = async (e: FormChangeable) => {
        e.preventDefault();
        const { id, value } = e.currentTarget;
        await setValue(id, value, { shouldValidate: true });
        await crossContactInfoFieldValidation(id, trigger);
    };

    const onSubmit = (values: PersonModel) => {
        dispatcherWithPromise(dispatch, motorActions.update, { recovererInformation: values })
            .then(() => dispatcherWithPromise(dispatch, flowActions.update, END))
            .then(() => dispatcherWithPromise(dispatch, commonActions.send))
            .then(() => dispatch(wizardActions.goToNext()));
    };

    return (
        <PageLayout
            backBtnText={t(BACK_BUTTON)}
            continueBtnText={t(CONTINUE_BUTTON)}
            domainTitle={t(PAGE_NAME)}
            footerText={tWithNS.t(HELP_TEXT)}
            handleContinueButton={handleSubmit(onSubmit)}
            headerSubTitle={tWithNS.t(SUB_TITLE)}
            headerTitle={tWithNS.t(TITLE)}
            {...{ handleBackButton }}
        >
            <form onSubmit={handleSubmit(onSubmit)}>
                <HiddenInputSubmit />
                <Grid className={'align-center'}>
                    <Controller
                        control={control}
                        name={FIRST_NAME}
                        render={({ field: { ref, ...field } }) => (
                            <MuiTextInput
                                {...field}
                                autoComplete={GIVEN_NAME}
                                error={!!errors.firstName}
                                errorMessage={errors.firstName?.message}
                                id={FIRST_NAME}
                                inputFieldWrapper={'col-6'}
                                label={t(FIRST_NAME_LABEL)}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                placeholder={t(FIRST_NAME_PLACEHOLDER)}
                                reference={ref}
                                {...{ customCAN }}
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name={LAST_NAME}
                        render={({ field: { ref, ...field } }) => (
                            <MuiTextInput
                                {...field}
                                autoComplete={FAMILY_NAME}
                                error={!!errors.lastName}
                                errorMessage={errors.lastName?.message}
                                id={LAST_NAME}
                                inputFieldWrapper={'col-6'}
                                label={t(LAST_NAME_LABEL)}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                placeholder={t(LAST_NAME_PLACEHOLDER)}
                                reference={ref}
                                {...{ customCAN }}
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name={EMAIL}
                        render={({ field: { ref, ...field } }) => (
                            <MuiTextInput
                                {...field}
                                autoComplete={EMAIL_AUTOCOMPLETE}
                                error={!!errors.email}
                                errorMessage={errors.email?.message}
                                id={EMAIL}
                                inputFieldWrapper={'col-6'}
                                label={t(EMAIL_LABEL)}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                placeholder={t(EMAIL_PLACEHOLDER)}
                                reference={ref}
                                type={'email'}
                                {...{ customCAN }}
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name={PHONE}
                        render={({ field: { ref, ...field } }) => (
                            <MuiTextInput
                                {...field}
                                autoComplete={TEL}
                                error={!!errors.phone}
                                errorMessage={errors.phone?.message}
                                id={PHONE}
                                inputFieldWrapper={'col-6'}
                                label={t(PHONE_LABEL)}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                placeholder={t(PHONE_PLACEHOLDER)}
                                reference={ref}
                                {...{ customCAN }}
                            />
                        )}
                    />
                </Grid>
            </form>
        </PageLayout>
    );
};
