import * as Yup from 'yup';
import { TFunction } from 'i18next';
import { PhraseKeys } from '../../config/phraseKeys';
import { FormFieldNames } from '../../config/formFieldNames';

/**
 * Destructure necessary imports
 */
const { ADDRESS_LABEL, IS_UNKNOWN_LOCATION_LABEL, NOTE_LABEL } = PhraseKeys;
const { ADDRESS, NOTE } = FormFieldNames;

/**
 * Validation logic
 */
export const locationWithOptionSchema = (t: TFunction) => {
    return {
        isUnknownLocation: Yup.boolean().label(t(IS_UNKNOWN_LOCATION_LABEL)),
        note: Yup.string()
            .label(t(NOTE_LABEL))
            .ensure()
            .when('isUnknownLocation', {
                is: false,
                then: Yup.string().requiredIf([ADDRESS]),
            })
            .nullable(),
        address: Yup.string()
            .label(t(ADDRESS_LABEL))
            .when('isUnknownLocation', {
                is: false,
                then: Yup.string().requiredIf([NOTE]),
            })
            .nullable(),
    };
};
