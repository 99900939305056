import React from 'react';
import { MotorSummaryProps } from '../../../types/SummaryProps';
import { FileList, is, isOneOf, isYes, MotorClaimCauseKeys, SummaryItem } from '@protectorinsurance/ds-can';
import { TEST_ID_PREFIX } from '../../../utils/testIdUtil';
import { MotorRoutePaths } from '../../../config/wizardRouter/motorWizardRoutes';
import { createTextYesNoDontKnow } from '../../../utils/displayUtils';
import { claimCauseSummary } from '../../../utils/claimCauseUtils';

/**
 * Destructure necessary imports
 */
const {
    DYN_CAROUSEL_END_HAS_NON_VEHICLE_DAMAGES,
    DYN_CAROUSEL_END_HAS_PERSON_INJURIES,
    DYN_CAROUSEL_END_HAS_WITNESSES,
    DYN_CAROUSEL_END_IS_POLICE_CONTACTED,
    END_POLICE_CASE_NUMBER,
    END_POLICE_DISTRICT,
    END_POLICY_HOLDER,
    END_UPLOAD_ATTACHMENT,
} = MotorRoutePaths;
const { ENGINE, OTHER, PARKING, THEFT_AND_DAMAGE } = MotorClaimCauseKeys;

/**
 * Page view and logic
 */
export const DisplayEndSummary = ({ model, upload, t, tWithNS }: MotorSummaryProps) => {
    /**
     * Helper constants/functions
     */
    const {
        claimCause,
        hasNonVehicleDamages,
        hasParkedWitnesses,
        hasPersonInjuries,
        hasWitnesses,
        isPoliceContacted,
        policeCaseNumber,
        policeDistrict,
        externalReference,
    } = model;
    const { file } = upload;

    const renderOtherDamages = () => {
        return (
            <>
                {/* Other Damages */}
                {!isOneOf(claimCause, [ENGINE, THEFT_AND_DAMAGE, PARKING]) && (
                    <SummaryItem
                        textKey={tWithNS('hasNonVehicleDamages.heading.title')}
                        dataTestId={TEST_ID_PREFIX.hasNonVehicleDamages}
                        buttonText={t(createTextYesNoDontKnow(hasNonVehicleDamages))}
                        path={DYN_CAROUSEL_END_HAS_NON_VEHICLE_DAMAGES}
                        inputWrapperClass={'col-12 divider'}
                    />
                )}
            </>
        );
    };

    const renderInjuries = () => {
        return (
            <>
                {/* Injuries */}
                {!isOneOf(claimCause, [ENGINE, THEFT_AND_DAMAGE, PARKING]) && (
                    <SummaryItem
                        textKey={tWithNS('hasPersonInjuries.heading.title')}
                        dataTestId={TEST_ID_PREFIX.hasPersonInjuries}
                        buttonText={t(createTextYesNoDontKnow(hasPersonInjuries))}
                        path={DYN_CAROUSEL_END_HAS_PERSON_INJURIES}
                        inputWrapperClass={'col-12 divider'}
                    />
                )}
            </>
        );
    };

    const renderPolice = () => {
        return (
            <>
                {/* Police */}
                <SummaryItem
                    textKey={tWithNS('isPoliceContacted.heading.title')}
                    dataTestId={TEST_ID_PREFIX.isPoliceContacted}
                    buttonText={t(createTextYesNoDontKnow(isPoliceContacted))}
                    path={DYN_CAROUSEL_END_IS_POLICE_CONTACTED}
                    inputWrapperClass={`${isYes(isPoliceContacted) ? 'spacing' : 'divider'} col-12 text-align-left`}
                />

                {isYes(isPoliceContacted) && (
                    <>
                        {/* Reference */}
                        <SummaryItem
                            textKey={tWithNS('policeCaseNumber.heading.title')}
                            dataTestId={TEST_ID_PREFIX.policeCaseNumber}
                            buttonText={policeCaseNumber ? policeCaseNumber : '-'}
                            path={END_POLICE_CASE_NUMBER}
                            inputWrapperClass={'col-12 spacing text-align-left'}
                        />

                        {/* Police District */}
                        <SummaryItem
                            textKey={tWithNS('policeDistrict.heading.title')}
                            dataTestId={TEST_ID_PREFIX.policeDistrict}
                            buttonText={policeDistrict ? policeDistrict : '-'}
                            path={END_POLICE_DISTRICT}
                            inputWrapperClass={'col-12 spacing text-align-left'}
                        />
                    </>
                )}
            </>
        );
    };

    const renderWitnesses = () => {
        return (
            <>
                {/* Has Witnesses */}
                {!isYes(hasParkedWitnesses) && (
                    <SummaryItem
                        textKey={tWithNS('hasWitnesses.heading.title')}
                        dataTestId={TEST_ID_PREFIX.hasWitnesses}
                        buttonText={t(createTextYesNoDontKnow(hasWitnesses))}
                        path={DYN_CAROUSEL_END_HAS_WITNESSES}
                        inputWrapperClass={'col-12 divider'}
                    />
                )}
            </>
        );
    };

    const renderPolicyHolder = () => {
        return (
            <>
                {/* Policy Holder */}
                <SummaryItem
                    textKey={tWithNS('policyHoldersContact.heading.title')}
                    dataTestId={TEST_ID_PREFIX.externalReference}
                    buttonText={externalReference ? externalReference : '-'}
                    path={END_POLICY_HOLDER}
                    inputWrapperClass={'col-12 divider'}
                />
            </>
        );
    };

    const renderUpload = () => {
        return (
            <>
                {/* Upload */}
                <div className={'col-12 divider text-align-left'}>
                    <FileList
                        list={file.files}
                        path={END_UPLOAD_ATTACHMENT}
                        show={file.files.length > 0}
                        dataTestId={TEST_ID_PREFIX.uploadAttachments}
                        textKey={`${tWithNS('uploadAttachment.heading.title')}`}
                    />
                </div>
            </>
        );
    };

    return (
        <>
            {!is(claimCause, OTHER) && !claimCauseSummary(claimCause) && (
                <>
                    {renderOtherDamages()}

                    {renderInjuries()}

                    {renderPolice()}

                    {renderWitnesses()}

                    {renderPolicyHolder()}

                    {renderUpload()}
                </>
            )}
        </>
    );
};
