import React from 'react';
import { PhraseKeys } from '../../../config/phraseKeys';
import { useDispatch, useSelector } from 'react-redux';
import { selectAttachmentFiles } from '../../../sagas/selectors/uploadSelectors';
import { useI18n } from '../../../hooks/useI18n';
import { useGoBack } from '../../../hooks/useGoBack';
import { Clickable, FileUploader, is, isYes, LobKeys, PageLayout } from '@protectorinsurance/ds-can';
import { wizardRouterActions as wizardActions } from '../../../sagas/wizardRouter';
import FileModel from '../../../models/File'; //
import { uploadAttachmentActions } from '../../../sagas/upload/attachment/uploadAttachment';
import { selectIsWorkRelated } from '../../../sagas/selectors/lpoSelectors';
import { selectLob } from '../../../sagas/selectors/commonSelectors';
import { LpoRoutePaths } from '../../../config/wizardRouter/lpoWizardRoutes';
import dispatcherWithPromise from '../../../utils/dispatcherWithPromise';
import { commonActions } from '../../../sagas/common';
import { deleteFileActions } from '../../../sagas/upload/file/deleteFile';

/**
 * Destructure necessary imports
 */
const { BACK_BUTTON, CONTINUE_BUTTON, DROPZONE_DESCRIPTION, DROPZONE_TITLE, HELP_TEXT, PAGE_NAME, SUB_TITLE, TITLE } =
    PhraseKeys;
const { PERSON } = LobKeys;
const { DYN_CAROUSEL_GLASS_WHO_RECEIVES_SETTLEMENT } = LpoRoutePaths;

/**
 * Page view and page logic
 */
export const EndUploadAttachmentPage = () => {
    const dispatch = useDispatch();
    const lob = useSelector(selectLob);
    const fileStore = useSelector(selectAttachmentFiles);
    const isWorkRelated = useSelector(selectIsWorkRelated);
    const { t } = useI18n();
    const tWithNS = useI18n('lpo.end.uploadAttachment');

    const handleBackButton = useGoBack();
    const handleContinueButton = (e: Clickable) => {
        e.preventDefault();
        let nextAction = wizardActions.goToNext();
        if (is(lob, PERSON) && isYes(isWorkRelated)) {
            nextAction = wizardActions.goTo(DYN_CAROUSEL_GLASS_WHO_RECEIVES_SETTLEMENT);
        }

        dispatcherWithPromise(dispatch, commonActions.send).then(() => dispatch(nextAction));
    };

    const handleOnSuccess = (files: FileModel[]) => {
        files.forEach((file) => dispatch(uploadAttachmentActions.request(file)));
    };

    const handleDelete = (e: Clickable, file: FileModel) => {
        e.preventDefault();
        dispatch(deleteFileActions.request(file));
    };

    const checkFileErrors: boolean = fileStore.some((file) => {
        return file.errors.length > 0;
    });

    return (
        <PageLayout
            backBtnText={t(BACK_BUTTON)}
            continueBtnText={t(CONTINUE_BUTTON)}
            disableContinueButton={checkFileErrors}
            domainTitle={t(PAGE_NAME)}
            footerText={tWithNS.t(HELP_TEXT)}
            headerSubTitle={tWithNS.t(SUB_TITLE)}
            headerTitle={tWithNS.t(TITLE)}
            {...{ handleBackButton, handleContinueButton }}
        >
            <FileUploader
                onSuccess={handleOnSuccess}
                onDelete={handleDelete}
                text={tWithNS.t('upload.label')}
                title={tWithNS.t(DROPZONE_TITLE)}
                label={tWithNS.t(DROPZONE_DESCRIPTION)}
                fileStore={fileStore}
            />
        </PageLayout>
    );
};
