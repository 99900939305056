import React from 'react';
import {
    ClaimReporterRoleKeys,
    ClaimReporterRoleTypeModel,
    is,
    LobKeys,
    Nullable,
    SummaryItem,
    SummaryItemProps,
} from '@protectorinsurance/ds-can';

/**
 * Destructure necessary imports
 */
const { LIABILITY } = LobKeys;
const { BROKER } = ClaimReporterRoleKeys;

/**
 * Component view and component logic
 */
export const renderIsResponsible = (
    { inputWrapperClass, textKey, dataTestId, buttonText, path, handleClick }: SummaryItemProps,
    lob: Nullable<LobKeys>,
    claimReporterRole: ClaimReporterRoleTypeModel
) => {
    if (is(lob, LIABILITY) && is(claimReporterRole, BROKER)) {
        return <SummaryItem {...{ buttonText, dataTestId, handleClick, inputWrapperClass, path, textKey }} />;
    }
    return <></>;
};
