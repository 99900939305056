import React from 'react';
import { BankAccountInformationModel, is, SummaryItem, SummaryItemProps } from '@protectorinsurance/ds-can';

/**
 * Component view and component logic
 */
export const renderAccountNumber = (
    { inputWrapperClass, textKey, dataTestId, buttonText, path, handleClick }: SummaryItemProps,
    bankAccountInformation: BankAccountInformationModel
) => {
    if (!is(bankAccountInformation.accountNumber, null)) {
        return <SummaryItem {...{ buttonText, dataTestId, handleClick, inputWrapperClass, path, textKey }} />;
    }
    return <></>;
};
