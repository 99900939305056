import { combineReducers, Reducer } from 'redux';
import { History } from 'history';
import { connectRouter } from 'connected-react-router';
import { AppState } from './index';
import flow, { FlowAction, FlowActionTypes } from '../sagas/flow';
import wizardRouter, { WizardRouterAction, WizardRouterActionTypes } from '../sagas/wizardRouter';
import motor, { IMotorAction, MotorActionTypes } from '../sagas/motor';
import common, { CommonActionTypes, ICommonAction } from '../sagas/common';
import services, { ServicesActions, ServicesActionTypes } from '../sagas/services';
import upload, { UploadActions, UploadActionTypes } from '../sagas/upload';
import lpo, { LpoAction, LpoActionTypes } from '../sagas/lpo';
import { OrgnrServiceAction, OrgnrServiceActionTypes } from '../sagas/services/orgnrService';
import errors, { ErrorsActionTypes, IErrorsAction } from '../sagas/errors';

export type AppActions =
    | FlowAction
    | WizardRouterAction
    | IMotorAction
    | ICommonAction
    | UploadActions
    | ServicesActions
    | LpoAction
    | OrgnrServiceAction;

export type AppActionTypes =
    | FlowActionTypes
    | WizardRouterActionTypes
    | MotorActionTypes
    | CommonActionTypes
    | UploadActionTypes
    | ServicesActionTypes
    | LpoActionTypes
    | OrgnrServiceActionTypes
    | ErrorsActionTypes;

export type AppActionsWithPromise = IErrorsAction | LpoAction | UploadActions;

export default (state: AppState, action: AppActions, history: History): Reducer<AppState, AppActions> => {
    return combineReducers<any, any>({
        router: connectRouter(history),
        flow,
        errors,
        wizardRouter,
        motor,
        services,
        upload,
        common,
        lpo,
    })(state, action);
};
