import { DriverInformationModel, Nullable, YesNoKeys } from '@protectorinsurance/ds-can';
import { PhraseKeys } from '../config/phraseKeys';

/**
 * Destructure necessary imports
 */
const { DONT_KNOW, YES } = YesNoKeys;
const { SELECT_NO, SELECT_YES } = PhraseKeys;

/**
 * Logic
 */

export const createTextYesNoDontKnow = (value: Nullable<YesNoKeys>): string =>
    value === YES ? SELECT_YES : value === DONT_KNOW ? DONT_KNOW : SELECT_NO;

export const driverInformationButtonText = (driverInformation: DriverInformationModel) => {
    const { firstName, lastName, dateOfBirth, phone, email } = driverInformation;
    return `${firstName} ${lastName} ${dateOfBirth && `(${dateOfBirth})`} ${email ? email : ''} ${phone ? phone : ''}`;
};
