export const MAX_LENGTH_250 = 250;
export const MAX_LENGTH_80 = 80;
export const MAX_LENGTH_CLAIM_DESCRIPTION = 100000;

export enum FormFieldNames {
    ACCIDENT_DESCRIPTION = 'accidentDescription',
    ACCIDENT_SKETCH = 'accidentSketch',
    ACCOUNT_NUMBER = 'accountNumber',
    ADDRESS = 'address',
    BUSINESS_NUMBER = 'businessNumber',
    CHASSIS_NUMBER = 'chassisNumber',
    CLAIM_DESCRIPTION = 'claimDescription',
    CLAIM_NUMBER = 'claimNumber',
    CITY = 'city',
    CLAIM_CAUSE = 'claimCause',
    CLAIM_DATE = 'claimDate',
    CLAIM_TIME = 'claimTime',
    CLAIM_TYPE = 'claimType',
    COUNTERPARTY_EXTERNAL_REFERENCE = 'counterpartyExternalReference',
    COUNTERPARTY_INSURANCE_COMPANY = 'counterpartyInsuranceCompany',
    COUNTRY = 'country',
    DAMAGE_DESCRIPTION = 'damageDescription',
    DAMAGED_ITEM = 'damagedItem',
    DATE_OF_BIRTH = 'dateOfBirth',
    DATE_OF_PREVIOUS_INJURY = 'dateOfPreviousInjury',
    DATE_OF_TREATMENT = 'dateOfTreatment',
    DISTANCE_FROM_ROAD_SIDE = 'distanceFromRoadSide',
    DRIVING_SPEED = 'drivingSpeed',
    EMAIL = 'email',
    EXTERNAL_REFERENCE = 'externalReference',
    FIRE_CLAIM_DESCRIPTION = 'fireClaimDescription',
    FIRST_NAME = 'firstName',
    HEALTH_INSURANCE = 'healthInsurance',
    INJURIES_PER_VEHICLE = 'injuriesPerVehicle',
    INJURY_INSURANCE = 'injuryInsurance',
    INJURY_INSURANCE_EXTERNAL_REFERENCE = 'injuryInsuranceExternalReference',
    IS_COMPANY = 'isCompany',
    IS_DRIVER = 'isDriver',
    IS_REPORTER = 'isReporter',
    IS_TOTAL_DAMAGE = 'isTotalDamage',
    IS_UNKNOWN = 'isUnknown',
    IS_UNKNOWN_LOCATION = 'isUnknownLocation',
    LAST_NAME = 'lastName',
    MAKE = 'make',
    MILEAGE = 'mileage',
    MISSING_ITEMS = 'missingItems',
    MODEL = 'model',
    NAME = 'name',
    NATIONAL_IDENTITY = 'nationalIdentity',
    NUMBER_OF_PEOPLE_INJURED = 'numberOfPeopleInjured',
    NUMBER_OF_PEOPLE_HOSPITALIZED = 'numberOfPeopleHospitalized',
    OTHER_ID = 'otherId',
    OTHER_INSURANCE_COMPANY = 'otherInsuranceCompany',
    OTHER_MISSING_ITEMS_CLAIM_DESCRIPTION = 'otherMissingItemsClaimDescription',
    PARKING_DATE = 'parkingDate',
    PARKING_TIME = 'parkingTime',
    PASSENGERS = 'passengers',
    PHONE = 'phone',
    POLICY_NUMBER = 'policyNumber',
    POLICE_CASE_NUMBER = 'policeCaseNumber',
    POLICE_DISTRICT = 'policeDistrict',
    PROPERTY_ADDRESS = 'note',
    PROPERTY_UNIT = 'unit',
    RECOVERY_DATE = 'recoveryDate',
    REG_NR = 'registrationNumber',
    RELATION = 'relation',
    RELATION_TO_WITNESS = 'relationToWitness',
    ROAD_CONDITION_CLAIM_DESCRIPTION = 'roadConditionClaimDescription',
    ROAD_WIDTH = 'roadWidth',
    SPEED_LIMIT = 'speedLimit',
    SPEED_ON_IMPACT = 'speedOnImpact',
    STREET = 'street',
    NOTE = 'note',
    THEFT_AND_DAMAGES_CLAIM_DESCRIPTION = 'theftAndDamagesClaimDescription',
    THIRD_PARTY_SPEED = 'thirdPartySpeed',
    TIME_SINCE_ACTION = 'timeSinceAction',
    TITLE = 'title',
    TYPE = 'type',
    TYPE_OF_GLASS_DAMAGE = 'typeOfGlassDamage',
    TYPE_OF_INJURY = 'typeOfInjury',
    VEHICLE_TYPE = 'vehicleType',
    WHO_RESPONSIBLE_CLAIM_DESCRIPTION = 'whoResponsibleClaimDescription',
    ZIP = 'zip',
}

export enum FormFieldErrors {
    EMAIL_ERROR_MESSAGE = 'form.validation.string.email',
    FIELD_ERROR = 'form.validation.string.fieldError',
    IF_REQUIRED_ERROR_MESSAGE = 'form.validation.string.ifRequired',
    INVALID_ACCOUNT_NUMBER = 'form.validation.string.invalidAccountNumber',
    INVALID_ACCOUNT_REGISTRATION_NUMBER = 'form.validation.string.invalidAccountRegistrationNumber',
    INVALID_REGISTRATION_NUMBER = 'form.validation.string.invalidRegistrationNumberFormat',
    INVALID_ZIP_CODE = 'form.validation.string.invalidZipCode',
    MANDATORY_DATE_FIELD = 'form.validation.string.mandatoryDateField',
    MANDATORY_TIME_FIELD = 'form.validation.string.mandatoryTimeField',
    MAX_LENGTH_ERROR_MESSAGE = 'form.validation.string.maxLength',
    MIN_LENGTH_ERROR_MESSAGE = 'form.validation.string.minLength',
    MIN_REQUIRED_AGE = 'form.validation.string.minRequiredAge',
    MISSING_ITEMS_REQUIRED = 'form.validation.string.missingItems',
    NO_JWT_RECEIVED = 'form.validation.string.noJWTReceived',
    NUMBER_ERROR_MESSAGE = 'form.validation.string.numberError',
    NUMBER_OF_DIGITS_ERROR = 'form.validation.number.numberOfDigits',
    PERSON_EXISTS = 'form.validation.string.personExists',
    REGISTRATION_NUMBER_EXISTS = 'form.validation.string.registrationNumberExists',
    REQUIRED_ERROR_MESSAGE = 'form.validation.string.required',
    TYPE_ERROR = 'form.validation.number.typeError',
    DUPLICATE_OWNERS = 'form.validation.string.duplicateOwners',
    NO_NEGATIVE_NUMBERS = 'form.validation.number.noNegativeNumbers',
}
