import * as Yup from 'yup';
import { TFunction } from 'i18next';
import { injuryExternalReferenceSchema } from '../fieldSchemas/injuryExternalReferenceSchema';

/**
 * Validation logic
 */
export const injuryInsuranceExternalReferenceSchema = (t: TFunction) => {
    return Yup.object().shape({
        ...injuryExternalReferenceSchema(t),
    });
};
