import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { wizardRouterActions as wizardActions } from 'sagas/wizardRouter';
import { useI18n } from '../../../hooks/useI18n';
import { useGoBack } from '../../../hooks/useGoBack';
import { PhraseKeys } from '../../../config/phraseKeys';
import { DEFAULT_MAP_LOCATION, MAP_KEY } from '../../../config/maps';
import {
    FlowKeys,
    FormChangeable,
    getGeoLocation,
    Grid,
    HiddenInputSubmit,
    LobKeys,
    LocationModel,
    MuiTextInput,
    Nullable,
    OptionType,
    PageLayout,
} from '@protectorinsurance/ds-can';
import { AutocompleteService } from '../../../components/map/AutocompleteService';
import { Loader } from '../../../components/loader/Loader';
import { FormFieldNames } from '../../../config/formFieldNames';
import { Controller, useForm } from 'react-hook-form';
import dispatcherWithPromise from '../../../utils/dispatcherWithPromise';
import { lpoActions } from '../../../sagas/lpo';
import { GeoCoder, getGeocoderAddressByLocation, getGeocoderLocationByPlaceId } from '../../../components/map/GeoCoder';
import { LoadMapScript } from '../../../components/map/LoadMapScript';
import { Marker } from '../../../components/map/Marker';
import { InfoWindow } from '../../../components/map/InfoWindow';
import { Map } from '../../../components/map/Map';
import { selectAccidentLocation } from 'sagas/selectors/lpoSelectors';
import { LpoRoutePaths } from '../../../config/wizardRouter/lpoWizardRoutes';
import { selectCustomCAN, selectLob } from '../../../sagas/selectors/commonSelectors';
import { flowActions } from '../../../sagas/flow';
import { lpoAccidentLocationSchema } from '../../../validations/schemas/lpoAccidentLocationSchema';
import { commonActions } from '../../../sagas/common';
import { lpoAccidentLocationWithAddressSchema } from '../../../validations/schemas/lpoAccidentLocationWithAddressSchema';
import { yupResolver } from '@hookform/resolvers/yup';

/**
 * Destructure necessary imports
 */
const {
    ADDRESS_LABEL,
    ADDRESS_PLACEHOLDER,
    BACK_BUTTON,
    CONTINUE_BUTTON,
    HELP_TEXT,
    NO_OPTIONS_MESSAGE,
    NOTE_LABEL,
    PAGE_NAME,
    SUB_TITLE,
    TITLE,
} = PhraseKeys;
const { ADDRESS, NOTE } = FormFieldNames;
const { PROPERTY, TRANSPORT, WORKSHOP } = LobKeys;
const { START_PROPERTY_ADDRESS } = LpoRoutePaths;
const { END, START } = FlowKeys;

/**
 * Page view and page logic
 */
export const StartAccidentLocationPage = () => {
    const dispatch = useDispatch();
    const location = useSelector(selectAccidentLocation);
    const lob = useSelector(selectLob);
    const customCAN = useSelector(selectCustomCAN);
    const [, setMapScript] = useState<HTMLScriptElement | null>(null);
    const [mapIsLoading, setMapIsLoading] = useState(true);
    const [map, setMap] = useState<google.maps.Map | null>(null);
    const [marker, setMarker] = useState<google.maps.Marker | null>(null);
    const [lat] = useState<number>(0);
    const [lng] = useState<number>(0);
    const [geocoder, setGeocoder] = useState<google.maps.Geocoder | null>(null);
    const [markerAddress, setMarkerAddress] = useState<google.maps.GeocoderResult[]>([]);
    const [options, setOptions] = useState<OptionType[]>([]);
    const [selectedOption, setSelectedOption] = useState<OptionType | null>(null);
    const [infoWindow, setInfoWindow] = useState<google.maps.InfoWindow | null>(null);
    const { t } = useI18n();
    const tWithNs = useI18n('lpo.start.location');
    const lobs = [TRANSPORT, WORKSHOP];
    const schema = lob && lobs.includes(lob) ? lpoAccidentLocationWithAddressSchema(t) : lpoAccidentLocationSchema(t);
    const {
        control,
        formState: { errors },
        handleSubmit,
        register,
        setValue,
        trigger,
    } = useForm<LocationModel>({
        resolver: yupResolver(schema),
    });

    useEffect(() => {
        const temp = markerAddress.map((r: google.maps.GeocoderResult) => {
            return { label: r.formatted_address, value: r.place_id };
        });
        setOptions(() => [...temp]);
    }, [markerAddress, setOptions]);

    useEffect(() => {
        async function getLocation() {
            try {
                const {
                    position: {
                        coords: { longitude, latitude },
                    },
                } = await getGeoLocation();
                positionMap({ lat: latitude, lng: latitude }, 10);
                dispatch(lpoActions.update({ accidentLocation: { ...location, latitude, longitude, note: null } }));
            } catch (e) {
                positionMap(
                    { lat: DEFAULT_MAP_LOCATION.lat, lng: DEFAULT_MAP_LOCATION.lng },
                    DEFAULT_MAP_LOCATION.zoom
                );
            }
        }
        if (location && location.longitude && location.latitude) {
            if (location && location.note) {
                setSelectedOption({ label: location.note, value: '' });
                setValue('note', location.note, { shouldValidate: true });
            }
            positionMap({ lat: location.latitude, lng: location.longitude }, 15);
        } else {
            getLocation();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [map, marker, location, dispatch]);

    useEffect(() => {
        register(NOTE);
    }, [register]);

    const handleBackButton = useGoBack();

    const positionMap = ({ lat, lng }: { lat: number; lng: number }, zoom: number) => {
        if (map && marker) {
            const latLng = new google.maps.LatLng(lat, lng);
            map.setCenter(latLng);
            map.setZoom(zoom);
            marker.setPosition(latLng);
        }
    };

    const onMarkerDrop = async (e: google.maps.MouseEvent) => {
        if (geocoder && map) {
            const res = await getGeocoderAddressByLocation(geocoder, e.latLng);
            setMarkerAddress(() => [...res]);
            setSelectedOption(null);
            await setValue('note', null);
            dispatch(
                lpoActions.update({
                    accidentLocation: { ...location, longitude: e.latLng.lng(), latitude: e.latLng.lat(), note: null },
                })
            );
        }
    };

    const onSelectedPlace = async (selected: Nullable<OptionType>) => {
        if (geocoder && map && selected && marker) {
            try {
                await setValue('note', selected.label, { shouldValidate: true });
                const res = await getGeocoderLocationByPlaceId(geocoder, selected.value);
                const loc = res[0].geometry.location;
                positionMap({ lat: loc.lat(), lng: loc.lng() }, 16);
                dispatch(
                    lpoActions.update({
                        accidentLocation: {
                            ...location,
                            longitude: loc.lng(),
                            latitude: loc.lat(),
                            note: null,
                        },
                    })
                );
            } catch (e) {
                // Do nothing. There is already a no options message.
            }
        }
    };

    const handleMapClick = async (e: google.maps.MouseEvent) => {
        dispatch(
            lpoActions.update({
                accidentLocation: { ...location, longitude: e.latLng.lng(), latitude: e.latLng.lat(), note: null },
            })
        );

        const geocode = new google.maps.Geocoder();
        const res = await getGeocoderAddressByLocation(geocode, e.latLng);
        setMarkerAddress(() => [...res]);
        setSelectedOption(null);
        await setValue('note', null);
    };

    const handleBlur = async (e: FormChangeable) => {
        e.preventDefault();
        const { id } = e.currentTarget;
        await trigger(id);
    };

    const handleChange = async (e: FormChangeable) => {
        e.preventDefault();
        const { id, value } = e.currentTarget;
        await setValue(id, value, { shouldValidate: true });
    };

    const onSubmit = async (values: LocationModel) => {
        const { address, note } = values;
        const lobs = [PROPERTY, WORKSHOP];
        let nextAction = wizardActions.goToNext();
        let flow = END;
        if (lob && lobs.includes(lob)) {
            flow = START;
            nextAction = wizardActions.goTo(START_PROPERTY_ADDRESS);
        }

        dispatcherWithPromise(dispatch, lpoActions.update, { accidentLocation: { ...location, note, address } })
            .then(() => dispatcherWithPromise(dispatch, flowActions.update, flow))
            .then(() => dispatcherWithPromise(dispatch, commonActions.send))
            .then(() => dispatch(nextAction));
    };

    return (
        <PageLayout
            backBtnText={t(BACK_BUTTON)}
            continueBtnText={t(CONTINUE_BUTTON)}
            domainTitle={t(PAGE_NAME)}
            footerText={tWithNs.t(HELP_TEXT)}
            handleContinueButton={handleSubmit(onSubmit)}
            headerSubTitle={tWithNs.t(SUB_TITLE)}
            headerTitle={tWithNs.t(TITLE)}
            {...{ handleBackButton }}
        >
            <LoadMapScript
                apiKey={MAP_KEY}
                isLoading={mapIsLoading}
                loader={<Loader size={'normal'} inline={false} placement={'center'} fixed={false} />}
                setIsLoading={setMapIsLoading}
                setMapScript={setMapScript}
            >
                <GeoCoder setGeocoder={setGeocoder} />
                <form onSubmit={handleSubmit(onSubmit)}>
                    <HiddenInputSubmit />
                    <Grid className={'align-center'}>
                        <AutocompleteService
                            autoCompleteLabel={t(NOTE_LABEL)}
                            error={errors.note?.message}
                            inputWrapperClass={lob && lobs.includes(lob) ? 'col-6' : 'single-4-center margin-bottom'}
                            name={NOTE}
                            noOptionsText={t(NO_OPTIONS_MESSAGE)}
                            onSelectedPlace={onSelectedPlace}
                            {...{ customCAN, options, setOptions, selectedOption, setSelectedOption }}
                        />
                        {lob && lobs.includes(lob) && (
                            <Controller
                                control={control}
                                name={ADDRESS}
                                render={({ field: { ref, ...field } }) => (
                                    <MuiTextInput
                                        {...field}
                                        error={!!errors.address}
                                        errorMessage={errors.address?.message}
                                        id={ADDRESS}
                                        inputFieldWrapper={'col-6'}
                                        label={t(ADDRESS_LABEL)}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        placeholder={t(ADDRESS_PLACEHOLDER)}
                                        reference={ref}
                                        {...{ customCAN }}
                                    />
                                )}
                            />
                        )}
                    </Grid>
                </form>
                <Map
                    label={tWithNs.t('map.label')}
                    setMap={setMap}
                    className={'col-12 center mb-3'}
                    onClick={handleMapClick}
                />
                <Marker
                    {...{ lng, lat, map, marker, setMarker, infoWindow }}
                    infoWindowLabel={tWithNs.t('infoWindow.label.location')}
                    infoWindowLatText={tWithNs.t('infoWindow.text.lat')}
                    infoWindowLngText={tWithNs.t('infoWindow.text.lng')}
                    onMarkerDrop={onMarkerDrop}
                />
                <InfoWindow setInfoWindow={setInfoWindow} />
            </LoadMapScript>
        </PageLayout>
    );
};
