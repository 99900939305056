import {
    actionWithPromise,
    BankAccountInformationModel,
    ClaimantInformationListTypeModel,
    ClaimantModel,
    ClaimDescriptionTypeModel,
    ClaimLocationTypeModel,
    ClaimReporterRoleTypeModel,
    ClaimTypeTypeModel,
    CompanyModel,
    CounterpartyInsuranceCompanyTypeModel,
    Datable,
    DoctorInformationModel,
    emptyFn,
    GuardianInformationModel,
    initLocation,
    initReporterInformation,
    initVehicleModel,
    InjuryInsuranceExternalReferenceTypeModel,
    InjuryInsuranceTypeModel,
    LocationModel,
    LpoClaimCauseTypeModel,
    Nullable,
    OtherInsuranceCompanyTypeModel,
    PoliceCaseNumberTypeModel,
    PolicyHoldersContactModel,
    PrivacyTypeModel,
    RegistrationNumberTypeModel,
    Rejectable,
    ReporterInformationModel,
    Resolvable,
    TypeOfGlassDamageTypeModel,
    VehicleModel,
    WhoReceivesSettlementTypeModel,
    YesNoModel,
} from '@protectorinsurance/ds-can';
import { put, takeEvery } from 'redux-saga/effects';

/**
 * Constants
 */
export enum LpoActionTypes {
    UPDATE = '@lpo/UPDATE',
    UPDATED = '@lpo/UPDATED',
}

/**
 * Interfaces
 */
export interface LpoAction {
    type: LpoActionTypes;
    data?: Partial<LpoState>;
    resolve?: Resolvable;
    reject?: Rejectable;
}

export interface LpoState {
    acceptedPoliceContact: PrivacyTypeModel;
    acceptedPrivacy: PrivacyTypeModel;
    accidentLocation: LocationModel;
    bankAccountInformation: BankAccountInformationModel;
    claimCause: LpoClaimCauseTypeModel;
    claimDate: Datable;
    claimDescription: ClaimDescriptionTypeModel;
    claimLocation: ClaimLocationTypeModel;
    claimReporterRole: ClaimReporterRoleTypeModel;
    claimType: ClaimTypeTypeModel;
    claimantInformation: ClaimantModel;
    claimantInformationList: ClaimantInformationListTypeModel;
    companyInformation: CompanyModel;
    counterpartyExternalReference: Nullable<string>;
    counterpartyInsuranceCompany: CounterpartyInsuranceCompanyTypeModel;
    dateOfPreviousInjury: Datable;
    dateOfTreatment: Datable;
    doctorInformation: DoctorInformationModel;
    duringWork: YesNoModel;
    externalReference: Nullable<string>;
    glassClaimDescription: ClaimDescriptionTypeModel;
    guardianInformation: GuardianInformationModel;
    hasContributedToInjury: YesNoModel;
    hasHealthInsurance: YesNoModel;
    healthInsurance: OtherInsuranceCompanyTypeModel;
    injuryInsurance: InjuryInsuranceTypeModel;
    injuryInsuranceExternalReference: InjuryInsuranceExternalReferenceTypeModel;
    isAccident: YesNoModel;
    isDoctorContacted: YesNoModel;
    isPersonInjury: YesNoModel;
    isPoliceContacted: YesNoModel;
    isResponsible: YesNoModel;
    isUnderaged: YesNoModel;
    isWorkRelated: YesNoModel;
    liabilityClaimDescription: ClaimDescriptionTypeModel;
    liabilityDamageClaimDescription: ClaimDescriptionTypeModel;
    liabilityInjuredClaimDescription: ClaimDescriptionTypeModel;
    otherInsurance: YesNoModel;
    otherInsuranceCompany: OtherInsuranceCompanyTypeModel;
    policeCaseNumber: PoliceCaseNumberTypeModel;
    policeDistrict: Nullable<string>;
    policyHoldersContact: PolicyHoldersContactModel;
    previousInjury: YesNoModel;
    registrationNumber: RegistrationNumberTypeModel;
    reportedToCounterparty: YesNoModel;
    reportedToInsurersInsurance: YesNoModel;
    reporterInformation: ReporterInformationModel;
    selectedVehicle: VehicleModel;
    typeOfGlassDamage: TypeOfGlassDamageTypeModel;
    typeOfInjury: LpoClaimCauseTypeModel;
    whoReceivesSettlement: WhoReceivesSettlementTypeModel;
}

/**
 * Initial State
 */
export const lpoInitState: LpoState = {
    acceptedPoliceContact: false,
    acceptedPrivacy: false,
    accidentLocation: initLocation,
    bankAccountInformation: {
        accountNumber: null,
        companyName: null,
        dontKnowBankInformation: false,
        isCompany: false,
        ownerFamilyName: null,
        ownerGivenName: null,
        registrationNumber: null,
    },
    claimCause: null,
    claimDate: null,
    claimDescription: '',
    claimLocation: null,
    claimReporterRole: null,
    claimType: null,
    claimantInformation: {
        city: null,
        email: null,
        firstName: null,
        lastName: null,
        nationalIdentity: null,
        nationality: null,
        nationalityId: {
            id: null,
            key: null,
        },
        phone: null,
        title: null,
        street: null,
        zip: null,
    },
    claimantInformationList: [],
    companyInformation: {
        name: null,
        businessNumber: null,
        policyNumber: null,
    },
    counterpartyExternalReference: null,
    counterpartyInsuranceCompany: null,
    dateOfPreviousInjury: null,
    dateOfTreatment: null,
    doctorInformation: {
        city: null,
        name: null,
        street: null,
        zip: null,
    },
    duringWork: null,
    externalReference: null,
    glassClaimDescription: '',
    guardianInformation: {
        city: null,
        email: null,
        firstName: null,
        lastName: null,
        phone: null,
        relation: null,
        street: null,
        zip: null,
    },
    hasContributedToInjury: null,
    hasHealthInsurance: null,
    healthInsurance: null,
    injuryInsurance: null,
    injuryInsuranceExternalReference: null,
    isAccident: null,
    isDoctorContacted: null,
    isPersonInjury: null,
    isPoliceContacted: null,
    isResponsible: null,
    isUnderaged: null,
    isWorkRelated: null,
    liabilityClaimDescription: '',
    liabilityDamageClaimDescription: '',
    liabilityInjuredClaimDescription: '',
    otherInsurance: null,
    otherInsuranceCompany: null,
    policeCaseNumber: null,
    policeDistrict: null,
    policyHoldersContact: {
        email: null,
        firstName: null,
        isReporter: false,
        lastName: null,
        phone: null,
    },
    previousInjury: null,
    registrationNumber: null,
    reportedToCounterparty: null,
    reportedToInsurersInsurance: null,
    reporterInformation: initReporterInformation,
    selectedVehicle: initVehicleModel,
    typeOfGlassDamage: null,
    typeOfInjury: null,
    whoReceivesSettlement: null,
};

/**
 * Default Reducer
 *
 * @param state
 * @param action
 */
export default function (state = lpoInitState, { type, data }: LpoAction) {
    switch (type) {
        case LpoActionTypes.UPDATED:
            return { ...state, ...data };
        default:
            return state;
    }
}

/**
 * Redux Actions
 */
export const lpoActions = {
    update: actionWithPromise<LpoActionTypes.UPDATE, Partial<LpoState>>(LpoActionTypes.UPDATE),
    updated: actionWithPromise<LpoActionTypes.UPDATED, Partial<LpoState>>(LpoActionTypes.UPDATED),
};

/**
 * Saga watchers
 */
export const lpoWatcher = function* () {
    yield takeEvery(LpoActionTypes.UPDATE, lpoSagas.update);
};

/**
 * Saga functions
 */
export const lpoSagas = {
    *update({ data, resolve = emptyFn, reject = emptyFn }: LpoAction) {
        try {
            yield put(lpoActions.updated({ ...data }));
            resolve();
        } catch (e) {
            reject();
        }
    },
};
