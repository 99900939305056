import { takeEvery, takeLatest } from 'redux-saga/effects';
import {
    actionWithPromise,
    CountryCodeEnums,
    CountryEnums,
    customCANProps,
    emptyFn,
    is,
    LanguageCodeEnums,
    LobKeys,
    LobModel,
    LocaleModel,
    LocaleServiceInstance,
    LogServiceModel,
    Nullable,
    Rejectable,
    Resolvable,
} from '@protectorinsurance/ds-can';
import CANError from '../models/CANError';
import { wizardRouterActions } from './wizardRouter';
import { api } from 'utils/api';
import { BaseRoutePaths } from '../config/wizardRouter/baseWizardRoutes';
import { selectClaimReport, selectClaimReportId } from './selectors/reportSelectors';
import { MotorRoutePaths } from '../config/wizardRouter/motorWizardRoutes';
import { LpoRoutePaths } from '../config/wizardRouter/lpoWizardRoutes';
import { selectLob, selectRequestId } from './selectors/commonSelectors';
import { call, put, select } from 'typed-redux-saga';
import { uuidUtil } from '../utils/uuidUtils';
import { logServiceActions } from './services/logService';
import { NODE_API_BASE_URL } from 'config/api';

/**
 * Constants
 */
export enum CommonActionTypes {
    ERROR = '@common/ERROR',
    INIT = '@common/INIT',
    UPDATE = '@common/UPDATE',
    POST = '@common/POST',
    PUT = '@common/PUT',
    SEND = '@common/SEND',
    SUBMIT = '@common/SUBMIT',
}

/**
 * Interfaces
 */
export interface ICommonAction {
    type: CommonActionTypes;
    data?: Partial<CommonState>;
    resolve?: Resolvable;
    reject?: Rejectable;
}

export interface CommonState extends LobModel {
    country: CountryEnums;
    customCAN?: customCANProps;
    errors: CANError[];
    id: Nullable<string>;
    loading: boolean;
    locale: LocaleModel;
    requestId: Nullable<string>;
    submitted: boolean;
    timeoutWarning: number;
}

/**
 * Initial state
 */
export const commonInitState: CommonState = {
    country: CountryEnums.DENMARK,
    errors: [],
    id: null,
    loading: true,
    lob: null,
    locale: {
        country: CountryCodeEnums.DK,
        language: LanguageCodeEnums.DA,
    },
    requestId: null,
    submitted: false,
    timeoutWarning: 0,
};

/**
 * Default reducer
 *
 * @param state
 * @param action
 */
export default function (state = commonInitState, { type, data }: ICommonAction) {
    switch (type) {
        case CommonActionTypes.ERROR:
            return { ...state, errors: [data] };
        case CommonActionTypes.SEND:
            return { ...state, errors: [] };
        case CommonActionTypes.UPDATE:
            return { ...state, ...data };
        default:
            return state;
    }
}

/**
 * Redux Actions
 */
export const commonActions = {
    init: actionWithPromise(CommonActionTypes.INIT),
    error: actionWithPromise<CommonActionTypes, Partial<CANError>>(CommonActionTypes.ERROR),
    send: actionWithPromise<CommonActionTypes, Partial<CommonState>>(CommonActionTypes.SEND),
    put: actionWithPromise<CommonActionTypes, Partial<CommonState>>(CommonActionTypes.PUT),
    post: actionWithPromise<CommonActionTypes, Partial<CommonState>>(CommonActionTypes.POST),
    update: actionWithPromise<CommonActionTypes, Partial<CommonState>>(CommonActionTypes.UPDATE),
    submit: actionWithPromise<CommonActionTypes, Partial<CommonState>>(CommonActionTypes.SUBMIT),
};

/**
 * Saga watchers
 */
export const commonWatcher = function* () {
    yield takeLatest(CommonActionTypes.INIT, commonSagas.init);
    yield takeEvery(CommonActionTypes.POST, commonSagas.post);
    yield takeEvery(CommonActionTypes.PUT, commonSagas.put);
    yield takeEvery(CommonActionTypes.SEND, commonSagas.send);
    yield takeEvery(CommonActionTypes.SUBMIT, commonSagas.submit);
};

/**
 * Saga functions
 */
let sendingCounter = 0;

export const commonSagas = {
    *init() {
        const { country, language } = LocaleServiceInstance.getLocaleFromUrl();
        const requestId = yield* select(selectRequestId);
        const uuid = uuidUtil();
        const urlParams = new URLSearchParams(window.location.search);
        let company = urlParams.get('company');
        if (company === 'viggo') {
            company = 'viggo_2905990';
        }
        const partyId = company ? company.split('_').pop() : null;
        let customCAN;
        if (partyId) {
            const resSettings: any = yield* call(api.get, `metadata/settings/${partyId}`, {
                baseURL: NODE_API_BASE_URL,
                headers: { 'X-Request-Id': `${requestId}` },
            });
            // @ts-ignore
            const resLogo: any = yield* call(api.get, `metadata/image/${partyId}`, {
                baseURL: NODE_API_BASE_URL,
                responseType: 'blob',
                headers: { 'X-Request-Id': `${requestId}` },
            });
            customCAN = { logo: URL.createObjectURL(resLogo.data), ...resSettings.data.data };
        }
        yield* put(commonActions.update({ customCAN, loading: false, locale: { country, language }, requestId: uuid }));
    },

    *post({ resolve = emptyFn, reject = emptyFn }: ICommonAction) {
        const requestId = yield* select(selectRequestId);
        const claimReportData = yield* select(selectClaimReport);
        try {
            const res: any = yield* call(api.post, `claims/report`, claimReportData, {
                baseURL: NODE_API_BASE_URL,
                headers: { 'X-Request-Id': `${requestId}` },
            });
            yield* put(commonActions.update({ id: res.data.data.uuid }));
            resolve();
        } catch (e) {
            reject(e);
            yield* put(commonActions.error({ type: CommonActionTypes.POST, error: e as any }));
            yield* put(wizardRouterActions.goTo(BaseRoutePaths.ERROR));
        }
    },

    *submit({ resolve = emptyFn, reject = emptyFn }: ICommonAction) {
        const requestId = yield* select(selectRequestId);
        const claimReportData = yield* select(selectClaimReport);
        const uuid = yield* select(selectClaimReportId);
        const lob = yield* select(selectLob);
        let goToLink = wizardRouterActions.goTo(LpoRoutePaths.END_REPORT_COMPLETED);
        if (is(lob, LobKeys.AUTO)) {
            goToLink = wizardRouterActions.goTo(MotorRoutePaths.END_REPORT_COMPLETED);
        }

        try {
            sendingCounter++;
            yield* call(api.post, `claims/submission/${uuid}/${Date.now()}/${sendingCounter++}`, claimReportData, {
                baseURL: NODE_API_BASE_URL,
                headers: { 'X-Request-Id': `${requestId}` },
            });
            yield* put(goToLink);
            resolve();
        } catch (e) {
            if ((e as any).url?.includes('null')) {
                const requestId = yield* select(selectRequestId);
                const errorPayload: LogServiceModel = {
                    level: 'error',
                    message: `Missing UUID: Tried to submit claim (DNK). X-Request-Id=${requestId}`,
                };
                yield* put(logServiceActions.request(errorPayload));
            }
            /**
             * TODO Temporary fix until BE fixes the error on their hand
             *
             * See task CLAIMS-11422
             */
            if (!(e as any)?.message?.includes('deserializing Avro message')) {
                reject(e);
                yield* put(commonActions.error({ type: CommonActionTypes.SUBMIT, error: e as any }));
                yield* put(wizardRouterActions.goTo(BaseRoutePaths.ERROR));
            } else {
                yield* put(goToLink);
            }
        }
    },

    *put({ data, resolve = emptyFn, reject = emptyFn }: ICommonAction) {
        const requestId = yield* select(selectRequestId);
        const claimReportData = yield* select(selectClaimReport);
        try {
            yield* call(api.put, `claims/store/${data && data.id}`, claimReportData, {
                baseURL: NODE_API_BASE_URL,
                headers: { 'X-Request-Id': `${requestId}` },
            });
            resolve();
        } catch (e) {
            reject(e);
            yield* put(commonActions.error({ type: CommonActionTypes.PUT, error: e as any }));
            yield* put(wizardRouterActions.goTo(BaseRoutePaths.ERROR));
        }
    },

    *send({ resolve, reject }: ICommonAction) {
        const id = yield* select(selectClaimReportId);
        if (id === null) {
            yield* put(commonActions.post(undefined, resolve, reject));
        } else {
            yield* put(commonActions.put({ id }, resolve, reject));
        }
    },
};
