import AttachmentModel from '../../models/Attachment';
import attachment, { AttachmentAction, AttachmentActionTypes, attachmentInitState } from './attachment/attachment';
import { PollAttachmentActionTypes, pollAttachmentWatchers } from './attachment/pollAttachment';
import {
    UploadAttachmentAction,
    UploadAttachmentActionTypes,
    uploadAttachmentWatchers,
} from './attachment/uploadAttachment';
import { DeleteAttachmentActionTypes, deleteAttachmentWatchers } from './attachment/deleteAttachment';
import { combineReducers } from 'redux';
import report, { ReportAction, ReportActionTypes, reportInitState } from './report/report';
import { DeleteReportActionTypes, deleteReportWatchers } from './report/deleteReport';
import { PollReportActionTypes, pollReportWatchers } from './report/pollReport';
import { UploadReportAction, UploadReportActionTypes, uploadReportWatchers } from './report/uploadReport';
import file, { FileAction, FileActionTypes, fileInitState } from './file/file';
import { PollFileActionTypes, pollFileWatchers } from './file/pollFile';
import { DeleteFileActionTypes, deleteFileWatchers } from './file/deleteFile';
import { UploadFileAction, UploadFileActionTypes, uploadFileWatchers } from './file/uploadFile';
import receipt, { ReceiptAction, ReceiptActionTypes, receiptInitState } from './receipt/receipt';
import { PollReceiptActionTypes, pollReceiptWatchers } from './receipt/pollReceipt';
import { DeleteReceiptActionTypes, deleteReceiptWatchers } from './receipt/deleteReceipt';
import { UploadReceiptAction, UploadReceiptActionTypes, uploadReceiptWatchers } from './receipt/uploadReceipt';
import descriptionFile, {
    DescriptionFileAction,
    DescriptionFileActionTypes,
    descriptionFileInitState,
} from './descriptionFile/descriptionFile';
import { PollDescriptionFileActionTypes, pollDescriptionFileWatchers } from './descriptionFile/pollDescriptionFile';
import {
    DeleteDescriptionFileActionTypes,
    deleteDescriptionFileWatchers,
} from './descriptionFile/deleteDescriptionFile';
import {
    UploadDescriptionFileAction,
    UploadDescriptionFileActionTypes,
    uploadDescriptionFileWatchers,
} from './descriptionFile/uploadDescriptionFile';

export type UploadActionTypes =
    | AttachmentActionTypes
    | DeleteAttachmentActionTypes
    | PollAttachmentActionTypes
    | UploadAttachmentActionTypes
    | ReportActionTypes
    | DeleteReportActionTypes
    | PollReportActionTypes
    | UploadReportActionTypes
    | FileActionTypes
    | PollFileActionTypes
    | DeleteFileActionTypes
    | UploadFileActionTypes
    | ReceiptActionTypes
    | DeleteReceiptActionTypes
    | UploadReceiptActionTypes
    | PollReceiptActionTypes
    | DescriptionFileActionTypes
    | PollDescriptionFileActionTypes
    | DeleteDescriptionFileActionTypes
    | UploadDescriptionFileActionTypes;

export type UploadActions =
    | AttachmentAction
    | UploadAttachmentAction
    | ReportAction
    | UploadReportAction
    | FileAction
    | UploadFileAction
    | UploadReceiptAction
    | ReceiptAction
    | DescriptionFileAction
    | UploadDescriptionFileAction;

export const uploadInitState = {
    attachment: attachmentInitState,
    report: reportInitState,
    file: fileInitState,
    receipt: receiptInitState,
    descriptionFile: descriptionFileInitState,
};

/**
 * Export namespace state
 */
export interface UploadState {
    attachment: AttachmentModel;
    report: AttachmentModel;
    file: AttachmentModel;
    receipt: AttachmentModel;
    descriptionFile: AttachmentModel;
}

/**
 * Export namespace watchers
 */
export const uploadWatchers = [
    uploadAttachmentWatchers,
    pollAttachmentWatchers,
    deleteAttachmentWatchers,
    uploadReportWatchers,
    pollReportWatchers,
    deleteReportWatchers,
    uploadFileWatchers,
    pollFileWatchers,
    deleteFileWatchers,
    uploadReceiptWatchers,
    pollReceiptWatchers,
    deleteReceiptWatchers,
    uploadDescriptionFileWatchers,
    pollDescriptionFileWatchers,
    deleteDescriptionFileWatchers,
];

/**
 * Export combined reducers as default
 */
export default combineReducers({
    attachment,
    report,
    file,
    receipt,
    descriptionFile,
});
