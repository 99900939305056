import React, { RefObject, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { wizardRouterActions as wizardActions } from 'sagas/wizardRouter';
import { useI18n } from '../../../hooks/useI18n';
import {
    addDamageImageDataToSelectedVehicle,
    addDamageToSelectedVehicle,
    Changable,
    CircleGarbageIcon,
    CircleIconButton,
    Clickable,
    DamageDescriptionModel,
    DamagePoint,
    findSelectedVehicle,
    findSelectedVehicleIndex,
    FormChangeable,
    Grid,
    initVehicleModel,
    InputValidationError,
    is,
    MuiTextInput,
    PageLayout,
    PencilIcon,
    removeDamageToSelectedVehicle,
    SELECT_NO,
    SELECT_YES,
    SingleCheckbox,
    StandardModal,
    SVGClickable,
    VehicleDamagesModel,
    VehicleListItem,
} from '@protectorinsurance/ds-can';
import { motorActions } from '../../../sagas/motor';
import dispatcherWithPromise from '../../../utils/dispatcherWithPromise';
import { VehicleBodyDamages } from '../../../components/vehicle/VehicleBodyDamages';
import { PhraseKeys } from '../../../config/phraseKeys';
import { useVehicles } from '../../../hooks/useVehicles';
import { FormFieldNames } from '../../../config/formFieldNames';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { Controller, useForm } from 'react-hook-form';
import { damageDescriptionSchema } from '../../../validations/schemas/damageDescriptionSchema';
import { goBack } from 'connected-react-router';
import { selectSelectedVehicleId } from '../../../sagas/selectors/motorSelectors';
import { yupResolver } from '@hookform/resolvers/yup';
import { commonActions } from '../../../sagas/common';
import { selectCustomCAN } from '../../../sagas/selectors/commonSelectors';

/**
 * Destructure necessary imports
 */
const {
    BACK_BUTTON,
    CANCEL_BUTTON,
    CONFIRM_BUTTON,
    CONTINUE_BUTTON,
    DAMAGE_DESCRIPTION_LABEL,
    DAMAGE_DESCRIPTION_PLACEHOLDER,
    EDIT_BUTTON,
    HELP_TEXT,
    IS_TOTAL_DAMAGE_LABEL,
    PAGE_NAME,
    RESET_BUTTON,
    SUB_TITLE,
    TITLE,
} = PhraseKeys;
const { DAMAGE_DESCRIPTION, IS_TOTAL_DAMAGE } = FormFieldNames;

/**
 * Page view and page logic
 */
export const BodyDamagesPage = () => {
    const dispatch = useDispatch();
    const customCAN = useSelector(selectCustomCAN);
    const tWithNS = useI18n('motor.end.bodyDamages');
    const { t } = useI18n();
    const { selectedVehicleInternalId, vehicles } = useVehicles();
    const currentVehicle = findSelectedVehicle(vehicles, selectedVehicleInternalId);
    const selectedVehicle = useSelector(selectSelectedVehicleId);
    const [editableDamageDescription, setEditableDamageDescription] = useState<boolean>(true);
    const [validation, setValidation] = useState<boolean>(true);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const damagePoints = currentVehicle
        ? currentVehicle.damages.reduce((acc: DamagePoint[], curr: VehicleDamagesModel) => {
              const { x, y, svgId } = curr.svgLocation;
              return [...acc, { x, y, svgId, vehicleBodyPart: curr.body }];
          }, [])
        : [];
    const [isTotalDamage, setIsTotalDamage] = useState<boolean>(false);
    const vehiclesList = [...vehicles];
    const vehicleIndex = findSelectedVehicleIndex(vehicles, selectedVehicleInternalId);

    useEffect(() => {
        const selected = currentVehicle?.isTotalDamage;
        if (selected) {
            setIsTotalDamage(selected);
        }
    }, [currentVehicle]);

    const { control, handleSubmit, reset, setValue, trigger, watch } = useForm<DamageDescriptionModel>({
        resolver: yupResolver(damageDescriptionSchema(t, DAMAGE_DESCRIPTION_LABEL)),
        defaultValues: { damageDescription: currentVehicle?.otherDamageComment || '' },
    });

    const handleBackButton = () => {
        if (!currentVehicle?.damages.length) {
            const updatedVehicleList = vehicles.map((vehicle) => {
                if (is(vehicle.internalId, selectedVehicle)) {
                    return {
                        ...initVehicleModel,
                        ...currentVehicle,
                        vehicleHasDamages: null,
                        otherDamageComment: null,
                    };
                } else {
                    return vehicle;
                }
            });
            dispatcherWithPromise(dispatch, motorActions.update, {
                vehicles: updatedVehicleList,
                selectedVehicle: initVehicleModel,
            }).then(() => dispatch(goBack()));
        } else {
            dispatch(goBack());
        }
    };

    useEffect(() => {
        if (editableDamageDescription) {
            setValue('damageDescription', currentVehicle?.otherDamageComment || '');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editableDamageDescription]);

    const damageDescriptionValue = watch().damageDescription;

    const handleContinueButton = (e: Clickable) => {
        e.preventDefault();
        let checkValidation = true;

        if (!currentVehicle?.damages.length && !currentVehicle?.isTotalDamage) {
            checkValidation = false;
        } else {
            dispatcherWithPromise(dispatch, commonActions.send).then(() => dispatch(wizardActions.goToNext()));
        }
        setValidation(checkValidation);
    };

    const handleSVGClick = (d: DamagePoint, refSVG?: RefObject<SVGSVGElement>) => {
        let withDamagesAndImages;
        const withDamages = addDamageToSelectedVehicle(vehicles, selectedVehicleInternalId, d);

        if (refSVG?.current) {
            withDamagesAndImages = addDamageImageDataToSelectedVehicle(withDamages, selectedVehicleInternalId, refSVG);
        }

        dispatcherWithPromise(dispatch, motorActions.update, { vehicles: withDamagesAndImages || withDamages });

        if (d) {
            setValidation(true);
        }
    };

    const removeDamagePoint = (e: SVGClickable, point: DamagePoint) => {
        e.preventDefault();
        dispatch(
            motorActions.update({ vehicles: removeDamageToSelectedVehicle(vehicles, selectedVehicleInternalId, point) })
        );
    };

    const handleBlur = async (e: FormChangeable) => {
        e.preventDefault();
        const { id } = e.currentTarget;
        await trigger(id);
    };

    const handleChange = async (e: FormChangeable) => {
        e.preventDefault();
        const { id, value } = e.currentTarget;
        await trigger(id);
        await setValue(id, value);
        const updatedVehicleList = vehicles.map((vehicle) => {
            if (vehicle.internalId === selectedVehicle) {
                return {
                    ...initVehicleModel,
                    ...currentVehicle,
                    otherDamageComment: value,
                };
            } else {
                return vehicle;
            }
        });
        dispatcherWithPromise(dispatch, motorActions.update, {
            vehicles: updatedVehicleList,
            selectedVehicle: initVehicleModel,
        });
    };

    const onSubmit = () => {
        setEditableDamageDescription(false);
    };

    const onCancelSubmit = () => {
        const damageDescription = null;

        const updatedVehicleList = vehicles.map((vehicle) => {
            if (vehicle.internalId === selectedVehicle) {
                return {
                    ...initVehicleModel,
                    ...currentVehicle,
                    otherDamageComment: damageDescription,
                };
            } else {
                return vehicle;
            }
        });
        dispatcherWithPromise(dispatch, motorActions.update, {
            vehicles: updatedVehicleList,
            selectedVehicle: initVehicleModel,
        });

        reset();
        setEditableDamageDescription(false);
    };

    const onEditClick = () => {
        setEditableDamageDescription(true);
    };

    const handleChecked = async (e: Changable) => {
        const { checked } = e.target;
        vehiclesList[vehicleIndex] = {
            ...initVehicleModel,
            ...currentVehicle,
            isTotalDamage: checked,
        };
        await setIsTotalDamage(checked);
        dispatch(motorActions.update({ vehicles: vehiclesList }));
    };

    const handleClear = (e: Clickable) => {
        e.preventDefault();
        setIsModalOpen(true);
    };

    const handleModalClose = () => {
        setIsModalOpen(() => false);
    };

    const handleModalConfirm = () => {
        setIsModalOpen(() => false);

        const updatedVehicleList = vehicles.map((vehicle) => {
            if (is(vehicle.internalId, selectedVehicle)) {
                return {
                    ...initVehicleModel,
                    ...currentVehicle,
                    damages: [],
                    otherDamageComment: null,
                };
            } else {
                return vehicle;
            }
        });

        dispatcherWithPromise(dispatch, motorActions.update, {
            vehicles: updatedVehicleList,
            selectedVehicle: initVehicleModel,
        });

        setEditableDamageDescription(true);
    };

    return (
        <PageLayout
            backBtnText={t(BACK_BUTTON)}
            continueBtnText={t(CONTINUE_BUTTON)}
            domainTitle={t(PAGE_NAME)}
            footerText={tWithNS.t(HELP_TEXT)}
            headerSubTitle={tWithNS.t(SUB_TITLE)}
            headerTitle={tWithNS.t(TITLE)}
            {...{ handleBackButton, handleContinueButton }}
        >
            <Grid>
                {currentVehicle && (
                    <VehicleListItem
                        dataTestId={'selected-vehicle'}
                        make={currentVehicle.make}
                        model={currentVehicle.model}
                        registrationNumber={currentVehicle.registrationNumber}
                        type={currentVehicle.type}
                        vehicleType={currentVehicle.vehicleType}
                        {...{ customCAN }}
                    />
                )}
                <SingleCheckbox
                    checked={isTotalDamage}
                    handleChange={handleChecked}
                    name={IS_TOTAL_DAMAGE}
                    wrapperClass={'col-12 multiple'}
                    {...{ customCAN }}
                >
                    {t(IS_TOTAL_DAMAGE_LABEL)}
                </SingleCheckbox>
            </Grid>
            {currentVehicle && (
                <VehicleBodyDamages
                    addDamagePoint={handleSVGClick}
                    className={isTotalDamage ? 'total-damage' : ''}
                    removeDamagePoint={removeDamagePoint}
                    selectedDamagePoints={damagePoints}
                    tWithNs={tWithNS.t}
                    vehicleType={currentVehicle.vehicleType}
                />
            )}
            {damagePoints.length > 0 &&
                (editableDamageDescription ? (
                    <Grid className={'align-center'}>
                        <div className={'col-12 descInputField'}>
                            <button onClick={handleSubmit(onSubmit)} type={'submit'}>
                                <CheckCircleIcon style={{ color: '#48b782', float: 'left' }} />
                                <span>{t(CONFIRM_BUTTON)}</span>
                            </button>
                            <button onClick={handleSubmit(onCancelSubmit)} type={'submit'}>
                                <CancelIcon style={{ color: '#19303f', float: 'left' }} />
                                <span>{t(CANCEL_BUTTON)}</span>
                            </button>
                        </div>
                        <Controller
                            control={control}
                            name={DAMAGE_DESCRIPTION}
                            render={({ field: { ref, ...field } }) => (
                                <MuiTextInput
                                    {...field}
                                    id={DAMAGE_DESCRIPTION}
                                    inputFieldWrapper={'col-12'}
                                    label={t(DAMAGE_DESCRIPTION_LABEL)}
                                    multiline={true}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    placeholder={t(DAMAGE_DESCRIPTION_PLACEHOLDER)}
                                    reference={ref}
                                    rows={5}
                                    {...{ customCAN }}
                                />
                            )}
                        />
                    </Grid>
                ) : (
                    <>
                        <button
                            aria-label={t(EDIT_BUTTON)}
                            className={'edit-damage-description'}
                            onClick={onEditClick}
                            type={'button'}
                        >
                            {<PencilIcon wh={20} />}
                        </button>
                        <div className={'uneditableDescriptionField'} tabIndex={0}>
                            {damageDescriptionValue || currentVehicle?.otherDamageComment}
                        </div>
                    </>
                ))}

            {!validation && <InputValidationError fieldName={'Vehicle Body Damage'} error={tWithNS.t('error')} />}

            {currentVehicle && (
                <CircleIconButton
                    dataTestId={'btn-reset'}
                    icon={<CircleGarbageIcon />}
                    handleClick={handleClear}
                    ariaLabel={t(RESET_BUTTON)}
                    label={t(RESET_BUTTON)}
                />
            )}
            {isModalOpen && (
                <StandardModal
                    title={tWithNS.t('modal.title')}
                    onClose={handleModalClose}
                    onConfirm={handleModalConfirm}
                    closeButtonText={t(SELECT_NO)}
                    confirmButtonText={t(SELECT_YES)}
                    showConfirmButton={true}
                    showCloseButton={true}
                >
                    <div className="dialog__content text-center">{tWithNS.t('modal.question')}</div>
                </StandardModal>
            )}
        </PageLayout>
    );
};
