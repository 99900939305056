import * as Yup from 'yup';
import { PhraseKeys } from '../../config/phraseKeys';
import { TFunction } from 'i18next';
import { ACCOUNT_REGISTRATION_NUMBER_REGEX } from '../formats/stringFormats';
import { FormFieldErrors } from '../../config/formFieldNames';

/**
 * Destructure necessary import
 */
const { ACCOUNT_REGISTRATION_NUMBER_LABEL } = PhraseKeys;
const { INVALID_ACCOUNT_REGISTRATION_NUMBER } = FormFieldErrors;

/**
 * Validation logic
 */
export const accountRegistrationNumberSchema = (t: TFunction) => {
    return {
        registrationNumber: Yup.string()
            .matches(ACCOUNT_REGISTRATION_NUMBER_REGEX, t(INVALID_ACCOUNT_REGISTRATION_NUMBER))
            .label(ACCOUNT_REGISTRATION_NUMBER_LABEL)
            .nullable()
            .required(),
    };
};
