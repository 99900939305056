import React from 'react';
import { is, isYes, LobKeys, Nullable, SummaryItem, SummaryItemProps, YesNoModel } from '@protectorinsurance/ds-can';

/**
 * Destructure necessary imports
 */
const { LIABILITY } = LobKeys;

/**
 * Component view and component logic
 */
export const renderLiabilityClaimDescription = (
    { inputWrapperClass, textKey, dataTestId, buttonText, path, handleClick }: SummaryItemProps,
    lob: Nullable<LobKeys>,
    isResponsible: YesNoModel
) => {
    if (is(lob, LIABILITY) && isYes(isResponsible)) {
        return <SummaryItem {...{ buttonText, dataTestId, handleClick, inputWrapperClass, path, textKey }} />;
    }
    return <></>;
};
