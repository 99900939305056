import { CarouselConfigPageProps } from '@protectorinsurance/ds-can';
import { CarouselConfigLookupProps } from '../../../utils/carouselPageLookup';
import { startClaimReporterRolePage } from './start/claimReporterRole';
import { endHasOtherInsurancePage } from './end/hasOtherInsurance';
import { liabilityIsPersonInjuryPage } from './liability/isPersonInjury';
import { liabilityIsResponsiblePage } from './liability/isResponsible';
import { liabilityHasContributedToInjuryPage } from './liability/hasContributedToInjury';
import { glassIsWorkRelatedPage } from './glass/isWorkRelated';
import { glassClaimLocationPage } from './glass/claimLocation';
import { glassHasHealthInsurancePage } from './glass/hasHealthInsurance';
import { glassWhoReceivesSettlementPage } from './glass/whoReceivesSettlement';
import { groupAccidentIsAccidentPage } from './groupAccident/isAccident';
import { groupAccidentIsPoliceContactedPage } from './groupAccident/isPoliceContacted';
import { groupAccidentReportedToCounterpartyPage } from './groupAccident/reportedToCounterparty';
import { groupAccidentDuringWorkPage } from './groupAccident/duringWork';
import { groupAccidentReportedToInsurersInsurancePage } from './groupAccident/reportedToInsurersInsurance';
import { groupAccidentIsUnderagedPage } from './groupAccident/isUnderaged';
import { groupAccidentIsDoctorContactedPage } from './groupAccident/isDoctorContacted';
import { groupAccidentPreviousInjuryPage } from './groupAccident/previousInjury';

export const lpoCarouselConfigPages = (props: CarouselConfigLookupProps): CarouselConfigPageProps => [
    // Start
    startClaimReporterRolePage(props),

    // Liability
    liabilityIsPersonInjuryPage(props),
    liabilityIsResponsiblePage(props),
    liabilityHasContributedToInjuryPage(props),

    // Glass
    glassIsWorkRelatedPage(props),
    glassClaimLocationPage(props),
    glassHasHealthInsurancePage(props),
    glassWhoReceivesSettlementPage(props),

    // Group Accident
    groupAccidentIsAccidentPage(props),
    groupAccidentIsPoliceContactedPage(props),
    groupAccidentReportedToCounterpartyPage(props),
    groupAccidentDuringWorkPage(props),
    groupAccidentReportedToInsurersInsurancePage(props),
    groupAccidentIsUnderagedPage(props),
    groupAccidentIsDoctorContactedPage(props),
    groupAccidentPreviousInjuryPage(props),

    // End
    endHasOtherInsurancePage(props),
];
