import * as Yup from 'yup';
import { TFunction } from 'i18next';
import { optionalContactDetailsSchema } from '../fieldSchemas/optionalContactDetailsSchema';
import { firstNameSchema } from '../fieldSchemas/firstNameSchema';
import { optionalLastNameSchema } from '../fieldSchemas/optionalLastNameSchema';

export const claimDiscovererSchema = (t: TFunction) => {
    return Yup.object().shape({
        ...firstNameSchema(t),
        ...optionalLastNameSchema(t),
        ...optionalContactDetailsSchema(t),
    });
};
