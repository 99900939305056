import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { wizardRouterActions as wizardActions } from 'sagas/wizardRouter';
import { useI18n } from '../../../hooks/useI18n';
import {
    DriverInformationModel,
    dtParse,
    FormChangeable,
    Grid,
    HiddenInputSubmit,
    LocaleServiceInstance,
    MuiDatePickerInput,
    MuiTextInput,
    Nullable,
    PageLayout,
    YesNoKeys,
} from '@protectorinsurance/ds-can';
import { useGoBack } from '../../../hooks/useGoBack';
import {
    selectClaimDate,
    selectDriverInformation,
    selectHasParkedWitnesses,
} from '../../../sagas/selectors/motorSelectors';
import { Controller, useForm } from 'react-hook-form';
import { motorActions } from '../../../sagas/motor';
import dispatcherWithPromise from '../../../utils/dispatcherWithPromise';
import { commonActions } from '../../../sagas/common';
import { PhraseKeys } from '../../../config/phraseKeys';
import { FormFieldErrors, FormFieldNames } from '../../../config/formFieldNames';
import { driverInformationSchema } from '../../../validations/schemas/driverInformationSchema';
import { MotorRoutePaths } from '../../../config/wizardRouter/motorWizardRoutes';
import { AutocompleteValueKeys } from '../../../config/AutocompleteValueKeys';
import { crossContactInfoFieldValidation } from '../../../utils/validation/crossContactInfoFieldValidation';
import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment';
import { selectCustomCAN } from '../../../sagas/selectors/commonSelectors';

/**
 * Destructure necessary imports
 */
const { DATE_OF_BIRTH, EMAIL, FIRST_NAME, LAST_NAME, PHONE } = FormFieldNames;
const {
    BACK_BUTTON,
    CONTINUE_BUTTON,
    DATE_OF_BIRTH_LABEL,
    EMAIL_LABEL,
    EMAIL_PLACEHOLDER,
    FIRST_NAME_LABEL,
    FIRST_NAME_PLACEHOLDER,
    HELP_TEXT,
    LAST_NAME_LABEL,
    LAST_NAME_PLACEHOLDER,
    PAGE_NAME,
    PHONE_LABEL,
    PHONE_PLACEHOLDER,
    SUB_TITLE,
    TITLE,
} = PhraseKeys;
const { END_WITNESS_INFORMATION } = MotorRoutePaths;
const { EMAIL: EMAIL_AUTOCOMPLETE, FAMILY_NAME, GIVEN_NAME, TEL } = AutocompleteValueKeys;
const { YES } = YesNoKeys;
const { MIN_REQUIRED_AGE } = FormFieldErrors;

interface DriverFormModel extends Omit<DriverInformationModel, 'dateOfBirth'> {
    dateOfBirth?: Nullable<Date>;
}

/**
 * Page view and page logic
 */
export const DriverInformationPage = () => {
    const dispatch = useDispatch();
    const { dateOfBirth, email, firstName, lastName, phone } = useSelector(selectDriverInformation);
    const hasParkedWitnesses = useSelector(selectHasParkedWitnesses);
    const claimDate = useSelector(selectClaimDate);
    const customCAN = useSelector(selectCustomCAN);
    const [dateError, setDateError] = useState<string>('');
    const { t } = useI18n();
    const tWithNS = useI18n('motor.end.driverInformation');
    const {
        control,
        formState: { errors },
        handleSubmit,
        setValue,
        trigger,
        watch,
    } = useForm<DriverFormModel>({
        resolver: yupResolver(driverInformationSchema(t)),
        defaultValues: {
            dateOfBirth: dateOfBirth && dateOfBirth.toDate(),
            email,
            firstName,
            lastName,
            phone,
        },
    });
    const date = watch(DATE_OF_BIRTH);

    const handleBackButton = useGoBack();

    const handleBlur = async (e: FormChangeable) => {
        e.preventDefault();
        const { id } = e.currentTarget;
        await trigger(id);
    };

    const handleChange = async (e: FormChangeable) => {
        e.preventDefault();
        const { id, value } = e.currentTarget;
        await setValue(id, value, { shouldValidate: true });
        await crossContactInfoFieldValidation(id, trigger);
    };

    const handleDateChanged = async (date: Nullable<Date>) => {
        setValue('dateOfBirth', date, { shouldValidate: true });
        const minRequiredAgeToDrive = 15;

        if (date && claimDate) {
            const driverDate = dtParse(date);
            claimDate.startOf('day');
            driverDate.startOf('day');
            const yearDifference = claimDate.diff(driverDate, 'years', true);

            if (yearDifference < minRequiredAgeToDrive) {
                setDateError(t(MIN_REQUIRED_AGE));
            } else {
                setDateError('');
            }
        }
    };

    const onSubmit = (values: DriverFormModel) => {
        if (!dateError) {
            if (hasParkedWitnesses === YES) {
                dispatcherWithPromise(dispatch, motorActions.update, {
                    driverInformation: {
                        ...values,
                        dateOfBirth: values.dateOfBirth ? moment(values.dateOfBirth) : null,
                    },
                })
                    .then(() => dispatcherWithPromise(dispatch, commonActions.send))
                    .then(() => dispatch(wizardActions.goTo(END_WITNESS_INFORMATION)));
            } else {
                dispatcherWithPromise(dispatch, motorActions.update, {
                    driverInformation: {
                        ...values,
                        dateOfBirth: values.dateOfBirth ? moment(values.dateOfBirth) : null,
                    },
                })
                    .then(() => dispatcherWithPromise(dispatch, commonActions.send))
                    .then(() => dispatch(wizardActions.goToNext()));
            }
        }
    };

    return (
        <PageLayout
            backBtnText={t(BACK_BUTTON)}
            continueBtnText={t(CONTINUE_BUTTON)}
            domainTitle={t(PAGE_NAME)}
            footerText={tWithNS.t(HELP_TEXT)}
            handleContinueButton={handleSubmit(onSubmit)}
            headerSubTitle={tWithNS.t(SUB_TITLE)}
            headerTitle={tWithNS.t(TITLE)}
            {...{ handleBackButton }}
        >
            <form onSubmit={handleSubmit(onSubmit)}>
                <HiddenInputSubmit />
                <Grid className={'align-center'}>
                    <Controller
                        control={control}
                        name={FIRST_NAME}
                        render={({ field: { ref, ...field } }) => (
                            <MuiTextInput
                                {...field}
                                autoComplete={GIVEN_NAME}
                                error={!!errors.firstName}
                                errorMessage={errors.firstName?.message}
                                id={FIRST_NAME}
                                inputFieldWrapper={'col-6'}
                                label={t(FIRST_NAME_LABEL)}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                placeholder={t(FIRST_NAME_PLACEHOLDER)}
                                reference={ref}
                                {...{ customCAN }}
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name={LAST_NAME}
                        render={({ field: { ref, ...field } }) => (
                            <MuiTextInput
                                {...field}
                                autoComplete={FAMILY_NAME}
                                error={!!errors.lastName}
                                errorMessage={errors.lastName?.message}
                                id={LAST_NAME}
                                inputFieldWrapper={'col-6'}
                                label={t(LAST_NAME_LABEL)}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                placeholder={t(LAST_NAME_PLACEHOLDER)}
                                reference={ref}
                                {...{ customCAN }}
                            />
                        )}
                    />
                    <MuiDatePickerInput
                        disableFuture={true}
                        error={!!dateError}
                        errorMessage={dateError}
                        id={DATE_OF_BIRTH}
                        inputFieldWrapper={'col-3'}
                        label={t(DATE_OF_BIRTH_LABEL)}
                        locale={LocaleServiceInstance.getMoment()}
                        onChange={handleDateChanged}
                        value={date}
                        {...{ customCAN }}
                    />
                    <Controller
                        control={control}
                        name={EMAIL}
                        render={({ field: { ref, ...field } }) => (
                            <MuiTextInput
                                {...field}
                                autoComplete={EMAIL_AUTOCOMPLETE}
                                error={!!errors.email}
                                errorMessage={errors.email?.message}
                                id={EMAIL}
                                inputFieldWrapper={'col-6'}
                                label={t(EMAIL_LABEL)}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                placeholder={t(EMAIL_PLACEHOLDER)}
                                reference={ref}
                                type={'email'}
                                {...{ customCAN }}
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name={PHONE}
                        render={({ field: { ref, ...field } }) => (
                            <MuiTextInput
                                {...field}
                                autoComplete={TEL}
                                error={!!errors.phone}
                                errorMessage={errors.phone?.message}
                                id={PHONE}
                                inputFieldWrapper={'col-3'}
                                label={t(PHONE_LABEL)}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                placeholder={t(PHONE_PLACEHOLDER)}
                                reference={ref}
                                {...{ customCAN }}
                            />
                        )}
                    />
                </Grid>
            </form>
        </PageLayout>
    );
};
