import React, { MouseEvent } from 'react';
import {
    ClaimantInformationListModel,
    ClaimantInformationListTypeModel,
    SmallPersonIcon,
} from '@protectorinsurance/ds-can';
import './DisplayClaimantInformationList.scss';

/**
 * Component view and component logic
 */
interface DisplayClaimantInformationListProps {
    claimants: ClaimantInformationListTypeModel;
    handleDelete(e: MouseEvent, claimant: ClaimantInformationListModel): void;
    buttonText: string;
}

export const DisplayClaimantInformationList = ({
    claimants,
    handleDelete,
    buttonText,
}: DisplayClaimantInformationListProps) => {
    const renderPerson = (claimant: ClaimantInformationListModel) => (
        <>
            {claimant.firstName} {claimant.lastName}
        </>
    );

    const renderClaimantList = claimants.map((claimant: ClaimantInformationListModel, idx: number) => {
        return (
            <li key={idx} className={'claimant-list-item'}>
                <SmallPersonIcon />
                <span className={'list-text'} data-hj-suppress>
                    {renderPerson(claimant)}
                </span>
                <button
                    type={'button'}
                    data-testid={'delete-btn'}
                    className={'delete-btn'}
                    onClick={(e) => handleDelete(e, claimant)}
                >
                    {buttonText}
                </button>
            </li>
        );
    });

    return (
        <ul className={'list-container'} data-testid={'claimant-list'}>
            {renderClaimantList}
        </ul>
    );
};
