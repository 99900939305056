import { LocationModel } from '@protectorinsurance/ds-can';
import { TFunction } from 'i18next';

export const formatAddress = (accidentLocation: LocationModel, t: TFunction) => {
    if (accidentLocation.note) {
        return accidentLocation.note;
    } else if (accidentLocation.address) {
        return accidentLocation.address;
    } else if (accidentLocation.isUnknownLocation) {
        return t('text.isUnknownLocation');
    } else {
        return '';
    }
};
