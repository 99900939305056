import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { wizardRouterActions as wizardActions } from 'sagas/wizardRouter';
import { useI18n } from '../../../../hooks/useI18n';
import {
    Clickable,
    Datable,
    LocaleServiceInstance,
    MuiDatePicker,
    PageLayout,
    TimePicker,
    updateDateByDate,
    updateDateByHour,
    updateDateByMinutes,
} from '@protectorinsurance/ds-can';
import { useGoBack } from '../../../../hooks/useGoBack';
import { PhraseKeys } from '../../../../config/phraseKeys';
import { selectParkingDate } from '../../../../sagas/selectors/motorSelectors';
import moment from 'moment';
import { motorActions } from '../../../../sagas/motor';
import { FormFieldErrors, FormFieldNames } from '../../../../config/formFieldNames';
import dispatcherWithPromise from '../../../../utils/dispatcherWithPromise';
import { commonActions } from '../../../../sagas/common';
import { selectCustomCAN } from '../../../../sagas/selectors/commonSelectors';

/**
 * Destructure necessary imports
 */
const { BACK_BUTTON, CONTINUE_BUTTON, HELP_TEXT, PAGE_NAME, SUB_TITLE, TITLE } = PhraseKeys;
const { PARKING_DATE, PARKING_TIME } = FormFieldNames;
const { MANDATORY_DATE_FIELD, MANDATORY_TIME_FIELD } = FormFieldErrors;

/**
 * Page view and page logic
 */
export const PTParkingDatePage = () => {
    const dispatch = useDispatch();
    const { t } = useI18n();
    const tWithNS = useI18n('motor.parked.parkingDate');
    const parkingDate = useSelector(selectParkingDate);
    const customCAN = useSelector(selectCustomCAN);
    const [date, setDate] = useState<moment.Moment | null>(null);
    const [time, setTime] = useState<moment.Moment | null>(null);
    useEffect(() => {
        setDate(parkingDate);
        setTime(parkingDate);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const [errors, setErrors] = useState<string[]>([]);
    const handleBackButton = useGoBack();

    const handleContinueButton = (e: Clickable) => {
        e.preventDefault();
        if (date === null && time === null) {
            setErrors([t(MANDATORY_TIME_FIELD), t(MANDATORY_DATE_FIELD)]);
        } else if (date === null) {
            setErrors([t(MANDATORY_DATE_FIELD)]);
        } else if (time === null) {
            setErrors([t(MANDATORY_TIME_FIELD)]);
        } else {
            dispatcherWithPromise(dispatch, motorActions.update, { parkingDate: parkingDate })
                .then(() => dispatcherWithPromise(dispatch, commonActions.send))
                .then(() => dispatch(wizardActions.goToNext()));
        }
    };

    const selectedDate = (newDate: Datable) => {
        if (newDate) {
            const newFullDate = updateDateByDate(parkingDate, newDate);

            setDate(newDate);
            setErrors((prev) => {
                return prev ? prev.filter((p) => p !== t(MANDATORY_DATE_FIELD)) : [];
            });
            dispatch(motorActions.update({ parkingDate: newFullDate }));
        }
    };

    const onHourSelect = (newHour: number) => {
        const newFullDate = updateDateByHour(parkingDate, newHour);
        setTime(newFullDate);

        setErrors((prev) => {
            return prev ? prev.filter((p) => p !== t(MANDATORY_TIME_FIELD)) : [];
        });
        dispatch(motorActions.update({ parkingDate: newFullDate }));
    };

    const onMinSelect = (newMin: number) => {
        const newFullDate = updateDateByMinutes(parkingDate, newMin);
        setTime(newFullDate);
        setErrors((prev) => {
            return prev ? prev.filter((p) => p !== t(MANDATORY_TIME_FIELD)) : [];
        });
        dispatch(motorActions.update({ parkingDate: newFullDate }));
    };

    return (
        <PageLayout
            backBtnText={t(BACK_BUTTON)}
            continueBtnText={t(CONTINUE_BUTTON)}
            domainTitle={t(PAGE_NAME)}
            footerText={tWithNS.t(HELP_TEXT)}
            headerSubTitle={tWithNS.t(SUB_TITLE)}
            headerTitle={tWithNS.t(TITLE)}
            {...{ handleBackButton, handleContinueButton }}
        >
            <TimePicker
                className={'mb-1 mt-5 self-center'}
                error={errors.find((e) => e === t(MANDATORY_TIME_FIELD))}
                hoursLabel={t('timePicker.label.hours')}
                minutesLabel={t('timePicker.label.minutes')}
                mode={'quarterly'}
                name={PARKING_TIME}
                {...{ customCAN, onHourSelect, onMinSelect, time }}
            />
            <MuiDatePicker
                disableFuture={true}
                error={errors.find((e: string) => e === t(MANDATORY_DATE_FIELD))}
                locale={LocaleServiceInstance.getMoment()}
                name={PARKING_DATE}
                onChange={selectedDate}
                {...{ customCAN, date }}
            />
        </PageLayout>
    );
};
