export enum PhraseKeys {
    // Buttons
    ADD_BUTTON = 'form.buttons.add',
    ADD_UNKNOWN_BUTTON = 'form.buttons.addUnknown',
    BACK_BUTTON = 'form.buttons.back',
    BACK_TO_START_BUTTON = 'form.buttons.backToStart',
    CANCEL_BUTTON = 'form.buttons.cancel',
    CLOSE_BUTTON = 'form.buttons.close',
    COMPLETED_BUTTON = 'button.completed',
    CONFIRM_BUTTON = 'form.buttons.confirm',
    CONTINUE_BUTTON = 'form.buttons.continue',
    DELETE_BUTTON = 'button.delete',
    DONT_KNOW = 'generic.select.dontKnow',
    EDIT_BUTTON = 'button.edit',
    LOOKUP_BUTTON = 'button.lookup',
    SELECT_BUTTON = 'button.select',
    SELECT_NO = 'generic.select.no',
    SELECT_YES = 'generic.select.yes',
    SEND_BUTTON = 'form.buttons.send',
    SUBMITTING_BUTTON = 'form.buttons.submitting',
    RETRY_BUTTON = 'button.retry',
    LEFT_ARROW = 'generic.arrow.left',
    RIGHT_ARROW = 'generic.arrow.right',
    RESET_BUTTON = 'generic.buttons.reset',
    UNDO_BUTTON = 'generic.buttons.undo',

    // Page layout standards
    PAGE_NAME = 'pageName',
    TITLE = 'heading.title',
    SUB_TITLE = 'heading.sub',
    HELP_TEXT = 'help.bottomHelpText',

    // Upload phrases
    DROPZONE_TITLE = 'dropzone.title',
    DROPZONE_DESCRIPTION = 'dropzone.description',

    // Involved vehicles info
    UNKNOWN_COUNTERPARTY = 'label.counterpartyVehicle.unknown',

    // Autocomplete phrases
    NO_OPTIONS_MESSAGE = 'autoComplete.noOptionsMessage',

    // Field phrases
    ADDRESS_LABEL = 'form.fields.address',
    ADDRESS_PLACEHOLDER = 'form.fields.addressPlaceholder',
    ACCIDENT_DESCRIPTION_LABEL = 'form.fields.accidentDescription',
    ACCIDENT_SKETCH_LABEL = 'form.fields.accidentSketch',
    ACCIDENT_DESCRIPTION_PLACEHOLDER = 'form.fields.accidentDescriptionPlaceholder',
    ACCOUNT_NUMBER_LABEL = 'form.fields.accountNumber',
    ACCOUNT_NUMBER_PLACEHOLDER = 'form.fields.accountNumberPlaceholder',
    ACCOUNT_REGISTRATION_NUMBER_LABEL = 'form.fields.accountRegistrationNumber',
    ACCOUNT_REGISTRATION_NUMBER_PLACEHOLDER = 'form.fields.accountRegistrationNumberPlaceholder',
    CHASSIS_NUMBER_LABEL = 'form.fields.chassisNumber',
    CHASSIS_NUMBER_PLACEHOLDER = 'form.fields.chassisNumberPlaceholder',
    CITY_LABEL = 'form.fields.city',
    CITY_PLACEHOLDER = 'form.fields.cityPlaceholder',
    CLAIM_CAUSE_LABEL = 'form.fields.claimCause',
    CLAIM_CAUSE_PLACEHOLDER = 'form.fields.claimCausePlaceholder',
    CLAIM_DESCRIPTION_LABEL = 'form.fields.claimDescription',
    CLAIM_DESCRIPTION_PLACEHOLDER = 'form.fields.claimDescriptionPlaceholder',
    CLAIM_NUMBER_LABEL = 'form.fields.claimNumber',
    CLAIM_NUMBER_PLACEHOLDER = 'form.fields.claimNumberPlaceholder',
    CLAIM_TYPE_LABEL = 'form.fields.claimType',
    CLAIM_TYPE_PLACEHOLDER = 'form.fields.claimTypePlaceholder',
    COMPANY_NAME_LABEL = 'form.fields.companyInformation.name',
    COMPANY_NAME_PLACEHOLDER = 'form.fields.companyInformation.namePlaceholder',
    COMPANY_BUSINESS_NUMBER_LABEL = 'form.fields.companyInformation.businessNumber',
    COMPANY_BUSINESS_NUMBER_PLACEHOLDER = 'form.fields.companyInformation.businessNumberPlaceholder',
    COMPANY_POLICY_NUMBER_LABEL = 'form.fields.companyInformation.policyNumber',
    COMPANY_POLICY_NUMBER_PLACEHOLDER = 'form.fields.companyInformation.policyNumberPlaceholder',
    COUNTERPARTY_EXTERNAL_REFERENCE_LABEL = 'form.fields.counterpartyExternalReference',
    COUNTERPARTY_EXTERNAL_REFERENCE_PLACEHOLDER = 'form.fields.counterpartyExternalReferencePlaceholder',
    COUNTERPARTY_INSURANCE_COMPANY_LABEL = 'form.fields.counterpartyInsuranceCompany',
    COUNTERPARTY_INSURANCE_COMPANY_PLACEHOLDER = 'form.fields.counterpartyInsuranceCompanyPlaceholder',
    COUNTRY_LABEL = 'form.fields.country',
    COUNTRY_PLACEHOLDER = 'form.fields.countryPlaceholder',
    DAMAGE_DESCRIPTION_LABEL = 'form.fields.damageDescriptionLabel',
    DAMAGE_DESCRIPTION_PLACEHOLDER = 'form.fields.damageDescriptionPlaceholder',
    DAMAGED_ITEM_LABEL = 'form.fields.damagedItem',
    DAMAGED_ITEM_PLACEHOLDER = 'form.fields.damagedItemPlaceholder',
    DATE_OF_BIRTH_LABEL = 'form.fields.dateOfBirth',
    DATE_OF_BIRTH_PLACEHOLDER = 'form.fields.dateOfBirthPlaceholder',
    DISTANCE_FROM_ROAD_SIDE_LABEL = 'form.fields.distanceFromRoadSide',
    DISTANCE_FROM_ROAD_SIDE_PLACEHOLDER = 'form.fields.distanceFromRoadSidePlaceholder',
    DOCTOR_NAME_LABEL = 'form.fields.doctorName',
    DOCTOR_NAME_PLACEHOLDER = 'form.fields.doctorNamePlaceholder',
    DRIVING_SPEED_LABEL = 'form.fields.drivingSpeed',
    DRIVING_SPEED_PLACEHOLDER = 'form.fields.drivingSpeedPlaceholder',
    EMAIL_LABEL = 'form.fields.email',
    EMAIL_PLACEHOLDER = 'form.fields.emailPlaceholder',
    FIRST_NAME_LABEL = 'form.fields.firstName',
    FIRST_NAME_PLACEHOLDER = 'form.fields.firstNamePlaceholder',
    HEALTH_INSURANCE_LABEL = 'form.fields.healthInsurance',
    HEALTH_INSURANCE_PLACEHOLDER = 'form.fields.healthInsurancePlaceholder',
    INJURY_INSURANCE_LABEL = 'form.fields.injuryInsurance',
    INJURY_INSURANCE_PLACEHOLDER = 'form.fields.injuryInsurancePlaceholder',
    INJURY_INSURANCE_EXTERNAL_REFERENCE_LABEL = 'form.fields.injuryInsuranceExternalReference',
    INJURY_INSURANCE_EXTERNAL_REFERENCE_PLACEHOLDER = 'form.fields.injuryInsuranceExternalReferencePlaceholder',
    IS_COMPANY_LABEL = 'form.fields.isCompany',
    IS_DRIVER_LABEL = 'form.fields.isDriver',
    IS_REPORTER_LABEL = 'form.fields.isReporter',
    IS_TOTAL_DAMAGE_LABEL = 'form.fields.isTotalDamage',
    IS_UNKNOWN_LABEL = 'form.fields.isUnknown',
    IS_UNKNOWN_LOCATION_LABEL = 'form.fields.isUnknownLocation',
    LAST_NAME_LABEL = 'form.fields.lastName',
    LAST_NAME_PLACEHOLDER = 'form.fields.lastNamePlaceholder',
    MAKE_LABEL = 'form.fields.make',
    MAKE_PLACEHOLDER = 'form.fields.makePlaceholder',
    MILEAGE_LABEL = 'form.fields.mileage',
    MILEAGE_PLACEHOLDER = 'form.fields.mileagePlaceholder',
    MISSING_ITEMS_LABEL = 'form.fields.missingItems',
    MISSING_ITEMS_PLACEHOLDER = 'form.fields.missingItemsPlaceholder',
    MODEL_LABEL = 'form.fields.model',
    MODEL_PLACEHOLDER = 'form.fields.modelPlaceholder',
    NATIONAL_IDENTITY_LABEL = 'form.fields.nationalIdentity',
    NATIONAL_IDENTITY_PLACEHOLDER = 'form.fields.nationalIdentityPlaceholder',
    NOTE_LABEL = 'form.fields.note',
    NUMBER_OF_PEOPLE_INJURED_LABEL = 'form.fields.numberOfPeopleInjured',
    NUMBER_OF_PEOPLE_INJURED_PLACEHOLDER = 'form.fields.numberOfPeopleInjuredPlaceholder',
    NUMBER_OF_PEOPLE_HOSPITALIZED_LABEL = 'form.fields.numberOfPeopleHospitalized',
    NUMBER_OF_PEOPLE_HOSPITALIZED_PLACEHOLDER = 'form.fields.numberOfPeopleHospitalizedPlaceholder',
    OTHER_ID_LABEL = 'form.fields.otherId',
    OTHER_ID_PLACEHOLDER = 'form.fields.otherIdPlaceholder',
    OTHER_INSURANCE_COMPANY_LABEL = 'form.fields.otherInsuranceCompany',
    OTHER_INSURANCE_COMPANY_PLACEHOLDER = 'form.fields.otherInsuranceCompanyPlaceholder',
    PASSENGERS_LABEL = 'form.fields.passengers',
    PASSENGERS_PLACEHOLDER = 'form.fields.passengersPlaceholder',
    PHONE_LABEL = 'form.fields.phone',
    PHONE_PLACEHOLDER = 'form.fields.phonePlaceholder',
    POLICE_CASE_NUMBER_LABEL = 'form.fields.policeCaseNumber',
    POLICE_CASE_NUMBER_PLACEHOLDER = 'form.fields.policeCaseNumberPlaceholder',
    POLICE_DISTRICT_LABEL = 'form.fields.policeDistrict',
    POLICE_DISTRICT_PLACEHOLDER = 'form.fields.policeDistrictPlaceholder',
    PROPERTY_ADDRESS_LABEL = 'form.fields.propertyAddress',
    PROPERTY_ADDRESS_PLACEHOLDER = 'form.fields.propertyAddressPlaceholder',
    PROPERTY_UNIT_LABEL = 'form.fields.propertyUnit',
    PROPERTY_UNIT_PLACEHOLDER = 'form.fields.propertyUnitPlaceholder',
    EXTERNAL_REFERENCE_LABEL = 'form.fields.externalReference',
    EXTERNAL_REFERENCE_PLACEHOLDER = 'form.fields.externalReferencePlaceholder',
    REG_NR_LABEL = 'form.fields.registrationNumber',
    REG_NR_PLACEHOLDER = 'form.fields.registrationNumberPlaceholder',
    RELATION_LABEL = 'form.fields.relation',
    RELATION_PLACEHOLDER = 'form.fields.relationPlaceholder',
    RELATION_TO_WITNESS_LABEL = 'form.fields.relationToWitness',
    RELATION_TO_WITNESS_PLACEHOLDER = 'form.fields.relationToWitnessPlaceholder',
    ROAD_WIDTH_LABEL = 'form.fields.roadWidth',
    ROAD_WIDTH_PLACEHOLDER = 'form.fields.roadWidthPlaceholder',
    SPEED_LIMIT_LABEL = 'form.fields.speedLimit',
    SPEED_LIMIT_PLACEHOLDER = 'form.fields.speedLimitPlaceholder',
    SPEED_ON_IMPACT_LABEL = 'form.fields.speedOnImpact',
    SPEED_ON_IMPACT_PLACEHOLDER = 'form.fields.speedOnImpactPlaceholder',
    STREET_LABEL = 'form.fields.street',
    STREET_PLACEHOLDER = 'form.fields.streetPlaceholder',
    THIRD_PARTY_SPEED_LABEL = 'form.fields.thirdPartySpeed',
    THIRD_PARTY_SPEED_PLACEHOLDER = 'form.fields.thirdPartySpeedPlaceholder',
    TIME_SINCE_ACTION_LABEL = 'form.fields.timeSinceAction',
    TIME_SINCE_ACTION_PLACEHOLDER = 'form.fields.timeSinceActionPlaceholder',
    TITLE_LABEL = 'form.fields.title',
    TITLE_PLACEHOLDER = 'form.fields.titlePlaceholder',
    TYPE_LABEL = 'form.fields.type',
    TYPE_PLACEHOLDER = 'form.fields.typePlaceholder',
    TYPE_OF_GLASS_DAMAGE_LABEL = 'form.fields.typeOfGlassDamage',
    TYPE_OF_GLASS_DAMAGE_PLACEHOLDER = 'form.fields.typeOfGlassDamagePlaceholder',
    TYPE_OF_INJURY_LABEL = 'form.fields.typeOfInjury',
    TYPE_OF_INJURY_PLACEHOLDER = 'form.fields.typeOfInjuryPlaceholder',
    VEHICLE_TYPE_LABEL = 'form.fields.vehicleType',
    VEHICLE_TYPE_PLACEHOLDER = 'form.fields.vehicleTypePlaceholder',
    VIN_NUMBER_PLACEHOLDER = 'form.fields.VINNumberPlaceholder',
    ZIP_LABEL = 'form.fields.zip',
    ZIP_PLACEHOLDER = 'form.fields.zipPlaceholder',
    // Status text
    BAD_REQUEST = 'statusText.badRequest',
    INTERNAL_SERVER_ERROR = 'statusText.internalServerError',
}
