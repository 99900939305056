import React, { SyntheticEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { wizardRouterActions as wizardActions } from 'sagas/wizardRouter';
import { useI18n } from '../../../../hooks/useI18n';
import {
    Clickable,
    FormChangeable,
    Grid,
    isEmpty,
    MissingItemsKeys,
    MuiAutocomplete,
    OptionType,
    PageLayout,
} from '@protectorinsurance/ds-can';
import { useGoBack } from '../../../../hooks/useGoBack';
import { PhraseKeys } from '../../../../config/phraseKeys';
import { getMissingItemsOption } from '../../../../utils/flow/missingItems';
import { FormFieldErrors, FormFieldNames } from '../../../../config/formFieldNames';
import dispatcherWithPromise from '../../../../utils/dispatcherWithPromise';
import { motorActions } from '../../../../sagas/motor';
import { commonActions } from '../../../../sagas/common';
import { MotorRoutePaths } from '../../../../config/wizardRouter/motorWizardRoutes';
import { selectMissingItems } from '../../../../sagas/selectors/motorSelectors';
import { selectCustomCAN } from '../../../../sagas/selectors/commonSelectors';

/**
 * Destructure necessary imports
 */
const {
    BACK_BUTTON,
    CONTINUE_BUTTON,
    HELP_TEXT,
    MISSING_ITEMS_LABEL,
    MISSING_ITEMS_PLACEHOLDER,
    NO_OPTIONS_MESSAGE,
    PAGE_NAME,
    SUB_TITLE,
    TITLE,
} = PhraseKeys;
const { MISSING_ITEMS } = FormFieldNames;
const { MISSING_ITEMS_REQUIRED } = FormFieldErrors;
const { PARKED_OTHER_MISSING_ITEMS } = MotorRoutePaths;
const { EXTRA_LIGHTS, LICENSE_PLATE, OTHER, STEERING_WHEEL, WHEELS } = MissingItemsKeys;

/**
 * Page view and page logic
 */
export const PTMissingItemsPage = () => {
    const dispatch = useDispatch();
    const [missingItems, setMissingItems] = useState<OptionType[]>([]);
    const [error, setError] = useState<string>('');
    const missingItemsInState = useSelector(selectMissingItems);
    const customCAN = useSelector(selectCustomCAN);
    const { t } = useI18n();
    const tWithNS = useI18n('motor.parked.missingItems');

    // Options
    const extraLightsOption = getMissingItemsOption(t, EXTRA_LIGHTS);
    const licensePlaceOption = getMissingItemsOption(t, LICENSE_PLATE);
    const otherOption = getMissingItemsOption(t, OTHER);
    const steeringWheelOption = getMissingItemsOption(t, STEERING_WHEEL);
    const wheelsOption = getMissingItemsOption(t, WHEELS);

    const options = [extraLightsOption, licensePlaceOption, otherOption, steeringWheelOption, wheelsOption];

    useEffect(() => {
        const selected = missingItemsInState.map(
            (item) => options.find((x) => x.value === (item as MissingItemsKeys)) || { value: '', label: '' }
        );
        if (Array.isArray(selected) && selected.length !== 0) {
            setMissingItems(selected);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [missingItemsInState]);

    const handleBackButton = useGoBack();

    const handleBlur = (e: FormChangeable) => {
        e.preventDefault();
        if (isEmpty(missingItems)) {
            setError(t(MISSING_ITEMS_REQUIRED));
        } else {
            setError('');
        }
    };

    const handleSelect = (e: SyntheticEvent, option: OptionType[]) => {
        const selected = option ? option : null;
        if (selected) {
            setMissingItems([...selected]);
        }
    };

    const handleContinueButton = (e: Clickable) => {
        e.preventDefault();
        if (isEmpty(missingItems)) {
            setError(t(MISSING_ITEMS_REQUIRED));
        } else {
            setError('');
            const items = missingItems.map((item) => item.value as MissingItemsKeys);
            const isOther = items.includes(OTHER);

            if (isOther) {
                dispatch(wizardActions.skipBackToPrev(true));
                dispatcherWithPromise(dispatch, motorActions.update, { missingItems: items })
                    .then(() => dispatcherWithPromise(dispatch, commonActions.send))
                    .then(() => dispatch(wizardActions.goTo(PARKED_OTHER_MISSING_ITEMS)));
            } else {
                dispatcherWithPromise(dispatch, motorActions.update, { missingItems: items })
                    .then(() => dispatcherWithPromise(dispatch, commonActions.send))
                    .then(() => dispatch(wizardActions.goToNext()));
            }
        }
    };

    return (
        <PageLayout
            backBtnText={t(BACK_BUTTON)}
            continueBtnText={t(CONTINUE_BUTTON)}
            domainTitle={t(PAGE_NAME)}
            footerText={tWithNS.t(HELP_TEXT)}
            headerSubTitle={tWithNS.t(SUB_TITLE)}
            headerTitle={tWithNS.t(TITLE)}
            {...{ handleBackButton, handleContinueButton }}
        >
            <Grid className={'align-center'}>
                <MuiAutocomplete
                    error={!!error}
                    errorMessage={error}
                    filterSelectedOptions={true}
                    getOptionLabel={(option: OptionType) => option.label}
                    id={MISSING_ITEMS}
                    inputFieldWrapper={'col-12'}
                    isOptionEqualToValue={(option: OptionType, value: OptionType) => option.value === value.value}
                    label={t(MISSING_ITEMS_LABEL)}
                    multiple={true}
                    noOptionsText={t(NO_OPTIONS_MESSAGE)}
                    onBlur={handleBlur}
                    onChange={handleSelect}
                    openOnFocus={true}
                    placeholder={t(MISSING_ITEMS_PLACEHOLDER)}
                    value={missingItems}
                    {...{ customCAN, options }}
                />
            </Grid>
        </PageLayout>
    );
};
