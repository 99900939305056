import React, { useRef } from 'react';
import { BodyKeys } from '@protectorinsurance/ds-can';
import { useMarkers } from '../../../hooks/useMarkers';
import { createOnClickAddDamagePoint } from '../../../utils/svgUtils';
import { DamageMarkers } from '../DamageMarker';
import { SvgImg } from '@protectorinsurance/ds-can';

/**
 * Destructure necessary imports
 */
const { BACK_BUMPER, BACK_RIGHT, CENTER_RIGHT, FRONT_BUMPER, FRONT_RIGHT, WINDSHIELD } = BodyKeys;

/**
 * Component view and component logic
 */
export const BusRightImage = ({
    addDamagePoint,
    removeDamagePoint,
    selectedDamagePoints,
    title,
    id = 'bus-right-image-svg',
    className,
}: SvgImg) => {
    const refSVG = useRef<SVGSVGElement>(null);
    const thisMarkers = useMarkers(selectedDamagePoints, id);
    const onClick = createOnClickAddDamagePoint(refSVG, addDamagePoint);

    return (
        <>
            <svg
                id={id}
                data-testid={id}
                className={'vehicle-img-svg right-image-svg'}
                ref={refSVG}
                width="1615"
                height="556"
                viewBox="0 0 1615 556"
                aria-labelledby={`${id}-title`}
                role="img"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <title id={`${id}-title`}>{title}</title>
                <g className={'right-image-svg__selectable'}>
                    <path
                        id={BACK_RIGHT}
                        onClick={onClick}
                        d="M4.99998 555.5C2.5147 555.5 0.5 553.485 0.5 551L0.500007 0.5L469.601 0.500006L469.601 555.5L4.99998 555.5Z"
                        fill="#FFFFFF"
                        stroke="#7D8890"
                    />
                    <path
                        id={FRONT_RIGHT}
                        onClick={onClick}
                        d="M1610 555.5C1612.49 555.5 1614.5 553.485 1614.5 551L1614.5 0.5L1145.4 0.499994L1145.4 555.5L1610 555.5Z"
                        fill="#FFFFFF"
                        stroke="#7D8890"
                    />
                    <path
                        id={CENTER_RIGHT}
                        onClick={onClick}
                        d="M475.101 555.5C472.616 555.5 470.601 553.485 470.601 551L470.601 0.5L1144.4 0.500008L1144.4 555.5L475.101 555.5Z"
                        fill="#FFFFFF"
                        stroke="#7D8890"
                    />
                    <rect
                        id={BACK_BUMPER}
                        onClick={onClick}
                        x="0"
                        y="0"
                        width="80"
                        height="556"
                        fill="#FFFFFF"
                        stroke="#7D8890"
                    />
                    <rect
                        id={FRONT_BUMPER}
                        onClick={onClick}
                        x="1525"
                        y="110"
                        width="90"
                        height="225"
                        fill="#FFFFFF"
                        stroke="#7D8890"
                    />
                    <rect
                        id={WINDSHIELD}
                        onClick={onClick}
                        x="1525"
                        y="360"
                        width="90"
                        height="130"
                        fill="#FFFFFF"
                        stroke="#7D8890"
                    />
                </g>
                <g className={`right-image-svg__content ${className}`}>
                    <path
                        d="M154.5 65.5002H80.5C44.5 65.5002 30 85.0002 30 109.5V431C30 441 33.5 447 42.5 451L131 471H406C406 471 406 393 486 393C562 393 563.5 471 563.5 471H1162.5C1162.5 471 1162.5 393 1241 393C1319.5 393 1321.5 471 1321.5 471H1582C1594 468 1597.5 454 1595 439.5L1585.83 395.591C1585.64 394.664 1584.82 394 1583.87 394H1578C1574 394 1574 394 1574 386.5L1571.16 331.5M154.5 65.5002V41.0001H631.5V65.5002M154.5 65.5002H631.5M631.5 65.5002H931.5M931.5 65.5002V47.0001H1072.5V65.5002M931.5 65.5002H1072.5M1072.5 65.5002H1331.5M1331.5 65.5002V47.0001H1469.5V65.5002M1331.5 65.5002H1469.5M1469.5 65.5002H1539C1549.07 65.3531 1554.19 66.783 1562 73.0002L1570.5 97.5002C1573.2 108.305 1572.82 112.942 1564.5 115.5H1560L1560.65 128M1571.16 331.5L1536 309V128L1560.65 128M1571.16 331.5L1560.65 128M819 128H710.5C709.948 128 709.5 128.448 709.5 129V452C709.5 452.552 709.948 453 710.5 453H819M819 128H927.5C928.052 128 928.5 128.448 928.5 129V452C928.5 452.552 928.052 453 927.5 453H819M819 128V453M279 128H170.5C169.948 128 169.5 128.448 169.5 129V452C169.5 452.552 169.948 453 170.5 453H279M279 128H387.5C388.052 128 388.5 128.448 388.5 129V452C388.5 452.552 388.052 453 387.5 453H279M279 128V453M71.5 331H155.5V128H71.5V331ZM402.5 331V128L693.5 128V331H402.5ZM942.5 331H1126.5V128H942.5V331ZM1140.5 331H1324.5V128H1140.5V331ZM554.5 469.5C554.5 507.884 523.384 539 485 539C446.616 539 415.5 507.884 415.5 469.5C415.5 431.116 446.616 400 485 400C523.384 400 554.5 431.116 554.5 469.5ZM1311.5 469.5C1311.5 507.884 1280.38 539 1242 539C1203.62 539 1172.5 507.884 1172.5 469.5C1172.5 431.116 1203.62 400 1242 400C1280.38 400 1311.5 431.116 1311.5 469.5ZM1498 453H1358.5C1357.95 453 1357.5 452.552 1357.5 452V129C1357.5 128.448 1357.95 128 1358.5 128L1498 128C1498.55 128 1499 128.448 1499 129V452C1499 452.552 1498.55 453 1498 453Z"
                        stroke="black"
                        strokeWidth="3.6"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </g>
                <DamageMarkers removeDamagePoint={removeDamagePoint} selectedDamagePoints={thisMarkers} />
            </svg>
        </>
    );
};
