import React from 'react';
import { wizardRouterActions as wizardActions } from '../../../../../../sagas/wizardRouter';
import {
    CarouselConfigLookupResponse,
    CarouselConfigOptionType,
    Clickable,
    is,
    MotorClaimCauseKeys,
    RighOfWayIntersectionIcon,
    RoundAboutIcon,
    TSectionIcon,
} from '@protectorinsurance/ds-can';
import { CarouselConfigLookupProps } from '../../../../../../utils/carouselPageLookup';
import dispatcherWithPromise from '../../../../../../utils/dispatcherWithPromise';
import { commonActions } from '../../../../../../sagas/common';
import { MotorRoutePaths } from '../../../../../wizardRouter/motorWizardRoutes';
import { selectRightOfWayType } from '../../../../../../sagas/selectors/motorSelectors';
import { motorActions } from '../../../../../../sagas/motor';
import { flowActions } from '../../../../../../sagas/flow';

/**
 * Destructure necessary imports
 */
const { RIGHT_DUTY, UNCONDITIONAL_RIGHT_OF_WAY, UNCONDITIONAL_RIGHT_OF_WAY_ROUNDABOUT } = MotorClaimCauseKeys;

/**
 * Page view and page logic
 */
export const drivingVehiclesRightOfWayTypeOfRightOfWayPage = ({
    t,
    state,
    dispatch,
}: CarouselConfigLookupProps): CarouselConfigLookupResponse<MotorRoutePaths> => {
    const rightOfWayType = selectRightOfWayType(state);
    const options = [
        {
            id: RIGHT_DUTY,
            name: t(`motor.driving.rightOfWayType.select.${RIGHT_DUTY}`),
            icon: <RighOfWayIntersectionIcon />,
        },
        {
            id: UNCONDITIONAL_RIGHT_OF_WAY,
            name: t(`motor.driving.rightOfWayType.select.${UNCONDITIONAL_RIGHT_OF_WAY}`),
            icon: <TSectionIcon />,
        },
        {
            id: UNCONDITIONAL_RIGHT_OF_WAY_ROUNDABOUT,
            name: t(`motor.driving.rightOfWayType.select.${UNCONDITIONAL_RIGHT_OF_WAY_ROUNDABOUT}`),
            icon: <RoundAboutIcon />,
        },
    ];

    return {
        slug: MotorRoutePaths.DYN_CAROUSEL_DRIVING_VEHICLES_RIGHT_OF_WAY_TYPE,
        stateKey: 'rightOfWayType',
        i18n: {
            ns: 'motor.driving.rightOfWayType',
        },
        selectorValue: selectRightOfWayType(state),
        options,
        onSelect: (id: CarouselConfigOptionType, e: Clickable) => {
            e.preventDefault();
            const option = options?.find((x) => x.id === id);

            if (option) {
                if (!is(option.id, rightOfWayType)) {
                    dispatch(wizardActions.skipBackToPrev(true));
                }

                dispatcherWithPromise(dispatch, motorActions.update, { rightOfWayType: option.id })
                    .then(() => dispatcherWithPromise(dispatch, flowActions.update, option.id))
                    .then(() => dispatcherWithPromise(dispatch, commonActions.send))
                    .then(() => dispatch(wizardActions.goToNext(option.id)));
            }
        },
    };
};
