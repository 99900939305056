import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectVehicles } from '../../../sagas/selectors/motorSelectors';
import {
    Clickable,
    FormChangeable,
    Grid,
    initVehicleModel,
    MuiTextInput,
    Nullable,
    PageLayout,
    VehicleInputListItem,
    VehicleModel,
} from '@protectorinsurance/ds-can';
import { useI18n } from '../../../hooks/useI18n';
import { useGoBack } from '../../../hooks/useGoBack';
import { PhraseKeys } from '../../../config/phraseKeys';
import { TEST_ID_PREFIX } from '../../../utils/testIdUtil';
import { FormFieldErrors, FormFieldNames } from '../../../config/formFieldNames';
import { Controller, useForm } from 'react-hook-form';
import dispatcherWithPromise from '../../../utils/dispatcherWithPromise';
import { motorActions } from '../../../sagas/motor';
import { commonActions } from '../../../sagas/common';
import { wizardRouterActions as wizardActions } from '../../../sagas/wizardRouter';
import { selectCustomCAN } from '../../../sagas/selectors/commonSelectors';

/**
 * Destructure necessary imports
 */
const {
    BACK_BUTTON,
    CONTINUE_BUTTON,
    HELP_TEXT,
    PAGE_NAME,
    PASSENGERS_LABEL,
    PASSENGERS_PLACEHOLDER,
    SUB_TITLE,
    TITLE,
} = PhraseKeys;
const { vehicleList } = TEST_ID_PREFIX;
const { PASSENGERS } = FormFieldNames;
const { NO_NEGATIVE_NUMBERS, REQUIRED_ERROR_MESSAGE } = FormFieldErrors;

/**
 * Page view and page logic
 */
export const PassengersPage = () => {
    const dispatch = useDispatch();
    const vehicles = useSelector(selectVehicles);
    const customCAN = useSelector(selectCustomCAN);
    const { t } = useI18n();
    const tWithNS = useI18n('motor.end.passengers');
    const {
        clearErrors,
        control,
        formState: { errors },
        getValues,
        setError,
        setValue,
    } = useForm();

    useEffect(() => {
        vehicles.forEach((vehicle: VehicleModel, idx: number) => {
            setValue(`${PASSENGERS}-${idx}`, vehicle.passengers);
        });
    });

    const handleBackButton = useGoBack();

    const handleBlur = (selectedVehicleId: Nullable<string>, idx: number, value: string, e?: FormChangeable) => {
        e?.preventDefault();
        if (value === '' || value === null) {
            setError(`${PASSENGERS}-${idx}`, { type: 'manual', message: `${t(REQUIRED_ERROR_MESSAGE)}` });
        }
    };

    const handleChange = (selectedVehicleId: Nullable<string>, idx: number, value: string, e?: FormChangeable) => {
        e?.preventDefault();
        if (Number(value) < 0) {
            setError(`${PASSENGERS}-${idx}`, { type: 'manual', message: `${t(NO_NEGATIVE_NUMBERS)}` });
        } else {
            clearErrors(`${PASSENGERS}-${idx}`);
        }
        const vehiclesList = [...vehicles];
        const vehicle = vehicles.find((v: VehicleModel) => v.internalId === selectedVehicleId);
        const vehicleIndex = vehicles.findIndex((v: VehicleModel) => v.internalId === selectedVehicleId);
        vehiclesList[vehicleIndex] = {
            ...initVehicleModel,
            ...vehicle,
            passengers: Number(value),
        };
        dispatcherWithPromise(dispatch, motorActions.update, { vehicles: vehiclesList }).then(() =>
            dispatcherWithPromise(dispatch, commonActions.send)
        );
    };

    const handleContinueButton = (e: Clickable) => {
        e.preventDefault();
        const values = getValues();
        const objects = Object.entries(values).map(([key, value]) => ({ key, value }));
        const hasErrors = objects.filter((error) => error.value === '');
        const isErrorsEmpty = Object.keys(errors).length === 0 && errors.constructor === Object;
        if (isErrorsEmpty) {
            if (hasErrors.length > 0) {
                hasErrors.forEach((item) => {
                    setError(item.key, { type: 'manual', message: `${t(REQUIRED_ERROR_MESSAGE)}` });
                });
            } else {
                dispatcherWithPromise(dispatch, commonActions.send).then(() => dispatch(wizardActions.goToNext()));
            }
        }
    };

    return (
        <PageLayout
            backBtnText={t(BACK_BUTTON)}
            continueBtnText={t(CONTINUE_BUTTON)}
            domainTitle={t(PAGE_NAME)}
            footerText={tWithNS.t(HELP_TEXT)}
            headerSubTitle={tWithNS.t(SUB_TITLE)}
            headerTitle={tWithNS.t(TITLE)}
            {...{ handleBackButton, handleContinueButton }}
        >
            <Grid className={'align-center'}>
                {vehicles.map((vehicle: VehicleModel, idx: number) => {
                    const { internalId, registrationNumber, vehicleType } = vehicle;
                    return (
                        <VehicleInputListItem
                            dataTestId={`${vehicleList}-${idx}`}
                            key={idx}
                            {...{ registrationNumber, vehicleType }}
                        >
                            <Grid className={'align-center'}>
                                <Controller
                                    control={control}
                                    name={`${PASSENGERS}-${idx}`}
                                    render={({ field: { ref, ...field } }) => (
                                        <MuiTextInput
                                            {...field}
                                            error={!!errors[`${PASSENGERS}-${idx}`]}
                                            errorMessage={errors[`${PASSENGERS}-${idx}`]?.message?.toString()}
                                            id={`${PASSENGERS}-${idx}`}
                                            inputFieldWrapper={'col-6'}
                                            label={t(PASSENGERS_LABEL)}
                                            onBlur={({ target: { value } }) => handleBlur(internalId, idx, value)}
                                            onChange={({ target: { value } }) => handleChange(internalId, idx, value)}
                                            placeholder={t(PASSENGERS_PLACEHOLDER)}
                                            reference={ref}
                                            type={'number'}
                                            {...{ customCAN }}
                                        />
                                    )}
                                />
                            </Grid>
                        </VehicleInputListItem>
                    );
                })}
            </Grid>
        </PageLayout>
    );
};
