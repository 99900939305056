import CANError from './CANError';
import { UploadActionTypes } from '../sagas/upload';
import { AxiosError } from 'axios';
import { HTTPResponseStatus } from './CANRequestError';

export enum FileStatusEnums {
    CREATED = 'CREATED',
    PASSED = 'PASSED',
    PENDING = 'PENDING',
    FAILED = 'FAILED',
    ERROR = 'ERROR',
}

export default interface FileModel {
    id: string;
    name: string;
    type: string;
    size: number;
    status: FileStatusEnums;
    externalId?: string;
    src: {
        file: File;
    };
    errors: CANError[];
}

export const createFileError = (data: FileModel, type: UploadActionTypes, error: AxiosError | Error): FileModel => {
    let e;
    if ((error as AxiosError).response) {
        // @ts-ignore
        e = { ...(error as AxiosError).response?.data.error };
    } else if (error instanceof TypeError) {
        e = {
            message: error.message,
            status: HTTPResponseStatus.BAD_REQUEST.code,
            statusText: HTTPResponseStatus.BAD_REQUEST.text,
        };
    } else {
        e = { ...error };
    }

    return {
        ...data,
        status: FileStatusEnums.ERROR,
        errors: [
            ...data.errors,
            {
                type,
                error: e,
            },
        ],
    };
};
