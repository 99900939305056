import { OtherInsuranceCompanyEnums } from '@protectorinsurance/ds-can';
import { TFunction } from 'i18next';

/**
 * Utility logic
 */
const createOtherInsuranceKey = (id: string) => {
    return `lpo.otherInsurance.select.${id}`;
};

export const getOtherInsuranceOption = (t: TFunction, key: OtherInsuranceCompanyEnums) => {
    return {
        label: t(createOtherInsuranceKey(key)),
        value: key,
    };
};
