import React, { FC } from 'react';
import { Route, Switch } from 'react-router-dom';
import { LpoRoutePaths } from '../config/wizardRouter/lpoWizardRoutes';

// Dynamic Imports
import { DynCarouselPage } from 'pages/DynCarouselPage';

// Start Imports
import { StartOnboardingPage } from '../pages/lpo/start/Onboarding';
import { StartPrivacyPage } from '../pages/lpo/start/Privacy';
import { StartClaimDatePage } from '../pages/lpo/start/ClaimDate';
import { StartAccidentLocationPage } from '../pages/lpo/start/AccidentLocation';
import { StartPropertyAddressPage } from '../pages/lpo/start/PropertyAddress';

// Liability Imports
import { LiabilityClaimTypePage } from '../pages/lpo/liability/ClaimType';
import { LiabilityDamageClaimDescriptionPage } from '../pages/lpo/liability/DamageClaimDescription';
import { LiabilityClaimantInformationPage } from '../pages/lpo/liability/ClaimantInformation';
import { LiabilityClaimDescriptionPage } from '../pages/lpo/liability/ClaimDescription';
import { LiabilityInjuredClaimDescriptionPage } from '../pages/lpo/liability/InjuredClaimDescription';

// Property Imports
import { PropertyClaimTypePage } from '../pages/lpo/property/ClaimType';

// Transport Imports
import { TransportClaimTypePage } from '../pages/lpo/transport/ClaimType';
import { TransportVehicleRegistrationNumberPage } from '../pages/lpo/transport/VehicleRegistrationNumber';

// Glass Imports
import { GlassRequirementPage } from '../pages/lpo/glass/Requirement';
import { GlassClaimDatePage } from '../pages/lpo/glass/ClaimDate';
import { GlassClaimDescriptionPage } from '../pages/lpo/glass/ClaimDescription';
import { GlassHealthInsurancePage } from '../pages/lpo/glass/HealthInsurance';
import { GlassClaimantInformationPage } from '../pages/lpo/glass/ClaimantInformation';
import { GlassTypeOfGlassDamagePage } from '../pages/lpo/glass/TypeOfGlassDamage';
import { GlassBankAccountInformationPage } from '../pages/lpo/glass/BankAccountInformation';

// Group Accident Imports
import { GroupAccidentAccidentLocationPage } from '../pages/lpo/groupAccident/AccidentLocation';
import { GroupAccidentPoliceDistrictPage } from '../pages/lpo/groupAccident/PoliceDistrict';
import { GroupAccidentPoliceCaseNumberPage } from '../pages/lpo/groupAccident/PoliceCaseNumber';
import { GroupAccidentCounterpartyInsuranceCompanyPage } from '../pages/lpo/groupAccident/CounterpartyInsuranceCompany';
import { GroupAccidentCounterpartyExternalReferencePage } from '../pages/lpo/groupAccident/CounterpartyExternalReference';
import { GroupAccidentInjuryInsurancePage } from '../pages/lpo/groupAccident/InjuryInsurance';
import { GroupAccidentInjuryInsuranceExternalReferencePage } from '../pages/lpo/groupAccident/InjuryInsuranceExternalReference';
import { GroupAccidentGuardianInformationPage } from '../pages/lpo/groupAccident/GuardianInformation';
import { GroupAccidentClaimCausePage } from '../pages/lpo/groupAccident/ClaimCause';
import { GroupAccidentDateOfTreatmentPage } from '../pages/lpo/groupAccident/DateOfTreatment';
import { GroupAccidentDoctorInformationPage } from '../pages/lpo/groupAccident/DoctorInformation';
import { GroupAccidentTypeOfInjuryPage } from '../pages/lpo/groupAccident/TypeOfInjury';
import { GroupAccidentDateOfPreviousInjuryPage } from '../pages/lpo/groupAccident/DateOfPreviousInjury';

// End Imports
import { EndClaimDescriptionPage } from '../pages/lpo/end/ClaimDescription';
import { EndUploadAttachmentPage } from '../pages/lpo/end/UploadAttachment';
import { EndOtherInsuranceCompanyPage } from '../pages/lpo/end/OtherInsuranceCompany';
import { EndExternalReferencePage } from 'pages/lpo/end/ExternalReference';
import { EndPolicyHolderPage } from '../pages/lpo/end/PolicyHolder';
import { EndReporterInformationPage } from '../pages/lpo/end/ReporterInformation';
import { EndPolicyHoldersContactPage } from '../pages/lpo/end/PolicyHoldersContact';
import { EndFinalSummaryPage } from '../pages/lpo/end/FinalSummary';
import { EndReportCompletedPage } from '../pages/lpo/end/ReportCompleted';

export const Lpo: FC = () => {
    return (
        <Switch>
            {/* DYN */}
            <Route exact path={LpoRoutePaths.DYN_CAROUSEL} component={DynCarouselPage} />

            {/* START */}
            <Route exact path={LpoRoutePaths.INDEX} component={StartOnboardingPage} />
            <Route exact path={LpoRoutePaths.START_ONBOARDING} component={StartOnboardingPage} />
            <Route exact path={LpoRoutePaths.START_PRIVACY} component={StartPrivacyPage} />
            <Route exact path={LpoRoutePaths.START_CLAIM_DATE} component={StartClaimDatePage} />
            <Route exact path={LpoRoutePaths.START_ACCIDENT_LOCATION} component={StartAccidentLocationPage} />
            <Route exact path={LpoRoutePaths.START_PROPERTY_ADDRESS} component={StartPropertyAddressPage} />

            {/* LIABILITY */}
            <Route exact path={LpoRoutePaths.LIABILITY_CLAIM_TYPE} component={LiabilityClaimTypePage} />
            <Route
                exact
                path={LpoRoutePaths.LIABILITY_DAMAGE_CLAIM_DESCRIPTION}
                component={LiabilityDamageClaimDescriptionPage}
            />
            <Route
                exact
                path={LpoRoutePaths.LIABILITY_CLAIMANT_INFORMATION}
                component={LiabilityClaimantInformationPage}
            />
            <Route exact path={LpoRoutePaths.LIABILITY_CLAIM_DESCRIPTION} component={LiabilityClaimDescriptionPage} />
            <Route
                exact
                path={LpoRoutePaths.LIABILITY_INJURED_CLAIM_DESCRIPTION}
                component={LiabilityInjuredClaimDescriptionPage}
            />

            {/* PROPERTY */}
            <Route exact path={LpoRoutePaths.PROPERTY_CLAIM_TYPE} component={PropertyClaimTypePage} />

            {/* TRANSPORT */}
            <Route exact path={LpoRoutePaths.TRANSPORT_CLAIM_TYPE} component={TransportClaimTypePage} />
            <Route
                exact
                path={LpoRoutePaths.TRANSPORT_VEHICLE_REGISTRATION_NUMBER}
                component={TransportVehicleRegistrationNumberPage}
            />

            {/* GLASS */}
            <Route exact path={LpoRoutePaths.GLASS_REQUIREMENT} component={GlassRequirementPage} />
            <Route exact path={LpoRoutePaths.GLASS_CLAIM_DATE} component={GlassClaimDatePage} />
            <Route exact path={LpoRoutePaths.GLASS_OTHER_CLAIM_LOCATION} component={GlassClaimDescriptionPage} />
            <Route exact path={LpoRoutePaths.GLASS_HEALTH_INSURANCE} component={GlassHealthInsurancePage} />
            <Route exact path={LpoRoutePaths.GLASS_CLAIMANT_INFORMATION} component={GlassClaimantInformationPage} />
            <Route exact path={LpoRoutePaths.GLASS_TYPE_OF_GLASS_DAMAGE} component={GlassTypeOfGlassDamagePage} />
            <Route
                exact
                path={LpoRoutePaths.GLASS_BANK_ACCOUNT_INFORMATION}
                component={GlassBankAccountInformationPage}
            />

            {/* GROUP ACCIDENT */}
            <Route
                exact
                path={LpoRoutePaths.GROUP_ACCIDENT_ACCIDENT_LOCATION}
                component={GroupAccidentAccidentLocationPage}
            />
            <Route
                exact
                path={LpoRoutePaths.GROUP_ACCIDENT_POLICE_DISTRICT}
                component={GroupAccidentPoliceDistrictPage}
            />
            <Route
                exact
                path={LpoRoutePaths.GROUP_ACCIDENT_POLICE_CASE_NUMBER}
                component={GroupAccidentPoliceCaseNumberPage}
            />
            <Route
                exact
                path={LpoRoutePaths.GROUP_ACCIDENT_COUNTERPARTY_INSURANCE_COMPANY}
                component={GroupAccidentCounterpartyInsuranceCompanyPage}
            />
            <Route
                exact
                path={LpoRoutePaths.GROUP_ACCIDENT_COUNTERPARTY_EXTERNAL_REFERENCE}
                component={GroupAccidentCounterpartyExternalReferencePage}
            />
            <Route
                exact
                path={LpoRoutePaths.GROUP_ACCIDENT_INJURY_INSURANCE}
                component={GroupAccidentInjuryInsurancePage}
            />
            <Route
                exact
                path={LpoRoutePaths.GROUP_ACCIDENT_INJURY_INSURANCE_EXTERNAL_REFERENCE}
                component={GroupAccidentInjuryInsuranceExternalReferencePage}
            />
            <Route
                exact
                path={LpoRoutePaths.GROUP_ACCIDENT_GUARDIAN_INFORMATION}
                component={GroupAccidentGuardianInformationPage}
            />
            <Route exact path={LpoRoutePaths.GROUP_ACCIDENT_CLAIM_CAUSE} component={GroupAccidentClaimCausePage} />
            <Route
                exact
                path={LpoRoutePaths.GROUP_ACCIDENT_DATE_OF_TREATMENT}
                component={GroupAccidentDateOfTreatmentPage}
            />
            <Route
                exact
                path={LpoRoutePaths.GROUP_ACCIDENT_DOCTOR_INFORMATION}
                component={GroupAccidentDoctorInformationPage}
            />
            <Route exact path={LpoRoutePaths.GROUP_ACCIDENT_TYPE_OF_INJURY} component={GroupAccidentTypeOfInjuryPage} />
            <Route
                exact
                path={LpoRoutePaths.GROUP_ACCIDENT_DATE_OF_PREVIOUS_INJURY}
                component={GroupAccidentDateOfPreviousInjuryPage}
            />

            {/* END */}
            <Route exact path={LpoRoutePaths.END_CLAIM_DESCRIPTION} component={EndClaimDescriptionPage} />
            <Route exact path={LpoRoutePaths.END_UPLOAD_ATTACHMENT} component={EndUploadAttachmentPage} />
            <Route exact path={LpoRoutePaths.END_OTHER_INSURANCE_COMPANY} component={EndOtherInsuranceCompanyPage} />
            <Route exact path={LpoRoutePaths.END_EXTERNAL_REFERENCE} component={EndExternalReferencePage} />
            <Route exact path={LpoRoutePaths.END_POLICY_HOLDER} component={EndPolicyHolderPage} />
            <Route exact path={LpoRoutePaths.END_REPORTER_INFORMATION} component={EndReporterInformationPage} />
            <Route exact path={LpoRoutePaths.END_POLICY_HOLDERS_CONTACT} component={EndPolicyHoldersContactPage} />
            <Route exact path={LpoRoutePaths.END_FINAL_SUMMARY} component={EndFinalSummaryPage} />
            <Route exact path={LpoRoutePaths.END_REPORT_COMPLETED} component={EndReportCompletedPage} />
        </Switch>
    );
};
