import React, { SyntheticEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Clickable,
    FormChangeable,
    Grid,
    is,
    LpoClaimCauseEnums,
    MuiAutocomplete,
    OptionType,
    PageLayout,
} from '@protectorinsurance/ds-can';
import { selectTypeOfInjury } from '../../../sagas/selectors/lpoSelectors';
import { useI18n } from '../../../hooks/useI18n';
import { PhraseKeys } from '../../../config/phraseKeys';
import { useGoBack } from '../../../hooks/useGoBack';
import { FormFieldErrors, FormFieldNames } from '../../../config/formFieldNames';
import dispatcherWithPromise from '../../../utils/dispatcherWithPromise';
import { lpoActions } from '../../../sagas/lpo';
import { wizardRouterActions } from '../../../sagas/wizardRouter';
import { commonActions } from '../../../sagas/common';
import { getClaimCauseOption } from '../../../utils/lpo/claimCauseUtils';
import { selectCustomCAN } from '../../../sagas/selectors/commonSelectors';

/**
 * Destructure necessary imports
 */
const {
    BACK_BUTTON,
    CONTINUE_BUTTON,
    HELP_TEXT,
    NO_OPTIONS_MESSAGE,
    PAGE_NAME,
    SUB_TITLE,
    TITLE,
    TYPE_OF_INJURY_LABEL,
    TYPE_OF_INJURY_PLACEHOLDER,
} = PhraseKeys;
const { REQUIRED_ERROR_MESSAGE } = FormFieldErrors;
const { TYPE_OF_INJURY } = FormFieldNames;
const {
    CHEMICALS,
    CRASH_INJURY,
    CRUSH_INJURY,
    CUT,
    FALL,
    FIRE,
    LIFTING_INJURY,
    OTHER,
    SHOCK,
    TEMPERATURE_INJURY,
    VIOLENCE,
} = LpoClaimCauseEnums;

/**
 * Page view and page logic
 */
export const GroupAccidentTypeOfInjuryPage = () => {
    const dispatch = useDispatch();
    const [injury, setInjury] = useState<OptionType | null>(null);
    const [error, setError] = useState<string>('');
    const typeOfInjury = useSelector(selectTypeOfInjury);
    const customCAN = useSelector(selectCustomCAN);
    const { t } = useI18n();
    const tWithNS = useI18n('lpo.groupAccident.typeOfInjury');

    // Options
    const chemicalsOption = getClaimCauseOption(t, CHEMICALS);
    const crashInjuryOption = getClaimCauseOption(t, CRASH_INJURY);
    const crushInjuryOption = getClaimCauseOption(t, CRUSH_INJURY);
    const cutOption = getClaimCauseOption(t, CUT);
    const fallOption = getClaimCauseOption(t, FALL);
    const fireOption = getClaimCauseOption(t, FIRE);
    const liftingInjuryOption = getClaimCauseOption(t, LIFTING_INJURY);
    const otherOption = getClaimCauseOption(t, OTHER);
    const shockOption = getClaimCauseOption(t, SHOCK);
    const temperatureInjuryOption = getClaimCauseOption(t, TEMPERATURE_INJURY);
    const violenceOption = getClaimCauseOption(t, VIOLENCE);

    const options = [
        fallOption,
        liftingInjuryOption,
        crashInjuryOption,
        crushInjuryOption,
        cutOption,
        violenceOption,
        shockOption,
        temperatureInjuryOption,
        chemicalsOption,
        fireOption,
        otherOption,
    ];

    useEffect(() => {
        const selected = options.find((x) => x.value === typeOfInjury);
        if (selected) {
            setInjury(selected);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [typeOfInjury, setInjury]);

    const handleBackButton = useGoBack();

    const handleBlur = (e: FormChangeable) => {
        e.preventDefault();
        if (is(typeOfInjury, null)) {
            setError(t(REQUIRED_ERROR_MESSAGE));
        } else {
            setError('');
        }
    };

    const handleSelect = (e: SyntheticEvent, option: OptionType) => {
        const object = option ? option : null;
        const value = object ? object.value : null;
        setError('');
        setInjury(object);
        dispatch(lpoActions.update({ typeOfInjury: value as LpoClaimCauseEnums }));
    };

    const handleContinueButton = (e: Clickable) => {
        e.preventDefault();
        if (is(typeOfInjury, null)) {
            setError(t(REQUIRED_ERROR_MESSAGE));
        } else {
            setError('');
            dispatcherWithPromise(dispatch, commonActions.send).then(() => dispatch(wizardRouterActions.goToNext()));
        }
    };

    return (
        <PageLayout
            backBtnText={t(BACK_BUTTON)}
            continueBtnText={t(CONTINUE_BUTTON)}
            domainTitle={t(PAGE_NAME)}
            footerText={tWithNS.t(HELP_TEXT)}
            headerSubTitle={tWithNS.t(SUB_TITLE)}
            headerTitle={tWithNS.t(TITLE)}
            {...{ handleBackButton, handleContinueButton }}
        >
            <Grid className={'align-center'}>
                <MuiAutocomplete
                    error={!!error}
                    errorMessage={error}
                    id={TYPE_OF_INJURY}
                    inputFieldWrapper={'col-12'}
                    label={t(TYPE_OF_INJURY_LABEL)}
                    noOptionsText={t(NO_OPTIONS_MESSAGE)}
                    onBlur={handleBlur}
                    onChange={handleSelect}
                    openOnFocus={true}
                    placeholder={t(TYPE_OF_INJURY_PLACEHOLDER)}
                    value={injury}
                    {...{ customCAN, options }}
                />
            </Grid>
        </PageLayout>
    );
};
