import React from 'react';
import {
    ClaimantInformationListModel,
    ClaimantInformationListTypeModel,
    Clickable,
    is,
    isYes,
    LobKeys,
    Nullable,
    SummaryNavButton,
    SummaryText,
    YesNoModel,
} from '@protectorinsurance/ds-can';

/**
 * Destructure necessary imports
 */
const { LIABILITY } = LobKeys;

/**
 * Component view and component logic
 */
export interface SummaryClaimantInformationListProps {
    list: ClaimantInformationListTypeModel;
    textKey: string;
    path: string;
    show: boolean;
    dataTestId: string;
    className: string;
    handleClick?: (e: Clickable, path: string) => void;
}

export const renderClaimantInformation = (
    { list, path, textKey, dataTestId, className, handleClick }: SummaryClaimantInformationListProps,
    lob: Nullable<LobKeys>,
    isPersonInjury: YesNoModel
) => {
    const personButtonText = (model: ClaimantInformationListModel) => {
        const { firstName, lastName, email, phone } = model;
        return `${firstName} ${lastName}${email ? `, ${email}` : ''}${phone ? `, ${phone}` : ''}`;
    };
    if (is(lob, LIABILITY) && isYes(isPersonInjury)) {
        return (
            <div className={className}>
                <SummaryText text={textKey} />
                {list.map((item: ClaimantInformationListModel, idx: number) => {
                    return (
                        <div key={idx} className={'claimant-list'}>
                            <SummaryNavButton
                                dataTestId={dataTestId}
                                path={path}
                                buttonText={personButtonText(item)}
                                handleClick={handleClick}
                            />
                        </div>
                    );
                })}
            </div>
        );
    }

    return <></>;
};
