import React from 'react';
import { useDispatch } from 'react-redux';
import { useI18n } from '../../hooks/useI18n';
import { Clickable, DisplayError, PageLayout, TimeoutIcon } from '@protectorinsurance/ds-can';
import { wizardRouterActions } from '../../sagas/wizardRouter';
import { PhraseKeys } from '../../config/phraseKeys';
import { BaseRoutePaths } from '../../config/wizardRouter/baseWizardRoutes';

/**
 * Destructure necessary imports
 */
const { BACK_BUTTON, BACK_TO_START_BUTTON, HELP_TEXT, PAGE_NAME, SUB_TITLE, TITLE } = PhraseKeys;

/**
 * Page view and page logic
 */
export const SessionTimeoutPage = () => {
    const dispatch = useDispatch();
    const { t } = useI18n();
    const tWithNS = useI18n('error.sessionTimeout');

    const handleContinueButton = (e: Clickable) => {
        e.preventDefault();
        dispatch(wizardRouterActions.goTo(BaseRoutePaths.INDEX));
    };

    return (
        <PageLayout
            backBtnText={t(BACK_BUTTON)}
            continueBtnText={t(BACK_TO_START_BUTTON)}
            domainTitle={t(PAGE_NAME)}
            footerText={tWithNS.t(HELP_TEXT)}
            handleContinueButton={handleContinueButton}
            headerSubTitle={tWithNS.t(SUB_TITLE)}
            headerTitle={tWithNS.t(TITLE)}
            showBackButton={false}
        >
            <DisplayError icon={<TimeoutIcon />} info={tWithNS.t('errorInfo')} message={tWithNS.t('errorMessage')} />
        </PageLayout>
    );
};
