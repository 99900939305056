import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { wizardRouterActions } from 'sagas/wizardRouter';
import { useI18n } from '../../../hooks/useI18n';
import { FormFieldNames } from '../../../config/formFieldNames';
import { PhraseKeys } from '../../../config/phraseKeys';
import {
    selectVehicleServiceError,
    selectVehicleServiceLoading,
} from '../../../sagas/selectors/vehicleServiceSelectors';
import { Controller, useForm } from 'react-hook-form';
import { vehicleInformationSchema } from '../../../validations/methods/registrationNumberSchema';
import dispatcherWithPromise from '../../../utils/dispatcherWithPromise';
import { commonActions } from '../../../sagas/common';
import { DisplayVehicleInformation } from '../../../components/vehicle/DisplayVehicleInformation';
import {
    Clickable,
    CountryCodeEnums,
    FormChangeable,
    Grid,
    HiddenInputSubmit,
    initVehicleModel,
    MuiTextInput,
    PageLayout,
    PrimaryButton,
    RegistrationNumberModel,
    transformVehicleServiceResponse,
    VehicleModel,
    vehicleServiceActions,
    vehicleServiceErrorHandler,
    VehicleServiceModel,
} from '@protectorinsurance/ds-can';
import { goBack } from 'connected-react-router';
import { lpoActions } from '../../../sagas/lpo';
import { selectSelectedVehicle, selectVehicleRegistrationNumber } from '../../../sagas/selectors/lpoSelectors';
import { yupResolver } from '@hookform/resolvers/yup';
import { selectSearchedVehicles } from '../../../sagas/selectors/motorSelectors';
import { motorActions } from '../../../sagas/motor';
import { Card, CardContent } from '@mui/material';
import { selectCustomCAN } from '../../../sagas/selectors/commonSelectors';

/**
 * Destructure necessary imports
 */
const { REG_NR } = FormFieldNames;
const {
    BACK_BUTTON,
    CONTINUE_BUTTON,
    HELP_TEXT,
    LOOKUP_BUTTON,
    PAGE_NAME,
    REG_NR_LABEL,
    REG_NR_PLACEHOLDER,
    SELECT_BUTTON,
    SUB_TITLE,
    TITLE,
} = PhraseKeys;

/**
 * Page view and page logic
 */
export const TransportVehicleRegistrationNumberPage = () => {
    const dispatch = useDispatch();
    const registrationNumber = useSelector(selectVehicleRegistrationNumber);
    const loading = useSelector(selectVehicleServiceLoading);
    const vehicleServiceError = useSelector(selectVehicleServiceError);
    const selectedVehicle = useSelector(selectSelectedVehicle);
    const searchedVehicles = useSelector(selectSearchedVehicles);
    const customCAN = useSelector(selectCustomCAN);
    const { t } = useI18n();
    const tWithNS = useI18n('lpo.transport.vehicleRegistrationNumber');
    const {
        control,
        formState: { errors },
        getValues,
        handleSubmit,
        setValue,
        trigger,
    } = useForm<RegistrationNumberModel>({
        resolver: yupResolver(vehicleInformationSchema(t)),
        defaultValues: {
            registrationNumber,
        },
    });
    const [noVehicle, setNoVehicle] = useState<boolean>(false);
    const [searchRegNr, setSearchRegNr] = useState<string>('');

    const handleBackButton = () => {
        dispatcherWithPromise(dispatch, vehicleServiceActions.initialize)
            .then(() => dispatcherWithPromise(dispatch, lpoActions.update, { selectedVehicle: initVehicleModel }))
            .then(() => dispatch(goBack()));
    };

    useEffect(() => {
        const error = vehicleServiceErrorHandler(vehicleServiceError);

        setNoVehicle(!!error);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [vehicleServiceError, vehicleServiceErrorHandler]);

    const handleBlur = async (e: FormChangeable) => {
        e.preventDefault();
        const { id } = e.target;
        await trigger(id);
    };

    const handleChange = async (e: FormChangeable) => {
        e.preventDefault();
        const { id, value } = e.currentTarget;
        await setValue(id, value);
    };

    const handleClick = async (e: Clickable) => {
        e.preventDefault();
        const values = getValues();
        if (!errors.registrationNumber?.message) {
            if (values.registrationNumber && values.registrationNumber !== '') {
                setNoVehicle(false);
                dispatcherWithPromise(dispatch, lpoActions.update, { selectedVehicle: initVehicleModel })
                    .then(() =>
                        dispatcherWithPromise(dispatch, vehicleServiceActions.request, {
                            country: CountryCodeEnums.DK,
                            registrationNumber: values.registrationNumber && values.registrationNumber.toUpperCase(),
                        })
                    )
                    .then((res) => {
                        dispatch(
                            motorActions.update({
                                searchedVehicles: [
                                    ...searchedVehicles,
                                    transformVehicleServiceResponse(res as VehicleModel | VehicleServiceModel),
                                ],
                            })
                        );
                    })
                    .catch(() => {});
                setSearchRegNr(values.registrationNumber);
                setValue('registrationNumber', '');
            } else {
                setNoVehicle(true);
            }
        }
    };

    const onSubmit = (values: RegistrationNumberModel) => {
        if (!errors.registrationNumber?.message) {
            if (values.registrationNumber && values.registrationNumber !== '') {
                setNoVehicle(false);
                dispatcherWithPromise(dispatch, lpoActions.update, { selectedVehicle: initVehicleModel })
                    .then(() =>
                        dispatcherWithPromise(dispatch, vehicleServiceActions.request, {
                            country: CountryCodeEnums.DK,
                            registrationNumber: values.registrationNumber && values.registrationNumber.toUpperCase(),
                        })
                    )
                    .then((res) => {
                        dispatch(
                            motorActions.update({
                                searchedVehicles: [
                                    ...searchedVehicles,
                                    transformVehicleServiceResponse(res as VehicleModel | VehicleServiceModel),
                                ],
                            })
                        );
                    })
                    .catch(() => {});
                setSearchRegNr(values.registrationNumber);
                setValue('registrationNumber', '');
            } else {
                setNoVehicle(true);
            }
        }
    };

    const handleNoHit = () => {
        dispatcherWithPromise(dispatch, lpoActions.update, {
            registrationNumber: searchRegNr,
        })
            .then(() => dispatcherWithPromise(dispatch, commonActions.send))
            .then(() => dispatch(wizardRouterActions.goToNext()));
    };

    const handleSelectVehicle = (registrationNumber: string | null) => {
        const vehicle = searchedVehicles.find((v) => v.registrationNumber === registrationNumber);
        dispatcherWithPromise(dispatch, lpoActions.update, { selectedVehicle: vehicle }).then(() =>
            dispatch(commonActions.send())
        );
    };

    const handleSubmitVehicle = () => {
        dispatcherWithPromise(dispatch, lpoActions.update, {
            registrationNumber: selectedVehicle.registrationNumber?.toUpperCase(),
        })
            .then(() => dispatcherWithPromise(dispatch, commonActions.send))
            .then(() => dispatch(wizardRouterActions.goToNext()));
    };

    return (
        <PageLayout
            backBtnText={t(BACK_BUTTON)}
            continueBtnText={t(CONTINUE_BUTTON)}
            disableContinueButton={loading}
            domainTitle={t(PAGE_NAME)}
            footerText={tWithNS.t(HELP_TEXT)}
            handleContinueButton={handleSubmit(onSubmit)}
            headerSubTitle={tWithNS.t(SUB_TITLE)}
            headerTitle={tWithNS.t(TITLE)}
            showContinueButton={false}
            {...{ handleBackButton }}
        >
            <form onSubmit={handleSubmit(onSubmit)}>
                <HiddenInputSubmit />
                <Grid className={'align-center'}>
                    <Controller
                        control={control}
                        name={REG_NR}
                        render={({ field: { ref, ...field } }) => (
                            <MuiTextInput
                                {...field}
                                disabled={loading}
                                error={!!errors.registrationNumber}
                                errorMessage={errors.registrationNumber?.message}
                                id={REG_NR}
                                inputFieldWrapper={'col-8'}
                                label={t(REG_NR_LABEL)}
                                loading={loading}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                placeholder={t(REG_NR_PLACEHOLDER)}
                                reference={ref}
                                {...{ customCAN }}
                            />
                        )}
                    />
                    <div className={'col-4'}>
                        <PrimaryButton className={'grid'} onClick={handleClick} value={t(LOOKUP_BUTTON)} />
                    </div>
                    {noVehicle && (
                        <div className={'col-12'}>
                            <Card style={{ textAlign: 'left' }} variant={'outlined'}>
                                <CardContent>
                                    <p style={{ fontWeight: 900, marginBottom: '0.5rem', textTransform: 'uppercase' }}>
                                        {tWithNS.t('noHitTitle')}
                                    </p>
                                    <p style={{ marginBottom: '1rem' }}>
                                        {tWithNS.t('noHitMessage', {
                                            RegNr: searchRegNr ? ` ${t('withText')} ${searchRegNr?.toUpperCase()}` : '',
                                        })}
                                    </p>
                                    <PrimaryButton onClick={handleNoHit} value={tWithNS.t('noHitButton')} />
                                </CardContent>
                            </Card>
                        </div>
                    )}
                    {selectedVehicle && selectedVehicle.registrationNumber && (
                        <>
                            <div className={'col-12'}>
                                <h3 className={'vehicle-list-header'}>{tWithNS.t('selectedVehicle.title')}</h3>
                            </div>
                            {DisplayVehicleInformation({
                                buttonAction: handleSubmitVehicle,
                                buttonText: t(CONTINUE_BUTTON),
                                customCAN,
                                vehicle: selectedVehicle,
                            })}
                        </>
                    )}
                    {searchedVehicles && searchedVehicles.length > 0 && (
                        <>
                            <div className={'col-12 padding-top'}>
                                <h3 className={'vehicle-list-header'}>{tWithNS.t('lookup.title')}</h3>
                            </div>
                            {searchedVehicles
                                .slice(0)
                                .reverse()
                                .map((vehicle) => {
                                    return DisplayVehicleInformation({
                                        buttonAction: () => handleSelectVehicle(vehicle.registrationNumber),
                                        buttonText: t(SELECT_BUTTON),
                                        customCAN,
                                        vehicle,
                                    });
                                })}
                        </>
                    )}
                </Grid>
            </form>
        </PageLayout>
    );
};
