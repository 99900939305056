import { GuardianInformationModel } from '@protectorinsurance/ds-can';

/**
 * Utility logic
 */
export const formatGuardianInformation = (guardian: GuardianInformationModel) => {
    return `${guardian.firstName ? guardian.firstName : ''}${guardian.lastName ? ` ${guardian.lastName}` : ''}${
        guardian.relation ? ` (${guardian.relation})` : ''
    }${guardian.email ? `, ${guardian.email}` : ''}${guardian.phone ? `, ${guardian.phone}` : ''}${
        guardian.street ? `, ${guardian.street}` : ''
    }${guardian.zip ? `, ${guardian.zip}` : ''}${guardian.city ? ` ${guardian.city}` : ''}`;
};
