import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Clickable,
    Datable,
    is,
    LobKeys,
    LocaleServiceInstance,
    MuiDatePicker,
    PageLayout,
    updateDateByDate,
} from '@protectorinsurance/ds-can';
import { wizardRouterActions as wizardActions } from 'sagas/wizardRouter';
import { useI18n } from '../../../hooks/useI18n';
import { useGoBack } from '../../../hooks/useGoBack';
import { commonActions } from '../../../sagas/common';
import dispatcherWithPromise from '../../../utils/dispatcherWithPromise';
import { PhraseKeys } from '../../../config/phraseKeys';
import { FormFieldErrors, FormFieldNames } from '../../../config/formFieldNames';
import { selectClaimDate, selectIsWorkRelated } from 'sagas/selectors/lpoSelectors';
import { lpoActions } from '../../../sagas/lpo';
import { selectCustomCAN, selectLob } from '../../../sagas/selectors/commonSelectors';
import { LpoRoutePaths } from '../../../config/wizardRouter/lpoWizardRoutes';

/**
 * Destructure models, phrases etc.
 */
const { BACK_BUTTON, CONTINUE_BUTTON, HELP_TEXT, PAGE_NAME, SUB_TITLE, TITLE } = PhraseKeys;
const { CLAIM_DATE } = FormFieldNames;
const { MANDATORY_DATE_FIELD } = FormFieldErrors;
const { PERSON } = LobKeys;
const { GROUP_ACCIDENT_ACCIDENT_LOCATION } = LpoRoutePaths;

/**
 * Page view and page logic
 */
export const GlassClaimDatePage = () => {
    const dispatch = useDispatch();
    const lob = useSelector(selectLob);
    const claimDate = useSelector(selectClaimDate);
    const isWorkRelated = useSelector(selectIsWorkRelated);
    const customCAN = useSelector(selectCustomCAN);
    const [date, setDate] = useState<Datable>(claimDate || null);
    const [errors, setErrors] = useState<string[]>([]);
    const { t } = useI18n();
    const tWithNS = useI18n('lpo.glass.claimDate');
    const handleBackButton = useGoBack();

    useEffect(() => {
        setDate(claimDate);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleContinueButton = (e: Clickable) => {
        e.preventDefault();
        if (is(date, null)) {
            setErrors([t(MANDATORY_DATE_FIELD)]);
        } else {
            let nextAction = wizardActions.goToNext();
            if (is(lob, PERSON) && is(isWorkRelated, null)) {
                nextAction = wizardActions.goTo(GROUP_ACCIDENT_ACCIDENT_LOCATION);
            }
            dispatcherWithPromise(dispatch, lpoActions.update, { claimDate })
                .then(() => dispatcherWithPromise(dispatch, commonActions.send))
                .then(() => dispatch(nextAction));
        }
    };

    const selectedDate = (date: Datable) => {
        if (date) {
            const newFullDate = updateDateByDate(claimDate, date);
            setDate(date);
            setErrors((prev) => {
                return prev ? prev.filter((p) => p !== t(MANDATORY_DATE_FIELD)) : [];
            });
            dispatch(lpoActions.update({ claimDate: newFullDate }));
        }
    };

    return (
        <PageLayout
            backBtnText={t(BACK_BUTTON)}
            continueBtnText={t(CONTINUE_BUTTON)}
            domainTitle={t(PAGE_NAME)}
            footerText={tWithNS.t(HELP_TEXT)}
            headerSubTitle={tWithNS.t(SUB_TITLE)}
            headerTitle={tWithNS.t(TITLE)}
            {...{ handleBackButton, handleContinueButton }}
        >
            <MuiDatePicker
                disableFuture={true}
                error={errors.find((e: string) => e === t(MANDATORY_DATE_FIELD))}
                locale={LocaleServiceInstance.getMoment()}
                name={CLAIM_DATE}
                onChange={selectedDate}
                {...{ customCAN, date }}
            />
        </PageLayout>
    );
};
