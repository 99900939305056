import { AppState } from '../../store';

export const selectLpoState = (state: AppState) => state.lpo;

// Start Selectors
export const selectAcceptedPrivacy = (state: AppState) => state.lpo.acceptedPrivacy;
export const selectClaimReporterRole = (state: AppState) => state.lpo.claimReporterRole;
export const selectClaimDate = (state: AppState) => state.lpo.claimDate;
export const selectAccidentLocation = (state: AppState) => state.lpo.accidentLocation;

// Liability Selectors
export const selectIsPersonInjury = (state: AppState) => state.lpo.isPersonInjury;
export const selectLiabilityDamageClaimDescription = (state: AppState) => state.lpo.liabilityDamageClaimDescription;
export const selectClaimantInformationList = (state: AppState) => state.lpo.claimantInformationList;
export const selectIsResponsible = (state: AppState) => state.lpo.isResponsible;
export const selectLiabilityClaimDescription = (state: AppState) => state.lpo.liabilityClaimDescription;
export const selectHasContributedToInjury = (state: AppState) => state.lpo.hasContributedToInjury;
export const selectLiabilityInjuredClaimDescription = (state: AppState) => state.lpo.liabilityInjuredClaimDescription;

// Property Selectors
export const selectClaimType = (state: AppState) => state.lpo.claimType;

// Transport Selectors
export const selectVehicleRegistrationNumber = (state: AppState) => state.lpo.registrationNumber;
export const selectSelectedVehicle = (state: AppState) => state.lpo.selectedVehicle;

// Glass Selectors
export const selectIsWorkRelated = (state: AppState) => state.lpo.isWorkRelated;
export const selectClaimLocation = (state: AppState) => state.lpo.claimLocation;
export const selectGlassClaimDescription = (state: AppState) => state.lpo.glassClaimDescription;
export const selectClaimantInformation = (state: AppState) => state.lpo.claimantInformation;
export const selectTypeOfGlassDamage = (state: AppState) => state.lpo.typeOfGlassDamage;
export const selectWhoReceivesSettlement = (state: AppState) => state.lpo.whoReceivesSettlement;
export const selectBankAccountInformation = (state: AppState) => state.lpo.bankAccountInformation;
export const selectHasHealthInsurance = (state: AppState) => state.lpo.hasHealthInsurance;
export const selectHealthInsurance = (state: AppState) => state.lpo.healthInsurance;

// Group Accident Selectors
export const selectIsAccident = (state: AppState) => state.lpo.isAccident;
export const selectIsPoliceContacted = (state: AppState) => state.lpo.isPoliceContacted;
export const selectPoliceDistrict = (state: AppState) => state.lpo.policeDistrict;
export const selectPoliceCaseNumber = (state: AppState) => state.lpo.policeCaseNumber;
export const selectReportedToCounterparty = (state: AppState) => state.lpo.reportedToCounterparty;
export const selectCounterpartyInsuranceCompany = (state: AppState) => state.lpo.counterpartyInsuranceCompany;
export const selectCounterpartyExternalReference = (state: AppState) => state.lpo.counterpartyExternalReference;
export const selectDuringWork = (state: AppState) => state.lpo.duringWork;
export const selectReportedToInsurersInsurance = (state: AppState) => state.lpo.reportedToInsurersInsurance;
export const selectInjuryInsurance = (state: AppState) => state.lpo.injuryInsurance;
export const selectInjuryInsuranceExternalReference = (state: AppState) => state.lpo.injuryInsuranceExternalReference;
export const selectIsUnderaged = (state: AppState) => state.lpo.isUnderaged;
export const selectGuardianInformation = (state: AppState) => state.lpo.guardianInformation;
export const selectClaimCause = (state: AppState) => state.lpo.claimCause;
export const selectIsDoctorContacted = (state: AppState) => state.lpo.isDoctorContacted;
export const selectDateOfTreatment = (state: AppState) => state.lpo.dateOfTreatment;
export const selectDoctorInformation = (state: AppState) => state.lpo.doctorInformation;
export const selectPreviousInjury = (state: AppState) => state.lpo.previousInjury;
export const selectTypeOfInjury = (state: AppState) => state.lpo.typeOfInjury;
export const selectDateOfPreviousInjury = (state: AppState) => state.lpo.dateOfPreviousInjury;

// End Selectors
export const selectClaimDescription = (state: AppState) => state.lpo.claimDescription;
export const selectHasOtherInsurance = (state: AppState) => state.lpo.otherInsurance;
export const selectOtherInsuranceCompany = (state: AppState) => state.lpo.otherInsuranceCompany;
export const selectExternalReference = (state: AppState) => state.lpo.externalReference;
export const selectCompanyInformation = (state: AppState) => state.lpo.companyInformation;
export const selectReporterInformation = (state: AppState) => state.lpo.reporterInformation;
export const selectPolicyHoldersContact = (state: AppState) => state.lpo.policyHoldersContact;
export const selectAcceptedPoliceContact = (state: AppState) => state.lpo.acceptedPoliceContact;
