import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { PhraseKeys } from '../../config/phraseKeys';
import {
    AddIcon,
    CircleIconButton,
    FormChangeable,
    Grid,
    HiddenInputSubmit,
    MuiTextInput,
    OwnerInformationListModel,
} from '@protectorinsurance/ds-can';
import { FormFieldNames } from '../../config/formFieldNames';
import { AutocompleteValueKeys } from '../../config/AutocompleteValueKeys';
import { useI18n } from '../../hooks/useI18n';
import { useSelector } from 'react-redux';
import { selectCustomCAN } from '../../sagas/selectors/commonSelectors';

/**
 * Destructure necessary imports
 */
const { CITY, DAMAGED_ITEM, EMAIL, FIRST_NAME, LAST_NAME, PHONE, STREET, ZIP } = FormFieldNames;
const {
    ADD_BUTTON,
    CITY_LABEL,
    CITY_PLACEHOLDER,
    DAMAGED_ITEM_LABEL,
    DAMAGED_ITEM_PLACEHOLDER,
    EMAIL_LABEL,
    EMAIL_PLACEHOLDER,
    FIRST_NAME_LABEL,
    FIRST_NAME_PLACEHOLDER,
    LAST_NAME_LABEL,
    LAST_NAME_PLACEHOLDER,
    PHONE_LABEL,
    PHONE_PLACEHOLDER,
    STREET_LABEL,
    STREET_PLACEHOLDER,
    ZIP_LABEL,
    ZIP_PLACEHOLDER,
} = PhraseKeys;
const {
    GIVEN_NAME,
    FAMILY_NAME,
    EMAIL: EMAIL_AUTOCOMPLETE,
    TEL,
    STREET_ADDRESS,
    POSTAL_CODE,
    CITY: CITY_AUTOCOMPLETE,
} = AutocompleteValueKeys;

/**
 * Interfaces and types
 */
interface OwnerInformationListFormProps {
    onSubmit: (values: OwnerInformationListModel) => void;
}

/**
 * Component view and component logic
 */
export const OwnerInformationListPersonForm = ({ onSubmit }: OwnerInformationListFormProps) => {
    const customCAN = useSelector(selectCustomCAN);
    const {
        control,
        formState: { errors },
        handleSubmit,
        setValue,
        trigger,
    } = useFormContext<OwnerInformationListModel>();
    const { t } = useI18n();

    const handleChange = async (e: FormChangeable) => {
        e.preventDefault();
        const { id, value } = e.currentTarget;
        await setValue(id, value, { shouldValidate: true });
    };

    const handleBlur = async (e: FormChangeable) => {
        e.preventDefault();
        const { id } = e.currentTarget;
        await trigger(id);
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <HiddenInputSubmit />
            <Grid className={'align-center'}>
                <Controller
                    control={control}
                    name={FIRST_NAME}
                    render={({ field: { ref, ...field } }) => (
                        <MuiTextInput
                            {...field}
                            autoComplete={GIVEN_NAME}
                            error={!!errors.firstName}
                            errorMessage={errors.firstName?.message}
                            id={FIRST_NAME}
                            inputFieldWrapper={'col-6'}
                            label={t(FIRST_NAME_LABEL)}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            placeholder={t(FIRST_NAME_PLACEHOLDER)}
                            reference={ref}
                            {...{ customCAN }}
                        />
                    )}
                />
                <Controller
                    control={control}
                    name={LAST_NAME}
                    render={({ field: { ref, ...field } }) => (
                        <MuiTextInput
                            {...field}
                            autoComplete={FAMILY_NAME}
                            error={!!errors.lastName}
                            errorMessage={errors.lastName?.message}
                            id={LAST_NAME}
                            inputFieldWrapper={'col-6'}
                            label={t(LAST_NAME_LABEL)}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            placeholder={t(LAST_NAME_PLACEHOLDER)}
                            reference={ref}
                            {...{ customCAN }}
                        />
                    )}
                />
                <Controller
                    control={control}
                    name={STREET}
                    render={({ field: { ref, ...field } }) => (
                        <MuiTextInput
                            {...field}
                            autoComplete={STREET_ADDRESS}
                            error={!!errors.street}
                            errorMessage={errors.street?.message}
                            id={STREET}
                            inputFieldWrapper={'col-4'}
                            label={t(STREET_LABEL)}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            placeholder={t(STREET_PLACEHOLDER)}
                            reference={ref}
                            {...{ customCAN }}
                        />
                    )}
                />
                <Controller
                    control={control}
                    name={ZIP}
                    render={({ field: { ref, ...field } }) => (
                        <MuiTextInput
                            {...field}
                            autoComplete={POSTAL_CODE}
                            error={!!errors.zip}
                            errorMessage={errors.zip?.message}
                            id={ZIP}
                            inputFieldWrapper={'col-4'}
                            label={t(ZIP_LABEL)}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            placeholder={t(ZIP_PLACEHOLDER)}
                            reference={ref}
                            {...{ customCAN }}
                        />
                    )}
                />
                <Controller
                    control={control}
                    name={CITY}
                    render={({ field: { ref, ...field } }) => (
                        <MuiTextInput
                            {...field}
                            autoComplete={CITY_AUTOCOMPLETE}
                            error={!!errors.city}
                            errorMessage={errors.city?.message}
                            id={CITY}
                            inputFieldWrapper={'col-4'}
                            label={t(CITY_LABEL)}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            placeholder={t(CITY_PLACEHOLDER)}
                            reference={ref}
                            {...{ customCAN }}
                        />
                    )}
                />
                <Controller
                    control={control}
                    name={EMAIL}
                    render={({ field: { ref, ...field } }) => (
                        <MuiTextInput
                            {...field}
                            autoComplete={EMAIL_AUTOCOMPLETE}
                            error={!!errors.email}
                            errorMessage={errors.email?.message}
                            id={EMAIL}
                            inputFieldWrapper={'col-4'}
                            label={t(EMAIL_LABEL)}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            placeholder={t(EMAIL_PLACEHOLDER)}
                            reference={ref}
                            type={'email'}
                            {...{ customCAN }}
                        />
                    )}
                />
                <Controller
                    control={control}
                    name={PHONE}
                    render={({ field: { ref, ...field } }) => (
                        <MuiTextInput
                            {...field}
                            autoComplete={TEL}
                            error={!!errors.phone}
                            errorMessage={errors.phone?.message}
                            id={PHONE}
                            inputFieldWrapper={'col-4'}
                            label={t(PHONE_LABEL)}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            placeholder={t(PHONE_PLACEHOLDER)}
                            reference={ref}
                            {...{ customCAN }}
                        />
                    )}
                />
                <Controller
                    control={control}
                    name={DAMAGED_ITEM}
                    render={({ field: { ref, ...field } }) => (
                        <MuiTextInput
                            {...field}
                            error={!!errors.damagedItem}
                            errorMessage={errors.damagedItem?.message}
                            id={DAMAGED_ITEM}
                            inputFieldWrapper={'col-4'}
                            label={t(DAMAGED_ITEM_LABEL)}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            placeholder={t(DAMAGED_ITEM_PLACEHOLDER)}
                            reference={ref}
                            {...{ customCAN }}
                        />
                    )}
                />
                <CircleIconButton
                    ariaLabel={t(ADD_BUTTON)}
                    className={'flex flex-col single-4-center'}
                    dataTestId={'btn-add-owner'}
                    handleClick={handleSubmit(onSubmit)}
                    icon={<AddIcon />}
                    label={t(ADD_BUTTON)}
                />
            </Grid>
        </form>
    );
};
