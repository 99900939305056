import { ClaimantModel } from '@protectorinsurance/ds-can';

/**
 * Utility logic
 */
export const formatClaimantInformation = (claimant: ClaimantModel) => {
    return `${claimant.firstName ? claimant.firstName : ''}${claimant.lastName ? ` ${claimant.lastName}` : ''}${
        claimant.nationalIdentity ? ` (${claimant.nationalIdentity})` : ''
    }${claimant.email ? `, ${claimant.email}` : ''}${claimant.phone ? `, ${claimant.phone}` : ''}${
        claimant.street ? `, ${claimant.street}` : ''
    }${claimant.zip ? `, ${claimant.zip}` : ''}${claimant.city ? ` ${claimant.city}` : ''}`;
};
