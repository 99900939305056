import { combineReducers } from 'redux';

import {
    PDFServiceAction,
    PDFServiceActionTypes,
    pdfServiceReducer,
    PDFServiceState,
    pdfServiceWatcher,
    TogglesServiceAction,
    TogglesServiceActionTypes,
    togglesServiceReducer,
    TogglesServiceState,
    togglesServiceWatcher,
    VehicleServiceAction,
    VehicleServiceActionTypes,
    vehicleServiceReducer,
    VehicleState,
    vehicleWatcher,
} from '@protectorinsurance/ds-can';
import maps, { MapsServiceAction, MapsServiceActionTypes, MapsServiceState, mapsServiceWatcher } from './mapsService';
import { LogServiceActions, LogServiceActionTypes, logServiceWatchers } from './logService';
import orgnrService, { OrgnrServiceAction, OrgnrServiceActionTypes, OrgnrState, orgnrWatcher } from './orgnrService';

export type ServicesActionTypes =
    | VehicleServiceActionTypes
    | MapsServiceActionTypes
    | TogglesServiceActionTypes
    | PDFServiceActionTypes
    | LogServiceActionTypes
    | OrgnrServiceActionTypes;

export type ServicesActions =
    | VehicleServiceAction
    | MapsServiceAction
    | TogglesServiceAction
    | PDFServiceAction
    | LogServiceActions
    | OrgnrServiceAction;

export interface ServicesState {
    vehicleService: VehicleState;
    togglesService: TogglesServiceState;
    maps: MapsServiceState;
    pdf: PDFServiceState;
    orgnrService: OrgnrState;
}

/**
 * Export namespace watchers
 */
export const servicesWatchers = [
    vehicleWatcher,
    togglesServiceWatcher,
    mapsServiceWatcher,
    pdfServiceWatcher,
    logServiceWatchers,
    orgnrWatcher,
];

/**
 * Export combined reducers as default
 */
export default combineReducers({
    vehicleService: vehicleServiceReducer,
    togglesService: togglesServiceReducer,
    maps,
    pdf: pdfServiceReducer,
    orgnrService,
});
