import FileModel, { createFileError } from '../../../models/File';
import {
    actionWithPromise,
    api,
    emptyFn,
    FileErrorTypes,
    LogServiceModel,
    MULTI_PART_HEADER,
    Rejectable,
    Resolvable,
} from '@protectorinsurance/ds-can';
import { takeEvery } from 'redux-saga/effects';
import { selectClaimReportId } from '../../selectors/reportSelectors';
import { createFormData } from '../../../utils/createFormData';
import { reportActions } from './report';
import { pollReportActions } from './pollReport';
import { UploadReceiptActionTypes } from '../receipt/uploadReceipt';
import { call, put, select } from 'typed-redux-saga';
import { selectRequestId } from '../../selectors/commonSelectors';
import { logServiceActions } from '../../services/logService';
import { NODE_API_BASE_URL } from '../../../config/api';

/**
 * Action Types
 */
export enum UploadReportActionTypes {
    REQUEST = '@app/upload/report/upload/REQUEST',
    SUCCESS = '@app/upload/report/upload/SUCCESS',
    FAILURE = '@app/upload/report/upload/FAILURE',
}

/**
 * Action Definitions
 */
export interface UploadReportAction {
    type: UploadReportActionTypes;
    data: FileModel;
    resolve?: Resolvable;
    reject?: Rejectable;
}

/**
 * Redux Actions
 */
export const uploadReportActions = {
    request: actionWithPromise(UploadReportActionTypes.REQUEST),
    success: actionWithPromise(UploadReportActionTypes.SUCCESS),
    failure: actionWithPromise(UploadReportActionTypes.FAILURE),
};

/**
 * Saga Functions
 */
export const uploadReportSagas = {
    *request({ data, resolve = emptyFn, reject = emptyFn }: UploadReportAction) {
        try {
            yield* put(reportActions.add(data));

            const requestId = yield* select(selectRequestId);
            const submissionId = yield* select(selectClaimReportId);
            const formData = createFormData(data);
            const config = {
                baseURL: NODE_API_BASE_URL,
                headers: {
                    ...MULTI_PART_HEADER,
                    'X-Request-Id': `${requestId}`,
                },
            };

            if (data.errors.length === 0) {
                const res: any = yield* call(api.post, `upload/submission/${submissionId}/files`, formData, config);
                const externalId = res.headers.location.replace('/api/', '').replace('/meta', '');
                const file = { ...data, externalId };

                yield* put(reportActions.update(file));
                yield* put(pollReportActions.request(file));
                yield* put(uploadReportActions.success());
                resolve();
            } else {
                const file = createFileError(data, UploadReceiptActionTypes.FAILURE, {
                    name: 'FileValidationError',
                    message: FileErrorTypes.UNSUPPORTED,
                });
                yield* put(reportActions.update(file));
                yield* put(uploadReportActions.failure(file));
                reject();
            }
        } catch (e) {
            const file = createFileError(data, UploadReportActionTypes.FAILURE, e as any);
            if ((e as any).url?.includes('null')) {
                const requestId = yield* select(selectRequestId);
                const errorPayload: LogServiceModel = {
                    level: 'error',
                    message: `Missing UUID: Tried to upload file by name of ${file.name} in uploadReport (DK). X-Request-Id=${requestId}`,
                };
                yield* put(logServiceActions.request(errorPayload));
            }
            yield* put(reportActions.update(file));
            yield* put(uploadReportActions.failure(file));
            reject();
        }
    },
};

/**
 * Saga Watchers
 */
export const uploadReportWatchers = function* () {
    yield takeEvery(UploadReportActionTypes.REQUEST, uploadReportSagas.request);
};
