import React from 'react';
import { LpoRoutePaths } from '../../../config/wizardRouter/lpoWizardRoutes';
import { LpoSummaryProps } from '../../../types/SummaryProps';
import { TEST_ID_PREFIX } from '../../../utils/testIdUtil';
import { displayPerson, isYes } from '@protectorinsurance/ds-can';
import { createTextYesNoDontKnow } from '../../../utils/displayUtils';
import { renderReporterInformation } from './components/end/RenderReporterInformation';
import { renderPolicyHolder } from './components/end/RenderPolicyHolder';
import { renderAttachment } from './components/end/RenderAttachment';
import { renderClaimDescription } from './components/end/RenderClaimDescription';
import { renderPolicyHoldersContact } from './components/end/RenderPolicyHoldersContact';
import { renderHasOtherInsurance } from './components/end/RenderHasOtherInsurance';
import { renderOtherInsurance } from './components/end/RenderOtherInsurance';
import classNames from 'classnames';
import { renderExternalReference } from './components/end/RenderExternalReference';
import { formatCompanyInformation } from '../../../utils/strings/formatCompanyInformation';
import { renderIsResponsible } from './components/end/RenderIsResponsible';
import { renderLiabilityClaimDescription } from './components/end/RenderLiabilityClaimDescription';
import { renderHasContributedToInjury } from './components/end/RenderHasContributedToInjury';
import { renderLiabilityInjuredClaimDescription } from './components/end/RenderLiabilityInjuredClaimDescription';
import { renderTypeOfGlassDamage } from './components/end/RenderTypeOfGlassDamage';
import { renderWhoReceivesSettlement } from './components/end/RenderWhoReceivesSettlement';
import { renderAccountNumber } from './components/end/RenderAccountNumber';
import { renderAccountRegistrationNumber } from './components/end/RenderAccountRegistrationNumber';

/**
 * Destructure necessary imports
 */
const {
    DYN_CAROUSEL_END_HAS_OTHER_INSURANCE,
    DYN_CAROUSEL_GLASS_WHO_RECEIVES_SETTLEMENT,
    DYN_CAROUSEL_LIABILITY_HAS_CONTRIBUTED_TO_INJURY,
    DYN_CAROUSEL_LIABILITY_IS_RESPONSIBLE,
    END_CLAIM_DESCRIPTION,
    END_EXTERNAL_REFERENCE,
    END_OTHER_INSURANCE_COMPANY,
    END_POLICY_HOLDER,
    END_POLICY_HOLDERS_CONTACT,
    END_REPORTER_INFORMATION,
    END_UPLOAD_ATTACHMENT,
    GLASS_BANK_ACCOUNT_INFORMATION,
    GLASS_TYPE_OF_GLASS_DAMAGE,
    LIABILITY_CLAIM_DESCRIPTION,
    LIABILITY_INJURED_CLAIM_DESCRIPTION,
} = LpoRoutePaths;

/**
 * Component view and component logic
 */
export const DisplayEndSummary = ({ model, t, tWithNS, handleClick, upload, common }: LpoSummaryProps) => {
    const {
        bankAccountInformation,
        claimDescription,
        claimReporterRole,
        companyInformation,
        externalReference,
        hasContributedToInjury,
        isResponsible,
        isWorkRelated,
        liabilityClaimDescription,
        liabilityInjuredClaimDescription,
        otherInsurance,
        otherInsuranceCompany,
        policyHoldersContact,
        reporterInformation,
        typeOfGlassDamage,
        whoReceivesSettlement,
    } = model;
    const { lob } = common;
    const { attachment } = upload;

    // Classes
    const hasOtherInsuranceClass = classNames(
        {
            'col-4': isYes(otherInsurance),
            'col-12': !isYes(otherInsurance),
        },
        'divider text-align-left'
    );

    return (
        <>
            {renderClaimDescription(
                {
                    inputWrapperClass: 'col-12 divider text-align-left',
                    textKey: tWithNS('claimDescription.heading.title'),
                    dataTestId: TEST_ID_PREFIX.claimDescription,
                    buttonText: claimDescription ? claimDescription : '-',
                    path: END_CLAIM_DESCRIPTION,
                    handleClick,
                },
                claimDescription
            )}

            {renderTypeOfGlassDamage(
                {
                    inputWrapperClass: 'col-12 divider text-align-left',
                    textKey: tWithNS('typeOfGlassDamage.heading.title'),
                    dataTestId: TEST_ID_PREFIX.typeOfGlassDamage,
                    buttonText: t(`lpo.typeOfGlassDamage.select.${typeOfGlassDamage}`),
                    path: GLASS_TYPE_OF_GLASS_DAMAGE,
                    handleClick,
                },
                isWorkRelated
            )}

            {renderIsResponsible(
                {
                    inputWrapperClass: 'col-12 divider text-align-left',
                    textKey: tWithNS('isResponsible.heading.title'),
                    dataTestId: TEST_ID_PREFIX.isResponsible,
                    buttonText: t(createTextYesNoDontKnow(isResponsible)),
                    path: DYN_CAROUSEL_LIABILITY_IS_RESPONSIBLE,
                    handleClick,
                },
                lob,
                claimReporterRole
            )}

            {renderLiabilityClaimDescription(
                {
                    inputWrapperClass: 'col-12 divider text-align-left',
                    textKey: tWithNS('liabilityClaimDescription.heading.title'),
                    dataTestId: TEST_ID_PREFIX.liabilityClaimDescription,
                    buttonText: liabilityClaimDescription ? liabilityClaimDescription : '-',
                    path: LIABILITY_CLAIM_DESCRIPTION,
                    handleClick,
                },
                lob,
                isResponsible
            )}

            {renderHasContributedToInjury(
                {
                    inputWrapperClass: 'col-12 divider text-align-left',
                    textKey: tWithNS('hasContributedToInjury.heading.title'),
                    dataTestId: TEST_ID_PREFIX.hasContributedToInjury,
                    buttonText: t(createTextYesNoDontKnow(hasContributedToInjury)),
                    path: DYN_CAROUSEL_LIABILITY_HAS_CONTRIBUTED_TO_INJURY,
                    handleClick,
                },
                lob,
                isResponsible
            )}

            {renderLiabilityInjuredClaimDescription(
                {
                    inputWrapperClass: 'col-12 divider text-align-left',
                    textKey: tWithNS('liabilityInjuredClaimDescription.heading.title'),
                    dataTestId: TEST_ID_PREFIX.liabilityInjuredClaimDescription,
                    buttonText: liabilityInjuredClaimDescription ? liabilityInjuredClaimDescription : '-',
                    path: LIABILITY_INJURED_CLAIM_DESCRIPTION,
                    handleClick,
                },
                lob,
                isResponsible,
                hasContributedToInjury
            )}

            {renderAttachment(
                {
                    textKey: `${tWithNS('uploadAttachment.heading.title')}`,
                    dataTestId: TEST_ID_PREFIX.uploadAttachments,
                    path: END_UPLOAD_ATTACHMENT,
                    buttonText: '',
                },
                `${tWithNS('uploadAttachment.heading.title')}`,
                TEST_ID_PREFIX.uploadAttachments,
                attachment
            )}

            {renderWhoReceivesSettlement(
                {
                    inputWrapperClass: 'col-12 divider text-align-left',
                    textKey: tWithNS('whoReceivesSettlement.heading.title'),
                    dataTestId: TEST_ID_PREFIX.whoReceivesSettlement,
                    buttonText: t(`lpo.glass.whoReceivesSettlement.select.${whoReceivesSettlement}`),
                    path: DYN_CAROUSEL_GLASS_WHO_RECEIVES_SETTLEMENT,
                    handleClick,
                },
                whoReceivesSettlement
            )}

            {renderHasOtherInsurance(
                {
                    inputWrapperClass: hasOtherInsuranceClass,
                    textKey: tWithNS('hasOtherInsurance.heading.title'),
                    dataTestId: TEST_ID_PREFIX.hasOtherInsurance,
                    buttonText: t(createTextYesNoDontKnow(otherInsurance)),
                    path: DYN_CAROUSEL_END_HAS_OTHER_INSURANCE,
                    handleClick,
                },
                otherInsurance
            )}

            {renderOtherInsurance(
                {
                    inputWrapperClass: 'col-8 divider text-align-left',
                    textKey: tWithNS('otherInsuranceCompany.heading.title'),
                    dataTestId: TEST_ID_PREFIX.otherInsuranceCompany,
                    buttonText: otherInsuranceCompany ? otherInsuranceCompany : '-',
                    path: END_OTHER_INSURANCE_COMPANY,
                    handleClick,
                },
                otherInsurance
            )}

            {renderExternalReference(
                {
                    inputWrapperClass: 'col-12 divider text-align-left',
                    textKey: tWithNS('externalReference.heading.title'),
                    dataTestId: TEST_ID_PREFIX.externalReference,
                    buttonText: externalReference ? externalReference : '-',
                    path: END_EXTERNAL_REFERENCE,
                    handleClick,
                },
                externalReference
            )}

            {renderAccountNumber(
                {
                    inputWrapperClass: 'col-6 divider text-align-left',
                    textKey: tWithNS('accountNumber.heading.title'),
                    dataTestId: TEST_ID_PREFIX.accountNumber,
                    buttonText: bankAccountInformation.accountNumber ? bankAccountInformation.accountNumber : '-',
                    path: GLASS_BANK_ACCOUNT_INFORMATION,
                    handleClick,
                },
                bankAccountInformation
            )}

            {renderAccountRegistrationNumber(
                {
                    inputWrapperClass: 'col-6 divider text-align-left',
                    textKey: tWithNS('accountRegistrationNumber.heading.title'),
                    dataTestId: TEST_ID_PREFIX.registrationNumber,
                    buttonText: bankAccountInformation.registrationNumber
                        ? bankAccountInformation.registrationNumber
                        : '-',
                    path: GLASS_BANK_ACCOUNT_INFORMATION,
                    handleClick,
                },
                bankAccountInformation
            )}

            {renderPolicyHolder({
                inputWrapperClass: 'col-12 divider text-align-left',
                textKey: tWithNS('policyHolder.heading.title'),
                dataTestId: TEST_ID_PREFIX.policyHolder,
                buttonText: companyInformation ? formatCompanyInformation(companyInformation) : '-',
                path: END_POLICY_HOLDER,
                handleClick,
            })}

            {renderReporterInformation({
                inputWrapperClass: 'col-12 divider text-align-left',
                textKey: tWithNS('reporterInformation.heading.title'),
                dataTestId: TEST_ID_PREFIX.reporterInformation,
                buttonText: displayPerson(reporterInformation),
                path: END_REPORTER_INFORMATION,
                handleClick,
            })}

            {renderPolicyHoldersContact({
                inputWrapperClass: 'col-12 divider text-align-left',
                textKey: tWithNS('policyHoldersContact.heading.title'),
                dataTestId: TEST_ID_PREFIX.policyHoldersContact,
                buttonText: displayPerson(policyHoldersContact),
                path: END_POLICY_HOLDERS_CONTACT,
                handleClick,
            })}
        </>
    );
};
