export enum TEST_ID_PREFIX {
    acceptedPoliceContact = 'acceptedPoliceContact',
    acceptedPrivacy = 'accepted-privacy',
    acceptedPrivacyCheck = 'acceptedPrivacy',
    accidentLocation = 'accidentLocation',
    accountNumber = 'accountNumber',
    bankName = 'bankName',
    businessNumber = 'businessNumber',
    chassisNumber = 'chassisNumber',
    city = 'city',
    claimCause = 'claimCause',
    claimCauseDropdown = 'claimCause',
    claimCauseDetails = 'claimCauseDetails',
    claimDate = 'claim-date',
    claimDescription = 'claimDescription',
    claimDiscovererInformation = 'claimDiscovererInformation',
    claimLocation = 'claimLocation',
    claimNumber = 'claimNumber',
    claimReporterRole = 'claimReporterRole',
    claimType = 'claimType',
    claimantInformation = 'claimantInformation',
    claimantRoundaboutPositioning = 'claimantRoundaboutPositioning',
    clearingNumber = 'clearingNumber',
    collisionSituation = 'collisionSituation',
    companyInformation = 'companyInformation',
    companyName = 'name',
    counterpartyCompanyName = 'companyName',
    counterpartyExternalReference = 'counterpartyExternalReference',
    counterpartyInformation = 'counterpartyInformation',
    counterpartyInsuranceCompany = 'counterpartyInsuranceCompany',
    counterpartyRoundaboutPositioning = 'counterpartyRoundaboutPositioning',
    country = 'country',
    damagedItem = 'damagedItem',
    dateOfBirth = 'dateOfBirth',
    dateOfPreviousInjury = 'dateOfPreviousInjury',
    dateOfTreatment = 'dateOfTreatment',
    distanceFromRoadSide = 'distanceFromRoadSide',
    doctorInformation = 'doctorInformation',
    driverInformation = 'driverInformation',
    drivingSpeed = 'drivingSpeed',
    duringWork = 'duringWork',
    email = 'email',
    externalReference = 'externalReference',
    fireClaimDescription = 'fireClaimDescription',
    firstName = 'firstName',
    glassClaimDescription = 'glassClaimDescription',
    glassOtherInsuranceCompany = 'label-otherInsuranceCompany',
    guardianInformation = 'guardianInformation',
    hasAllKeys = 'hasAllKeys',
    hasAnimalEscaped = 'hasAnimalEscaped',
    hasAttachments = 'has-attachments',
    hasContributedToInjury = 'hasContributedToInjury',
    hasDigitalServiceBook = 'hasDigitalServiceBook',
    hasExpenses = 'has-expenses',
    hasHealthInsurance = 'hasHealthInsurance',
    hasNonVehicleDamages = 'hasNonVehicleDamages',
    hasOtherInsurance = 'hasOtherInsurance',
    hasPersonInjuries = 'hasPersonInjuries',
    hasWitnesses = 'hasWitnesses',
    healthInsurance = 'healthInsurance',
    injuredPersonInformationList = 'injuredPersonInformationList',
    injuryInsurance = 'injuryInsurance',
    injuryInsuranceExternalReference = 'injuryInsuranceExternalReference',
    insuranceType = 'insurance-type',
    insuredByProtector = 'insuredByProtector',
    isAccident = 'isAccident',
    isCompany = 'isCompany-checkbox-input',
    isCounterpartyKnown = 'isCounterpartyKnown',
    isCounterpartyStationary = 'isCounterpartyStationary',
    isDoctorContacted = 'isDoctorContacted',
    isDriver = 'isDriver-checkbox-input',
    isItemMissing = 'isItemMissing',
    isLaneChange = 'isLaneChange',
    isPersonInjury = 'isPersonInjury',
    isReporter = 'isReporter-checkbox-input',
    isResponsible = 'isResponsible',
    isReversing = 'isReversing',
    isDriving = 'isDriving',
    isOverRoadCenter = 'isOverRoadCenter',
    isOtherVehicleInvolved = 'isOtherVehicleInvolved',
    isPoliceContacted = 'isPoliceContacted',
    isSelfDiscoveredClaim = 'isSelfDiscoveredClaim',
    isUnderaged = 'isUnderaged',
    isVehicleRecovered = 'isVehicleRecovered',
    isVehicleStolen = 'isVehicleStolen',
    isWorkRelated = 'isWorkRelated',
    lastName = 'lastName',
    liabilityClaimDescription = 'liabilityClaimDescription',
    liabilityDamageClaimDescription = 'liabilityDamageClaimDescription',
    liabilityInjuredClaimDescription = 'liabilityInjuredClaimDescription',
    lob = 'lob',
    make = 'make',
    mileage = 'mileage',
    missingItems = 'missingItems',
    model = 'model',
    vehicleMileage = 'vehicleMileage',
    name = 'name',
    nationalIdentity = 'nationalIdentity',
    needVehicleTowing = 'needVehicleTowing',
    otherId = 'otherId',
    otherInsurance = 'otherInsurance',
    otherInsuranceCompany = 'otherInsuranceCompany',
    otherMissingItemsClaimDescription = 'otherMissingItemsClaimDescription',
    otherPartReporting = 'otherPartReporting',
    ownerGivenName = 'ownerGivenName',
    ownerFamilyName = 'ownerFamilyName',
    ownerInformationList = 'ownerInformationList',
    parkedVehicleInformation = 'parkedVehicleInformation',
    parkingDate = 'parkingDate',
    passengers = 'passengers-0',
    phone = 'phone',
    policeCaseNumber = 'policeCaseNumber',
    policeDistrict = 'policeDistrict',
    policyHolder = 'policyHolder',
    policyHoldersContact = 'policyHoldersContact',
    policyNumber = 'policyNumber',
    previousInjury = 'previousInjury',
    propertyAddress = 'propertyAddress',
    propertyUnit = 'unit',
    recoveryDate = 'recoveryDate',
    registrationNumber = 'registrationNumber',
    relation = 'relation',
    relationToWitness = 'relationToWitness',
    reportedToCounterparty = 'reportedToCounterparty',
    reporterInformation = 'reporterInformation',
    roadConditionClaimDescription = 'roadConditionClaimDescription',
    roadWidth = 'roadWidth',
    role = 'role',
    salary = 'salary',
    speedLimit = 'speedLimit',
    speedOnImpact = 'speedOnImpact',
    street = 'street',
    theftAndDamagesClaimDescription = 'theftAndDamagesClaimDescription',
    thirdPartySpeed = 'thirdPartySpeed',
    timeSinceAction = 'timeSinceAction',
    title = 'title',
    type = 'type',
    typeOfAnimal = 'typeOfAnimal',
    typeOfGlassDamage = 'typeOfGlassDamage',
    typeOfInjury = 'typeOfInjury',
    unidentifiedType = 'unidentifiedType',
    uploadAttachments = 'upload-attachments-',
    uploadPoliceReport = 'upload-police-report-',
    uploadReceipt = 'upload-receipt-',
    recovererInformation = 'recovererInformation',
    reportedToInsurersInsurance = 'reportedToInsurersInsurance',
    whoReport = 'who-report',
    vehicleInformation = 'vehicleInformation',
    vehicleList = 'vehicleList-',
    vehicleType = 'vehicleType',
    whoAtFault = 'whoAtFault',
    whoReceivesSettlement = 'whoReceivesSettlement',
    whoResponsibleClaimDescription = 'whoResponsibleClaimDescription',
    witnesses = 'witnesses',
    zip = 'zip',
}
