import * as Yup from 'yup';
import { TFunction } from 'i18next';
import { PhraseKeys } from '../../config/phraseKeys';
import { FormFieldNames } from '../../config/formFieldNames';

/**
 * Destructure necessary imports
 */
const { ADDRESS_LABEL, NOTE_LABEL } = PhraseKeys;
const { ADDRESS, NOTE } = FormFieldNames;

/**
 * Validation logic
 */
export const locationWithAddressSchema = (t: TFunction) => {
    return {
        note: Yup.string().label(t(NOTE_LABEL)).ensure().requiredIf([ADDRESS]).nullable(),
        address: Yup.string().label(t(ADDRESS_LABEL)).requiredIf([NOTE]).nullable(),
    };
};
