import React from 'react';
import { PhraseKeys } from '../../../config/phraseKeys';
import { useDispatch, useSelector } from 'react-redux';
import { useI18n } from '../../../hooks/useI18n';
import {
    ClaimTypeEnums,
    Clickable,
    FlowKeys,
    is,
    LobKeys,
    LpoClaimCauseEnums,
    PageLayout,
    StartSummary,
} from '@protectorinsurance/ds-can';
import { wizardRouterActions } from '../../../sagas/wizardRouter';
import dispatcherWithPromise from '../../../utils/dispatcherWithPromise';
import { flowActions } from '../../../sagas/flow';
import { goBack } from 'connected-react-router';
import { selectCustomCAN, selectLob } from '../../../sagas/selectors/commonSelectors';
import { LpoRoutePaths } from '../../../config/wizardRouter/lpoWizardRoutes';
import { lpoActions } from '../../../sagas/lpo';
import { commonActions } from '../../../sagas/common';

/**
 * Destructure necessary imports
 */
const { BACK_BUTTON, CONTINUE_BUTTON, HELP_TEXT, PAGE_NAME, SUB_TITLE, TITLE } = PhraseKeys;
const { START } = FlowKeys;
const { CYBER, LIABILITY, PERSON, PROPERTY, TRANSPORT, WORKSHOP } = LobKeys;
const { DYN_CAROUSEL_START_CLAIM_REPORTER_ROLE, START_CLAIM_DATE } = LpoRoutePaths;
const { UNKNOWN } = LpoClaimCauseEnums;
const { WORKSHOP: WORKSHOP_CLAIM_TYPE } = ClaimTypeEnums;

/**
 * Page view and page logic
 */
export const StartOnboardingPage = () => {
    const dispatch = useDispatch();
    const lob = useSelector(selectLob);
    const customCAN = useSelector(selectCustomCAN);
    const { t } = useI18n();
    const tWithNS = useI18n('lpo.start.onboarding');

    const handleBackButton = () => {
        dispatcherWithPromise(dispatch, flowActions.update, null).then(() => dispatch(goBack()));
    };

    const handleContinueButton = (e: Clickable) => {
        e.preventDefault();
        let nextAction = wizardRouterActions.goToNext();
        const lobs = [CYBER, LIABILITY, PERSON, PROPERTY, TRANSPORT];
        if (lob && lobs.includes(lob)) {
            nextAction = wizardRouterActions.goTo(DYN_CAROUSEL_START_CLAIM_REPORTER_ROLE);
        }

        if (is(lob, WORKSHOP)) {
            dispatcherWithPromise(dispatch, lpoActions.update, { claimCause: UNKNOWN, claimType: WORKSHOP_CLAIM_TYPE });
            nextAction = wizardRouterActions.goTo(START_CLAIM_DATE);
        }

        dispatcherWithPromise(dispatch, flowActions.update, START)
            .then(() => dispatcherWithPromise(dispatch, commonActions.send))
            .then(() => dispatch(nextAction));
    };

    return (
        <PageLayout
            backBtnText={t(BACK_BUTTON)}
            continueBtnText={t(CONTINUE_BUTTON)}
            domainTitle={t(PAGE_NAME)}
            footerText={tWithNS.t(HELP_TEXT)}
            headerSubTitle={tWithNS.t(SUB_TITLE)}
            headerTitle={tWithNS.t(TITLE)}
            {...{ handleBackButton, handleContinueButton }}
        >
            <StartSummary
                avgMin={tWithNS.t('body.avgMin')}
                description={tWithNS.t('body.paragraph.one')}
                sessionText={tWithNS.t('body.paragraph.two')}
                subTitle={tWithNS.t('body.subTitle')}
                title={tWithNS.t('body.title')}
                {...{ customCAN }}
            />
        </PageLayout>
    );
};
