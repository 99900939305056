import * as Yup from 'yup';
import { TFunction } from 'i18next';
import { FormFieldErrors, MAX_LENGTH_250 } from '../../config/formFieldNames';
import { PhraseKeys } from '../../config/phraseKeys';
import { PHONE_REGEX } from '../../utils/validation/stringFormats';

/**
 * Destructure necessary imports
 */
const { MAX_LENGTH_ERROR_MESSAGE, EMAIL_ERROR_MESSAGE, FIELD_ERROR } = FormFieldErrors;
const {
    COMPANY_NAME_LABEL,
    COMPANY_BUSINESS_NUMBER_LABEL,
    FIRST_NAME_LABEL,
    LAST_NAME_LABEL,
    EMAIL_LABEL,
    PHONE_LABEL,
} = PhraseKeys;

/**
 * Validation logic
 */
export const counterpartyOwnerSchema = (t: TFunction) => {
    return Yup.object().shape({
        // COMMON
        isCompany: Yup.boolean(),
        email: Yup.string()
            .label(t(EMAIL_LABEL))
            .email(t(EMAIL_ERROR_MESSAGE))
            .secureEndingEmail(t(EMAIL_ERROR_MESSAGE))
            .onlyAscii()
            .nullable(),
        phone: Yup.string()
            .label(t(PHONE_LABEL))
            .matches(PHONE_REGEX, {
                message: t(FIELD_ERROR),
                excludeEmptyString: true,
            })
            .nullable(),

        // COMPANY
        name: Yup.string().label(t(COMPANY_NAME_LABEL)).allowSpecialChars().nullable(),

        businessNumber: Yup.string().label(t(COMPANY_BUSINESS_NUMBER_LABEL)).businessNumber(false).nullable(),

        // PERSON
        firstName: Yup.string()
            .label(t(FIRST_NAME_LABEL))
            .max(
                MAX_LENGTH_250,
                t(MAX_LENGTH_ERROR_MESSAGE, {
                    field: t(FIRST_NAME_LABEL),
                    max: MAX_LENGTH_250,
                })
            )
            .noSpecialChars()
            .nullable(),
        lastName: Yup.string()
            .label(t(LAST_NAME_LABEL))
            .max(
                MAX_LENGTH_250,
                t(MAX_LENGTH_ERROR_MESSAGE, {
                    field: t(LAST_NAME_LABEL),
                    max: MAX_LENGTH_250,
                })
            )
            .noSpecialChars()
            .nullable(),
    });
};
