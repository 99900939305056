import { init as apmInit } from '@elastic/apm-rum';
import { APM_URL } from 'config/services';
const initApm = () => {
    apmInit({
        serviceName: 'claims-at-net-dk-frontend',
        serverUrl: APM_URL,
    });
};

export { initApm };
