import * as Yup from 'yup';
import { TFunction } from 'i18next';
import { optionalAddressSchema } from '../fieldSchemas/optionalAddressSchema';
import { relationToWitnessSchema } from '../fieldSchemas/relationToWitnessSchema';
import { firstNameSchema } from '../fieldSchemas/firstNameSchema';
import { optionalLastNameSchema } from '../fieldSchemas/optionalLastNameSchema';
import { contactDetailsSchema } from '../fieldSchemas/contactDetailsSchema';

export const witnessesSchema = (t: TFunction) => {
    return Yup.object().shape({
        ...firstNameSchema(t),
        ...optionalLastNameSchema(t),
        ...contactDetailsSchema(t),
        ...relationToWitnessSchema(t),
        ...optionalAddressSchema(t),
    });
};
