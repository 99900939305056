import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { wizardRouterActions as wizardActions } from 'sagas/wizardRouter';
import { useI18n } from '../../../hooks/useI18n';
import { useGoBack } from '../../../hooks/useGoBack';
import {
    AddIcon,
    ButtonClickable,
    CircleIconButton,
    Clickable,
    findSelectedVehicle,
    Grid,
    initVehicleModel,
    InputValidationError,
    is,
    PageLayout,
    SelectedVehicleTypeModel,
    VehicleListItem,
    VehicleModel,
    YesNoKeys,
} from '@protectorinsurance/ds-can';
import { PhraseKeys } from '../../../config/phraseKeys';
import { selectIsCounterparty } from '../../../sagas/selectors/motorSelectors';
import { MotorRoutePaths } from '../../../config/wizardRouter/motorWizardRoutes';
import dispatcherWithPromise from '../../../utils/dispatcherWithPromise';
import { motorActions } from '../../../sagas/motor';
import { TEST_ID_PREFIX } from '../../../utils/testIdUtil';
import { useVehicles } from '../../../hooks/useVehicles';
import { commonActions } from '../../../sagas/common';
import { selectCustomCAN } from '../../../sagas/selectors/commonSelectors';

/**
 * Destructure necessary imports
 */
const {
    ADD_BUTTON,
    BACK_BUTTON,
    CONTINUE_BUTTON,
    DONT_KNOW,
    HELP_TEXT,
    PAGE_NAME,
    SELECT_NO,
    SELECT_YES,
    SUB_TITLE,
    TITLE,
} = PhraseKeys;
const { DYN_CAROUSEL_END_HAS_NON_VEHICLE_DAMAGES, END_BODY, END_VEHICLE_REGISTRATION_NUMBER } = MotorRoutePaths;
const { vehicleList } = TEST_ID_PREFIX;
const { YES } = YesNoKeys;

/**
 * Page view and page logic
 */
export const DamageOverviewPage = () => {
    const dispatch = useDispatch();
    const { vehicles, claimVehicle, otherVehicles, counterpartyVehicle } = useVehicles();
    const isCounterparty = useSelector(selectIsCounterparty);
    const customCAN = useSelector(selectCustomCAN);
    const [validation, setValidation] = useState<boolean>(true);
    const { t } = useI18n();
    const tWithNS = useI18n('motor.end.damageOverview');
    const handleBackButton = useGoBack();
    const claimVehicleHeader = isCounterparty ? 'heading.counterpartyVehicle' : 'heading.claimVehicle';
    const addButtonLabel = claimVehicle ? 'add.button' : 'add.buttonCounterparty';

    const checkValidation = (v: VehicleModel[]) => {
        let noValidation = true;

        v.map(async (vehicle) => {
            if (vehicle.vehicleHasDamages == null) {
                noValidation = false;
            }
        });
        return noValidation;
    };

    const handleAddButton = (e: Clickable) => {
        e.preventDefault();
        dispatch(wizardActions.goTo(END_VEHICLE_REGISTRATION_NUMBER));
    };

    const handleClick = (id: SelectedVehicleTypeModel, e?: ButtonClickable) => {
        e?.preventDefault();
        const hasDamages = e?.currentTarget.value as YesNoKeys;
        const currentVehicle = findSelectedVehicle(vehicles, id);
        const updatedVehicleList = vehicles.map((vehicle) => {
            if (is(vehicle.internalId, id)) {
                if (hasDamages !== YES) {
                    return {
                        ...initVehicleModel,
                        ...currentVehicle,
                        vehicleHasDamages: hasDamages,
                        damages: [],
                        otherDamageComment: null,
                    };
                } else {
                    return {
                        ...initVehicleModel,
                        ...currentVehicle,
                        vehicleHasDamages: hasDamages,
                    };
                }
            } else {
                return vehicle;
            }
        });

        dispatcherWithPromise(dispatch, motorActions.update, {
            selectedVehicleId: id,
            vehicles: updatedVehicleList,
        }).then(() => {
            hasDamages === YES && dispatch(wizardActions.goTo(END_BODY));
        });

        if (checkValidation(updatedVehicleList)) {
            setValidation(checkValidation(updatedVehicleList));
        }
    };

    const handleDelete = (id: SelectedVehicleTypeModel, e?: Clickable) => {
        e?.preventDefault();
        const vehicleArray = vehicles.filter((vehicle) => vehicle.internalId !== id);
        dispatcherWithPromise(dispatch, motorActions.update, { vehicles: vehicleArray });
    };

    const handleDamages = (v: VehicleModel) => {
        const damages = v.damages.map((item) => tWithNS.t(`select.${item.body}`));
        const damagesList = damages.reduce((damagesCount: any, currentDamage) => {
            damagesCount[currentDamage] = (damagesCount[currentDamage] || 0) + 1;
            return damagesCount;
        }, {});

        const reducedDamagesList = Object.entries(damagesList).map(([key, value]) => ({ key, value }));

        return reducedDamagesList;
    };

    const handleContinueButton = async (e: Clickable) => {
        e.preventDefault();

        setValidation(checkValidation(vehicles));

        if (checkValidation(vehicles)) {
            let nextAction = wizardActions.goToNext();
            if (claimVehicle?.damages.length === 0) {
                nextAction = wizardActions.goTo(DYN_CAROUSEL_END_HAS_NON_VEHICLE_DAMAGES);
            }

            dispatcherWithPromise(dispatch, commonActions.send).then(() => dispatch(nextAction));
        }
    };

    const renderClaimVehicle = () =>
        claimVehicle && (
            <>
                <h2 className={'vehicle-list-header'}>{tWithNS.t(claimVehicleHeader)}</h2>
                <Grid>
                    <VehicleListItem
                        buttonNoTitle={t(SELECT_NO)}
                        buttonUnknownTitle={t(DONT_KNOW)}
                        buttonYesTitle={t(SELECT_YES)}
                        damages={handleDamages(claimVehicle)}
                        dataTestId={`${vehicleList}claimVehicle`}
                        handleClick={(e) => handleClick(claimVehicle?.internalId, e)}
                        hasDamages={true}
                        make={claimVehicle?.make}
                        model={claimVehicle?.model}
                        registrationNumber={claimVehicle?.registrationNumber}
                        type={claimVehicle?.type}
                        vehicleHasDamages={claimVehicle?.vehicleHasDamages}
                        vehicleType={claimVehicle?.vehicleType}
                        {...(isCounterparty && { handleDelete: () => handleDelete(claimVehicle?.internalId) })}
                        {...{ customCAN }}
                    />
                </Grid>
            </>
        );

    const renderCounterpartyVehicle = () =>
        counterpartyVehicle && (
            <>
                <h2 className={'vehicle-list-header'}>{tWithNS.t('heading.claimVehicle')}</h2>
                <Grid>
                    <VehicleListItem
                        buttonNoTitle={t(SELECT_NO)}
                        buttonUnknownTitle={t(DONT_KNOW)}
                        buttonYesTitle={t(SELECT_YES)}
                        damages={handleDamages(counterpartyVehicle)}
                        dataTestId={`${vehicleList}counterpartyVehicle`}
                        handleClick={(e) => handleClick(counterpartyVehicle?.internalId, e)}
                        hasDamages={true}
                        make={counterpartyVehicle?.make}
                        model={counterpartyVehicle?.model}
                        registrationNumber={counterpartyVehicle?.registrationNumber}
                        type={counterpartyVehicle?.type}
                        vehicleHasDamages={counterpartyVehicle?.vehicleHasDamages}
                        vehicleType={counterpartyVehicle?.vehicleType}
                        {...(!isCounterparty && { handleDelete: () => handleDelete(counterpartyVehicle?.internalId) })}
                        {...{ customCAN }}
                    />
                </Grid>
            </>
        );

    const renderOtherVehicles = () =>
        otherVehicles.length > 0 && (
            <>
                <h2 className={'vehicle-list-header'}>{tWithNS.t('heading.otherVehicles')}</h2>
                <Grid>
                    {otherVehicles.map((vehicle: VehicleModel, idx: number) => {
                        const { internalId, make, model, registrationNumber, type, vehicleHasDamages, vehicleType } =
                            vehicle;
                        return (
                            <VehicleListItem
                                {...{ make, model, registrationNumber, type, vehicleType }}
                                buttonNoTitle={t(SELECT_NO)}
                                buttonUnknownTitle={t(DONT_KNOW)}
                                buttonYesTitle={t(SELECT_YES)}
                                damages={handleDamages(vehicle)}
                                dataTestId={`${vehicleList}${idx}`}
                                handleClick={(e) => handleClick(internalId, e)}
                                handleDelete={() => handleDelete(internalId)}
                                hasDamages={true}
                                {...{ customCAN, vehicleHasDamages }}
                            />
                        );
                    })}
                </Grid>
            </>
        );

    return (
        <PageLayout
            backBtnText={t(BACK_BUTTON)}
            continueBtnText={t(CONTINUE_BUTTON)}
            domainTitle={t(PAGE_NAME)}
            footerText={tWithNS.t(HELP_TEXT)}
            headerSubTitle={tWithNS.t(SUB_TITLE)}
            headerTitle={tWithNS.t(TITLE)}
            {...{ handleBackButton, handleContinueButton }}
        >
            {renderCounterpartyVehicle()}
            {renderClaimVehicle()}
            {renderOtherVehicles()}

            {!validation ? <InputValidationError error={tWithNS.t('error')} fieldName={'Vehicle Damage'} /> : ''}

            <CircleIconButton
                ariaLabel={t(ADD_BUTTON)}
                className={'dual add-btn'}
                dataTestId={'btn-add-vehicle'}
                handleClick={handleAddButton}
                icon={<AddIcon />}
                label={tWithNS.t(addButtonLabel)}
            />
        </PageLayout>
    );
};
