import * as Yup from 'yup';
import { TFunction } from 'i18next';
import { PhraseKeys } from '../../config/phraseKeys';
import { FormFieldErrors, MAX_LENGTH_250 } from '../../config/formFieldNames';
import { ZIP_REGEX } from '../formats/stringFormats';

/**
 * Destructure necessary imports
 */
const { CITY_LABEL, STREET_LABEL, ZIP_LABEL } = PhraseKeys;
const { INVALID_ZIP_CODE, MAX_LENGTH_ERROR_MESSAGE } = FormFieldErrors;

/**
 * Validation logic
 */
export const addressSchema = (t: TFunction) => {
    const zipLabel = t(ZIP_LABEL);
    return {
        street: Yup.string()
            .label(t(STREET_LABEL))
            .max(MAX_LENGTH_250, t(MAX_LENGTH_ERROR_MESSAGE, { field: t(STREET_LABEL), max: MAX_LENGTH_250 }))
            .nullable()
            .required(),
        zip: Yup.string().matches(ZIP_REGEX, t(INVALID_ZIP_CODE)).label(zipLabel).nullable().required(),
        city: Yup.string()
            .label(t(CITY_LABEL))
            .max(MAX_LENGTH_250, t(MAX_LENGTH_ERROR_MESSAGE, { field: t(CITY_LABEL), max: MAX_LENGTH_250 }))
            .nullable()
            .city()
            .required(),
    };
};
