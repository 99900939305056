import * as Yup from 'yup';
import { TFunction } from 'i18next';
import { accountNumberSchema } from '../fieldSchemas/accountNumberSchema';
import { accountRegistrationNumberSchema } from '../fieldSchemas/accountRegistrationNumberSchema';

/**
 * Validation logic
 */
export const bankAccountInformationSchema = (t: TFunction) => {
    return Yup.object().shape({
        ...accountNumberSchema(t),
        ...accountRegistrationNumberSchema(t),
    });
};
