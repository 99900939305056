import { StringSchema, TestContext, ValidationError } from 'yup';
import { yupCustomLocale } from '../../config/yup';
import { isDev } from '../../utils/env';
import { ssnDenmarkValidator } from '@protectorinsurance/ds-can';

export default function ssn(this: StringSchema, message: string = yupCustomLocale.string.ssn) {
    // @ts-ignore
    return this.test('ssn', message, function (this: TestContext, value: string):
        | boolean
        | ValidationError
        | Promise<boolean | ValidationError> {
        return isDev() ? true : ssnDenmarkValidator(value).isValid();
    });
}
