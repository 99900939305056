import { TFunction } from 'i18next';
import * as Yup from 'yup';
import { PhraseKeys } from '../../config/phraseKeys';
import { FormFieldErrors } from '../../config/formFieldNames';

const { INVALID_REGISTRATION_NUMBER } = FormFieldErrors;
const { REG_NR_LABEL } = PhraseKeys;

export const registrationNumberSchema = (t: TFunction) => {
    return {
        registrationNumber: Yup.string()
            .label(t(REG_NR_LABEL))
            .registrationNumber(t(INVALID_REGISTRATION_NUMBER))
            .notRequired()
            .nullable(),
    };
};

export const vehicleInformationSchema = (t: TFunction): Yup.ObjectSchema => {
    return Yup.object().shape({
        ...registrationNumberSchema(t),
    });
};
