import * as Yup from 'yup';
import { TFunction } from 'i18next';
import { companyNameSchema } from '../fieldSchemas/companyNameSchema';
import { policyNumberSchema } from '../fieldSchemas/policyNumberSchema';
import { businessNumberSchema } from '../fieldSchemas/businessNumberSchema';

/**
 * Validation logic
 */
export const companyInformationSchema = (t: TFunction) => {
    return Yup.object().shape({
        ...companyNameSchema(t),
        ...policyNumberSchema(t),
        ...businessNumberSchema(t),
    });
};
