import React from 'react';
import { useI18n } from '../../../hooks/useI18n';
import { useGoBack } from '../../../hooks/useGoBack';
import { Link, PageLayout } from '@protectorinsurance/ds-can';
import { PhraseKeys } from '../../../config/phraseKeys';
import { useSelector } from 'react-redux';
import { selectCustomCAN } from '../../../sagas/selectors/commonSelectors';
import carglassLogo from '../../../assets/images/carglass-logo.png';
import danskBilglasLogo from '../../../assets/images/dansk-bilglas-logo.jpg';

/**
 * Destructure models, phrases etc.
 */
const { BACK_BUTTON, CONTINUE_BUTTON, HELP_TEXT, PAGE_NAME, SUB_TITLE, TITLE } = PhraseKeys;

/**
 * Page view and page logic
 */
export const WindshieldDamagePage = () => {
    const customCAN = useSelector(selectCustomCAN);
    const { t } = useI18n();
    const tWithNS = useI18n('motor.start.windshieldDamage');

    const handleBackButton = useGoBack();

    return (
        <PageLayout
            backBtnText={t(BACK_BUTTON)}
            continueBtnText={t(CONTINUE_BUTTON)}
            domainTitle={t(PAGE_NAME)}
            footerText={tWithNS.t(HELP_TEXT)}
            headerSubTitle={tWithNS.t(SUB_TITLE)}
            headerTitle={tWithNS.t(TITLE)}
            showContinueButton={false}
            {...{ handleBackButton }}
        >
            <p className={'text-left mb-5'} tabIndex={0}>
                {tWithNS.t('text.firstSection')}
            </p>
            <p className={'text-left mt-5'} tabIndex={0}>
                <span> {tWithNS.t('text.danskBilglas')} </span>
                <Link
                    linkText={tWithNS.t('linkText.danskBilglas')}
                    url={tWithNS.t('url.danskBilglas')}
                    {...{ customCAN }}
                />
            </p>
            <a href={tWithNS.t('url.danskBilglas')} target={'_blank'} rel={'noreferrer'}>
                <img alt={tWithNS.t('alt.danskBilglas')} className={'mt-5'} src={danskBilglasLogo} width={200} />
            </a>
            <p className={'text-left mt-5'} tabIndex={0}>
                <span> {tWithNS.t('text.carglass')} </span>
                <Link linkText={tWithNS.t('linkText.carglass')} url={tWithNS.t('url.carglass')} {...{ customCAN }} />
            </p>
            <a href={tWithNS.t('url.carglass')} target={'_blank'} rel={'noreferrer'}>
                <img alt={tWithNS.t('alt.carglass')} className={'mt-5'} src={carglassLogo} width={200} />
            </a>
        </PageLayout>
    );
};
