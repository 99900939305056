import { ClaimantInformationListModel, ClaimantInformationListTypeModel } from '@protectorinsurance/ds-can';

export const claimantExists = (
    claimant: ClaimantInformationListModel,
    existingClaimantList: ClaimantInformationListTypeModel
): boolean => {
    return !!existingClaimantList.find((c: ClaimantInformationListModel) => {
        if (!!c.firstName && !!claimant.firstName && !!c.lastName && !!claimant.lastName) {
            return (
                c.firstName.toLowerCase() === claimant.firstName.toLowerCase() &&
                c.lastName.toLowerCase() === claimant.lastName.toLowerCase()
            );
        }

        return null;
    });
};

export const removeClaimant = (
    claimant: ClaimantInformationListModel,
    existingClaimantList: ClaimantInformationListTypeModel
): ClaimantInformationListTypeModel => {
    return existingClaimantList.filter((c: ClaimantInformationListModel) => {
        if (c.firstName !== null && claimant.firstName !== null && c.lastName && claimant.lastName !== null) {
            return (
                c.firstName.toLowerCase() !== claimant.firstName.toLowerCase() ||
                c.lastName.toLowerCase() !== claimant.lastName.toLowerCase()
            );
        }

        return null;
    });
};
