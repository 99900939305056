import React, { ChangeEvent, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { wizardRouterActions as wizardActions } from 'sagas/wizardRouter';
import { useI18n } from '../../../hooks/useI18n';
import { useGoBack } from '../../../hooks/useGoBack';
import {
    DamagedItemKeys,
    isEmpty,
    OwnerInformationListModel,
    OwnerInformationListTypeModel,
    PageLayout,
    SingleCheckbox,
} from '@protectorinsurance/ds-can';
import { FormFieldNames } from '../../../config/formFieldNames';
import { PhraseKeys } from '../../../config/phraseKeys';
import { FormProvider, useForm } from 'react-hook-form';
import { selectOwnerInformationList } from '../../../sagas/selectors/motorSelectors';
import dispatcherWithPromise from '../../../utils/dispatcherWithPromise';
import { motorActions } from '../../../sagas/motor';
import { commonActions } from '../../../sagas/common';
import { OwnerInformationListPersonForm } from '../../../components/ownerInformationList/OwnerInformationListPersonForm';
import { OwnerInformationListCompanyForm } from '../../../components/ownerInformationList/OwnerInformationListCompanyForm';
import { ownerInformationListSchema } from '../../../validations/schemas/ownerInformationListSchema';
import { DisplayOwnerInformationList } from '../../../components/ownerInformationList/DisplayOwnerInformationList';
import { yupResolver } from '@hookform/resolvers/yup';
import { selectCustomCAN } from '../../../sagas/selectors/commonSelectors';

/**
 * Destructure necessary imports
 */
const { IS_COMPANY } = FormFieldNames;
const { BACK_BUTTON, CONTINUE_BUTTON, DELETE_BUTTON, HELP_TEXT, IS_COMPANY_LABEL, PAGE_NAME, SUB_TITLE, TITLE } =
    PhraseKeys;
const { OTHER } = DamagedItemKeys;

/**
 * Page view and page logic
 */
export const OwnerInformationListPage = () => {
    const dispatch = useDispatch();
    const { t } = useI18n();
    const tWithNS = useI18n('motor.end.ownerInformationList');
    const ownerInformation = useSelector(selectOwnerInformationList);
    const customCAN = useSelector(selectCustomCAN);
    const [isCompany, setIsCompany] = useState<boolean>(false);
    const form = useForm<OwnerInformationListModel>({
        resolver: yupResolver(ownerInformationListSchema(t)),
    });

    const handleBackButton = useGoBack();

    const isEmptyForm = (values: OwnerInformationListModel) => {
        const { businessNumber, city, email, firstName, isCompany, lastName, name, phone, street, zip } = values;
        let empty: boolean;
        if (isCompany) {
            empty =
                (!name || name.length === 0) &&
                (!businessNumber || businessNumber.length === 0) &&
                (!email || email.length === 0) &&
                (!phone || phone.length === 0);
        } else {
            empty =
                (!firstName || firstName.length === 0) &&
                (!lastName || lastName.length === 0) &&
                (!street || street.length === 0) &&
                (!zip || zip.length === 0) &&
                (!city || city.length === 0) &&
                (!email || email.length === 0) &&
                (!phone || phone.length === 0);
        }
        return empty;
    };

    const handleContinueButton = () => {
        const values = form.getValues();
        if (!isEmptyForm(values)) {
            ownerInformationListSchema(t)
                .isValid(values)
                .then((valid) => {
                    if (valid) {
                        handleDispatch(values, true);
                    } else {
                        form.trigger();
                    }
                });
        } else if (isEmpty(ownerInformation)) {
            form.trigger();
        } else {
            // Send to server and go to next
            dispatcherWithPromise(dispatch, commonActions.send).then(() => dispatch(wizardActions.goToNext()));
        }
    };

    const handleDispatch = (owner: OwnerInformationListModel, isNext = false) => {
        const damagedItem = !!owner.damagedItem ? owner.damagedItem : OTHER;
        const currentOwner = { ...owner, damagedItem };
        dispatcherWithPromise(dispatch, motorActions.update, {
            ownerInformationList: [...ownerInformation, currentOwner],
        })
            .then(() => isNext && dispatcherWithPromise(dispatch, commonActions.send))
            .then(() => {
                form.reset();
                if (isNext) {
                    dispatch(wizardActions.goToNext());
                }
            });
    };

    const removeOwnerInformation = (
        owner: OwnerInformationListModel,
        existingPersons: OwnerInformationListTypeModel
    ): OwnerInformationListTypeModel => {
        return existingPersons.filter((o: OwnerInformationListModel) => {
            return (
                o.firstName !== owner.firstName ||
                o.lastName !== owner.lastName ||
                o.street !== owner.street ||
                o.zip !== owner.zip ||
                o.city !== owner.city ||
                o.email !== owner.email ||
                o.phone !== owner.phone ||
                o.name !== owner.name ||
                o.businessNumber !== owner.businessNumber
            );
        });
    };

    const handleDelete = (e: React.MouseEvent, owner: OwnerInformationListModel) => {
        e.preventDefault();
        const updatedOwnerList = removeOwnerInformation(owner, ownerInformation);
        dispatch(motorActions.update({ ownerInformationList: updatedOwnerList }));
    };

    const handleOnSubmit = (owner: OwnerInformationListModel) => handleDispatch(owner);

    const handleChecked = async (e: ChangeEvent<HTMLInputElement>) => {
        const { checked } = e.target;
        await form.setValue('isCompany', checked);
        await setIsCompany(checked);
    };

    return (
        <PageLayout
            backBtnText={t(BACK_BUTTON)}
            continueBtnText={t(CONTINUE_BUTTON)}
            domainTitle={t(PAGE_NAME)}
            footerText={tWithNS.t(HELP_TEXT)}
            handleContinueButton={handleContinueButton}
            headerSubTitle={tWithNS.t(SUB_TITLE)}
            headerTitle={tWithNS.t(TITLE)}
            {...{ handleBackButton }}
        >
            <FormProvider {...form}>
                <SingleCheckbox
                    checked={isCompany}
                    handleChange={handleChecked}
                    name={IS_COMPANY}
                    wrapperClass={'col-12 multiple'}
                    {...{ customCAN }}
                >
                    {t(IS_COMPANY_LABEL)}
                </SingleCheckbox>
                {isCompany ? (
                    <OwnerInformationListCompanyForm onSubmit={handleOnSubmit} />
                ) : (
                    <OwnerInformationListPersonForm onSubmit={handleOnSubmit} />
                )}
                <DisplayOwnerInformationList
                    buttonText={t(DELETE_BUTTON)}
                    handleDelete={handleDelete}
                    owners={ownerInformation}
                />
            </FormProvider>
        </PageLayout>
    );
};
