import { TFunction } from 'i18next';
import { TypeOfGlassDamageEnums } from '@protectorinsurance/ds-can';

/**
 * Utility logic
 */
const createTypeOfGlassDamageKey = (id: string) => {
    return `lpo.typeOfGlassDamage.select.${id}`;
};

export const getTypeOfGlassDamageOption = (t: TFunction, key: TypeOfGlassDamageEnums) => {
    return {
        label: t(createTypeOfGlassDamageKey(key)),
        value: key,
    };
};
