import { TFunction } from 'i18next';
import { OptionType, VehicleTypeKeys } from '@protectorinsurance/ds-can';

const createVehicleType = (id: string) => {
    return `motor.vehicleInformation.vehicleType.select.${id}`;
};

export const getVehicleTypeOptions = (t: TFunction): OptionType[] => {
    const {
        CAR,
        MOTORCYCLE,
        MOPED,
        BUS,
        EXCAVATOR,
        WHEEL_LOADER,
        TRUCK,
        TRAILER,
        TERRAIN_SCOOTER,
        TERRAIN_TRAILER,
        TRACTOR,
        OTHER,
    } = VehicleTypeKeys;
    return [
        { value: CAR, label: t(createVehicleType(CAR)) },
        { value: MOTORCYCLE, label: t(createVehicleType(MOTORCYCLE)) },
        { value: MOPED, label: t(createVehicleType(MOPED)) },
        { value: BUS, label: t(createVehicleType(BUS)) },
        { value: EXCAVATOR, label: t(createVehicleType(EXCAVATOR)) },
        { value: WHEEL_LOADER, label: t(createVehicleType(WHEEL_LOADER)) },
        { value: TRUCK, label: t(createVehicleType(TRUCK)) },
        { value: TRAILER, label: t(createVehicleType(TRAILER)) },
        { value: TERRAIN_SCOOTER, label: t(createVehicleType(TERRAIN_SCOOTER)) },
        { value: TERRAIN_TRAILER, label: t(createVehicleType(TERRAIN_TRAILER)) },
        { value: TRACTOR, label: t(createVehicleType(TRACTOR)) },
        { value: OTHER, label: t(createVehicleType(OTHER)) },
    ];
};
