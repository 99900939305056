import React from 'react';
import { wizardRouterActions as wizardActions } from '../../../../sagas/wizardRouter';
import {
    CarouselConfigLookupResponse,
    CarouselConfigOptionType,
    ClaimReporterRoleKeys,
    ClaimTypeEnums,
    Clickable,
    CompanyIcon,
    EmployeeIcon,
    FlowKeys,
    IAmInjuredIcon,
    is,
    KindergardenIcon,
    LobKeys,
    MunicipalityIcon,
    OtherInjuriesIcon,
    PedestrianIcon,
} from '@protectorinsurance/ds-can';
import { CarouselConfigLookupProps } from '../../../../utils/carouselPageLookup';
import dispatcherWithPromise from '../../../../utils/dispatcherWithPromise';
import { commonActions } from '../../../../sagas/common';
import { LpoRoutePaths } from '../../../wizardRouter/lpoWizardRoutes';
import { selectClaimReporterRole } from '../../../../sagas/selectors/lpoSelectors';
import { lpoActions } from '../../../../sagas/lpo';
import { selectLob } from '../../../../sagas/selectors/commonSelectors';
import { selectFlow } from '../../../../sagas/selectors/flowSelectors';
import { flowActions } from '../../../../sagas/flow';

/**
 * Destructure necessary imports
 */
const { BROKER, CONDOMINIUM_HOLDERS, INJURED, OTHER, POLICY_HOLDER, PROPERTY_MANAGER, TENANT } = ClaimReporterRoleKeys;
const { CYBER, LIABILITY, PERSON, TRANSPORT } = LobKeys;
const { GLASS_CLAIM_DATE, LIABILITY_CLAIM_TYPE, START_CLAIM_DATE, TRANSPORT_CLAIM_TYPE } = LpoRoutePaths;
const { GLASS } = FlowKeys;
const { CYBER: CYBER_CLAIM_TYPE } = ClaimTypeEnums;

/**
 * Page view and page logic
 */
export const startClaimReporterRolePage = ({
    t,
    state,
    dispatch,
}: CarouselConfigLookupProps): CarouselConfigLookupResponse<LpoRoutePaths> => {
    const lob = selectLob(state);
    let flow = selectFlow(state);

    // Options
    const optBroker = { id: BROKER, name: t(`lpo.start.claimReporterRole.select.${BROKER}`), icon: <EmployeeIcon /> };
    const optCondominiumHolders = {
        id: CONDOMINIUM_HOLDERS,
        name: t(`lpo.start.claimReporterRole.select.${CONDOMINIUM_HOLDERS}`),
        icon: <KindergardenIcon />,
    };
    const optInjured = {
        id: INJURED,
        name: t(`lpo.start.claimReporterRole.select.${INJURED}`),
        icon: <IAmInjuredIcon />,
    };
    const optOther = {
        id: OTHER,
        name: t(`lpo.start.claimReporterRole.select.${OTHER}`),
        icon: <OtherInjuriesIcon />,
    };
    const optPolicyHolder = {
        id: POLICY_HOLDER,
        name: t(`lpo.start.claimReporterRole.select.${POLICY_HOLDER}`),
        icon: <CompanyIcon />,
    };
    const optPropertyManager = {
        id: PROPERTY_MANAGER,
        name: t(`lpo.start.claimReporterRole.select.${PROPERTY_MANAGER}`),
        icon: <MunicipalityIcon />,
    };
    const optTenant = { id: TENANT, name: t(`lpo.start.claimReporterRole.select.${TENANT}`), icon: <PedestrianIcon /> };

    let options = [optBroker, optPolicyHolder, optCondominiumHolders, optPropertyManager, optTenant];
    if (is(lob, LIABILITY)) {
        options = [optBroker, optPolicyHolder, optOther];
    }

    if (is(lob, PERSON)) {
        options = [optInjured, optBroker, optPolicyHolder, optOther];
    }

    return {
        slug: LpoRoutePaths.DYN_CAROUSEL_START_CLAIM_REPORTER_ROLE,
        stateKey: 'claimReporterRole',
        i18n: {
            ns: 'lpo.start.claimReporterRole',
        },
        selectorValue: selectClaimReporterRole(state),
        options,
        onSelect: (id: CarouselConfigOptionType, e: Clickable) => {
            e.preventDefault();
            const option = options?.find((x) => x.id === id);

            if (option) {
                let nextAction = wizardActions.goToNext();
                if (is(lob, CYBER)) {
                    nextAction = wizardActions.goTo(START_CLAIM_DATE);
                    dispatcherWithPromise(dispatch, lpoActions.update, { claimType: CYBER_CLAIM_TYPE });
                }

                if (is(lob, TRANSPORT)) {
                    nextAction = wizardActions.goTo(TRANSPORT_CLAIM_TYPE);
                }

                if (is(lob, LIABILITY)) {
                    nextAction = wizardActions.goTo(LIABILITY_CLAIM_TYPE);
                }

                if (is(lob, PERSON)) {
                    flow = GLASS;
                    nextAction = wizardActions.goTo(GLASS_CLAIM_DATE);
                }

                dispatcherWithPromise(dispatch, lpoActions.update, {
                    claimReporterRole: option.id,
                })
                    .then(() => dispatcherWithPromise(dispatch, flowActions.update, flow))
                    .then(() => dispatcherWithPromise(dispatch, commonActions.send))
                    .then(() => dispatch(nextAction));
            }
        },
    };
};
