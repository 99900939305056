import * as Yup from 'yup';
import { TFunction } from 'i18next';
import { nameSchema } from '../fieldSchemas/nameSchema';
import { contactDetailsSchema } from '../fieldSchemas/contactDetailsSchema';

export const claimantInformationListSchema = (t: TFunction) => {
    return Yup.object().shape({
        ...nameSchema(t),
        ...contactDetailsSchema(t),
    });
};
