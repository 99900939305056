import React, { ChangeEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { WizardRouterAction, wizardRouterActions as wizardActions } from 'sagas/wizardRouter';
import { useI18n } from '../../../hooks/useI18n';
import { useGoBack } from '../../../hooks/useGoBack';
import { PhraseKeys } from '../../../config/phraseKeys';
import { getGeoLocation } from '../../../utils/maps/geoLocation';
import { DEFAULT_MAP_LOCATION, MAP_KEY } from '../../../config/maps';
import { Map } from '../../../components/map/Map';
import { Marker } from '../../../components/map/Marker';
import { LoadMapScript } from '../../../components/map/LoadMapScript';
import {
    FormChangeable,
    Grid,
    HiddenInputSubmit,
    InsuredByProtectorKeys,
    LocationModel,
    MotorClaimCauseKeys,
    MuiTextInput,
    Nullable,
    OptionType,
    PageLayout,
    SingleCheckbox,
    YesNoKeys,
} from '@protectorinsurance/ds-can';
import { GeoCoder, getGeocoderAddressByLocation, getGeocoderLocationByPlaceId } from '../../../components/map/GeoCoder';
import { motorActions } from '../../../sagas/motor';
import { InfoWindow } from '../../../components/map/InfoWindow';
import { AutocompleteService } from '../../../components/map/AutocompleteService';
import {
    selectAccidentLocation,
    selectClaimCause,
    selectIsDriving,
    selectIsOtherVehicleInvolved,
    selectIsVehicleRecovered,
    selectIsVehicleStolen,
} from '../../../sagas/selectors/motorSelectors';
import { Loader } from '../../../components/loader/Loader';
import { FormFieldNames } from '../../../config/formFieldNames';
import { MotorRoutePaths } from '../../../config/wizardRouter/motorWizardRoutes';
import { Controller, useForm } from 'react-hook-form';
import { accidentLocationSchema } from '../../../validations/schemas/accidentLocationSchema';
import dispatcherWithPromise from '../../../utils/dispatcherWithPromise';
import { useVehicles } from '../../../hooks/useVehicles';
import { yupResolver } from '@hookform/resolvers/yup';
import { commonActions } from '../../../sagas/common';
import { selectCustomCAN } from '../../../sagas/selectors/commonSelectors';

/**
 * Destructure necessary imports
 */
const {
    ADDRESS_LABEL,
    ADDRESS_PLACEHOLDER,
    BACK_BUTTON,
    CONTINUE_BUTTON,
    HELP_TEXT,
    IS_UNKNOWN_LOCATION_LABEL,
    NO_OPTIONS_MESSAGE,
    NOTE_LABEL,
    PAGE_NAME,
    SUB_TITLE,
    TITLE,
} = PhraseKeys;
const { ADDRESS, IS_UNKNOWN_LOCATION, NOTE } = FormFieldNames;
const { NO, YES } = YesNoKeys;
const { DYN_CAROUSEL_END_IS_POLICE_CONTACTED, END_ACCIDENT_DESCRIPTION, END_DAMAGE_OVERVIEW } = MotorRoutePaths;
const { COMPARTMENT_DAMAGE, ENGINE, FIRE, LOST_KEY, NATURAL_DISASTER, OTHER, PARKING, THEFT_AND_DAMAGE, WRONG_FUEL } =
    MotorClaimCauseKeys;
const { NO: NOT_INSURED } = InsuredByProtectorKeys;

/**
 * Page view and page logic
 */
export const AccidentLocationPage = () => {
    const dispatch = useDispatch();
    const { t } = useI18n();
    const tWithNs = useI18n('motor.end.location');
    const location = useSelector(selectAccidentLocation);
    const customCAN = useSelector(selectCustomCAN);
    const [, setMapScript] = useState<HTMLScriptElement | null>(null);
    const [mapIsLoading, setMapIsLoading] = useState(true);
    const [map, setMap] = useState<google.maps.Map | null>(null);
    const [marker, setMarker] = useState<google.maps.Marker | null>(null);
    const [lat] = useState<number>(0);
    const [lng] = useState<number>(0);
    const [geocoder, setGeocoder] = useState<google.maps.Geocoder | null>(null);
    const [markerAddress, setMarkerAddress] = useState<google.maps.GeocoderResult[]>([]);
    const [options, setOptions] = useState<OptionType[]>([]);
    const [selectedOption, setSelectedOption] = useState<OptionType | null>(null);
    const [infoWindow, setInfoWindow] = useState<google.maps.InfoWindow | null>(null);
    const isVehicleStolen = useSelector(selectIsVehicleStolen);
    const isVehicleRecovered = useSelector(selectIsVehicleRecovered);
    const isDriving = useSelector(selectIsDriving);
    const isOtherVehicleInvolved = useSelector(selectIsOtherVehicleInvolved);
    const { counterpartyVehicle } = useVehicles();
    const claimCause = useSelector(selectClaimCause);
    const {
        control,
        formState: { errors },
        handleSubmit,
        register,
        setValue,
        trigger,
    } = useForm<LocationModel>({
        resolver: yupResolver(accidentLocationSchema(t)),
        defaultValues: {
            address: location.address,
        },
    });

    useEffect(() => {
        const temp = markerAddress.map((r: google.maps.GeocoderResult) => {
            return { label: r.formatted_address, value: r.place_id };
        });
        setOptions(() => [...temp]);
    }, [markerAddress, setOptions]);

    useEffect(() => {
        async function getLocation() {
            try {
                const {
                    position: {
                        coords: { longitude, latitude },
                    },
                } = await getGeoLocation();
                positionMap({ lat: latitude, lng: latitude }, 10);
                dispatch(motorActions.update({ accidentLocation: { ...location, latitude, longitude, note: null } })); //should this be set here?
            } catch (e) {
                positionMap(
                    { lat: DEFAULT_MAP_LOCATION.lat, lng: DEFAULT_MAP_LOCATION.lng },
                    DEFAULT_MAP_LOCATION.zoom
                );
            }
        }

        if (location && location.longitude && location.latitude) {
            if (location && location.note) {
                setSelectedOption({ label: location.note, value: '' });
                setValue('note', location.note, { shouldValidate: true });
            }
            positionMap({ lat: location.latitude, lng: location.longitude }, 15);
            marker?.setMap(map);
        } else if (location && location.isUnknownLocation) {
            marker?.setMap(null);
        } else {
            getLocation();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [map, marker, location, dispatch]);

    useEffect(() => {
        register(NOTE);
        register(IS_UNKNOWN_LOCATION);
    }, [register]);

    useEffect(() => {
        setValue('isUnknownLocation', false, { shouldValidate: true });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleBackButton = useGoBack();

    const handleChecked = (e: ChangeEvent<HTMLInputElement>) => {
        const { checked } = e.target;
        setValue('isUnknownLocation', checked);
        setSelectedOption(null);
        setValue('note', null);
        setValue('address', null);
        dispatch(
            motorActions.update({
                accidentLocation: {
                    address: null,
                    isUnknownLocation: checked,
                    latitude: null,
                    longitude: null,
                    note: null,
                },
            })
        );
    };

    const positionMap = ({ lat, lng }: { lat: number; lng: number }, zoom: number) => {
        if (map && marker) {
            const latLng = new google.maps.LatLng(lat, lng);
            map.setCenter(latLng);
            map.setZoom(zoom);
            marker.setPosition(latLng);
        }
    };

    const onMarkerDrop = async (e: google.maps.MouseEvent) => {
        if (geocoder && map) {
            const res = await getGeocoderAddressByLocation(geocoder, e.latLng);
            setMarkerAddress(() => [...res]);
            setSelectedOption(null);
            await setValue('note', null);
            dispatch(
                motorActions.update({
                    accidentLocation: { ...location, latitude: e.latLng.lat(), longitude: e.latLng.lng(), note: null },
                })
            );
        }
    };

    const onSelectedPlace = async (selected: Nullable<OptionType>) => {
        if (geocoder && map && selected && marker) {
            try {
                await setValue('note', selected.label, { shouldValidate: true });
                const res = await getGeocoderLocationByPlaceId(geocoder, selected.value);
                const loc = res[0].geometry.location;
                positionMap({ lat: loc.lat(), lng: loc.lng() }, 16);
                dispatch(
                    motorActions.update({
                        accidentLocation: {
                            ...location,
                            latitude: loc.lat(),
                            longitude: loc.lng(),
                            note: null,
                        },
                    })
                );
            } catch (e) {
                //do nothing. There is already a no options message.
            }
        }
    };

    const handleMapClick = async (e: google.maps.MouseEvent) => {
        dispatch(
            motorActions.update({
                accidentLocation: { ...location, latitude: e.latLng.lat(), longitude: e.latLng.lng(), note: null },
            })
        );

        const geocode = new google.maps.Geocoder();
        const res = await getGeocoderAddressByLocation(geocode, e.latLng);
        setMarkerAddress(() => [...res]);
        setSelectedOption(null);
        await setValue('note', null);
    };

    const handleBlur = async (e: FormChangeable) => {
        e.preventDefault();
        const { id } = e.currentTarget;
        await trigger(id);
    };

    const handleChange = async (e: FormChangeable) => {
        e.preventDefault();
        const { id, value } = e.currentTarget;
        await setValue(id, value, { shouldValidate: true });
    };

    const onSubmit = async (values: LocationModel) => {
        const { address, note } = values;
        let nextAction: WizardRouterAction<string | undefined> = wizardActions.goToNext();

        if ((isDriving && isOtherVehicleInvolved) || counterpartyVehicle?.insuredByProtector === NOT_INSURED) {
            nextAction = wizardActions.goTo(END_ACCIDENT_DESCRIPTION);
        } else if (isVehicleStolen === YES && isVehicleRecovered === NO) {
            nextAction = wizardActions.goTo(DYN_CAROUSEL_END_IS_POLICE_CONTACTED);
        } else if (
            claimCause === THEFT_AND_DAMAGE ||
            claimCause === ENGINE ||
            claimCause === PARKING ||
            claimCause === FIRE ||
            claimCause === COMPARTMENT_DAMAGE ||
            claimCause === WRONG_FUEL ||
            claimCause === LOST_KEY ||
            (claimCause === OTHER && isDriving === NO)
        ) {
            nextAction = wizardActions.goTo(END_ACCIDENT_DESCRIPTION);
        } else if (claimCause === NATURAL_DISASTER) {
            nextAction = wizardActions.goTo(END_DAMAGE_OVERVIEW);
        }

        dispatcherWithPromise(dispatch, motorActions.update, {
            accidentLocation: { ...location, note, address },
        })
            .then(() => dispatcherWithPromise(dispatch, commonActions.send))
            .then(() => dispatch(nextAction));
    };

    return (
        <PageLayout
            backBtnText={t(BACK_BUTTON)}
            continueBtnText={t(CONTINUE_BUTTON)}
            domainTitle={t(PAGE_NAME)}
            footerText={tWithNs.t(HELP_TEXT)}
            handleContinueButton={handleSubmit(onSubmit)}
            headerSubTitle={tWithNs.t(SUB_TITLE)}
            headerTitle={tWithNs.t(TITLE)}
            {...{ handleBackButton }}
        >
            <LoadMapScript
                apiKey={MAP_KEY}
                isLoading={mapIsLoading}
                setMapScript={setMapScript}
                loader={<Loader size={'normal'} inline={false} placement={'center'} fixed={false} />}
                setIsLoading={setMapIsLoading}
            >
                <GeoCoder setGeocoder={setGeocoder} />
                <form onSubmit={handleSubmit(onSubmit)}>
                    <HiddenInputSubmit />
                    <Grid className={'align-center'}>
                        <AutocompleteService
                            autoCompleteLabel={t(NOTE_LABEL)}
                            disabled={location.isUnknownLocation}
                            error={errors.note?.message}
                            inputWrapperClass={'col-6 margin-bottom'}
                            name={NOTE}
                            noOptionsText={t(NO_OPTIONS_MESSAGE)}
                            onSelectedPlace={onSelectedPlace}
                            {...{ customCAN, options, selectedOption, setOptions, setSelectedOption }}
                        />
                        <SingleCheckbox
                            checked={location.isUnknownLocation}
                            handleChange={handleChecked}
                            name={IS_UNKNOWN_LOCATION}
                            wrapperClass={'col-6 justify-content-center'}
                            {...{ customCAN }}
                        >
                            {t(IS_UNKNOWN_LOCATION_LABEL)}
                        </SingleCheckbox>
                        <Controller
                            control={control}
                            name={ADDRESS}
                            render={({ field: { ref, ...field } }) => (
                                <MuiTextInput
                                    {...field}
                                    disabled={location.isUnknownLocation}
                                    error={!!errors.address}
                                    errorMessage={errors.address?.message}
                                    id={ADDRESS}
                                    inputFieldWrapper={'col-6'}
                                    label={t(ADDRESS_LABEL)}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    placeholder={t(ADDRESS_PLACEHOLDER)}
                                    reference={ref}
                                    {...{ customCAN }}
                                />
                            )}
                        />
                    </Grid>
                </form>
                <Map
                    className={'col-12 center padding-top'}
                    label={tWithNs.t('map.label')}
                    onClick={handleMapClick}
                    setMap={setMap}
                />
                <Marker
                    {...{ lng, lat, map, marker, setMarker, infoWindow }}
                    infoWindowLabel={tWithNs.t('infoWindow.label.location')}
                    infoWindowLatText={tWithNs.t('infoWindow.text.lat')}
                    infoWindowLngText={tWithNs.t('infoWindow.text.lng')}
                    onMarkerDrop={onMarkerDrop}
                />
                <InfoWindow setInfoWindow={setInfoWindow} />
            </LoadMapScript>
        </PageLayout>
    );
};
