import React from 'react';
import { Changable, SingleCheckbox, trueFalseToYesNo } from '@protectorinsurance/ds-can';
import { LpoSummaryProps } from '../../../../../types/SummaryProps';
import { lpoActions } from '../../../../../sagas/lpo';
import { useDispatch } from 'react-redux';
import { renderPrivacy } from '../start/RenderPrivacy';
import { TEST_ID_PREFIX } from '../../../../../utils/testIdUtil';
import { LpoRoutePaths } from '../../../../../config/wizardRouter/lpoWizardRoutes';

/**
 * Destructure necessary imports
 */
const { END_FINAL_SUMMARY } = LpoRoutePaths;

/**
 * Component view and component logic
 */
export const DisplayContactPolice = ({
    customCAN,
    model,
    t,
    tWithNS,
    printSummary,
    handleClick,
    upload,
    common,
}: LpoSummaryProps) => {
    const dispatch = useDispatch();
    const { acceptedPoliceContact } = model;
    const { lob } = common;

    const handleCheckbox = (e: Changable) => {
        dispatch(lpoActions.update({ acceptedPoliceContact: e.target.checked }));
    };

    if (printSummary) {
        return (
            <>
                <SingleCheckbox
                    name={'acceptedPoliceContact'}
                    checked={acceptedPoliceContact}
                    handleChange={handleCheckbox}
                    wrapperClass={'col-12 divider'}
                    {...{ customCAN }}
                >
                    {tWithNS('policeContact.text')}
                </SingleCheckbox>
            </>
        );
    } else {
        return renderPrivacy(
            {
                inputWrapperClass: 'col-12 divider text-align-left',
                textKey: tWithNS('policeContact.heading.title'),
                dataTestId: TEST_ID_PREFIX.acceptedPoliceContact,
                buttonText: t(trueFalseToYesNo(acceptedPoliceContact)),
                path: END_FINAL_SUMMARY,
                handleClick,
            },
            lob
        );
    }
};
