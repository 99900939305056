import * as Yup from 'yup';
import { PhraseKeys } from '../../config/phraseKeys';
import { TFunction } from 'i18next';

/**
 * Destructure necessary imports
 */
const { RELATION_TO_WITNESS_LABEL } = PhraseKeys;

/**
 * Validation logic
 */
export const relationToWitnessSchema = (t: TFunction) => {
    return {
        relationToWitness: Yup.string().label(t(RELATION_TO_WITNESS_LABEL)).ensure().required().nullable(),
    };
};
